import { Box, Grid, makeStyles, useTheme } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import { connect } from 'react-redux';
import * as actionCreator from '../../../store/action';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ContainerTitle from '../../../components/common/title/ContainerTitle';
import KidsProfilePic from '../../../components/profilePic/KidsProfilePic';
import { capitalFirstLetter } from '../../../utils/stringMethods';

const useStyle = makeStyles(theme => ({
  container: {
    padding: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  innerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    margin: '1rem 0 0 0rem ',
  },
  childContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  profilePicContainer: {
    margin: '0rem 0 0 0rem ',
    borderRadius: 12,
    backgroundColor: '#fff',
  },
  profilePicBackground: {
    // border: " 0.1px solid #555",
    // borderRadius: "7px",
    margin: '0rem 0.5rem 0.5rem 0.5rem',
  },
  profilePic: {
    fontSize: '2.8rem',
  },
  relation: {
    fontSize: '0.9rem',
    color: '#355151',
  },
  name: {
    fontSize: '0.7rem',
  },
}));
const KidProfileContainer = props => {
  const { kidData, profile } = props;
  const theme = useTheme();
  const [relation, setRelation] = React.useState('');
  const classes = useStyle(theme);

  React.useEffect(() => {
    kidData?.family?.map(relative => {
      if (relative?._id == profile?._id && relative?.yourRelation) {
        setRelation(relative?.yourRelation);
      }
    });
  }, [kidData]);
  return (
    <Grid style={{ backgroundColor: '#C9E5EC', borderRadius: '5px' }}>
      <Box className={clsx(classes.container)}>
        {kidData?.profile_pic ? (
          <Grid
            container
            style={{
              paddingTop: '0.4rem',
            }}>
            <Grid item>
              <Box className={clsx(classes.profilePicBackground)}>
                <KidsProfilePic
                  style={{ borderRadius: '5px' }}
                  pic={`${process.env.REACT_APP_SPACE_KEY}/${kidData?.profile_pic}`}
                />
              </Box>
            </Grid>
            <Grid item xs={9}>
              <Box>
                <ContainerTitle
                  title={capitalFirstLetter(relation)}
                  className={clsx(classes.relation)}
                />
              </Box>
              <Box>
                <ContainerTitle
                  title={`${kidData?.firstName} ${kidData?.lastName}`}
                  className={clsx(classes.name)}
                />
              </Box>
            </Grid>
          </Grid>
        ) : (
          <Grid>
            <Box className={clsx(classes.profilePicContainer)}>
              <AccountCircleIcon className={clsx(classes.profilePic)} />
            </Box>
          </Grid>
        )}
      </Box>
    </Grid>
  );
};

const mapStateToProps = ({ userReducer }) => {
  return {
    profile: userReducer.profile,
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(KidProfileContainer);
