import {
  Box,
  makeStyles,
  useTheme,
  Tooltip,
  Button,
  ButtonBase,
} from '@material-ui/core';
import CogitoLogo from '../common/Logo/CogitoLogo';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { constant } from '../../utils/constant';
import { connect } from 'react-redux';
import * as actionCreator from '../../store/action';
import BaseButton from '../buttons/BaseButton';
import { Link, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import CloseIcon from '@material-ui/icons/Close';
import Kids from '../../../src/assets/images/sidebar/Kids.svg';
import Gift from '../../../src/assets/images/sidebar/Gift.svg';
import Calendar from '../../../src/assets/images/sidebar/Calender.svg';
import Home from '../../../src/assets/images/sidebar/Dashboard.svg';
import Logout from '../../../src/assets/images/sidebar/Logout.svg';
import GiftK from '../../../src/assets/images/sidebar/GiftK.svg';
import GuardianK from '../../../src/assets/images/sidebar/GuardianK.svg';
import CalendarK from '../../../src/assets/images/sidebar/CalenderK.svg';
import HomeK from '../../../src/assets/images/sidebar/DashboardK.svg';
import selectedHome from '../../../src/assets/images/sidebar/DashboardSelected.svg';
import LogoutK from '../../../src/assets/images/sidebar/LogoutK.svg';
import selectedCalender from '../../../src/assets/images/sidebar/CalenderSelected.svg';
import selectedProfile from '../../../src/assets/images/sidebar/GuardianSelected.svg';
import selectedRewards from '../../../src/assets/images/sidebar/giftSelected.svg';
import { DeleteOutline } from '@material-ui/icons';
import { useState } from 'react';
import CommonModel from '../common/model/CommonModel';
import DeleteProfileModal from './DeleteProfileModal/DeleteProfileModal';

const useStyles = ({ hamburger }) =>
  makeStyles(theme => ({
    sideBarStyle: {
      position: 'fixed',
      top: 0,
      left: 0,
      width: '4rem',
      height: '100vh',
      backgroundColor: '#fff',
      borderTopRightRadius: 12,
      borderBottomRightRadius: 12,
      zIndex: 2,
      transition: '0.4s',
      [theme.breakpoints.up(constant.breakLaptopAuthPoint)]: {
        width: '5.5rem',
      },
      [theme.breakpoints.down(constant.breakMobileAuthPoint)]: {
        left: hamburger ? 0 : -100,
      },
    },
    sideBarContainer: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-between',
      height: '85vh',
    },
    cogitoLogoStyle: {
      width: '2rem',
      [theme.breakpoints.up(constant.breakLaptopAuthPoint)]: {
        width: '3rem',
      },
      [theme.breakpoints.down(constant.breakMobileAuthPoint)]: {
        display: 'none',
      },
    },
    closeHamburgerIcon: {
      fontSize: '2rem',
      color: '#359DB6',
      display: 'none',
      [theme.breakpoints.down(constant.breakMobileAuthPoint)]: {
        display: 'unset',
      },
    },
    sidebarSubContainer: {
      margin: '1rem auto',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-between',
      [theme.breakpoints.up(constant.breakLaptopAuthPoint)]: {
        margin: '1.6rem auto',
      },
    },
    menuIconContainer: {
      margin: '1rem auto',
      [theme.breakpoints.up(constant.breakLaptopAuthPoint)]: {
        margin: '0.9rem auto',
      },
      padding: '4px 2px 1.5px 2px',
      '&:hover': {
        borderRadius: 10,
        background: theme.customTheme.sidebar,
      },
    },
    menuIconStyle: {
      cursor: 'pointer',
      width: 38,
      padding: 4,
      height: 38,
      border: 'none',
      borderRadius: 10,
      background: 'transparent',
      '&:hover': {
        webkitBoxShadow: '2px 4px 19px 5px rgba(23,214,19,1)',
        mozBoxShadow: '2px 4px 19px 5px rgba(23,214,19,1)',
      },
    },
    selectedMenuStyle: {
      cursor: 'pointer',
      width: 38,
      height: 38,
      padding: 4,
      border: 'none',
      borderRadius: 10,
      justifyContent: 'center',
      alignItems: 'center',
      color: 'rgba(255, 255, 255, 0.6)',
      // background: 'linear-gradient(154.4deg, #2C94AC 15.73%, #0E6D85 85.25%)',
      background: theme.customTheme.sidebar,
      boxShadow: '0px 4px 6px -2px rgba(83 173 170)',
    },
    menuIconStyleK: {
      cursor: 'pointer',
      width: 38,
      padding: 4,
      height: 38,
      border: 'none',
      borderRadius: 10,
      background: 'transparent',
      '&:hover': {
        webkitBoxShadow: '2px 4px 19px 5px rgba(23,214,19,1)',
        mozBoxShadow: '2px 4px 19px 5px rgba(23,214,19,1)',
      },
    },
    selectedMenuStyleK: {
      cursor: 'pointer',
      width: 38,
      height: 38,
      padding: 4,
      border: 'none',
      borderRadius: 10,
      justifyContent: 'center',
      alignItems: 'center',
      color: 'rgba(255, 255, 255, 0.6)',
      // background: 'linear-gradient(154.4deg, #2C94AC 15.73%, #0E6D85 85.25%)',
      background: theme.customTheme.sidebar,
      boxShadow: theme.customTheme.sidebarBoxShadow,
    },
  }));
const Sidebar = props => {
  const {
    hamburger,
    handleHanburger,
    logoutUser,
    profile,
    isParentLoggedIn,
    isChildrenLoggedIn,
  } = props;
  const param = useLocation();
  const currentRoute = param.pathname;
  const theme = useTheme();
  const classes = useStyles({ hamburger })(theme);
  const [deletProfile, setDeleteProfile] = useState(false);
  const logout = () => {
    logoutUser();
    localStorage.removeItem(constant.token);
  };

  const deletProfilehandler = () => {
    setDeleteProfile(!deletProfile);
  };
  return (
    <>
      <CommonModel
        title="Delete Account"
        open={deletProfile}
        handleClose={deletProfilehandler}
        body={
          <DeleteProfileModal
            open={deletProfile}
            handleClose={deletProfilehandler}
          />
        }
      />
      <Box className={classes.sideBarStyle}>
        <Box className={classes.sideBarContainer}>
          <Box className={classes.sidebarSubContainer}>
            <Link to="/">
              <CogitoLogo className={classes.cogitoLogoStyle} />
            </Link>
            <BaseButton
              text={<CloseIcon className={classes.closeHamburgerIcon} />}
              onClick={handleHanburger}
              id="sideBar-menu"
            />
          </Box>
          <Box className={classes.sidebarSubContainer}>
            <Box className={classes.menuIconContainer} id="menuBar-home-icon">
              <Link to={constant.routes.dashboard}>
                <Tooltip title="Home">
                  {/* <HomeIcon */}
                  {profile?.isMinor ? (
                    <img
                      src={
                        currentRoute === constant.routes.dashboard
                          ? selectedHome
                          : HomeK
                      }
                      onClick={handleHanburger}
                      className={clsx(
                        classes.menuIconStyleK,
                        currentRoute === constant.routes.dashboard
                          ? classes.selectedMenuStyleK
                          : '',
                      )}
                    />
                  ) : (
                    <img
                      src={Home}
                      onClick={handleHanburger}
                      style={{ width: 40, height: 40, padding: 0 }}
                      className={clsx(
                        classes.menuIconStyle,
                        currentRoute === constant.routes.dashboard
                          ? classes.selectedMenuStyle
                          : '',
                      )}
                    />
                  )}
                  {/* <img
                  src={Home}
                  className={clsx(
                    classes.menuIconStyle,
                    currentRoute === constant.routes.dashboard
                      ? classes.selectedMenuStyle
                      : '',
                  )} */}
                </Tooltip>
              </Link>
            </Box>
            <Box
              className={classes.menuIconContainer}
              id="menuBar-calendar-icon">
              <Link to={constant.routes.calendar}>
                <Tooltip title="Calendar">
                  {/* <DateRangeIcon */}
                  {profile?.isMinor ? (
                    <img
                      src={
                        currentRoute === constant.routes.calendar
                          ? selectedCalender
                          : CalendarK
                      }
                      onClick={handleHanburger}
                      className={clsx(
                        classes.menuIconStyleK,
                        currentRoute === constant.routes.calendar
                          ? classes.selectedMenuStyleK
                          : '',
                      )}
                    />
                  ) : (
                    <img
                      src={Calendar}
                      onClick={handleHanburger}
                      className={clsx(
                        classes.menuIconStyle,
                        currentRoute === constant.routes.calendar
                          ? classes.selectedMenuStyle
                          : '',
                      )}
                    />
                  )}
                  {/* <img
                  src={Calendar}
                  className={clsx(
                    classes.menuIconStyle,
                    currentRoute === constant.routes.calendar
                      ? classes.selectedMenuStyle
                      : '',
                  )}
                /> */}
                </Tooltip>
              </Link>
            </Box>
            <Box className={classes.menuIconContainer} id="menuBar-ProfilePage">
              <Link
                to={
                  profile?.isMinor
                    ? constant.routes.guardianProfile
                    : constant.routes.kidsProfile
                }>
                <Tooltip
                  title={
                    profile?.isMinor ? 'Parents Profile' : 'Children Profile'
                  }>
                  {/* <PersonOutlineOutlined */}
                  {profile?.isMinor ? (
                    <img
                      src={
                        currentRoute === constant.routes.guardianProfile
                          ? selectedProfile
                          : GuardianK
                      }
                      onClick={handleHanburger}
                      className={clsx(
                        classes.menuIconStyleK,
                        currentRoute ===
                          (profile?.isMinor
                            ? constant.routes.guardianProfile
                            : constant.routes.kidsProfile)
                          ? classes.selectedMenuStyleK
                          : '',
                      )}
                    />
                  ) : (
                    <img
                      src={Kids}
                      style={{ width: 45, height: 45, padding: 0 }}
                      onClick={handleHanburger}
                      className={clsx(
                        classes.menuIconStyle,
                        currentRoute ===
                          (profile?.isMinor
                            ? constant.routes.guardianProfile
                            : constant.routes.kidsProfile)
                          ? classes.selectedMenuStyle
                          : '',
                      )}
                    />
                  )}
                </Tooltip>
              </Link>
            </Box>
            <Box className={classes.menuIconContainer} id="menuBar-Rewards">
              <Link to={constant.routes.rewards}>
                <Tooltip title={'Rewards'}>
                  {/* <RedeemIcon */}
                  {profile?.isMinor ? (
                    <img
                      src={
                        currentRoute === constant.routes.rewards
                          ? selectedRewards
                          : GiftK
                      }
                      onClick={handleHanburger}
                      className={clsx(
                        classes.menuIconStyleK,
                        currentRoute === constant.routes.rewards
                          ? classes.selectedMenuStyleK
                          : '',
                      )}
                    />
                  ) : (
                    <img
                      src={Gift}
                      onClick={handleHanburger}
                      className={clsx(
                        classes.menuIconStyle,
                        currentRoute === constant.routes.rewards
                          ? classes.selectedMenuStyle
                          : '',
                      )}
                    />
                  )}
                  {/* <img
                  src={Gift}
                  className={clsx(
                    classes.menuIconStyle,
                    currentRoute === constant.routes.rewards
                      ? classes.selectedMenuStyle
                      : '',
                  )}
                /> */}
                </Tooltip>
              </Link>
            </Box>
          </Box>
          <Box className={classes.sidebarSubContainer}>
            <Box className={classes.menuIconContainer} id="logout">
              <Tooltip title={'Logout'}>
                {/* <BaseButton */}
                {profile?.isMinor ? (
                  <img
                    src={LogoutK}
                    className={clsx(classes.menuIconStyleK)}
                    onClick={logout}
                    text={<ExitToAppIcon className={classes.menuIconStyle} />}
                  />
                ) : (
                  <img
                    src={Logout}
                    className={clsx(classes.menuIconStyle)}
                    onClick={logout}
                    text={<ExitToAppIcon className={classes.menuIconStyle} />}
                  />
                )}
                {/* <img
                src={Logout}
                className={clsx(classes.menuIconStyle)}
                onClick={logout}
                text={<ExitToAppIcon className={classes.menuIconStyle} />}
              /> */}
              </Tooltip>
            </Box>
            <Tooltip title={'Delete Profile'}>
              <Button
                className={classes.menuIconContainer}
                id="delete-profile"
                onClick={deletProfilehandler}>
                {!profile?.isMinor ? (
                  <DeleteOutline
                    style={{ fill: '#086880', fontSize: '2rem' }}
                  />
                ) : (
                  <DeleteOutline
                    style={{ fill: '#999999', fontSize: '2rem' }}
                  />
                )}
                {/* <img
                src={Logout}
                className={clsx(classes.menuIconStyle)}
                onClick={logout}
                text={<ExitToAppIcon className={classes.menuIconStyle} />}
              /> */}
              </Button>
            </Tooltip>
          </Box>
        </Box>
      </Box>
    </>
  );
};
const mapStateToProps = ({ userReducer, authReducer }) => {
  return {
    profile: userReducer.profile,
    isParentLoggedIn: authReducer.isParentLoggedIn,
    isChildrenLoggedIn: authReducer.isChildrenLoggedIn,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    logoutUser: () => dispatch(actionCreator.logoutUser()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
