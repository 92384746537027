import { makeStyles, useTheme } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  image1Style: {
    width: '100vw',
    right: 0,
    bottom: 0,
    position: 'fixed',
    [theme.breakpoints.down(600)]: {
      width: '150vw',
    },
    [theme.breakpoints.down(450)]: {
      width: '200vw',
    },
  },
  image2Style: {
    width: '100vw',
    right: -20,
    bottom: -10,
    position: 'fixed',
    [theme.breakpoints.between('xs', 'sm')]: {
      // height: '80vh',
      width: '100vw',
      right: -0,
      bottom: 0,
    },
  },
}));
const PageBackgroundTheme3 = () => {
  const theme = useTheme();
  const classes = useStyles(theme);
  return (
    <>
      <img
        src={theme?.customTheme?.pageBackgroundImage1}
        className={clsx(classes.image2Style)}
      />
    </>
  );
};

export default PageBackgroundTheme3;
