import { Switch, Route, Redirect } from 'react-router-dom';

const Router = props => {
  const { routes } = props;
  return (
    <Switch>
      {routes?.routes?.map(route => {
        return (
          <Route
            path={route.path}
            exact={!!route.isExact}
            component={route.component}
            key={route.path}></Route>
        );
      })}
      {routes?.redirect && <Redirect to={routes.redirect} />}
    </Switch>
  );
};
export default Router;
