import { constant } from '../utils/constant';
import Age11Image from '../assets/images/themeImages/Age1.1.png';
import Age12Image from '../assets/images/themeImages/Age1.2.png';
import { grey } from '@material-ui/core/colors';

export const ageGroupOneTheme = {
  isChild: true,
  isChild1: true,
  pageBackgroundColor: '#E5F3FF',
  headerBackgroundColor: '#87BBEC',
  navBarIconColorSelected: '#FFAF00',
  navBarIconColorUnSelected: '#999999',
  pageBackgroundImage1: Age11Image,
  pageBackgroundImage2: Age12Image,
  nameTextColorAtDashboard: '#9A76B8',
  nameSubTextColorAtDashboard: '#9A76B8',

  //ProfilePage

  profilePageHeaderColor: '#4B7DBE',
  profilePageTableHeaderColor: '#CC8D6A',
  profilePageColouredTableRowColor: '#E4DBEC',
  profilePageTransparentTableRowColor: '#E1E3F6',
  profilePageRowDataEvenColor: '#333333',
  profilePageRowDataOddColor: '#333333',
  inviteButton: '#4B7DBE',

  // Calendar Page

  textColorCalendarCard: '#000',
  backgroundColorCalendar: '#D1E5FF',
  borderColorDate: '#075062',
  borderColorCalendarCard: '#B79DCC',
  calendarCardBackgroundColor: '#E4DBEC',
  textColorCalendarSlider: '#4B7DBE',
  textColorCalendarSliderOnHover: '#fff',
  textColorCalendarTime: 'BLACK',
  statusButton: '#B79DCC',
  // submitButton: '#',

  taskNotComplete: 'Black',

  //Update Modal
  updateTaskModalBackgroundColor: '#E5F3FF',
  updateTaskModalHeaderColor: '#87BBEC',
  commonModalHeaderBackground: '#87BBEC',
  commonModalBackground: '#E5F3FF',
  commonModalButtonColor: '#31598D',
  commonModalCancelButtonBorder: '2px solid #CB8F6E',
  commonModalCancelButtontext: '#CB8F6E',
  commonModalTextStyle: '#FFFFFF',
  commonModalImageContainer: '1px dashed #4B7DBE',
  commonModalImagetext: '#9A76B8',

  rewardTitleTextColor: '#000',
  requestContainerTitleColor: '#333333',
  sidebar: '#87BBEC',
  NoProfileImage: '#E5F3FF',
  HeaderNameColor: '#fff',
  NoTaskText: '#d93a11',
  RequestContainer: '#D1E5FF',
  requestContainertext: '#000',
  rewardCalendarButton: '2px solid #699595',
  rewardCalendar: '#699595',

  sidebarBoxShadow: '0px 4px 6px -2px #31598D',
  requestContainerSelect: grey[600],
  selectFieldBorder: '0.5px solid #000',
  overDuetaskColor: '#E4DBEC',
  statusColor: '#9A76B8',
  paginationOverduetasks: '#B79DCC',
  statusButtonBorder: '1px solid #9A76B8',
  completedStatusColor: '#D1E5FF',
  completedCheckedBoxIcon: '#31598D',
  createdTaskColor: '#E1E3F6',
  createdCheckIcon: '#5E648F',
  paginationAlltasks: '#868BB6',
  completedTasksBackground: '#D1E5FF',
  paginationCompletedTasks: '#4B7DBE',
  approvedStatus: '#4B7DBE',
  cancelIconColor: '#9A76B8',
  acceptIconColor: '#4B7DBE',
  taskContainerTitle: '#000',
  rewardcard: '#868BB6',
  overDueIcon: '#B79DCC',
};
