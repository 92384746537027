import './App.css';
import React, { createRef } from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import { Box, IconButton } from '@material-ui/core';
import { authRoutes } from './routes/AuthRoutes';
import { parentRoutes } from './routes/parentRoutes';
import { childrenRoutes } from './routes/childrentRoutes';
import Router from './routes/Router';
import { globalTheme } from './theme/globalTheme';
import { connect } from 'react-redux';
import * as actionCreator from './store/action';
import Notifier from './components/layouts/Notify';
import { SnackbarProvider } from 'notistack';
import { Close } from '@material-ui/icons';
import Layout from './components/loggedIn/Layout';
import { constant } from './utils/constant';
import moment from 'moment';
import { getAgeFromDob } from './utils/timeMethods';

function App(props) {
  const {
    profile,
    isChildrenLoggedIn,
    isParentLoggedIn,
    isLoggedIn,
    notify,
    logoutUser,
    updateChildData,
  } = props;

  const notistackRef = createRef();
  const onClickDismiss = key => () => {
    notistackRef.current.closeSnackbar(key);
  };

  const checkForAutoLogout = refreshRate => {
    let checkForNext = refreshRate + 60 * 1000;
    if (profile) {
      let expiryTimestamp = JSON.parse(localStorage.getItem('token'));
      if (expiryTimestamp) {
        let date = new Date().getTime() + checkForNext;
        if (date > expiryTimestamp?.expiry && !document.hidden) {
          actionCreator.refreshToken();
        } else if (date > expiryTimestamp?.expiry && document.hidden) {
          logoutUser();
          window.location = profile?.isMinor
            ? constant.routes.childrenLogin
            : constant.routes.parentLogin;
        }
      } else {
        logoutUser();
        window.location = profile?.isMinor
          ? constant.routes.childrenLogin
          : constant.routes.parentLogin;
      }
    }
  };
  React.useEffect(() => {
    if (profile && profile.dateOfBirth) {
      let age = getAgeFromDob(profile.dateOfBirth);
      if (age <= 16 && !profile.isMinor) {
        updateChildData({ changes: { isMinor: true } }, logoutUser);
      } else if (age > 16 && profile.isMinor) {
        updateChildData({ changes: { isMinor: false } }, logoutUser);
      }
    }
  }, [profile]);

  React.useEffect(() => {
    let refreshRate = 3 * 60 * 1000;
    try {
      if (isLoggedIn) {
        checkForAutoLogout(refreshRate);
        const interval1 = setInterval(function () {
          checkForAutoLogout(refreshRate);
        }, refreshRate);
        return () => {
          clearInterval(interval1);
        };
        // 360000 = 6 min, 300000 = 5 min, 720000 = 12 min, 240000 = 4 min
      }
    } catch (err) {
      console.log('JSON.parse Error: ', err);
    }
  }, [isLoggedIn, profile]);

  React.useEffect(() => {
    isLoggedIn();
  }, []);

  return (
    <ThemeProvider
      theme={globalTheme({
        profile,
      })}>
      <SnackbarProvider
        ref={notistackRef}
        action={key => (
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={onClickDismiss(key)}>
            <Close fontSize="small" />
          </IconButton>
        )}>
        <Notifier />
        <Box>
          {profile !== null ? (
            profile ? (
              <Layout>
                <>
                  {profile?.isMinor ? (
                    <Router routes={childrenRoutes} />
                  ) : (
                    <Router routes={parentRoutes} />
                  )}
                </>
              </Layout>
            ) : (
              <Router routes={authRoutes} />
            )
          ) : null}
        </Box>
      </SnackbarProvider>
    </ThemeProvider>
  );
}

const mapStateToProps = ({ userReducer, authReducer }) => {
  return {
    profile: userReducer.profile,
    isParentLoggedIn: authReducer.isParentLoggedIn,
    isChildrenLoggedIn: authReducer.isChildrenLoggedIn,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    isLoggedIn: () => dispatch(actionCreator.isLogInAsync()),
    notify: () => dispatch(actionCreator.notify()),
    logoutUser: () => dispatch(actionCreator.logoutUser()),
    updateChildData: (data, callBack) =>
      actionCreator.updateChildData(data, callBack),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(App);
