import {
  Box,
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  OutlinedInput,
  Select,
  createTheme,
  useTheme,
  ThemeProvider,
  FormHelperText,
} from '@material-ui/core';
import clsx from 'clsx';
import ContainerTitle from '../common/title/ContainerTitle';

const useStyle = makeStyles(theme => ({
  errorStyle: {
    color: 'red',
    fontSize: '0.9rem',
    fontWeight: 500,
    position: 'absolute',
    bottom: -20,
    color: theme.customTheme.requestContainertext,
  },
  FormControlCss: {
    minWidth: '100%',
    width: '150%',
    [theme.breakpoints.down('sm')]: {
      width: '110%',
    },
  },
  ChildRequest: {
    minWidth: 90,
    marginLeft: '8%',
    [theme.breakpoints.down('sm')]: {
      marginLeft: '0%',
      marginTop: '3%',
      minWidth: 50,
      outerHeight: 40,
    },
  },
  labelText: {
    fontSize: '13px',
    left: 10,
    color: theme.customTheme.requestContainertext,
    [theme.breakpoints.down('sm')]: {
      fontSize: '10px',
    },
  },
  infoText: {
    color: '#fff',
    fontSize: '0.6rem',
    marginLeft: '3%',
    // position: 'absolute',
    color: theme.customTheme.requestContainertext,

    [theme.breakpoints.down('sm')]: {
      fontSize: '0.4rem',
      marginBottom: '1rem',
    },
  },
}));
// const OutlinedInputStyles = makeStyles(theme => ({
//   root: {

//   },
// }));

const SelectField = props => {
  const theme = useTheme();
  const classes = useStyle(theme);
  // const outlinedInputStyles = OutlinedInputStyles(theme);
  const {
    placeholder,
    variant,
    size,
    className,
    value,
    onChange,
    type,
    errorMessage,
    field,
    required,
    inputProps,
    helperText,
    options,
    containerClassName,
    name,
  } = props;
  const fieldProps = {
    placeholder,
    variant,
    size,
    value,
    type,
    inputProps,
    required,
    className,
  };
  const themee = createTheme({
    palette: {
      primary: {
        main: theme.customTheme.requestContainerSelect,
        //     action: {
        //       // active: theme.customTheme.requestContainertext,
        //     },
      },
      // overrrides: {},
    },
  });

  const MenuProps = {
    PaperProps: {
      style: {
        marginTop: '55px',
        border: theme.customTheme.selectFieldBorder,
      },
    },
  };

  return (
    <>
      <Box className={classes.ChildRequest}>
        <ThemeProvider theme={themee}>
          <FormControl className={classes.FormControlCss} required>
            <InputLabel className={classes.labelText}>{name}</InputLabel>
            <Select
              input={<OutlinedInput label={name} />}
              fullWidth
              {...fieldProps}
              onChange={onChange}
              MenuProps={MenuProps}>
              {options.map(data => (
                <MenuItem value={data.value} key={data.value}>
                  {data.label}
                </MenuItem>
              ))}
            </Select>
            {errorMessage ? (
              <ContainerTitle
                title={errorMessage}
                className={classes.errorStyle}
              />
            ) : null}
            <FormHelperText error className={classes.infoText}>
              {helperText}
            </FormHelperText>
          </FormControl>
        </ThemeProvider>
      </Box>
    </>
  );
};
SelectField.defaultProps = {
  placeholder: '',
  size: 'small',
  type: 'text',
  options: [],
  onChange: () => {},
  containerClassName: '',
};
export default SelectField;
