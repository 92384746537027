import { constant } from '../utils/constant';
import Age21Image from '../assets/images/themeImages/Age2.1.png';
import Age22Image from '../assets/images/themeImages/Age2.2.png';
import { grey } from '@material-ui/core/colors';

export const ageGroupTwoTheme = {
  isChild: true,
  isChild2: true,
  pageBackgroundColor: '#022B51',
  headerBackgroundColor: '#1e395e',
  navBarIconColorSelected: '#FFAF00',
  navBarIconColorUnSelected: '#999999',
  pageBackgroundImage1: Age21Image,
  pageBackgroundImage2: Age22Image,

  profilePageHeaderColor: '#F3F7FC',
  profilePageTableHeaderColor: '#F3F7FC',
  profilePageColouredTableRowColor: '#B7C1CD',
  profilePageTransparentTableRowColor: '#F3F7FC',
  profilePageRowDataEvenColor: '#000',
  profilePageRowDataOddColor: '#000',
  inviteButton: '#977EA0',

  nameTextColorAtDashboard: '#fff',
  nameSubTextColorAtDashboard: '#fff',

  textColorCalendarCard: '#10477A',
  borderColorDate: '#F6EEFA',
  borderColorCalendarCard: '#6684A0',
  backgroundColorCalendar: '#F6EEFA',
  textColorCalendarSlider: '#977EA0',
  calendarCardBackgroundColor: '#EBF4FD',
  textColorCalendarSliderOnHover: '#fff',
  textColorCalendarTime: '#FFF',
  taskNotComplete: '#fff',
  statusButton: '#6684A0',
  approvedStatus: '#977EA0',

  updateTaskModalBackgroundColor: '#E5F3FF',
  updateTaskModalHeaderColor: '#043562',

  rewardTitleTextColor: '#F6EEFA',
  commonModalHeaderBackground: '#043562',
  commonModalBackground: '#E5F3FF',
  commonModalButtonColor: '#977EA0',
  commonModalCancelButtonBorder: '2px solid #977EA0',
  commonModalCancelButtontext: '#977EA0',
  commonModalTextStyle: '#fff',
  commonModalImageContainer: '1px dashed #6684A0',
  commonModalImagetext: '#6E7885',

  requestContainerTitleColor: '#FFFFFF',
  RequestContainer: '#F6EEFA',
  requestContainerSelect: grey[600],

  sidebar: '#043562',
  NoProfileImage: '#FFEBED',
  HeaderNameColor: '#fff',
  NoTaskText: '#FF9838',
  requestContainertext: '#000',
  rewardCalendarButton: '2px solid #F6EEFA',
  sidebarBoxShadow: '0px 4px 6px -2px #977EA0',
  selectFieldBorder: '0.5px solid #000',
  rewardCalendar: '#F6EEFA',

  overDuetaskColor: '#F3F7FC',
  statusColor: '#6684A0',
  paginationOverduetasks: '#B7C1CD',
  statusButtonBorder: '1px solid #6E7885',
  completedStatusColor: '#977EA0',
  completedCheckedBoxIcon: '#977EA0',
  createdTaskColor: '#EBF4FD',
  createdCheckIcon: '#6684A0',
  paginationAlltasks: '#A7C3DE',
  completedTasksBackground: '#F6EEFA',
  cancelIconColor: '#6684A0',
  acceptIconColor: '#977EA0',
  taskContainerTitle: '#fff',
  paginationCompletedTasks: '#C4BAC8',
  rewardcard: '#977EA0',
  overDueIcon: '#B7C1CD',
};
