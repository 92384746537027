import { Button, makeStyles, useTheme } from '@material-ui/core';
import React from 'react';
import clsx from 'clsx';
import { constant } from '../../utils/constant';

const useStyles = makeStyles(theme => ({
  textStyle: {
    width: 130,
    height: 40,
    padding: 18,
    border: '0px solid #000000',
    backgroundColor: theme.customTheme.commonModalButtonColor,
    color: '#ffffff',
    borderRadius: 12,
    textTransform: 'none',
    fontSize: 14,
    fontWeight: 600,
    '&:hover': {
      backgroundColor: theme.customTheme.commonModalButtonColor,
    },
  },
}));

const BorderButton = props => {
  const {
    text,
    width,
    height,
    padding,
    onClick,
    border,
    backgroundColor,
    style,
    color,
    borderRadius,
    type,
    className,
    textTransform,
    disabled,
    id,
  } = props;
  const theme = useTheme();
  const classes = useStyles(theme);
  return (
    <Button
      style={{
        width,
        height,
        padding,
        border,
        backgroundColor,
        color,
        borderRadius,
        textTransform,
        ...style,
      }}
      id={id}
      disabled={disabled}
      type={type}
      onClick={onClick}
      className={clsx(classes.textStyle, className)}>
      {text}
    </Button>
  );
};
BorderButton.defaultProps = {
  className: '',
  text: constant.click,
  onClick: () => {},
  style: {},
  type: '',
  disabled: false,
};
export default BorderButton;
