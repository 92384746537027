import ChildrenProfilePage from '../pages/kidSection/childrenProfilePage/ChildrenProfilePage';
import Dashboard from '../pages/dashboard/Dashboard';
// import DemoChild from '../pages/Demo';
// import DemoChild2 from '../pages/Demo2';
import Reward from '../pages/reward/Reward';
import { constant } from '../utils/constant';
import CalenderPage from '../pages/calenderPage/CalenderPage';
import KidsDashboard from '../pages/kidSection/kidsDashboard/KidsDashboard';
import GuardianProfilePage from '../pages/parentSection/GuardianProfilePage.js/GuardianProfilePage';

export const parentRoutes = {
  routes: [
    { path: constant.routes.dashboard, component: Dashboard, isExact: true },
    { path: constant.routes.calendar, component: CalenderPage },
    { path: constant.routes.kidsProfile, component: ChildrenProfilePage },
    { path: constant.routes.rewards, component: Reward },
  ],
  redirect: constant.routes.dashboard,
};
