import { Box, makeStyles, useTheme } from '@material-ui/core';
import clsx from 'clsx';
import { connect } from 'react-redux';
import ContainerTitle from '../../../../components/common/title/ContainerTitle';
import SparkleImage from '../../../../assets/images/rewardsImages/sparkleImage.png';
import RewardsLogo from '../../../../assets/images/rewardsImages/rewardLogo.png';
import CogLogo from '../../../../assets/images/reward/cogCoin.svg';
import { priceFormatter } from '../../../../utils/methods';

const useStyle = makeStyles(theme => ({
  parentContainer: {
    position: 'relative',
  },
  container: {
    backgroundColor: '#fff',
    padding: '1rem 2rem 0.2rem 2rem',
    borderRadius: 12,
    minWidth: 250,
    overflow: 'hidden',
  },
  rewardHeading: {
    color: theme.customTheme.rewardcard,
    fontSize: '0.8rem',
    fontWeight: 550,
  },
  rewardAmountStyle: {
    color: theme.customTheme.rewardcard,
    fontSize: '2rem',
  },
  rewardsAmountContainer: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
  },
  cogLogoStyle: {
    height: '2rem',
    marginRight: 8,
  },
  sparkleImageStyle: {
    position: 'absolute',
    right: -10,
    height: 130,
  },
  rewardsLogoStyle: {
    position: 'absolute',
    top: -10,
    left: -25,
    height: 50,
  },
}));

const RewardsContainer = props => {
  const { profile, totalReward } = props;
  const theme = useTheme();
  const classes = useStyle(theme);
  return (
    <>
      <Box className={clsx(classes.parentContainer)}>
        <Box className={clsx(classes.container)}>
          <ContainerTitle
            title={`You have gained`}
            className={classes.rewardHeading}
          />
          <Box className={clsx(classes.rewardsAmountContainer)}>
            <img src={CogLogo} className={clsx(classes.cogLogoStyle)} />
            <ContainerTitle
              title={`${priceFormatter(totalReward || 0)}`}
              className={classes.rewardAmountStyle}
            />

            <img
              src={SparkleImage}
              className={clsx(classes.sparkleImageStyle)}
            />
          </Box>
        </Box>
        <img src={RewardsLogo} className={clsx(classes.rewardsLogoStyle)} />
      </Box>
    </>
  );
};

const mapStateToProps = ({ userReducer, authReducer }) => {
  return {
    profile: userReducer.profile,
    totalReward: userReducer.totalReward,
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(RewardsContainer);
