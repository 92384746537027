import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';

import {
  Box,
  Grid,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';

import * as actionCreator from '../../../store/action/index';
import ArrowLeft from '../../../../src/assets/images/calenderPage/ArrowLeft.png';
import ArrowRight from '../../../../src/assets/images/calenderPage/ArrowRight.png';
import KidCalenderCardY from './KidCalenderCardY';
import {
  getDateFromCompleteDate,
  getMonthFromCompleteDate,
  getWeekDayFromCompleteDate,
  getYearFromCompleteDate,
  yyyyMmDd,
} from '../../../utils/timeMethods';
import BaseButton from '../../../components/buttons/BaseButton';
import EmptyTask from '../../../components/EmptyData/EmptyTask';
import ContainerTitle from '../../../components/common/title/ContainerTitle';
import KidTransparent from '../../../assets/images/Kid_Transparent.gif';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
  container: {
    height: '90vh',
    width: '100%',
    boxSizing: 'border-box',
    padding: '5vh 5vw',
  },
  base: {
    backgroundColor: '#fff',
    borderRadius: '15px',
    height: '12vh',
    width: '100%',
    color: '#E7A993',
    boxShadow: '5px 5px 3px 0px rgba(28,27,27,0.64)',
    webkitBoxShadow: '5px 5px 3px 0px rgba(28,27,27,0.64)',
    mozBoxShadow: '5px 5px 3px 0px rgba(28,27,27,0.64)',
  },
  centered: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  datesTopContainer: {
    overflow: 'hidden',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  dateScrollContainer: {
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    transition: 'all 0.5s',
  },
  dateContainer: {
    borderRadius: '4px',
    display: 'flex',
    boxShadow: '5px 5px 3px 5px rgba(28,27,27,0.64)',
    backgroundColor: theme.customTheme.backgroundColorCalendar,
  },
  datesBaseButton: {
    padding: '0px 4px !important',
  },
  selectedDate: {
    border: `1px  solid ${theme.customTheme.borderColorDate}`,
    color: '#fff !important',
    // color: theme.customTheme.textColorCalendarSliderOnHover,
    backgroundColor: `${theme.customTheme.textColorCalendarSlider} !important`,
    padding: '3px !important',
  },
  dateTopContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      fontSize: 2,
    },
  },
  dateBottomContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      fontSize: 2,
    },
  },
  loader: {
    width: '20%',
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      width: '50%',
    },
    [theme.breakpoints.down('md')]: {
      width: '50%',
    },
  },
  emptyTaskStyle: { color: theme.customTheme.NoTaskText },
  NoTaskImg: { width: '40%', maxWidth: '400px' },
}));

const CalenderPage = props => {
  const theme = useTheme();
  const classes = useStyles();
  const [selectedDate, setSelectedDate] = React.useState();
  const [data, setData] = useState({
    id: '',
    fromDate: '',
    toDate: '',
  });
  const { getTaskBy_Date, getTaskByDate, getGuardianList, loadings } = props;
  const startPoint = 0;
  const [datesToDisplay, setDatesToDisplay] = React.useState([]);
  const [leftSpace, setLeftSpace] = React.useState(startPoint);
  const [scrollCount, setScrollCount] = React.useState({
    left: 0,
    right: 0,
    currentScroll: 0,
  });
  const shiftBy = 200;

  const addDates = isRight => {
    let lastDate = datesToDisplay[isRight ? datesToDisplay.length - 1 : 0];
    let dates = [];
    let datesToIncrease = 4;

    for (let i = 1; i <= datesToIncrease; i++) {
      let newDate = yyyyMmDd(
        new Date(
          new Date(lastDate).getTime() +
            24 *
              60 *
              60 *
              1000 *
              (isRight ? i : -1 * (datesToIncrease + 1 - i)),
        ),
      );
      dates.push(newDate);
    }
    setDatesToDisplay(date => {
      let tempDate = date;
      if (isRight) {
        tempDate = [...tempDate, ...dates];
      } else {
        tempDate = [...dates, ...tempDate];
      }
      return [...tempDate];
    });
  };

  const handleLeftScroll = () => {
    setLeftSpace(data => data + shiftBy);
    setScrollCount(data => {
      let tempData = { ...data };
      tempData.currentScroll = tempData.currentScroll - 1;
      if (tempData.currentScroll < tempData.left) {
        tempData.left = tempData.currentScroll;
        addDates(false);
      }
      return tempData;
    });
  };

  const handleRightScroll = () => {
    setLeftSpace(data => data - shiftBy);
    setScrollCount(data => {
      let tempData = { ...data };
      tempData.currentScroll = tempData.currentScroll + 1;
      if (tempData.currentScroll > tempData.right) {
        tempData.right = tempData.currentScroll;
        addDates(true);
      }
      return tempData;
    });
  };

  const selectDate = filterDate => {
    getTaskBy_Date(filterDate);
  };

  const handleSelectedDate = date => {
    let filterDate;
    setSelectedDate(date);
    let today = new Date();
    let year = today.getFullYear();
    let month = (today.getMonth() + 1).toString().padStart(2, '0');
    let day = today.getDate().toString().padStart(2, '0');

    // if (date === year + '-' + month + '-' + day) {
    //   filterDate = {
    //     fromDate: null,
    //     toDate: null,
    //   };
    // } else {
    filterDate = {
      fromDate: moment(date + ' 00:00').toISOString(),
      toDate: moment(date + ' 23:59').toISOString(),
    };
    // }
    setData(filterDate);
    selectDate(filterDate);
  };

  React.useEffect(() => {
    let dates = [];
    let currentDate = yyyyMmDd(new Date());
    for (let i = -10; i <= 10; i++) {
      let newDate = yyyyMmDd(
        new Date(new Date(currentDate).getTime() + i * 24 * 60 * 60 * 1000),
      );
      dates.push(newDate);
    }
    setSelectedDate(currentDate);
    setDatesToDisplay(dates);
  }, []);

  React.useEffect(() => {
    getGuardianList();
    // getTaskBy_Date(selectDate);
    handleSelectedDate(moment(new Date()).format('yyyy-MM-DD'));
  }, []);

  return (
    <Grid className={classes.container}>
      {/* calendar */}
      <div container className={classes.base}>
        <Grid container style={{ height: '100%' }}>
          <Grid item xs={1} className={classes.centered}>
            <img src={ArrowLeft} onClick={handleLeftScroll} />
          </Grid>
          <Grid
            item
            xs={10}
            className={classes.centered}
            style={{ height: '100%' }}>
            <Box className={clsx(classes.datesTopContainer)}>
              <Box
                className={clsx(classes.dateScrollContainer)}
                style={{ left: leftSpace }}>
                {datesToDisplay?.map(date => {
                  return (
                    <Box
                      m={2}
                      className={clsx(classes.dateContainer)}
                      key={date}>
                      {' '}
                      <BaseButton
                        text={
                          <Box>
                            <Box className={classes.dateTopContainer}>
                              <Typography>
                                {getMonthFromCompleteDate(date)}
                              </Typography>
                              -
                              <Typography>
                                {getYearFromCompleteDate(date)}
                              </Typography>
                            </Box>
                            <Box className={classes.dateBottomContainer}>
                              <Typography style={{ paddingLeft: '6px' }}>
                                {getDateFromCompleteDate(date)}
                              </Typography>
                              ,
                              <Typography style={{ paddingRight: '6px' }}>
                                {getWeekDayFromCompleteDate(date)}
                              </Typography>
                            </Box>
                          </Box>
                        }
                        className={
                          selectedDate == date
                            ? classes.selectedDate
                            : classes.datesBaseButton
                        }
                        onClick={() => {
                          handleSelectedDate(date);
                        }}
                      />
                    </Box>
                  );
                })}
              </Box>
            </Box>
          </Grid>
          <Grid item xs={1} className={classes.centered}>
            <img src={ArrowRight} onClick={handleRightScroll} />
          </Grid>
        </Grid>
      </div>

      {/* tasks list */}
      <Grid
        container
        className={classes.centered}
        style={{
          marginTop: '3vh',
          alignItems: 'flex-start',
          height: '65vh',
          overflow: 'scroll',
          overflowX: 'hidden',
        }}>
        {!loadings.getTasks ? (
          getTaskByDate ? (
            <>
              <Grid
                container
                item
                justifyContent="space-around"
                xs={12}
                md={11}
                style={{ justifyContent: 'space-between' }}
                spacing={2}>
                {getTaskByDate?.map(e => {
                  return (
                    <Grid
                      style={{ justifyContent: 'flex-start' }}
                      item
                      xs={12}
                      md={5}>
                      <KidCalenderCardY
                        taskData={e}
                        header={e.taskTitle}
                        subTitle={e.taskDescription}
                        status={e.status}
                        timeTo={e.taskTimeStamp}
                        timeFrom={e.deadline}
                        updateCallBack={() => {
                          getTaskBy_Date(data);
                        }}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </>
          ) : (
            <>
              <EmptyTask
                text={
                  <ContainerTitle
                    title={'No Tasks to display'}
                    className={classes.emptyTaskStyle}
                  />
                }
                imageClass={classes.NoTaskImg}
              />
            </>
          )
        ) : (
          <Grid container justifyContent="center">
            <img src={KidTransparent} className={classes.loader} />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

const mapStateToProps = ({ userReducer }) => {
  return {
    status: userReducer.status,
    loadings: userReducer.loadings,
    recommendations: userReducer.recommendations,
    profile: userReducer.profile,
    successMessage: userReducer.successMessage,
    getTaskByDate: userReducer.getTaskByDate,
    childList: userReducer.childList,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    getTaskBy_Date: data => dispatch(actionCreator.getTaskBy_Date(data)),
    getChildList: () => {
      dispatch(actionCreator.getChildList());
    },
    getGuardianList: () => {
      dispatch(actionCreator.getGuardianList());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CalenderPage);
