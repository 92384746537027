import React from 'react';
import {
  Grid,
  makeStyles,
  Typography,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';
import taskAdd from '../../../../src/assets/images/landingPageImages/Reward.png';
import Complete from '../../../../src/assets/images/landingPageImages/Reward2.png';
import Reward from '../../../../src/assets/images/landingPageImages/Reward3.png';
const useStyles = makeStyles(theme => ({
  box: {
    width: '90%',
    height: '80px',
    borderRadius: '8px',
    padding: '0px 10px 10px 10px',
    margin: ' 10px',
    backgroundColor: '#C5E2E2',
  },
  box1: {
    width: '60%',
    height: '80px',
    borderRadius: '8px',
    marginLeft: '25%',
    padding: '0px 10px 10px 10px',
    margin: ' 10px',
    backgroundColor: '#C5E2E2',
  },
  subBox1: {
    fontSize: '2rem',
    padding: '10px 0 0 30px',
    [theme.breakpoints.up('xs')]: {
      fontSize: '1.4rem',
      padding: '15px 0 0 10px',
    },
  },

  detailText: {
    color: 'black',
    fontSize: '16px',
    fontWeight: 'bold',
  },
  detailText1: {
    color: 'black',
    fontSize: '16px',
    fontWeight: 'bold',
  },
  detailBox: {
    paddingTop: '2%',
    paddingLeft: '0%',
    [theme.breakpoints.up('xs')]: {
      paddingLeft: '11%',
    },
  },
  text: {
    color: 'black',
    fontSize: '10px',
    lineHeight: '1.2',
  },
  Featureimg: {
    width: '90px',
    height: '100px',
    position: 'absolute',
    bottom: '0',
  },
  Featureimg1: {
    width: '140px',
    height: '140px',
    position: 'absolute',
    bottom: '0',
  },
  imagediv: {
    position: 'relative',
    height: '100%',
    [theme.breakpoints.up('xs')]: {
      height: '112%',
    },
  },
}));

const FeatureTab = () => {
  const theme = useTheme();
  const classes = useStyles();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  const data = [
    {
      num: '01',
      image: taskAdd,
      detailText: ` Create`,
      text: `Decide a task, create it on your Pocket Money profile and
    assign it to your child.`,
    },
    {
      num: '02',
      image: Complete,
      detailText: `Complete`,
      text: ` Your child goes through the task details, completes it and
    achieves the set goal.`,
    },
    {
      num: '03',
      image: Reward,
      detailText: `Rewards`,
      text: `Cogito rewards your child by sending COGs to their Pocket
    Money account.`,
    },
  ];
  return (
    <>
      {matches ? (
        <>
          {data.map(({ num, image, detailText, text }) => (
            <div className={classes.box} style={{ marginTop: '12%' }}>
              <Grid
                container
                style={{ height: '100%', backgroundColor: '#C5E2E2' }}>
                <Grid item xs={2}>
                  <div>
                    <Typography className={classes.subBox1}>{num}</Typography>
                  </div>
                </Grid>
                <Grid item xs={3}>
                  <div className={classes.imagediv}>
                    <img src={image} className={classes.Featureimg} />
                  </div>
                </Grid>

                <Grid item xs={6}>
                  <div className={classes.detailBox}>
                    <Typography className={classes.detailText}>
                      {detailText}
                    </Typography>
                    <Typography className={classes.text}>{text}</Typography>
                  </div>
                </Grid>
              </Grid>
            </div>
          ))}
        </>
      ) : (
        <>
          {data.map(({ num, image, detailText, text }) => (
            <div className={classes.box1} style={{ marginTop: '5%' }}>
              <Grid container style={{ height: '100%' }}>
                <Grid xs={1}>
                  <div>
                    <Typography className={classes.subBox1}> {num}</Typography>
                  </div>
                </Grid>
                <Grid xs={2} md={3}>
                  <div className={classes.imagediv}>
                    <img src={image} className={classes.Featureimg1} />
                  </div>
                </Grid>

                <Grid xs={6}>
                  <div className={classes.detailBox}>
                    <Typography className={classes.detailText1}>
                      {detailText}
                    </Typography>
                    <Typography className={classes.text}>{text}</Typography>
                  </div>
                </Grid>
              </Grid>
            </div>
          ))}
        </>
      )}
    </>
  );
};
export default FeatureTab;
