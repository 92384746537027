import { makeStyles, useTheme } from '@material-ui/core';
import clsx from 'clsx';
import { constant } from '../../utils/constant';

const useStyle = makeStyles(theme => ({
  profilePic: {
    [theme.breakpoints.up(constant.breakLaptopAuthPoint)]: {
      width: '2.3rem',
      borderRadius: '60px',
      height: '2.2rem',
      margin: '0 0.7rem 0 0rem',
    },
    [theme.breakpoints.down(constant.breakLaptopAuthPoint)]: {
      width: '2.5rem',
      borderRadius: '60px',
      height: '2.5rem',
      margin: '0 0.4rem 0 0rem',
    },
    [theme.breakpoints.down(constant.breakMobileAuthPoint)]: {
      width: '2.2rem',
      borderRadius: '60px',
      height: '2.2rem',
      margin: '0 0.3rem 0 0rem',
    },
    [theme.breakpoints.down(constant.breakSmallMobileAuthPoint)]: {
      width: '2rem',
      borderRadius: '60px',
      height: '2rem',
      margin: '0 0.3rem 0 0rem',
    },
  },
}));

const ProfilePic = props => {
  const { pic, className } = props;
  const theme = useTheme();
  const classes = useStyle(theme);
  return (
    <img
      src={pic}
      alt="profile"
      className={clsx(classes.profilePic, className)}
    />
  );
};
ProfilePic.defaultProps = {
  className: '',
};
export default ProfilePic;
