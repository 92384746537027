import {
  Grid,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import React from 'react';
import Header from '../../../components/common/header/Header';
import People from '../../../assets/images/aboutUs/IllustrationTeam.svg';
import KidIllustration from '../../../assets/images/aboutUs/KidIllustration.svg';
import WomenImg from '../../../assets/images/aboutUs/WomenImg.svg';
import CurveImg from '../../../assets/images/contactUs/sideBorder.svg';
import PatternDesign from '../../../assets/images/icon1.svg';
import { textAlign } from '@mui/system';
import Footer from '../../../components/common/footer/Footer';

const useStyles = makeStyles(theme => ({
  gridContainer: {
    display: 'grid',
    backgroundColor: '#eef7fa',
  },
  innerContainer: {
    margin: '10% 14% 4% 14%',
    backgroundColor: '#ffff',
    borderRadius: '20px',
    [theme.breakpoints.up('xs')]: {
      margin: '40% 10% 4% 10%',
    },
    [theme.breakpoints.up('sm')]: {
      margin: '22% 10% 4% 10%',
    },
    [theme.breakpoints.up('md')]: {
      margin: '10% 10% 4% 10%',
    },
  },
  heading: {
    fontSize: '2rem',
    fontWeight: 'bold',
  },
  basicCssForText: {
    color: '#6F6F6F',
  },
  subText: {
    textAlign: 'center',
    margin: '3% 10% 3% 10% ',
    lineHeight: '2rem',
    color: '#6F6F6F',
  },
  subText2: {
    margin: '0 10% 3% 10%',
    lineHeight: '2rem',
    color: '#6F6F6F',
  },
  text: {
    fontWeight: 'bold',
    fontSize: '0.8rem',
    color: '#1D1D1D',
    textAlign: 'center',
    margin: ' 10% 2% 0 20%',
    [theme.breakpoints.down('xs')]: {
      margin: '5% 0 5% 0',
    },
    [theme.breakpoints.down('md')]: {
      margin: ' 10% 15% 0 15%',
    },
  },
  KidIllustration: {
    width: '15vw',
    [theme.breakpoints.down('xs')]: {
      width: '60vw',
    },
    [theme.breakpoints.down('md')]: {
      width: '63vw',
      marginLeft: '10%',
    },
    [theme.breakpoints.up(960)]: {
      width: '17vw',
    },
  },

  PatternDesign1: {
    width: '7vw',
    position: 'absolute',
    top: '82%',
    left: '7%',
    [theme.breakpoints.down('md')]: {
      left: '5vw',
    },
  },
  PatternDesign2: {
    width: '7vw',
    position: 'absolute',
    top: '180%',
    right: '7%',
    [theme.breakpoints.down('md')]: {
      right: '6vw',
    },
  },
  descText: {
    color: '#6F6F6F',
    margin: '0 17% 2.5% 10%',
    lineHeight: '1.8rem',
    fontSize: '0.9rem',
    [theme.breakpoints.down('md')]: {
      margin: '0 10% 12% 10%',
    },
  },
  headText: {
    color: '#333333',
    margin: '0 10% 2% 10%',
    fontWeight: 'bold',
  },
  peopleImg: {
    width: 'auto',
    [theme.breakpoints.down('xs')]: {
      width: '72vw',
    },
    [theme.breakpoints.down('md')]: {
      width: '53vw',
    },
    [theme.breakpoints.down('sm')]: {
      width: '60vw',
    },
  },
  textDescription: {
    [theme.breakpoints.down('md')]: {
      margin: '0 10% 2% 10%',
    },
  },
}));
const AboutUsPage = () => {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('xs'));
  return (
    <div>
      <Header />
      <Grid container className={classes.gridContainer}>
        <Grid className={classes.innerContainer}>
          <Grid item xs={12} style={{ textAlign: 'right' }}>
            <img src={CurveImg} style={{ width: '6vw' }} />
          </Grid>
          <Grid>
            <Grid item xs={12} style={{ textAlign: 'center' }}>
              <Typography className={classes.heading}>
                {'About '} <text style={{ color: '#359DB6' }}>{'Us'}</text>{' '}
                <br />
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography className={classes.subText}>
                The Pocket Money App is an Ed-tech invention, brought to life by
                the creators of Cogito Metaverse. Since the future of money is
                digital, we realised that there needs to be a tool that educates
                our children about the realities of personal finance and at the
                same time instils them with a sense of independence. After all,
                it is important that our children understand the practicality of
                living independently in this digitally-driven era of
                self-reliance and are able to confidently walk hand-in-hand with
                all that the modern world has to offer.
              </Typography>
            </Grid>
            <Grid container>
              {!matches ? (
                <Grid item xs={1}>
                  <img className={classes.PatternDesign1} src={PatternDesign} />
                </Grid>
              ) : null}

              <Grid item xs={11} style={{ textAlign: 'center' }}>
                <img src={People} className={classes.peopleImg} />
              </Grid>
            </Grid>
            <Grid xs={12} style={{ textAlign: 'center' }}>
              <Typography className={classes.subText2}>
                The best way to make this happen is by presenting our kids with
                opportunities where they can equate effort with value and learn
                skills that will stay with them for a lifetime. The Pocket Money
                App supports this idea and offers children a platform where they
                can become responsible and learn the value of their hard work.
              </Typography>
            </Grid>
            <div style={{ margin: '0 10% 0 10%' }}>
              <hr />
            </div>
            <Grid container>
              <Grid item xs={12} md={8}>
                <Typography className={classes.text}>
                  Parents, caregivers and guardians can use the app as an
                  effective tool to help them on their parenting journey. They
                  can:
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                md={4}
                style={{ textAlign: !matches ? 'left' : 'center' }}>
                <img
                  className={classes.KidIllustration}
                  src={KidIllustration}
                />
              </Grid>
              <Grid item xs={12} style={{ textAlign: 'left' }}>
                <Typography className={classes.headText}>
                  Teach Life Skills
                </Typography>
              </Grid>
              <Grid item xs={12} style={{ textAlign: 'left' }}>
                <Typography className={classes.descText}>
                  Developing age-appropriate life skills is important for
                  children as it allows them to be self-dependent. With tasks
                  like cleaning the play area, making the bed and packing the
                  school bag, children learn to take care of themselves while
                  forming core skills like time management and decision making.
                </Typography>
              </Grid>
              <Grid item xs={12} style={{ textAlign: 'left' }}>
                <Typography className={classes.headText}>
                  Raise responsible kids
                </Typography>
              </Grid>
              <Grid item xs={12} style={{ textAlign: 'left' }}>
                <Typography className={classes.descText}>
                  Instilling a sense of responsibility in children can take
                  quite some time, so it is best to start young. When children
                  are given the opportunities to take accountability of their
                  actions they learn to be responsible and this in turn gives
                  them a strong sense of autonomy and self-sufficiency.
                </Typography>
              </Grid>
              <Grid item xs={12} style={{ textAlign: 'left' }}>
                <Typography className={classes.headText}>
                  Develop positive money habits
                </Typography>
              </Grid>
              <Grid item xs={12} style={{ textAlign: 'left' }}>
                <Typography className={classes.descText}>
                  Understanding the value of money is the key to leading a happy
                  and successful life. When children learn how to earn for their
                  efforts, save for their future and budget for their needs,
                  they become financially aware. They can thus make financially
                  sound decisions and face difficult economic situations
                  confidently later in life.
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="flex-end">
              {!matches ? (
                <Grid item xs={1}>
                  <img className={classes.PatternDesign2} src={PatternDesign} />
                </Grid>
              ) : null}
            </Grid>
            <div style={{ margin: '0 10% 0 10%' }}>
              <hr />
            </div>

            <Grid container>
              <Grid item xs={12} md={8}>
                <Typography className={classes.text}>
                  Young kids can use this app to understand about the working of
                  money and the value of their effort in the world. They can:
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <img className={classes.KidIllustration} src={WomenImg} />
              </Grid>
              <Grid item xs={12} style={{ textAlign: 'left' }}>
                <Typography className={classes.headText}>
                  Earn for their work
                </Typography>
              </Grid>
              <Grid item xs={12} style={{ textAlign: 'left' }}>
                <Typography className={classes.descText}>
                  It is important that children learn the value of the work they
                  are doing and this only happens when they are rewarded for the
                  same. This gives young kids the confidence to appreciate their
                  hard work and build it into a lifelong skill that can be
                  monetarily rewarding.
                </Typography>
              </Grid>
              <Grid item xs={12} style={{ textAlign: 'left' }}>
                <Typography className={classes.headText}>
                  Learn to manage money{' '}
                </Typography>
              </Grid>
              <Grid item xs={12} style={{ textAlign: 'left' }}>
                <Typography className={classes.descText}>
                  In the world today children are expected to have the know-how
                  of money. For this it is necessary that they learn to manage
                  their finances through regular earnings and savings. This
                  therefore sets them for a money secure future and makes them
                  financially literate.
                </Typography>
              </Grid>
              <Grid item xs={12} style={{ textAlign: 'left' }}>
                <Typography className={classes.headText}>
                  Grow to be independent{' '}
                </Typography>
              </Grid>
              <Grid item xs={12} style={{ textAlign: 'left' }}>
                <Typography
                  className={classes.descText}
                  style={{ paddingBottom: '3%' }}>
                  When children take responsibility of their daily tasks,
                  whether it is finishing their homework or keeping their room
                  clean, they develop the ability to be self-reliant. As every
                  day they earn for their learning, they gradually develop the
                  courage and the confidence to operate as healthy independent
                  individuals in the society.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Footer />
    </div>
  );
};

export default AboutUsPage;
