import { Box, Grid, makeStyles, useTheme } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';
import AuthScreen from '../preLoggedIn/accountType/components/AuthScreen';
import LeftContainer from '../preLoggedIn/accountType/components/LeftContainer';
import LoginContainer from './components/LoginContainer';
import PasswordResetContainer from './components/PasswordResetContainer';

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
    height: '100vh',
    backgroundColor: '#EEF7FA',
  },
}));

const PasswordReset = props => {
  const theme = useTheme();
  const { isParentReset } = props;
  const classes = useStyles(theme);
  const leftContainer = (
    <LeftContainer
      child={<PasswordResetContainer />}
      isParent={isParentReset}
    />
  );
  return (
    <Box className={clsx(classes.container)}>
      <AuthScreen leftContainer={leftContainer} />
    </Box>
  );
};

const mapStateToProps = ({ authReducer }) => {
  return {
    isParentReset: authReducer.isParentReset,
  };
};
const mapDispatchToProps = dispatch => {
  return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(PasswordReset);
