import { constant } from '../utils/constant';
import KidCalenderPage from '../pages/kidSection/kidCalenderPage/KidCalenderPage';
import KidsDashboard from '../pages/kidSection/kidsDashboard/KidsDashboard';
import GuardianProfilePage from '../pages/parentSection/GuardianProfilePage.js/GuardianProfilePage';
import KidReward from '../pages/kidSection/kidReward/KidReward';

export const childrenRoutes = {
  routes: [
    {
      path: constant.routes.dashboard,
      component: KidsDashboard,
      isExact: true,
    },
    { path: constant.routes.guardianProfile, component: GuardianProfilePage },
    { path: constant.routes.calendar, component: KidCalenderPage },
    { path: constant.routes.rewards, component: KidReward },
  ],
  redirect: constant.routes.dashboard,
};
