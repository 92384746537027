import { Box, Grid, makeStyles, Typography, useTheme } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';
import { constant } from '../../../../utils/constant';
import BorderButton from '../../../../components/buttons/BorderButton';
import { useHistory } from 'react-router-dom';
import Title from '../../../loginPage/components/Title';

const useStyles = makeStyles(theme => ({
  selectionContainer: {
    display: 'flex',
    justifyContent: 'center',
    paddingLeft: 30,
    marginTop: 40,
    [theme.breakpoints.down(constant.breakSmallMobileAuthPoint)]: {
      paddingLeft: 0,
      justifyContent: 'center',
      marginTop: 5,
      marginBottom: 12,
    },
  },
  innerSelectionContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  backtextStyle: {
    fontSize: 13,
  },
  backIconStyle: {
    fontSize: 18,
  },
  titleFont: {
    fontSize: 22,
    color: '#155B6B',
    fontWeight: 800,
  },
  subTitleFont: {
    fontSize: 14,
    color: '#359DB6',
    fontWeight: 500,
    marginTop: 4,
  },
  titleContainer: {
    marginTop: 16,
    marginBottom: 32,
  },
  buttonContainer: {
    marginTop: 16,
  },
  ...constant.authButtonStyle(theme),
}));

const SelectAccountType = () => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const history = useHistory();
  const redirect = route => {
    history.push(route);
  };
  return (
    <Grid item xs={12}>
      <Box className={clsx(classes.selectionContainer)}>
        <Box className={clsx(classes.innerSelectionContainer)}>
          <Title title={constant.letsStart} subtitle={constant.letsStartDesc} />
          <Box className={clsx(classes.buttonContainer)}>
            <BorderButton
              id="parent-login-page"
              onClick={() => {
                redirect(constant.routes.parentLogin);
              }}
              text={constant.iAmGuardian}
              className={classes.buttonStyle}
            />
          </Box>
          <Box className={clsx(classes.buttonContainer)}>
            <BorderButton
              id="kid-login-page"
              onClick={() => {
                redirect(constant.routes.childrenLogin);
              }}
              text={constant.iAmKid}
              {...constant.lightButtonStyle}
              className={classes.buttonStyle}
            />
          </Box>
        </Box>
      </Box>
    </Grid>
  );
};

const mapStateToProps = ({}) => {
  return {};
};
const mapDispatchToProps = dispatch => {
  return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(SelectAccountType);
