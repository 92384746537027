import {
  Box,
  makeStyles,
  TextField,
  useTheme,
  ThemeProvider,
  createTheme,
  FormControl,
  Typography,
  Button,
  Grid,
  useMediaQuery,
} from '@material-ui/core';
import clsx from 'clsx';
import { connect } from 'react-redux';
import * as actionCreator from '../../../store/action';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { constant } from '../../../utils/constant';
import {
  capitalFirstLetter,
  maxStringLength,
} from '../../../utils/stringMethods';
import ContainerTitle from '../../../components/common/title/ContainerTitle';
import React, { useEffect } from 'react';
import ProfilePic from '../../../components/profilePic/ProfilePic';
// import TextField from "@material-ui/core/TextField";
import { grey } from '@material-ui/core/colors';
import SelectField from '../../../components/muiInputFields.js/SelectFeild';

import { CancelOutlined } from '@material-ui/icons';
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline';
const useStyle = makeStyles(theme => {
  return {
    root: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    margin: {
      margin: theme.spacing(1),
    },
    container: {
      padding: '0.4rem 0.9rem',
      display: 'flex',
      borderRadius: '17px',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      Width: '100%',
      backgroundColor: theme.customTheme.RequestContainer,
      [theme.breakpoints.down('sm')]: {
        padding: '0rem 0.3rem ',
      },
    },
    innerContainer: {
      display: 'flex',
      flexDirection: 'row',
      paddingTop: '1rem',
      // justifyContent: 'space-between',
      color: theme.customTheme.requestContainertext,
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
        width: 110,
        justifyContent: 'flex-start',
        paddingTop: '0rem',
      },
    },

    profileIconStyle: {
      fontSize: '2rem',
      color: '#EEF7FA',
      marginRight: '0.5rem',

      [theme.breakpoints.up(constant.breakLaptopAuthPoint)]: {
        fontSize: '2.5rem',
        marginRight: '0.7rem',
      },
    },
    nameStyle: {
      display: 'flex',
      fontSize: '0.9rem !important',
      color: theme.customTheme.requestContainertext,
      [theme.breakpoints.down(constant.breakMobileAuthPoint)]: {
        fontSize: '0.6rem !important',
      },
    },
    taskNameStyle: {
      fontSize: '0.6rem !important',
      display: 'flex',
      fontWeight: 400,
      color: theme.customTheme.requestContainertext,
      [theme.breakpoints.down(constant.breakMobileAuthPoint)]: {
        fontSize: '0.55rem !important',
      },
    },

    image: {
      flexDirection: 'column ',
      minWidth: 35,
      '&:hover': {
        boxShadow: '0px 4px 6px -2px rgba(105 149 149)',
        borderRadius: 9,
      },
      [theme.breakpoints.down('xs')]: {
        width: 30,
      },
    },
    image1: {
      width: 55,
      height: 69,

      [theme.breakpoints.down('xs')]: {
        width: 40,
        height: 50,
      },
    },
    cancelBox: {
      color: theme.customTheme.cancelIconColor,
    },

    cancelIcon: {
      width: 40,
      height: 56,
      [theme.breakpoints.down(600)]: {
        width: 30,
        padding: 0,
      },
    },
    AcceptBox: {
      color: theme.customTheme.acceptIconColor,
    },
    FormControlCss: {
      minWidth: 80,
      width: 130,
      [theme.breakpoints.down('xs')]: {
        width: 80,
      },
    },
    childRelationBox: {
      // minWidth: 80,
      width: 130,
      marginLeft: '2rem',
      [theme.breakpoints.down('xs')]: {
        // margin: '2rem 0rem 1rem ',
      },
    },
    childRelationBoxMob: {
      marginTop: '1rem',
      marginLeft: '0rem',
      width: 60,
    },
    RelationLabel: {
      fontSize: '0.4rem',
      wordWrap: 'break-word',
      '& input.Mui-disabled': {
        color: theme.customTheme.requestContainertext,
      },
      '& label.Mui-disabled': {
        color: theme.customTheme.requestContainertext,
      },
    },
    RelationLabelMob: {
      fontSize: '0.6rem',
      // marginTop: '1rem',
      fontWeight: 'bold',
    },
    buttonBox: {
      [theme.breakpoints.down('xs')]: {
        // textAlign: 'center',
        display: 'flex',
        flexDirection: 'row',
      },
    },
    nameContainer: {
      display: 'flex',
      flexDirection: 'column',
      maxWidth: 110,

      [theme.breakpoints.down('xs')]: {
        overflowWrap: 'anywhere',
        paddingRight: '5px',
      },
    },
    outerNameContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      width: '20%',
      [theme.breakpoints.between('xs', 'sm')]: {
        width: '25%',
      },
      [theme.breakpoints.down('xs')]: {
        width: '40%',
      },
    },
    infoText: {
      // color: 'red',
      fontSize: '0.56rem',
      marginLeft: '1%',
      position: 'absolute',
      color: theme.customTheme.requestContainertext,

      [theme.breakpoints.down('sm')]: {
        fontSize: '0.5rem',
        marginBottom: '1rem',
      },
    },
    selectField: {
      color: theme.customTheme.requestContainerSelect,
      [theme.breakpoints.down('xs')]: {
        fontSize: '0.6rem',
      },
    },
    icon: {
      fill: theme.customTheme.requestContainertext,
    },
  };
});
const RequestContainer = props => {
  const { detail, profile, updateChildRequest, isMinor, location } = props;
  const theme = useTheme();
  const classes = useStyle(theme);
  const [relation, setRelation] = React.useState('');
  const [parentRelation, setParentRelation] = React.useState('');
  const matches = useMediaQuery(theme.breakpoints.down('xs'));
  const themee = createTheme({
    palette: {
      primary: {
        main: grey[200],
      },
      action: {
        disabled: theme.customTheme.requestContainertext,
      },
    },
  });

  const handleChildRelation = e => {
    setChildRelation(data => ({ ...data, value: e.target.value }));
  };
  const handleGuardianRelation = e => {
    setGuardianRelation(data => ({ ...data, value: e.target.value }));
  };
  const [childRelation, setChildRelation] = React.useState({
    value: '',
    options: [
      { value: 'son', label: 'Son' },
      { value: 'daughter', label: 'Daughter' },
      { value: 'ward', label: 'Ward' },
    ],
    onChange: e => {
      handleChildRelation(e);
    },
  });

  const [guardianRelation, setGuardianRelation] = React.useState({
    value: '',
    options: [
      { value: 'father', label: 'Father' },
      { value: 'mother', label: 'Mother' },
      { value: 'guardian', label: 'Guardian' },
    ],
    onChange: e => {
      handleGuardianRelation(e);
    },
  });
  const handleCancel = () => {
    let data = {};
    if (location === 'addChildInParent') {
      data = {
        from: profile?._id,
        to: detail._id,
        status: 'Cancelled',
        isMinor: true,
      };
    } else {
      data = {
        from: detail._id,
        to: profile?._id,
        status: 'Cancelled',
        isMinor,
      };
    }
    updateChildRequest(data);
  };
  const handleApprove = () => {
    let data = {
      from: detail._id,
      to: profile?._id,
      fromRelation: parentRelation,
      status: 'Accept',
      isMinor,
      toRelation: childRelation.value,
    };
    updateChildRequest(data);
  };
  React.useEffect(() => {
    if (detail?.family) {
      detail?.family?.map(request => {
        if (request?._id == profile?._id && request?.relation != '') {
          setRelation(request?.relation);
          setParentRelation(request?.yourRelation);
        } else {
          setRelation(request?.yourRelation);
        }
      });
    } else {
      setParentRelation(detail?.yourRelation);
    }
  }, [detail]);

  return (
    <Box>
      <Box className={clsx(classes.container)}>
        <Box className={classes.outerNameContainer}>
          <Box>
            {detail?.profile_pic ? (
              <ProfilePic
                pic={`${process.env.REACT_APP_SPACE_KEY}/${detail?.profile_pic}`}
              />
            ) : (
              <AccountCircleIcon className={clsx(classes.profileIconStyle)} />
            )}
          </Box>
          <Box className={classes.nameContainer} style={{}}>
            <ContainerTitle
              title={`${detail.firstName} ${detail.lastName}`}
              className={clsx(classes.nameStyle)}
            />

            {matches && location !== 'addChildInParent' ? (
              <Box
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}>
                <Typography style={{ fontSize: '0.6rem', fontWeight: '500' }}>
                  Relation |
                </Typography>
                <Typography className={clsx(classes.RelationLabelMob)}>
                  {isMinor
                    ? capitalFirstLetter(relation)
                    : capitalFirstLetter(parentRelation)}
                </Typography>
              </Box>
            ) : (
              <ContainerTitle
                title={maxStringLength(detail?.email || '', 30)}
                className={clsx(classes.taskNameStyle)}
              />
            )}
          </Box>
        </Box>
        {/* Relation containers */}
        <Box className={classes.innerContainer}>
          <ThemeProvider theme={themee}>
            <FormControl className={classes.FormControlCss}>
              {!matches ? (
                <TextField
                  className={clsx(classes.RelationLabel)}
                  label={profile?.isMinor ? 'Relation' : 'Your Relation'}
                  variant="outlined"
                  value={
                    location == 'addChildInParent'
                      ? capitalFirstLetter(parentRelation)
                      : capitalFirstLetter(relation)
                  }
                  disabled={true}
                />
              ) : !isMinor ? (
                <Box sx={{ flexDirection: 'row' }}>
                  <Typography style={{ fontSize: '0.6rem', fontWeight: '500' }}>
                    {profile?.isMinor ? 'Relation  ' : 'Your Relation  '}
                  </Typography>
                  <Typography className={clsx(classes.RelationLabelMob)}>
                    {location == 'addChildInParent'
                      ? capitalFirstLetter(parentRelation)
                      : capitalFirstLetter(relation)}
                  </Typography>
                </Box>
              ) : null}
            </FormControl>
          </ThemeProvider>
          <Box>
            {profile?.isMinor ? (
              <Box
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  width: 110,
                  paddingTop: matches ? '1rem' : '0rem',
                }}>
                <SelectField
                  name="Your Relation"
                  {...childRelation}
                  className={classes.selectField}
                  helperText={
                    !childRelation?.value ? 'Please select your relation' : null
                  }
                  inputProps={{
                    classes: {
                      icon: classes.icon,
                    },
                  }}
                />

                {/* {!guardianRelation?.value ? (
                  <Typography className={classes.infoText}>
                    Please select Parent relation
                  </Typography>
                ) : null} */}
              </Box>
            ) : (
              <>
                {location == 'addChildInParent' ? null : (
                  <Box>
                    <ThemeProvider theme={themee}>
                      <Box className={classes.childRelation}>
                        {!matches ? (
                          <FormControl className={classes.childRelationBox}>
                            <TextField
                              className={clsx(classes.RelationLabel)}
                              label="Child relation"
                              variant="outlined"
                              value={capitalFirstLetter(parentRelation)}
                              disabled={true}
                            />
                          </FormControl>
                        ) : null}
                      </Box>
                    </ThemeProvider>
                  </Box>
                )}
              </>
            )}
          </Box>
        </Box>
        {/* //Button */}
        <Box className={classes.buttonBox}>
          <Button className={classes.image} onClick={handleCancel}>
            <Box className={classes.cancelBox}>
              <CancelOutlined className={classes.cancelIcon} />
              {matches ? null : <Typography>Cancel</Typography>}
            </Box>
          </Button>
          {location !== 'addChildInParent' && (
            <Button
              className={classes.image}
              disabled={isMinor ? !childRelation.value : null}
              onClick={handleApprove}>
              <Box className={classes.AcceptBox}>
                <CheckCircleOutline className={classes.cancelIcon} />
                {matches ? null : <Typography>Accept</Typography>}
              </Box>
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  );
};

const mapStateToProps = ({ userReducer }) => {
  return {
    profile: userReducer.profile,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateChildRequest: data =>
      dispatch(actionCreator.updateChildRequest(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(RequestContainer);
