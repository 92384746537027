import { constant } from '../utils/constant';
import Age31Image from '../assets/images/themeImages/AgeGroup3.svg';
import { grey } from '@material-ui/core/colors';

export const ageGroupThreeTheme = {
  isChild: true,
  isChild3: true,
  pageBackgroundColor: '#EFE8CB',
  headerBackgroundColor: '#4A8778',
  navBarIconColorSelected: '#4A8778',
  navBarIconColorUnSelected: '#999999',

  pageBackgroundImage1: Age31Image,
  profilePageHeaderColor: '#36685C',
  profilePageTableHeaderColor: '#36685C',
  profilePageColouredTableRowColor: '#FFFCF3',
  profilePageTransparentTableRowColor: '#EEFFFB',
  profilePageRowDataEvenColor: '#333333',
  profilePageRowDataOddColor: '#333333',
  inviteButton: '#CE6A85',

  nameTextColorAtDashboard: '#36685C',
  nameSubTextColorAtDashboard: '#36685C',

  textColorCalendarCard: '#000',
  borderColorDate: '#F6EEFA',
  borderColorCalendarCard: '#CE6A85',
  textColorCalendarSlider: '#4A8778',
  calendarCardBackgroundColor: '#FFFCF3',
  textColorCalendarSliderOnHover: '#EFE8CB',
  textColorCalendarTime: '#FFF',
  taskNotComplete: '#fff',
  statusButton: '#CE6A85',
  approvedStatus: '#94845D',

  updateTaskModalBackgroundColor: '#E5F3FF',
  updateTaskModalHeaderColor: '#337465',
  rewardTitleTextColor: '#000',
  commonModalHeaderBackground: '#337465',
  commonModalBackground: '#E5F3FF',
  commonModalButtonColor: '#CE6A85',
  commonModalCancelButtonBorder: '2px solid #CB8F6E',
  commonModalCancelButtontext: '#CB8F6E',
  commonModalTextStyle: '#FFFFFF',
  commonModalImageContainer: '1px dashed #4A8778',
  commonModalImagetext: '#94845D',

  requestContainerTitleColor: '#4A8778',

  sidebar: '#4A8778',
  NoProfileImage: '#FFEBED',
  HeaderNameColor: '#fff',
  NoTaskText: '#E63868',
  RequestContainer: '#FFFCF3',
  requestContainertext: '#00',
  rewardCalendarButton: '2px solid #CE6A85',
  sidebarBoxShadow: '0px 4px 6px -2px rgba(3, 93, 85, 1)',
  requestContainerSelect: grey[600],
  selectFieldBorder: '0.5px solid #000',
  rewardCalendar: '#CE6A85',

  overDuetaskColor: '#FFF0F4',
  statusColor: '#CE6A85',
  paginationOverduetasks: '#CE6A85',
  statusButtonBorder: '1px solid #CE6A85',
  completedStatusColor: '#94845D',
  completedCheckedBoxIcon: '#94845D',
  createdTaskColor: '#EEFFFB',
  createdCheckIcon: '#337465',
  paginationAlltasks: '#4F9887',
  completedTasksBackground: '#FFFCF3',
  cancelIconColor: '#D8936C',
  acceptIconColor: '#6F7F72',
  taskContainerTitle: '#000',
  paginationCompletedTasks: '#C7BCA0',
  rewardcard: '#AB4964',
  overDueIcon: '#AB4964',
};
