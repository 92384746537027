import { Button, makeStyles, useTheme } from '@material-ui/core';
import React from 'react';
import clsx from 'clsx';
import { constant } from '../../utils/constant';

const useStyles = makeStyles(theme => ({
  textStyle: {
    fontSize: 12,
    fontWeight: 500,
  },
}));

const BaseButton = props => {
  const {
    text,
    width,
    height,
    padding,
    onClick,
    style,
    color,
    id,
    minWidth,
    className,
  } = props;
  const theme = useTheme();
  const classes = useStyles(theme);
  return (
    <Button
      style={{
        width,
        height,
        padding,
        color,
        minWidth,
        textTransform: 'none',
        ...style,
      }}
      onClick={onClick}
      id={id}
      className={clsx(className, classes.textStyle)}>
      {text}
    </Button>
  );
};
BaseButton.defaultProps = {
  className: '',
  text: constant.click,
  padding: 0,
  onClick: () => {},
  color: '#074D5F',

  minWidth: 0,
  style: {},
  //  style: {
  //
  //   border: "1px solid #CC8D6A",
  //   //   "&:hover": {
  //   //    backgroundColor: "#CC8D6A",
  //   //    color: "white",
  //   //   },
  //   },
};
export default BaseButton;
