import {
  Box,
  ButtonBase,
  Grid,
  makeStyles,
  Typography,
  useTheme,
} from '@material-ui/core';
import clsx from 'clsx';
import * as actionCreator from '../../store/action';
import React from 'react';
import { connect } from 'react-redux';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ContainerTitle from '../common/title/ContainerTitle';
import {
  getAmPm,
  getDateMonthYearFormat,
  getTime12HFormat,
  isTaskActive,
} from '../../utils/timeMethods';
import DateRangeIcon from '@material-ui/icons/DateRange';
import { constant } from '../../utils/constant';
import BorderButton from '../../components/buttons/BorderButton';
import ImageOutlinedIcon from '@material-ui/icons/ImageOutlined';
import { uploadProfilePic } from '../../store/action/userAction';
import CommonModel from '../common/model/CommonModel';
import DeleteTaskModal from '../../pages/kidSection/childrenProfilePage/DeleteTaskModal';
import CogCoin from '../../assets/images/reward/cogCoin.svg';
import EditIcon from '../../assets/images/edit.svg';
import DeleteIcon from '../../assets/images/delete.svg';
import CloseIcon from '@material-ui/icons/Close';
import BaseButton from '../buttons/BaseButton';
import { maxStringLength } from '../../utils/stringMethods';
import {
  Delete,
  DeleteForeverOutlined,
  DeleteOutlined,
  DeleteOutlineOutlined,
  DeleteOutlineTwoTone,
  DeleteSharp,
  DeleteSweep,
  DeleteSweepOutlined,
  Image,
} from '@material-ui/icons';
import { sizeInMegaBytes } from '../../utils/methods';
import ImageOutlined from '@material-ui/icons/ImageOutlined';
import SmallLoader from '../loader/SmallLoader';

const useStyle = makeStyles(theme => ({
  createTaskContainer: {
    width: '100%',
    maxWidth: '800px',
  },
  innerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  childContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  profileIconStyle: {
    fontSize: '2.3rem',
    marginRight: '0.5rem',
    [theme.breakpoints.up(constant.breakLaptopAuthPoint)]: {
      fontSize: '2.5rem',
      marginRight: '0.7rem',
    },
    [theme.breakpoints.down(constant.breakMobileAuthPoint)]: {
      fontSize: '1.8rem',
    },
    [theme.breakpoints.down(constant.breakSmallMobileAuthPoint)]: {
      fontSize: '1.6rem',
    },
  },
  directionColumn: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    flexDirection: 'column',
  },
  nameStyle: {
    fontSize: '0.9rem !important',
    [theme.breakpoints.down(constant.breakMobileAuthPoint)]: {
      fontSize: '0.8rem !important',
    },
    [theme.breakpoints.down(constant.breakSmallMobileAuthPoint)]: {
      fontSize: '0.7rem !important',
    },
  },
  divideDescription: {
    margin: '0 0.5rem',
  },
  taskNameStyle: {
    color: '#6F6F6F !important',
    fontSize: '0.9rem !important',
    [theme.breakpoints.down(constant.breakMobileAuthPoint)]: {
      fontSize: '0.8rem !important',
    },
    [theme.breakpoints.down(constant.breakSmallMobileAuthPoint)]: {
      fontSize: '0.8rem !important',
    },
  },
  timeStyle: {
    fontSize: '0.7rem',
    fontWeight: 550,
    color: '#333333',
    [theme.breakpoints.down(constant.breakMobileAuthPoint)]: {
      fontSize: '0.6rem !important',
    },
    [theme.breakpoints.down(constant.breakSmallMobileAuthPoint)]: {
      fontSize: '0.6rem !important',
    },
  },
  amPmStyle: {
    fontSize: '0.7rem',
    fontWeight: 550,
    color: '#6F6F6F',
  },
  calendarStyle: {
    fontSize: '1.2rem',
    color: '#6F6F6F',
    [theme.breakpoints.down(constant.breakMobileAuthPoint)]: {
      fontSize: '1.1rem !important',
    },
    [theme.breakpoints.down(constant.breakSmallMobileAuthPoint)]: {
      fontSize: '0.8rem !important',
    },
  },
  dateStyle: {
    fontSize: '0.8rem',
    color: '#6F6F6F',
    marginLeft: '0.3rem',
    [theme.breakpoints.down(constant.breakMobileAuthPoint)]: {
      fontSize: '0.7rem !important',
      marginLeft: '0.2rem',
    },
    [theme.breakpoints.down(constant.breakSmallMobileAuthPoint)]: {
      fontSize: '0.7rem !important',
      marginLeft: '0.1rem',
    },
  },
  documentStyle: {
    width: '100%',
    height: '30vh',
    minHeight: '200px',
    maxHeight: '550px',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  approveButtonStyle: {
    margin: '0 5px',
    // background: 'transparent',
  },
  cancelButtonStyle: {
    margin: '0 5px',
    backgroundColor: 'transparent',
    color: theme.customTheme.commonModalCancelButtontext,
    border: theme.customTheme.commonModalCancelButtonBorder,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  imageUploadContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: 150,
    border: theme.customTheme.commonModalImageContainer,
    margin: '12px 0',
    borderRadius: 12,
  },
  uploadImageIconStyle: {
    fontSize: 40,
  },
  uploadImageTextStyle: {
    fontSize: 16,
    color: theme.customTheme.commonModalHeaderBackground,
  },
  selectedImageContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    // position: 'absolute',
    // textAlign: 'center',
  },
  uploadedImageStyle: {
    height: '90px',
    justifyContent: 'center',
    width: '20%',
    margin: '8px 8px',
  },
  imgBox: {
    display: 'flex',
    alignItems: 'center',
    width: '45px',
    borderRadius: '60px',
    height: '45px',
    marginRight: '10px',
    [theme.breakpoints.up('xs')]: {
      //  paddingRight: "20px",
    },
    [theme.breakpoints.up('md')]: {
      //  paddingLeft: "20px",
    },
  },
  taskDescriptionStyle: {
    color: '#6F6F6F !important',
    fontSize: '0.6rem !important',
    [theme.breakpoints.down(constant.breakMobileAuthPoint)]: {
      fontSize: '0.5rem !important',
    },
    [theme.breakpoints.down(constant.breakSmallMobileAuthPoint)]: {
      fontSize: '0.5rem !important',
      textWrap: true,
    },
  },
  childContainer1: {
    display: 'flex',
    alignItems: 'center',
  },
  modelTitleContainerStyle: {
    justifyContent: 'flex-start !important',
  },
  deleteTaskContainerStyle: {
    maxWidth: 480,
  },
  displayCenter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  displayStart: {
    display: 'flex',
    // justifyContent: 'start',
    // alignItems: 'center',
  },
  titleStyle: {
    color: '#333333',
    fontSize: 16,
    fontWeight: 600,
  },
  titleStyle2: {
    [theme.breakpoints.down('xs')]: {
      marginLeft: '20%',
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: '20%',
    },
  },
  rewardStyle: {
    marginLeft: 2,
    fontSize: 14,
    fontWeight: 500,
    color: '#6F6F6F',
  },
  scroll: {
    [theme.breakpoints.down('600')]: {
      overflowX: 'scroll',
    },
  },
  uploadImageBox: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    border: '1px solid #4B7DBE',
    marginTop: '1rem',
  },
  deleteIcon: { color: theme.customTheme.commonModalImagetext },
}));
const UpdateModal = props => {
  const {
    taskDetail,
    profile,
    isParentLoggedIn,
    updateChildrenTask,
    callBack,
    setUpdateModal,
    handleClose,
    handleEdit,
    loadings,
  } = props;
  const theme = useTheme();
  const [selectedImage, setSelectedImage] = React.useState([]);
  const [child, setChild] = React.useState();
  const [deleteTask, setDeleteTask] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState(false);
  const classes = useStyle(theme);

  const onSubmit = async () => {
    let media = [];
    if (selectedImage?.count <= 3 || selectedImage?.count === undefined) {
      for (let i = 0; i < (selectedImage?.count || 0); i++) {
        let response = await uploadProfilePic(
          'pocketmoneyMedia',
          selectedImage?.files[i],
        );
        if (response?.uploaded_docs_url?.[0]) {
          media.push(response?.uploaded_docs_url?.[0]);
        }
      }
      let data1 = {
        id: taskDetail.id,
        status: constant.childApprovedStatus,
        media,
      };

      updateChildrenTask(data1, callBack);
      handleClose();
    } else {
      setErrorMsg(true);
      var myDiv = document.getElementById('update-modal');
      myDiv.scrollTop = 0;
    }
  };

  const onParentApprove = () => {
    let data = {
      id: taskDetail.id,
      status: constant.parentApprovedStatus,
    };
    updateChildrenTask(data, callBack);
    handleClose();
  };
  const onSelectImageField = () => {
    let field = document.getElementById('update-task-image-upload');
    if (selectedImage?.files?.length >= 3) {
      setErrorMsg(true);
    } else {
      field?.click();
      setUpdateModal && setUpdateModal(true);
    }
  };

  const setSelectedFile = e => {
    e.persist();

    setSelectedImage(data => {
      return {
        count: (e?.target?.files?.length || 0) + (data?.count || 0),
        files: [...(data?.files || []), ...(e?.target?.files || [])],
      };
    });
  };

  const handleDeleteTask = () => {
    setDeleteTask(!deleteTask);
  };

  const handleEditTask = () => {
    handleEdit();
    handleClose();
  };
  const firstColumnSize =
    !profile?.isMinor && taskDetail.status == constant.createdStatus ? 5 : 7;
  const deleteFile = index => {
    setSelectedImage(data => {
      return {
        count: (data?.count || 0) - 1,
        files: data?.files?.filter((_, i) => i != index) || [],
      };
    });
    setErrorMsg(false);
  };

  return (
    <>
      <CommonModel
        title={'Delete task'}
        titleContainerStyle={classes.modelTitleContainerStyle}
        containerStyle={classes.deleteTaskContainerStyle}
        open={deleteTask}
        handleClose={handleDeleteTask}
        body={
          <DeleteTaskModal
            open={deleteTask}
            onDelete={handleClose}
            taskDetail={taskDetail}
            callBack={callBack}
            handleClose={handleDeleteTask}
          />
        }
      />
      <Box
        className={clsx(classes.createTaskContainer, 'horizontal-scrollbar')}>
        <Grid container spacing={1}>
          <Grid item xs={12} className={classes.scroll}>
            <Box style={{ minWidth: 500, paddingBottom: '2rem' }}>
              <Grid container>
                <Grid item container xs={12}>
                  <Grid item xs={firstColumnSize}></Grid>
                  <Grid item xs={3}>
                    <Box className={clsx(classes.displayCenter)}>
                      <Typography className={clsx(classes.titleStyle)}>
                        Date & Time
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={2}>
                    <Box className={clsx(classes.displayCenter)}>
                      <Typography className={clsx(classes.titleStyle)}>
                        Reward
                      </Typography>
                    </Box>
                  </Grid>
                  {!profile?.isMinor &&
                  taskDetail.status == constant.createdStatus ? (
                    <Grid item xs={2}>
                      <Box className={clsx(classes.displayCenter)}>
                        <Typography
                          className={clsx(
                            classes.titleStyle,
                            classes.titleStyle2,
                          )}>
                          Quick Actions
                        </Typography>
                      </Box>
                    </Grid>
                  ) : null}
                </Grid>
                <Grid item container xs={12} style={{ alignItems: 'center' }}>
                  <Grid item xs={firstColumnSize}>
                    <Box className={clsx(classes.displayStart)}>
                      <Box className={clsx(classes.childContainer)}>
                        {/* <AccountCircleIcon className={clsx(classes.profileIconStyle)} /> */}
                        {!isParentLoggedIn ? (
                          <>
                            {/* {taskDetail?.child?.profile_pic ? (
                              <img
                                src={`${process.env.REACT_APP_SPACE_KEY}/${taskDetail?.child?.profile_pic}`}
                                alt="profile"
                                className={classes.imgBox}
                              />
                            ) : ( */}
                            <AccountCircleIcon
                              className={classes.profileIconStyle}
                            />
                            {/* )} */}
                          </>
                        ) : (
                          <>
                            {taskDetail?.child?.profile_pic ? (
                              <img
                                src={`${process.env.REACT_APP_SPACE_KEY}/${taskDetail?.child?.profile_pic}`}
                                alt="profile"
                                className={classes.imgBox}
                              />
                            ) : (
                              <AccountCircleIcon className={classes.imgBox} />
                            )}
                          </>
                        )}

                        <Box className={clsx(classes.directionColumn)}>
                          <Box>
                            {isParentLoggedIn ? (
                              <>
                                <ContainerTitle
                                  title={
                                    (taskDetail?.child?.firstName || '') +
                                    ' ' +
                                    (taskDetail?.child?.lastName || '')
                                  }
                                  className={clsx(classes.nameStyle)}
                                />
                              </>
                            ) : (
                              <>
                                <ContainerTitle
                                  title={
                                    (taskDetail?.firstName || '') +
                                    ' ' +
                                    (taskDetail?.child?.lastName || '')
                                  }
                                  className={clsx(classes.nameStyle)}
                                />
                              </>
                            )}
                            <ContainerTitle
                              title={taskDetail?.taskTitle}
                              className={clsx(classes.taskNameStyle)}
                            />
                            <ContainerTitle
                              title={taskDetail?.taskDescription}
                              className={clsx(classes.taskDescriptionStyle)}
                            />
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={3}>
                    <Box
                      className={clsx(classes.displayCenter)}
                      style={{ flexDirection: 'column' }}>
                      <Box className={clsx(classes.childContainer1)}>
                        <DateRangeIcon
                          className={clsx(classes.calendarStyle)}
                        />
                        <span className={classes.dateStyle}>
                          {taskDetail.taskTimeStamp
                            ? getDateMonthYearFormat(taskDetail.taskTimeStamp)
                            : null}
                        </span>
                      </Box>
                      <Box>
                        <Box>
                          <span className={clsx(classes.timeStyle)}>
                            {taskDetail?.taskTimeStamp
                              ? getTime12HFormat(taskDetail.taskTimeStamp)
                              : null}
                          </span>
                          <span className={clsx(classes.amPmStyle)}>
                            {taskDetail?.taskTimeStamp
                              ? getAmPm(taskDetail.taskTimeStamp)
                              : null}
                            {'-'}
                          </span>
                          <span className={clsx(classes.timeStyle)}>
                            {taskDetail.deadline
                              ? getTime12HFormat(taskDetail.deadline)
                              : null}
                          </span>
                          <span className={clsx(classes.amPmStyle)}>
                            {taskDetail.deadline
                              ? getAmPm(taskDetail.deadline)
                              : null}
                          </span>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={2}>
                    <Box className={clsx(classes.displayCenter)}>
                      <img
                        src={CogCoin}
                        style={{ width: '20px', height: '20px' }}
                      />
                      <Typography className={clsx(classes.rewardStyle)}>
                        {taskDetail?.reward || 0}
                      </Typography>
                    </Box>
                  </Grid>
                  {!profile?.isMinor ? (
                    <Grid item xs={2}>
                      <Box className={clsx(classes.displayCenter)}>
                        {false && (
                          <ButtonBase
                            style={{ margin: 'auto 5px' }}
                            onClick={handleEditTask}>
                            <img
                              src={EditIcon}
                              style={{
                                width: '20px',
                                height: '20px',
                              }}
                            />
                          </ButtonBase>
                        )}
                        {taskDetail.status == constant.createdStatus ? (
                          <ButtonBase
                            style={{ margin: 'auto 5px' }}
                            onClick={handleDeleteTask}
                            id={`Approve-${taskDetail?.id}`}>
                            <img
                              src={DeleteIcon}
                              style={{
                                width: '20px',
                                height: '20px',
                              }}
                            />
                          </ButtonBase>
                        ) : null}
                      </Box>
                    </Grid>
                  ) : null}
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12} container spacing={1} justifyContent="center">
            {isParentLoggedIn ? (
              <>
                {taskDetail?.media?.map(image => {
                  return (
                    <Grid item xs={6} sm={4}>
                      <img
                        src={`${process.env.REACT_APP_SPACE_KEY}/${image}`}
                        className={classes.documentStyle}
                      />
                    </Grid>
                  );
                })}
              </>
            ) : (
              <>
                {taskDetail.status === constant.createdStatus &&
                isTaskActive(taskDetail.taskTimeStamp) ? (
                  <>
                    <Box
                      className={clsx(
                        classes.imageUploadContainer,
                        'hoverPointer',
                      )}
                      onClick={onSelectImageField}>
                      {errorMsg ? (
                        <Typography style={{ color: 'red' }}>
                          Cannot upload more than 3 images
                        </Typography>
                      ) : (
                        <>
                          <ImageOutlined
                            className={clsx(classes.uploadImageIconStyle)}
                          />

                          <Typography
                            className={clsx(classes.uploadImageTextStyle)}>
                            <text>Click to </text>
                            <text
                              style={{
                                color: theme.customTheme.commonModalImagetext,
                              }}>
                              upload from device
                            </text>
                          </Typography>
                        </>
                      )}
                    </Box>
                  </>
                ) : null}
                {taskDetail?.media?.length
                  ? taskDetail?.media?.map(image => {
                      return (
                        <Grid item xs={6} sm={6}>
                          <img
                            src={`${process.env.REACT_APP_SPACE_KEY}/${image}`}
                            className={classes.documentStyle}
                          />
                        </Grid>
                      );
                    })
                  : null}
                {selectedImage?.count ? (
                  <Box
                    className={clsx(
                      classes.selectedImageContainer,
                      'hoverPointer',
                    )}
                    // onClick={onSelectImageField}
                  >
                    {Array(selectedImage.count)
                      .fill(0)
                      .map((_, index) => {
                        return (
                          <>
                            <Box className={classes.uploadImageBox}>
                              <img
                                key={index}
                                src={URL.createObjectURL(
                                  selectedImage?.files[index],
                                )}
                                className={clsx(classes.uploadedImageStyle)}
                              />
                              <Box
                                sx={{
                                  flexDirection: 'column',
                                  width: '70%',
                                }}>
                                <Typography>
                                  {maxStringLength(
                                    selectedImage?.files[index]?.name || '',
                                    20,
                                  )}
                                </Typography>
                                <Typography>
                                  {sizeInMegaBytes(
                                    Number(selectedImage?.files[index]?.size),
                                  )
                                    ?.toFixed(2)
                                    .toLocaleString('en-US') + `MB`}
                                </Typography>
                              </Box>
                              <Box>
                                <BaseButton
                                  text={
                                    <DeleteOutlined
                                      className={classes.deleteIcon}
                                    />
                                  }
                                  onClick={() => deleteFile(index)}
                                />
                              </Box>
                            </Box>
                          </>
                        );
                      })}
                  </Box>
                ) : null}

                <input
                  style={{ display: 'none' }}
                  id="update-task-image-upload"
                  type="file"
                  onChange={setSelectedFile}
                  multiple
                  maxlength="4"
                  accept=".jpeg,.jpg,.png"
                />

                {/* <button>Remove</button> */}
              </>
            )}
          </Grid>

          {isParentLoggedIn ? (
            <>
              {taskDetail.status === constant.childApprovedStatus ? (
                <Grid item xs={12} style={{ marginTop: 12 }}>
                  <Box className={clsx(classes.buttonContainer)}>
                    <BorderButton
                      text={loadings ? <SmallLoader /> : 'Approve'}
                      onClick={onParentApprove}
                      id={`Approve-${taskDetail?.id}`}
                      className={classes.approveButtonStyle}
                    />
                  </Box>
                </Grid>
              ) : null}
            </>
          ) : (
            <>
              {taskDetail.status === constant.createdStatus &&
              isTaskActive(taskDetail.taskTimeStamp) ? (
                <Grid item xs={12}>
                  <Box className={clsx(classes.buttonContainer)}>
                    <BorderButton
                      text={loadings ? <SmallLoader /> : 'Submit'}
                      onClick={onSubmit}
                      className={classes.approveButtonStyle}
                      id={`submit-${taskDetail?.id}`}
                      disabled={loadings}
                    />
                  </Box>
                </Grid>
              ) : null}
            </>
          )}
        </Grid>
      </Box>
    </>
  );
};

const mapStateToProps = ({ userReducer }) => {
  return {
    profile: userReducer.profile,
    loadings: userReducer.loadings.updatingTaskStatus,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateChildrenTask: (data, callBack) => {
      dispatch(actionCreator.updateTaskAction(data, callBack));
    },
    notify: data => {
      dispatch(actionCreator.notify(data));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(UpdateModal);
