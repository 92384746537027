import { Box, makeStyles, Typography, useTheme } from '@material-ui/core';
import clsx from 'clsx';
import { connect } from 'react-redux';
import * as actionCreator from '../../../../store/action';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { constant } from '../../../../utils/constant';
import { maxStringLength } from '../../../../utils/stringMethods';
import ContainerTitle from '../../../../components/common/title/ContainerTitle';
import {
  getAmPm,
  getDateMonthYearFormat,
  getTime12HFormat,
  isTaskActive,
} from '../../../../utils/timeMethods';
import DateRangeIcon from '@material-ui/icons/DateRange';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import React, { useEffect } from 'react';
import CommonModel from '../../../../components/common/model/CommonModel';
import ProfilePic from '../../../../components/profilePic/ProfilePic';
import BorderButton from '../../../../components/buttons/BorderButton';
import UpdateModal from '../../../../components/tasks/UpdateModal';
import {
  CheckBoxOutlineBlank,
  CheckBoxOutlineBlankOutlined,
  CheckBoxOutlined,
} from '@material-ui/icons';

const useStyle = makeStyles(theme => ({
  created: {
    backgroundColor: theme.customTheme.createdTaskColor,
  },
  inProgress: {
    backgroundColor: theme.customTheme.overDuetaskColor,
  },
  childApproved: {
    backgroundColor: theme.customTheme.completedStatusColor,
  },
  parentApproved: {
    backgroundColor: theme.customTheme.completedTasksBackground,
  },
  overDue: {
    backgroundColor: theme.customTheme.overDuetaskColor,
  },
  createdCheckIcon: {
    color: theme.customTheme.createdCheckIcon,
  },
  inProgressCheckIcon: {
    color: '#C9E5EC',
  },
  childApprovedCheckIcon: {
    color: '#FBA754',
  },
  parentApprovedCheckIcon: {
    color: theme.customTheme.completedCheckedBoxIcon,
  },
  overDueCheckIcon: {
    color: '#C49C39',
  },
  container: {
    padding: '0.3rem 0.6rem',
    borderRadius: 8,
  },
  innerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  childContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  directionColumn: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    flexDirection: 'column',
  },
  profileIconStyle: {
    fontSize: '2rem',
    marginRight: '0.5rem',
    [theme.breakpoints.up(constant.breakLaptopAuthPoint)]: {
      fontSize: '2.5rem',
      marginRight: '0.7rem',
    },
    [theme.breakpoints.down(constant.breakMobileAuthPoint)]: {
      fontSize: '1.8rem',
    },
    [theme.breakpoints.down(constant.breakSmallMobileAuthPoint)]: {
      fontSize: '1.6rem',
    },
  },
  unCheckedColor: {
    color: '#359DB6',
  },
  checkedColor: {
    color: '#C49C39',
  },
  checkedIconStyle: {
    fontSize: '1.4rem',
    marginRight: '0.5rem',
    [theme.breakpoints.up(constant.breakLaptopAuthPoint)]: {
      fontSize: '1.9rem',
      marginRight: '0.7rem',
    },
    [theme.breakpoints.down(constant.breakMobileAuthPoint)]: {
      fontSize: '1.5rem',
      marginRight: '0.4rem',
    },
    [theme.breakpoints.down(constant.breakSmallMobileAuthPoint)]: {
      fontSize: '1.1rem',
      marginRight: '0.3rem',
    },
  },
  nameStyle: {
    fontSize: '0.9rem !important',
    [theme.breakpoints.down(constant.breakMobileAuthPoint)]: {
      fontSize: '0.8rem !important',
    },
    [theme.breakpoints.down(constant.breakSmallMobileAuthPoint)]: {
      fontSize: '0.7rem !important',
    },
  },
  divideDescription: {
    margin: '0 0.5rem',
  },
  taskNameStyle: {
    color: '#6F6F6F !important',
    fontSize: '0.6rem !important',
    overflow: 'elipsis',
    [theme.breakpoints.down(constant.breakMobileAuthPoint)]: {
      fontSize: '0.5rem !important',
    },
    [theme.breakpoints.down(constant.breakSmallMobileAuthPoint)]: {
      fontSize: '0.5rem !important',
    },
  },
  timeStyle: {
    fontSize: '0.6rem',
    fontWeight: 550,
    color: '#333333',
    [theme.breakpoints.down(constant.breakMobileAuthPoint)]: {
      fontSize: '0.5rem !important',
    },
    [theme.breakpoints.down(constant.breakSmallMobileAuthPoint)]: {
      fontSize: '0.5rem !important',
    },
  },
  amPmStyle: {
    fontSize: '0.6rem',
    fontWeight: 500,
    color: '#999999',
  },
  calendarStyle: {
    fontSize: '1rem',
    color: '#6F6F6F',
    [theme.breakpoints.down(constant.breakMobileAuthPoint)]: {
      fontSize: '0.9rem !important',
    },
    [theme.breakpoints.down(constant.breakSmallMobileAuthPoint)]: {
      fontSize: '0.7rem !important',
    },
  },
  dateStyle: {
    fontSize: '0.7rem',
    color: '#6F6F6F',
    marginLeft: '0.3rem',
    [theme.breakpoints.down(constant.breakMobileAuthPoint)]: {
      fontSize: '0.6rem !important',
      marginLeft: '0.2rem',
    },
    [theme.breakpoints.down(constant.breakSmallMobileAuthPoint)]: {
      fontSize: '0.5rem !important',
      marginLeft: '0.1rem',
    },
  },
  modelTitleContainerStyle: {
    justifyContent: 'flex-start !important',
  },
  rightContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    flexDirection: 'column',
    alignItems: 'center',
  },
  approveButtonStyle: {
    color: theme.customTheme.statusColor,
    border: theme.customTheme.statusButtonBorder,
    backgroundColor: 'transparent',
    fontSize: '0.7rem',
    fontWeight: 400,
    height: 20,
    padding: 0,
    width: 70,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  taskStatusStyle: {
    fontSize: 12,
    fontWeight: 600,
  },
  completedStatusColor: {
    color: theme.customTheme.completedCheckedBoxIcon,
  },

  onGoingTaskColor: {
    color: theme.customTheme.createdCheckIcon,
  },
  overDueTaskColor: {
    color: theme.customTheme.createdTaskColor,
  },
  overDueIcon: {
    color: theme.customTheme.overDueIcon,
  },
  titleTextStyle: {
    color: theme.customTheme.commonModalTextStyle,
  },
  modalContainerSyle: {
    borderRadius: '20px',
    // [theme.breakpoints.up('md')]: {
    //   minWidth: '30vw',
    //   maxWidth: '45vw',
    // },
  },
  closeStyleBox: {
    borderRadius: 50,
    border: '2px solid #fff',
    color: '#fff !important',
  },
}));
const KidsTaskContainer = props => {
  const {
    checked,
    childList,
    taskDetail,
    profile,
    isParentLoggedIn,
    guardianList,
  } = props;
  const theme = useTheme();
  const classes = useStyle(theme);
  const [updateModal, setUpdateModal] = React.useState(null);
  const [profileImage, setProfileImage] = React.useState(null);
  const handleUpdateTaskModal = () => {
    // if (
    //   !checked &&
    //   (taskDetail.status == constant.createdStatus ||
    //     taskDetail.status == constant.inProgress)
    // ) {
    setUpdateModal(!updateModal);
    // }
  };
  useEffect(() => {
    for (let index = 0; index < guardianList.length; index++) {
      if (guardianList[index]._id === taskDetail?.createdBy) {
        setProfileImage(guardianList[index].profile_pic);
      }
    }
  }, []);
  const taskStatus =
    taskDetail.status == constant.parentApprovedStatus
      ? 'Completed'
      : taskDetail.status == constant.childApprovedStatus
      ? 'Submitted'
      : taskDetail.status == constant.createdStatus
      ? isTaskActive(taskDetail.taskTimeStamp)
        ? 'OnGoing'
        : 'Scheduled'
      : '';

  const taskStatusColor =
    taskDetail.status == constant.parentApprovedStatus
      ? classes.completedStatusColor
      : taskDetail.status == constant.childApprovedStatus
      ? classes.onGoingTaskColor
      : taskDetail.status == constant.createdStatus
      ? isTaskActive(taskDetail.taskTimeStamp)
        ? classes.overDueCheckIcon
        : classes.onGoingTaskColor
      : '';

  return (
    <Box
      className={clsx(
        classes.container,
        taskDetail.status === constant.createdStatus
          ? isTaskActive(taskDetail.taskTimeStamp)
            ? classes.inProgress
            : classes.created
          : taskDetail.status === constant.childApprovedStatus
          ? classes.created
          : taskDetail.status === constant.parentApprovedStatus
          ? classes.parentApproved
          : taskDetail.status === constant.overdueStatus
          ? classes.overDue
          : '',

        // checked ? classes.checkedTaskContainer : classes.unCheckedTaskContainer
      )}>
      <CommonModel
        title={
          taskDetail.status === constant.createdStatus &&
          isTaskActive(taskDetail.taskTimeStamp)
            ? constant.updateTask
            : 'Task Detail'
        }
        titleContainerStyle={classes.modelTitleContainerStyle}
        open={updateModal}
        containerStyle={classes.modalContainerSyle}
        handleClose={handleUpdateTaskModal}
        titleStyle={classes.titleTextStyle}
        closeStyle={classes.closeStyleBox}
        body={
          <UpdateModal
            open={updateModal}
            handleClose={handleUpdateTaskModal}
            taskDetail={{ ...taskDetail, profile }}
            isParentLoggedIn={isParentLoggedIn}
          />
        }
      />
      <Box
        className={clsx(classes.innerContainer)}
        onClick={handleUpdateTaskModal} id='task-Card-Dashboard'>
        <Box className={clsx(classes.childContainer)}>
          {checked == 1 ? (
            <CheckBoxOutlined
              className={clsx(
                classes.checkedIconStyle,
                taskDetail.status === constant.parentApprovedStatus
                  ? classes.parentApprovedCheckIcon
                  : taskDetail.status === constant.overdueStatus
                  ? classes.onGoingTaskColor
                  : '',
              )}
            />
          ) : (
            <CheckBoxOutlineBlankOutlined
              className={clsx(
                classes.checkedIconStyle,
                taskStatus == 'OnGoing'
                  ? classes.overDueIcon
                  : taskStatus == 'Scheduled'
                  ? classes.onGoingTaskColor
                  : taskStatus == 'Submitted'
                  ? classes.onGoingTaskColor
                  : ' ',
              )}
            />
          )}
          {profile?.profile_pic ? (
            <ProfilePic
              pic={`${process.env.REACT_APP_SPACE_KEY}/${profileImage}`}
            />
          ) : (
            <AccountCircleIcon className={clsx(classes.profileIconStyle)} />
          )}
          <Box className={clsx(classes.directionColumn)}>
            <Box>
              <ContainerTitle
                title={maxStringLength(taskDetail?.taskTitle || '', 16)}
                className={clsx(classes.nameStyle)}
              />
            </Box>
            <Box className={classes.childContainer}>
              <ContainerTitle
                title={maxStringLength(taskDetail?.taskDescription || '', 12)}
                className={clsx(classes.taskNameStyle)}
              />
              &nbsp;
              <Box className={clsx(classes.childContainer)}>
                <span className={clsx(classes.timeStyle)}>
                  {taskDetail?.taskTimeStamp
                    ? getTime12HFormat(taskDetail.taskTimeStamp)
                    : null}
                </span>
                <span className={clsx(classes.amPmStyle)}>
                  {taskDetail?.taskTimeStamp
                    ? getAmPm(taskDetail.taskTimeStamp)
                    : null}
                  {'-'}
                </span>
                <span className={clsx(classes.timeStyle)}>
                  {taskDetail.deadline
                    ? getTime12HFormat(taskDetail.deadline)
                    : null}
                </span>
                <span className={clsx(classes.amPmStyle)}>
                  {taskDetail.deadline ? getAmPm(taskDetail.deadline) : null}
                </span>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className={clsx(classes.rightContainer)}>
          {taskDetail.status === constant.createdStatus &&
          isTaskActive(taskDetail.taskTimeStamp) ? (
            <BorderButton
              className={classes.approveButtonStyle}
              text={'Submit'}
              onClick={handleUpdateTaskModal}
            />
          ) : (
            <ContainerTitle
              title={taskStatus}
              className={clsx(classes.taskStatusStyle, taskStatusColor)}
            />
          )}
          <Box className={clsx(classes.childContainer)}>
            <DateRangeIcon className={clsx(classes.calendarStyle)} />
            <span className={classes.dateStyle}>
              {taskDetail.taskTimeStamp
                ? getDateMonthYearFormat(taskDetail.taskTimeStamp)
                : null}
            </span>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const mapStateToProps = ({ userReducer }) => {
  return {
    childList: userReducer.childList,
    profile: userReducer.profile,
    guardianList: userReducer.guardianList,
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(KidsTaskContainer);
