import { Backdrop, Box, makeStyles, Modal, useTheme } from '@material-ui/core';
import ContainerTitle from '../title/ContainerTitle';
import CloseIcon from '@material-ui/icons/Close';
import BaseButton from '../../buttons/BaseButton';
import clsx from 'clsx';

const useStyle = makeStyles(theme => ({
  modal: {
    // alignItems: "center",
    display: 'flex',
    margin: 'auto',
    alignItems: 'center',
    justifyContent: 'center',
  },
  container: {
    minWidth: '50vw',
    maxWidth: '800px',
    width: '90vw',
    maxHeight: '96vh',
    overflowY: 'scroll',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    padding: '1rem 1.6rem 1rem 1.6rem',
    backgroundColor: theme.customTheme.commonModalHeaderBackground,
  },
  body: {
    padding: '1.6rem 1.6rem 1.6rem 1.6rem',
    backgroundColor: theme.customTheme.commonModalBackground,
    display: 'flex',
    justifyContent: 'center',
  },
  titleContainer: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
  },
  closeStyle: {
    borderRadius: 50,
    border: '2px solid #333',
    color: '#333 !important',
  },
}));

const CommonModel = props => {
  const {
    open,
    handleClose,
    title,
    close,
    body,
    headerStyle,
    bodyStyle,
    titleStyle,
    closeStyle,
    titleContainerStyle,
    containerStyle,
  } = props;
  const theme = useTheme();
  const classes = useStyle(theme);

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}>
        <Box className={clsx(classes.container, containerStyle)} 
        id='update-modal'>
          {title ? (
            <Box className={clsx(classes.header, headerStyle)}>
              <Box
                className={clsx(classes.titleContainer, titleContainerStyle)}>
                <ContainerTitle title={title} className={clsx(titleStyle)} />
              </Box>
              {close ? (
                <Box>
                  <BaseButton
                    className={clsx(classes.closeStyle, closeStyle)}
                    text={<CloseIcon />}
                    onClick={handleClose}
                    id='close'
                  />
                </Box>
              ) : null}
            </Box>
          ) : null}
          <Box className={clsx(classes.body, bodyStyle)}>{body}</Box>
        </Box>
      </Modal>
    </>
  );
};
CommonModel.defaultProps = {
  open: false,
  handleClose: () => {},
  title: '',
  close: true,
  headerStyle: '',
  bodyStyle: '',
  titleStyle: '',
  closeStyle: '',
  titleContainerStyle: '',
  containerStyle: '',
};
export default CommonModel;
