import { Button, ButtonBase, makeStyles, Typography } from '@material-ui/core';
import { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import BorderButton from '../../../components/buttons/BorderButton';
import { constant } from '../../../utils/constant';
import './header.css';
import LOGO from '../../../assets/images/logo-light.svg';
import { useEffect } from 'react';
import { Box } from '@mui/system';
import CommonModel from '../model/CommonModel';
import RedirectInfoPage from '../../../pages/preLoggedIn/redirectInfo/RedirectInfoPage';
import { Android, Apple } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  buttonContainer: {
    width: '67%',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    [theme.breakpoints.down(960)]: {
      width: '85%',
    },
    [theme.breakpoints.down(850)]: {
      width: '80%',
    },
    [theme.breakpoints.down(600)]: {
      width: '76%',
      alignItems: 'center',
      justifyContent: 'space-around',
    },

    [theme.breakpoints.down(400)]: {
      width: '62%',
    },
  },
  submenuItems: {
    position: 'absolute',
    padding: '1%',
    background: '#fff',
    borderRadius: '15px',
    width: '180px',
    top: '20px',
    left: '-30px',
    zIndex: 1000,
    boxShadow: '2px 2px 2px 2px rgba(27,27,27,0.2)',
    '& :hover': {
      background: '#C9E5EC',
      width: '100%',
      fontWeight: 700,
      borderRadius: '15px',
    },

    [theme.breakpoints.down('xs')]: {
      position: 'relative',
      left: 0,
      display: 'block',
      padding: '0',
      zIndex: 'auto',
      top: 0,
      boxShadow: '0 0 0 0 transparent',
      background: 'transparent',
      color: '#fff',
      borderRadius: '0',
      borderTop: '2px #ffff',
      width: '200px',
    },
  },
  submenuItem: {
    padding: '4% 0%',
    color: '#0000',
    //
  },
  submenuItemLink: {
    fontSize: '16px',
    textDecoration: 'none',
    color: 'black',
    [theme.breakpoints.down('xs')]: {
      color: '#ffff',
    },
  },
  AppLinksBox: {
    display: 'flex',
    flexDirection: 'row',
    // justifyContent: 'space-around',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      // flexDirection: 'column',
      // marginRight: '4%',
    },
  },
  appLinkButton: {
    border: '1px solid #359db6',
    fontSize: 9,
    height: '60%',
    width: '10vw',
    [theme.breakpoints.down('sm')]: {
      height: 'auto',
      border: '1px solid #359db6',
      fontSize: 6,
      width: '20vw',
    },
  },
}));
const Header = props => {
  const [click, setClick] = useState(false);
  const [button, setButton] = useState(false);
  const [navbar, setNavbar] = useState(false);
  const [redirectInfo, setRedirectInfo] = useState(false);
  const [aboutUsSubMenu, setAboutUsSubMenu] = useState(false);
  // const [subMenu, setSubMenu] = useState(false);
  const classes = useStyles();
  const handleClick = () => setClick(!click);
  const history = useHistory();

  const redirect = route => {
    history.push(route);
  };
  const closeMobileMenu = () => setClick(false);

  const handleMouseEnterAbout = e => {
    setAboutUsSubMenu(true);
  };

  const handleMouseLeaveAbout = e => {
    setAboutUsSubMenu(false);
  };
  const handleSubmenu = () => {
    setAboutUsSubMenu(!aboutUsSubMenu);
  };
  // const handleMouseEnterContact = e => {
  //   setSubMenu(true);
  // };
  // const handleMouseLeaveContact = e => {
  //   setSubMenu(false);
  // };

  const showButton = () => {
    if (window.innerWidth <= 900) {
      setButton(false);
    } else {
      setButton(true);
    }
  };

  const handleRequestInfo = () => {
    setRedirectInfo(!redirectInfo);
  };

  useEffect(() => {
    showButton();
  }, []);
  window.addEventListener('resize', showButton);
  const changeBackground = () => {
    if (window.scrollY >= 80) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  window.addEventListener('scroll', changeBackground);

  return (
    <>
      <CommonModel
        title="Please Note"
        open={redirectInfo}
        handleClose={() => handleRequestInfo()}
        body={
          <RedirectInfoPage
            open={redirectInfo}
            handleClose={() => handleRequestInfo()}
          />
        }
      />
      <nav className={navbar ? 'navbar active' : 'navbar'}>
        <div className="navbar-container">
          <div>
            <Link to="/" onClick={closeMobileMenu}>
              <img
                id="logo-img"
                src={LOGO}
                alt="LOGO"
                style={{
                  width: '45px',
                  paddingTop: '10px',
                  color: '#359DB6',
                  alignItem: 'left',
                }}
              />
            </Link>
          </div>

          {!button && (
            <div className={classes.buttonContainer}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <BorderButton
                  // className={classes.buttonContainer}
                  text="Sign up"
                  id="Sign-Up"
                  //   {...constant.lightButtonStyle}
                  width="95px"
                  height="9vw"
                  style={{
                    backgroundColor: 'transparent',
                    color: '#359DB6',
                    border: '1.5px solid #359DB6',
                    borderRadius: '12px',
                    margin: '0rem 0.8rem 0rem 0rem',
                  }}
                  onClick={() => {
                    // window.open(
                    //   `${process.env.REACT_APP_WEBSITE_HOST}/signup`,
                    //   '_blank',
                    // );
                    // redirect(constant.routes.redirectInfo);
                    handleRequestInfo();
                  }}
                />

                <div className="menu-icon" onClick={handleClick}>
                  <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
                </div>
              </div>
            </div>
          )}
          <div className="menu-icon" onClick={handleClick}>
            <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
          </div>

          <ul className={click ? 'nav-menu active' : 'nav-menu'}>
            <Box className="nav-item">
              <Link to="/" className="nav-links" onClick={closeMobileMenu}>
                HOME
              </Link>
            </Box>
            {/* <Box sx={{ display: 'flex', flexDirection: 'column' }}> */}
            <Box
              className="nav-item"
              onMouseEnter={handleMouseEnterAbout}
              onMouseLeave={handleMouseLeaveAbout}
              onClick={handleSubmenu}>
              <Link
                // to="/aboutUs"
                className="nav-links">
                ABOUT
              </Link>
              {aboutUsSubMenu ? (
                <ul className={classes.submenuItems}>
                  <li className={classes.submenuItem}>
                    <Link
                      to="/aboutUs"
                      onClick={closeMobileMenu}
                      className={classes.submenuItemLink}>
                      About Us
                    </Link>
                  </li>
                  <li className={classes.submenuItem}>
                    <Link
                      to="/ourStory"
                      onClick={closeMobileMenu}
                      className={classes.submenuItemLink}>
                      OUR STORY
                    </Link>
                  </li>
                  <li className={classes.submenuItem}>
                    <Link
                      to="/missionVision"
                      onClick={closeMobileMenu}
                      className={classes.submenuItemLink}>
                      MISSION & VISION
                    </Link>
                  </li>
                </ul>
              ) : null}
            </Box>

            {/* </Box> */}

            <Box className="nav-item">
              <Link
                to="/contactUs"
                className="nav-links"
                // style={{ textDecoration: 'none' }}
                onClick={closeMobileMenu}>
                CONTACT
              </Link>
            </Box>

            {/* <Box className="nav-item">
              <Link
                to="/careers"
                className="nav-links"
                onClick={closeMobileMenu}>
                CAREERS
              </Link>
            </Box> */}

            {!button && (
              <>
                <Box className="nav-item">
                  <Link
                    to={constant.routes.accountType}
                    className="nav-links"
                    onClick={closeMobileMenu}>
                    LOGIN
                  </Link>
                </Box>
              </>
            )}
          </ul>
          {button && (
            <>
              <BorderButton
                // className={classes.buttonContainer}
                text="Sign up"
                id="Sign-up"
                {...constant.transparentButton}
                width="100px"
                style={{
                  margin: '0.5rem 0.8rem 0rem 0rem',
                }}
                onClick={() => {
                  // window.open(
                  //   `${process.env.REACT_APP_WEBSITE_HOST}/signup`,
                  //   '_blank',
                  // );
                  // redirect(constant.routes.redirectInfo);
                  handleRequestInfo();
                }}
              />

              <BorderButton
                className={classes.buttonContainer}
                text="Log in "
                id="Log-In"
                //   {...constant.lightButtonStyle}
                width="80px"
                style={{
                  margin: '0.5rem 0rem 0rem 0rem',
                }}
                onClick={() => {
                  redirect(constant.routes.accountType);
                }}
              />
            </>
          )}
        </div>
      </nav>
    </>
  );
};
export default Header;
