import {
  Box,
  Grid,
  makeStyles,
  TextField,
  Typography,
  useTheme,
} from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';
import { constant } from '../../../utils/constant';
import BorderButton from '../../../components/buttons/BorderButton';
import { useHistory } from 'react-router-dom';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import FormikTextField from '../../../components/formikInputFields/FormikTextField';
import BaseButton from '../../../components/buttons/BaseButton';
import * as actionCreator from '../../../store/action';
import Title from './Title';
import { capitalFirstLetter } from '../../../utils/stringMethods';
import SmallLoader from '../../../components/loader/SmallLoader.js';

const useStyles = makeStyles(theme => ({
  selectionContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    padding: '0 29%',
    marginTop: 40,
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      padding: 0,
      justifyContent: 'center',
      marginTop: 10,
      width: '100%',
    },
  },
  innerSelectionContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      alignItems: 'center',
    },
  },
  backtextStyle: {
    fontSize: 13,
  },
  backIconStyle: {
    fontSize: 18,
  },
  titleFont: {
    fontSize: 22,
    color: '#155B6B',
    fontWeight: 800,
  },
  subTitleFont: {
    fontSize: 14,
    color: '#359DB6',
    fontWeight: 500,
    marginTop: 4,
  },
  titleContainer: {
    marginTop: 16,
    marginBottom: 32,
  },
  buttonContainer: {
    marginTop: 16,
  },
  loginField: {
    marginTop: 6,
  },
  formikStyle: {
    width: '100%',
  },
  loginAs: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 8,
  },
  ...constant.authButtonStyle(theme),
  ...constant.loginInputStyle(theme),
  ...constant.loginHelperTextStyle(theme),
}));

const SigninSchema = Yup.object().shape({
  password: Yup.string().required('Required'),
  id: Yup.string().email('Invalid email').required('Required'),
});

const LoginContainer = props => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { isParent, childrenLogin, parentLogin, setParentReset, loadings } =
    props;
  const history = useHistory();
  const redirect = route => {
    history.push(route);
  };
  React.useEffect(() => {
    setParentReset({ isParentReset: !!isParent });
  }, []);
  return (
    <Grid item xs={12}>
      <Box className={clsx(classes.selectionContainer)}>
        <Box className={clsx(classes.innerSelectionContainer)}>
          <Title
            title={constant.loginTitle}
            subtitle={constant.loginSubtitle(
              isParent ? constant.parent : constant.kid,
            )}
          />
          <Formik
            initialValues={{
              password: '',
              id: '',
            }}
            validationSchema={SigninSchema}
            onSubmit={values => {
              // same shape as initial values
              if (isParent) {
                parentLogin(values);
              } else {
                childrenLogin(values);
              }
            }}>
            {({ errors, touched }) => (
              <Form className={clsx(classes.formikStyle)}>
                <Box className={clsx(classes.loginField)}>
                  <FormikTextField
                    className={classes.inputStyle}
                    placeholder={
                      constant.email[0].toUpperCase() + constant.email.slice(1)
                    }
                    name={constant.id}
                    type={constant.email}
                    touched={touched.id}
                    error={errors.id}
                    id={constant.email}
                    style={{ width: '100%' }}
                  />
                </Box>
                <Box className={clsx(classes.loginField)}>
                  <FormikTextField
                    className={classes.inputStyle}
                    placeholder={
                      constant.password[0].toUpperCase() +
                      constant.password.slice(1)
                    }
                    id={constant.password}
                    name={constant.password}
                    type={constant.password}
                    touched={touched.password}
                    error={errors.password}
                    style={{ width: '100%' }}
                  />
                </Box>
                <Box>
                  <Typography className={clsx(classes.loginHelperTextStyle)}>
                    {constant.forgotPassword + ' '}
                    <BaseButton
                      text={constant.reset}
                      id={constant.reset}
                      className={clsx(classes.loginHelperTextStyle)}
                      style={{ color: '#F0795C', fontWeight: 600 }}
                      onClick={() => redirect(constant.routes.resetPassword)}
                    />
                  </Typography>
                </Box>
                <Box className={clsx(classes.loginField)}>
                  <BorderButton
                    text={
                      loadings.loginUser ? (
                        <SmallLoader />
                      ) : (
                        capitalFirstLetter(constant.login)
                      )
                    }
                    className={classes.buttonStyle}
                    type={constant.submit}
                    id={isParent ? 'parent-login' : 'kid-login'}
                    style={{ width: '100%' }}
                    disabled={loadings.loginUser}
                  />
                </Box>
                <Box className={clsx(classes.loginAs)}>
                  <BaseButton
                    onClick={() =>
                      redirect(
                        isParent
                          ? constant.routes.childrenLogin
                          : constant.routes.parentLogin,
                      )
                    }
                    text={
                      <Typography
                        className={clsx(classes.loginHelperTextStyle)}>
                        {constant.loginAs(
                          isParent ? constant.kid : constant.parent,
                          <span style={{ color: '#F0795C' }} id="login-as">
                            {constant.login}
                          </span>,
                        )}
                      </Typography>
                    }
                    style={{ fontWeight: 600 }}
                  />
                </Box>
              </Form>
            )}
          </Formik>
        </Box>
      </Box>
    </Grid>
  );
};

const mapStateToProps = ({ userReducer }) => {
  return { loadings: userReducer.loadings };
};
const mapDispatchToProps = dispatch => {
  return {
    childrenLogin: data => dispatch(actionCreator.childrenLoginAction(data)),
    parentLogin: data => dispatch(actionCreator.parentLoginAction(data)),
    setParentReset: data => dispatch(actionCreator.setParentReset(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(LoginContainer);
