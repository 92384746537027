import {
  Box,
  Grid,
  makeStyles,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import clsx from 'clsx';
import { connect } from 'react-redux';
import * as actionCreator from '../../store/action';
import { constant } from '../../utils/constant';
import TaskContainer from '../../pages/dashboard/components/TaskContainer';
import React from 'react';
import KidsTaskContainer from '../../pages/kidSection/kidsDashboard/components/KidsTaskContainer';
import ParentTransparent from '../../assets/images/Parent_Transparent.gif';
import { taskListHeight } from '../../utils/dom';
const useStyle = makeStyles(theme => ({
  container: {},
  taskContainer: {
    marginBottom: '0.7rem',
  },
  kidloader: {
    width: '20%',
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      width: '50%',
    },
    [theme.breakpoints.down('md')]: {
      width: '50%',
    },
  },
  parentloader: {
    width: '20%',
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      width: '50%',
    },
    [theme.breakpoints.down('md')]: {
      width: '30%',
    },
  },
}));
const TaskList = props => {
  const {
    profile,
    checked,
    getTaskList,
    completedTaskList,
    updateTaskList,
    allTaskList,
    paginationCompleteTask,
    paginationAllTask,
    paginationUpdateTask,
    taskContainerId,
    createdLoading,
    completedLoading,
    overDueLoading,
  } = props;
  let isParentLoggedIn = !profile?.isMinor;
  const theme = useTheme();
  const classes = useStyle(theme);
  const firstLoad = React.useRef(true);
  const elementRef = React.useRef();
  const matches = useMediaQuery(theme.breakpoints.down('xs'));
  const getTaskMethod = () => {
    let filter = {
      sort: {
        updated_at: -1,
      },
    };
    let paginationData =
      checked == 1
        ? paginationCompleteTask
        : checked == 2
        ? paginationUpdateTask
        : paginationAllTask;
    if (paginationData?.filter?.selectedChild) {
      filter.childId = paginationData?.filter?.selectedChild?._id;
    }
    if (checked == 1) {
      filter.status = constant.parentApprovedStatus;
    } else if (checked == 2) {
      filter.status = constant.childApprovedStatus;
    } else {
      filter.filter = {
        $and: [
          { status: { $ne: constant.parentApprovedStatus } },
          { status: { $ne: constant.childApprovedStatus } },
        ],
      };
    }

    filter = {
      ...filter,
      limit: paginationData.limit,
      skip: paginationData.skip * paginationData.limit,
    };
    getTaskList(filter);
  };
  React.useEffect(() => {
    if (isParentLoggedIn) {
      let paginationData =
        checked == 1
          ? paginationCompleteTask
          : checked == 2
          ? paginationUpdateTask
          : paginationAllTask;
      if (firstLoad.current) {
        getTaskMethod();
      }
      firstLoad.current = paginationData.total === null ? false : true;
    } else {
      getTaskMethod();
    }
  }, [
    checked == 1
      ? paginationCompleteTask
      : checked == 2
      ? paginationUpdateTask
      : paginationAllTask,
  ]);

  React.useEffect(() => {
    if (!elementRef.current || matches) return; // wait for the elementRef to be available
    const resizeObserver = new ResizeObserver(() => {
      // Do what you want to do when the size of the element changes
      taskListHeight();
    });
    resizeObserver.observe(elementRef.current);
    return () => resizeObserver.disconnect(); // clean up
  }, [matches]);

  const loading =
    checked == 1
      ? completedLoading
      : checked == 2
      ? overDueLoading
      : createdLoading;

  return (
    <Box className={clsx(classes.container)} id={taskContainerId}>
      <div id={`${taskContainerId}innerDiv`} ref={elementRef}>
        {!loading ? (
          (checked == 2
            ? updateTaskList
            : checked == 1
            ? completedTaskList
            : allTaskList
          )?.task?.map((data, index) => {
            return (
              <Box className={clsx(classes.taskContainer)} key={data.id}>
                <TaskContainer
                  checked={checked}
                  taskDetail={data}
                  index={index}
                  isParentLoggedIn={isParentLoggedIn}
                />
              </Box>
            );
          })
        ) : (
          <Grid container justifyContent="center">
            <>
              <img src={ParentTransparent} className={classes.parentloader} />
            </>
          </Grid>
        )}
      </div>
    </Box>
  );
};

const mapStateToProps = ({ userReducer, paginationReducer }) => {
  return {
    updateTaskList: userReducer.updateTasks,
    completedTaskList: userReducer.completedTasks,
    allTaskList: userReducer.allTasks,
    paginationUpdateTask: paginationReducer.paginationUpdateTask,
    paginationCompleteTask: paginationReducer.paginationCompleteTask,
    paginationAllTask: paginationReducer.paginationAllTask,
    completedLoading: userReducer.loadings.getTasksCompleted,
    createdLoading: userReducer.loadings.getTasksCreated,
    overDueLoading: userReducer.loadings.getTasksDue,
    profile: userReducer.profile,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getTaskList: data => {
      dispatch(actionCreator.getTaskList(data));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(TaskList);
