import {
  Button,
  Grid,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core';
import React from 'react';
import BorderButton from '../../buttons/BorderButton';
import { connect } from 'react-redux';
import * as actionCreator from '../../../store/action';
import { useState } from 'react';

const useStyles = makeStyles(theme => ({
  container: '',
}));

const DeleteProfileModal = props => {
  const { handleClose, deleteProfile } = props;
  const [enterPassword, SetEnterPassword] = useState(false);
  const classes = useStyles();
  const handleDeleteRelation = () => {
    SetEnterPassword(true);
  };
  return (
    <>
      <Grid container className={classes.container}>
        <Grid item xs={12} style={{ textAlign: 'center' }}>
          <Typography>
            <text style={{ fontSize: '1.2rem' }}>
              Are you sure you want delete your account ?
            </text>
            <br />
            <br />
            <text style={{ fontSize: '0.9rem' }}>
              This action is permanent and cannot be reverted back.
            </text>
          </Typography>
        </Grid>
        {/* {enterPassword ? (
          <>
            <Grid
              item
              xs={12}
              style={{
                textAlign: 'center',
                marginTop: '5%',
              }}>
              <TextField
                required={true}
                type="Password"
                placeholder="confirm your password"
              />
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                textAlign: 'center',
                marginTop: '5%',
              }}>
              <BorderButton
                text="Confirm Delete"
                onClick={() => deleteProfile()}
              />
            </Grid> */}
        {/* </> */}
        {/* ) : ( */}
        <Grid
          item
          xs={12}
          style={{
            textAlign: 'center',
            marginTop: '5%',
          }}>
          <BorderButton text=" Delete" onClick={() => deleteProfile()} />
        </Grid>
        {/* )} */}
      </Grid>
    </>
  );
};
const mapStateToProps = ({ userReducer, authReducer }) => {
  return {
    profile: userReducer.profile,
    user: authReducer.user,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    deleteProfile: data => dispatch(actionCreator.deleteProfile(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(DeleteProfileModal);
