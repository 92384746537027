import React from 'react';
import {
  Grid,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import Rewards from '../../../assets/images/reward/Rewards.png';
import Ribbion from '../../../assets/images/reward/Ribbion.png';
import { connect } from 'react-redux';
import * as actionCreator from '../../../store/action';
import { priceFormatter } from '../../../utils/methods';
const useStyle = makeStyles(theme => ({
  ribbion: {
    width: '280px',
    justifyContent: 'flex-end',
    [theme.breakpoints.only('sm')]: {
      paddingTop: '2%',
      paddingLeft: '20%',
    },
  },
}));
const KidRewardCard = props => {
  const { getRewards, rewards } = props;
  const theme = useTheme();
  const classes = useStyle(theme);
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const [totalReward, setTotalReward] = React.useState(0);

  React.useEffect(() => {
    let reward = 0;
    rewards?.map(data => {
      reward += data?.total_reward || 0;
    });
    setTotalReward(reward);
  }, [rewards]);
  return (
    <>
      <Grid
        item
        container
        sm={12}
        style={{
          width: '100%',
          height: '100px',
          //   paddingTop: "100px",
          padding: ' 0px 20px 20px 0px ',
          backgroundColor: '#fff',
          borderRadius: '10px',
        }}>
        <Grid
          item
          xs={3}
          //   style={{ borderRadius: "10px" }}
        >
          <img
            src={Rewards}
            style={{
              padding: '10px 0px 0px 0px',
              width: '80px',
              height: '90px',
            }}
          />
        </Grid>
        <Grid item xs={4} style={{ padding: '16px 0px 0px 25px' }}>
          <Typography style={{ color: '#BABABA', fontSize: '0.7rem' }}>
            {' '}
            Current Rewards
          </Typography>
          <Typography
            style={{
              color: '#FFCB5B',
              fontSize: '1.4rem',
              fontWeight: 'bold',
              paddingTop: '10px',
            }}>
            {priceFormatter(totalReward)}
          </Typography>
        </Grid>
        <Grid item xs={2} className={classes.ribbion}>
          <img src={Ribbion} style={{ width: '145px', paddingTop: '10%' }} />
        </Grid>
      </Grid>
    </>
  );
};
const mapStateToProps = ({ userReducer }) => {
  return {
    profile: userReducer.profile,
    rewards: userReducer.rewards,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getRewards: data => dispatch(actionCreator.getRewardsKid(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(KidRewardCard);
