import {
  Container,
  Divider,
  Grid,
  Typography,
  makeStyles,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import React from 'react';
import curveImg from '../../../../assets/images/curveCareer.svg';
import Header from '../../../../components/common/header/Header';
import firstImg from '../../../../assets/images/missionVision/FirstImage.svg';
import secondImg from '../../../../assets/images/missionVision/SecondImage.svg';
import Footer from '../../../../components/common/footer/Footer';
const useStyles = makeStyles(theme => ({
  mainContainer: {
    background: '#eef7fa',
    display: 'grid',
  },
  innerContainer: {
    borderRadius: '15px',
    margin: '10% 15%',
    width: '70%',
    display: 'flex',
    background: '#fff',
    flexDirection: 'column',
    [theme.breakpoints.down('xs')]: {
      margin: '15vh 15%',
    },
    [theme.breakpoints.between('sm', 'md')]: {
      margin: '15%',
    },
  },
  title: {
    textAlign: 'center',
    fontSize: '40px',
    fontWeight: 'bolder',
    [theme.breakpoints.down('xs')]: {
      fontSize: '25px',
      padding: '4%',
    },
  },
  itemContainer: {
    // margin: '2%',
    padding: '6%',
  },
  text: {
    color: '#6F6F6F',
    [theme.breakpoints.down('xs')]: {
      padding: '8% 4%',
    },
  },
  subTitle: {
    color: '#359DB6',
    fontSize: '1.5rem',
    fontWeight: 'bold',
    [theme.breakpoints.down('xs')]: {
      // fontSize: '1.2rem',
      padding: '10px',
    },
  },
  missionImg: {
    width: '60%',
    paddingTop: '4%',
    [theme.breakpoints.down('md')]: {
      paddingTop: '8%',
      // width: '100%',
    },
  },
}));
const MissionVision = () => {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('xs'));
  return (
    <>
      <Header />
      <div className={classes.mainContainer}>
        <Container>
          <Grid Container className={classes.innerContainer}>
            <Grid item xs={12} style={{ textAlign: 'right' }}>
              <img src={curveImg} width="80px" />
            </Grid>
            <Grid item xs={12}>
              <Typography className={classes.title}>
                <text style={{ color: '#359DB6' }}>{'Mission'} </text>
                {'and'} <text style={{ color: '#359DB6' }}> {'Vision'}</text>
              </Typography>
            </Grid>
            <Grid item container className={classes.itemContainer}>
              <Grid item xs={12} md={6}>
                <Typography className={classes.subTitle}>Mission</Typography>
                {/* </Grid>
              <Grid xs={12} md={6}> */}
                {matches ? <img src={firstImg} width="100%" /> : null}
                <Typography className={classes.text}>
                  Our mission with the Pocket Money App is to build a world
                  where children understand the working of personal finance
                  while learning to be responsible and independent. Along with
                  this, we hope that our digital innovation acts as a supporting
                  hand for caregivers on their parenting journey.
                </Typography>
              </Grid>
              {!matches ? (
                <Grid item xs={12} md={6} style={{ textAlign: 'center' }}>
                  <img src={firstImg} className={classes.missionImg} />
                </Grid>
              ) : null}
              <Grid item xs={12}>
                {/* dots */}
              </Grid>
            </Grid>
            <Divider orientation="horizontal" variant="middle" />
            <Grid item container className={classes.itemContainer}>
              <Grid item xs={12} md={6} style={{ textAlign: 'center' }}>
                {!matches ? <img src={secondImg} width="60%" /> : null}
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography className={classes.subTitle}>Vision</Typography>
                {/* </Grid>
              <Grid xs={12} md={6}> */}
                {matches ? <img src={secondImg} width="180px" /> : null}
                <Typography className={classes.text}>
                  Our vision is to take forward the value and awareness created
                  by the Pocket Money App into the community and utilise that
                  knowledge to advance further in the areas of healthcare,
                  investments as well as education.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Container>
        <Footer />
      </div>
    </>
  );
};

export default MissionVision;
