import {
  Divider,
  Grid,
  useMediaQuery,
  makeStyles,
  Typography,
  useTheme,
} from '@material-ui/core';
import { React } from 'react';
import Footer from '../../../components/common/footer/Footer';
import Header from '../../../components/common/header/Header';
import curveImg from '../../../assets/images/contactUs/sideBorder.svg';
import { constant } from '../../../utils/constant';
import PatternDesign from '../../../assets/images/contactUs/PatternDesign.svg';
// import personImg from '../../../assets/images/careerPageManIcon.svg';
const useStyles = makeStyles(theme => ({
  container: {
    backgroundColor: '#EEF7FA',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    minHeight: '90vh',
    padding: '0 30% 0 30%',
    [theme.breakpoints.up(constant.breakLaptopAuthPoint)]: {
      padding: '0 20% 0 20%',
    },
    [theme.breakpoints.down(constant.breakLaptopAuthPoint)]: {
      padding: '0 15% 0 15%',
    },
    [theme.breakpoints.down(constant.breakMobileAuthPoint)]: {
      padding: '0 10% 0 10%',
    },
  },
  gridContainer: {
    backgroundColor: '#ffff',
    borderRadius: 25,
    minHeight: '10vh',
    [theme.breakpoints.up(constant.breakLaptopAuthPoint)]: {
      minHeight: '20vh',
      // padding: '0 20% 0 20%',
    },
    [theme.breakpoints.down(constant.breakLaptopAuthPoint)]: {
      minHeight: '20vh',
    },
    [theme.breakpoints.down(constant.breakMobileAuthPoint)]: {
      minHeight: '20vh',
    },
  },
  curveImg: {
    width: '6vw',
    [theme.breakpoints.down('sm')]: {
      width: '8vw',
    },
    [theme.breakpoints.down('xs')]: {
      width: '12vw',
    },
  },
  patternImg: {
    width: '5vw',
  },
  heading: {
    textAlign: 'center',
    fontSize: '2rem',
    fontWeight: 'bold',
    [theme.breakpoints.down('md')]: {
      fontSize: '2rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '2rem',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.5rem',
    },
  },
  subText: {
    textAlign: 'center',
    color: '#6F6F6F',
    fontSize: '1rem',
    paddingTop: '3%',
    height: '100px',
    paddingLeft: '2%',
    paddingRight: '2%',
    lineHeight: '30px',
    [theme.breakpoints.down('md')]: {
      paddingTop: '5%',
      fontSize: '1rem',
      lineHeight: '25px',
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: '5%',
      fontSize: '0.9rem',
      lineHeight: '25px',
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: '5%',
      fontSize: '0.7rem',
      lineHeight: '20px',
    },
  },
  pattern: {
    position: 'absolute',
    [theme.breakpoints.up('md')]: {
      top: '55%',
      left: '21%',
    },
    [theme.breakpoints.up('sm')]: {
      top: '60%',
      left: '11%',
    },
    [theme.breakpoints.up('850')]: {
      top: '60%',
      left: '16%',
    },
    [theme.breakpoints.up('1400')]: {
      top: '60%',
      left: '21%',
    },
  },
}));
const ContactUsPage = () => {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('xs'));
  return (
    <div>
      <Header />

      <div className={classes.container}>
        <Grid container className={classes.gridContainer}>
          <Grid item xs={12} style={{ textAlign: 'right' }}>
            <img src={curveImg} className={classes.curveImg} />
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.heading}>
              {'Contact '} <text style={{ color: '#359DB6' }}>{'Us'}</text>
            </Typography>
            <div style={{ paddingBottom: '10%' }}>
              <Typography className={classes.subText}>
                {'You can reach out to us at'}
                <text style={{ color: '#359DB6', fontWeight: 'bold' }}>
                  <br />
                  {'support@principalityofcogito.com'}
                </text>
                {' for any enquiries.'}
              </Typography>
            </div>
          </Grid>
          {!matches ? (
            <Grid
              item
              xs={12}
              style={{ textAlign: 'left' }}
              className={classes.pattern}>
              <img src={PatternDesign} className={classes.patternImg} />
            </Grid>
          ) : null}
        </Grid>
      </div>
      <Footer />
    </div>
  );
};

export default ContactUsPage;
