import moment from 'moment';
import callApi, { API, callApiPocketmoney } from '../../assets/api';
import { getWithExpiry } from '../../assets/utility';
import { constant } from '../../utils/constant';
import * as actionTypes from './actionTypes';
import { isLogIn, isLogInAsync, setLoading, setStatus } from './authAction';
import { notify } from './notifyAction';

export const logoutUser = data => {
  return (dispatch, getState) => {
    logoutActivity();
    localStorage.clear();
    dispatch({
      type: actionTypes.LOGOUT_USER,
    });
    dispatch({
      type: actionTypes.SET_DEFAULT_PAGINATION,
    });
  };
};

export const setUserLoading = data => {
  return {
    type: actionTypes.SET_USER_LOADING,
    payload: data,
  };
};

export const setUser = data => {
  return {
    type: actionTypes.SET_USER,
    payload: data,
  };
};

export const setGuardianRequest = data => {
  return {
    type: actionTypes.SET_GUARDIAN_REQUEST,
    payload: data,
  };
};
export const setUserTask = data => {
  return {
    type: actionTypes.SET_TASK,
    payload: data,
  };
};

export const setTaskOptions = data => {
  return {
    type: actionTypes.SET_TASK_OPTIONS,
    payload: data,
  };
};

export const setRewards = data => {
  return {
    type: actionTypes.SET_REWARDS,
    payload: data,
  };
};

export const setUserTaskByDate = data => {
  return {
    type: actionTypes.SET_TASK_BY_DATE,
    payload: data,
  };
};
export const setGetRecommendation = data => {
  return {
    type: actionTypes.SET_RECOMMENDATION_LOADING,
    payload: data,
  };
};

export const setCompleteTask = data => {
  return {
    type: actionTypes.SET_COMPLETE_TASK,
    payload: data,
  };
};

export const setUpdateTask = data => {
  return {
    type: actionTypes.SET_UPDATE_TASK,
    payload: data,
  };
};

export const setAllTask = data => {
  return {
    type: actionTypes.SET_ALL_TASK,
    payload: data,
  };
};

export const setChildList = data => {
  return {
    type: actionTypes.SET_CHILD_LIST,
    payload: data,
  };
};

export const setGuardianList = data => {
  return {
    type: actionTypes.SET_GUARDIAN_LIST,
    payload: data,
  };
};

export const createTaskAction = data => {
  return (dispatch, getState) => {
    dispatch(setUserLoading({ tasks: true, modal: true }));
    const reqBody = {
      childId: data.childId || '',
      taskType: data.taskType || '',
      taskTitle: data.taskTitle || '',
      taskDescription: data.taskDescription || '',
      taskTypeId: data.taskTypeId,
      reward: data.reward || 0,
      category: data.category || null,
      media: data.media || [],
      status: '',
      deadline: data.deadline || null,
      frequency: data.frequency || '',
      duration: data.duration || null,
      startDate: data.startDate || null,
      endDate: data.endDate || null,
      taskTimeStamp: data.taskTimeStamp || null,
      deadline: data.deadline || null,
    };

    callApiPocketmoney('createTask', 'POST', reqBody)
      .then(res => {
        const { status, successMessage, errorMessage, error } = res;
        dispatch(
          setStatus({
            error: error,
            successMessage: successMessage,
            errorMessage: errorMessage,
          }),
        );
        dispatch(setUserLoading({ tasks: false, modal: false }));
        if (status) {
          dispatch(notify(successMessage || 'Task created!', 'success'));
          dispatch(setUserLoading({ tasks: false, modal: false }));

          if (
            data?.alternateCallBack &&
            typeof data.alternateCallBack == 'function'
          ) {
            data.alternateCallBack();
          } else {
            let state = getState();
            dispatch(
              setAllTaskPaginationPage({
                total:
                  state.paginationReducer?.paginationAllTask?.total +
                  (data.taskTimeStamp?.length || 1) *
                    (data.childId?.length || 1),
              }),
            );
          }
        } else {
          dispatch(notify(errorMessage || 'Failed to create!', 'error'));
          dispatch(setUserLoading({ tasks: false, modal: false }));
        }
      })
      .catch(err => {
        dispatch(
          setStatus({
            error: err?.response?.data || err,
            errorMessage:
              err?.response?.data?.errorMessage ||
              'Please try again by refresh..!',
          }),
        );
        dispatch(
          notify(
            err?.response?.data?.errorMessage || err?.response?.data,
            'error',
          ),
        );
        dispatch(setUserLoading({ tasks: false }));
      });
  };
};

export const updateTaskAction = (data, callBack) => {
  return (dispatch, getState) => {
    dispatch(setUserLoading({ updatingTaskStatus: true }));
    const reqBody = {
      id: data.id,
      update_request: {
        status: data.status || '',
        media: data.media || [],
      },
    };

    callApiPocketmoney('updateTask', 'POST', reqBody)
      .then(res => {
        const { status, successMessage, errorMessage, data, error } = res;
        dispatch(setUserLoading({ updatingTaskStatus: false }));

        dispatch(
          setStatus({
            error: error,
            successMessage: successMessage,
            errorMessage: errorMessage,
          }),
        );
        if (status) {
          dispatch(
            notify(successMessage || 'Status update successfull', 'success'),
          );
          dispatch(setUserLoading({ updatingTaskStatus: false }));
          if (!callBack) {
            let state = getState();
            let profile = state?.userReducer?.profile;
            let paginationAllTask = state?.paginationReducer?.paginationAllTask;
            let paginationCompleteTask =
              state?.paginationReducer?.paginationCompleteTask;
            let paginationUpdateTask =
              state?.paginationReducer?.paginationUpdateTask;
            let filter1 = {
              sort: {
                updated_at: -1,
              },
            };
            let filter2 = {
              sort: {
                updated_at: -1,
              },
            };
            let filter3 = {
              sort: {
                updated_at: -1,
              },
            };
            if (paginationAllTask?.filter?.selectedChild?._id) {
              filter1.childId = paginationAllTask?.filter?.selectedChild?._id;
            }
            if (paginationCompleteTask?.filter?.selectedChild?._id) {
              filter2.childId =
                paginationCompleteTask?.filter?.selectedChild?._id;
            }
            if (paginationUpdateTask?.filter?.selectedChild?._id) {
              filter3.childId =
                paginationUpdateTask?.filter?.selectedChild?._id;
            }
            if (profile?.isMinor) {
              filter2.status = constant.parentApprovedStatus;

              filter3.status = constant.createdStatus;
              filter3.toDate = moment(new Date()).toISOString();

              filter1.kidsAllTaskFilter = {
                neStatus: constant.parentApprovedStatus,
                orStatus: constant.childApprovedStatus,
                orNeStatus: constant.createdStatus,
                orTimeStamp: moment(new Date()).toISOString(),
              };
              dispatch(
                getKidsTaskList({
                  ...filter1,
                  limit: paginationAllTask.limit,
                  skip: paginationAllTask.skip * paginationAllTask.limit,
                }),
              );
              dispatch(
                getKidsTaskList({
                  ...filter2,
                  limit: paginationCompleteTask.limit,
                  skip:
                    paginationCompleteTask.skip * paginationCompleteTask.limit,
                }),
              );
              dispatch(
                getKidsTaskList({
                  ...filter3,
                  limit: paginationUpdateTask.limit,
                  skip: paginationUpdateTask.skip * paginationUpdateTask.limit,
                }),
              );
            } else {
              filter2.status = constant.parentApprovedStatus;

              filter3.status = constant.childApprovedStatus;

              filter1.filter = {
                $and: [
                  { status: { $ne: constant.parentApprovedStatus } },
                  { status: { $ne: constant.childApprovedStatus } },
                ],
              };

              dispatch(
                getTaskList({
                  ...filter1,
                  limit: paginationAllTask.limit,
                  skip: paginationAllTask.skip * paginationAllTask.limit,
                }),
              );
              dispatch(
                getTaskList({
                  ...filter2,
                  limit: paginationCompleteTask.limit,
                  skip:
                    paginationCompleteTask.skip * paginationCompleteTask.limit,
                }),
              );
              dispatch(
                getTaskList({
                  ...filter3,
                  limit: paginationUpdateTask.limit,
                  skip: paginationUpdateTask.skip * paginationUpdateTask.limit,
                }),
              );
            }
          } else {
            typeof callBack == 'function' && callBack();
          }
        }
      })
      .catch(err => {
        dispatch(setUserLoading({ updatingTaskStatus: false }));
        dispatch(
          setStatus({
            error: err?.response?.data || err,
            errorMessage:
              err?.response?.data?.errorMessage ||
              'Please try again by refresh..!',
          }),
        );
      });
  };
};

export const deleteTaskAction = (data, callBack) => {
  return (dispatch, getState) => {
    dispatch(setLoading());
    const reqBody = {
      id: data.id,
    };

    callApiPocketmoney('deleteTask', 'POST', reqBody)
      .then(res => {
        const { status, successMessage, errorMessage, data, error } = res;
        if (status) {
          if (!callBack) {
            let state = getState();
            let profile = state?.userReducer?.profile;
            let paginationAllTask = state?.paginationReducer?.paginationAllTask;
            let paginationCompleteTask =
              state?.paginationReducer?.paginationCompleteTask;
            let paginationUpdateTask =
              state?.paginationReducer?.paginationUpdateTask;
            let filter1 = {
              sort: {
                updated_at: -1,
              },
            };
            let filter2 = {
              sort: {
                updated_at: -1,
              },
            };
            let filter3 = {
              sort: {
                updated_at: -1,
              },
            };
            if (paginationAllTask?.filter?.selectedChild?._id) {
              filter1.childId = paginationAllTask?.filter?.selectedChild?._id;
            }
            if (paginationCompleteTask?.filter?.selectedChild?._id) {
              filter2.childId =
                paginationCompleteTask?.filter?.selectedChild?._id;
            }
            if (paginationUpdateTask?.filter?.selectedChild?._id) {
              filter3.childId =
                paginationUpdateTask?.filter?.selectedChild?._id;
            }
            if (profile?.isMinor) {
              filter2.status = constant.parentApprovedStatus;

              filter3.status = constant.createdStatus;
              filter3.toDate = moment(new Date()).toISOString();

              filter1.kidsAllTaskFilter = {
                neStatus: constant.parentApprovedStatus,
                orStatus: constant.childApprovedStatus,
                orNeStatus: constant.createdStatus,
                orTimeStamp: moment(new Date()).toISOString(),
              };
              dispatch(
                getKidsTaskList({
                  ...filter1,
                  limit: paginationAllTask.limit,
                  skip: paginationAllTask.skip * paginationAllTask.limit,
                }),
              );
              dispatch(
                getKidsTaskList({
                  ...filter2,
                  limit: paginationCompleteTask.limit,
                  skip:
                    paginationCompleteTask.skip * paginationCompleteTask.limit,
                }),
              );
              dispatch(
                getKidsTaskList({
                  ...filter3,
                  limit: paginationUpdateTask.limit,
                  skip: paginationUpdateTask.skip * paginationUpdateTask.limit,
                }),
              );
            } else {
              filter2.status = constant.parentApprovedStatus;

              filter3.status = constant.childApprovedStatus;

              filter1.filter = {
                $and: [
                  { status: { $ne: constant.parentApprovedStatus } },
                  { status: { $ne: constant.childApprovedStatus } },
                ],
              };

              dispatch(
                getTaskList({
                  ...filter1,
                  limit: paginationAllTask.limit,
                  skip: paginationAllTask.skip * paginationAllTask.limit,
                }),
              );
              dispatch(
                getTaskList({
                  ...filter2,
                  limit: paginationCompleteTask.limit,
                  skip:
                    paginationCompleteTask.skip * paginationCompleteTask.limit,
                }),
              );
              dispatch(
                getTaskList({
                  ...filter3,
                  limit: paginationUpdateTask.limit,
                  skip: paginationUpdateTask.skip * paginationUpdateTask.limit,
                }),
              );
            }
          } else {
            typeof callBack == 'function' && callBack();
          }
        }
        dispatch(
          setStatus({
            error: error,
            successMessage: successMessage,
            errorMessage: errorMessage,
          }),
        );
      })
      .catch(err => {
        dispatch(
          setStatus({
            error: err?.response?.data || err,
            errorMessage:
              err?.response?.data?.errorMessage ||
              'Please try again by refresh..!',
          }),
        );
      });
  };
};

export const getChildrenRequest = () => {
  return (dispatch, getState) => {
    dispatch(setLoading());
    const reqBody = {};

    callApiPocketmoney('getChildRequest', 'POST', reqBody)
      .then(res => {
        const { status, successMessage, errorMessage, child, error } = res;
        dispatch(
          setStatus({
            error: error,
            successMessage: successMessage,
            errorMessage: errorMessage,
          }),
        );
        dispatch(setGuardianRequest(child));
      })
      .catch(err => {
        dispatch(
          setStatus({
            error: err?.response?.data || err,
            errorMessage:
              err?.response?.data?.errorMessage || 'Failed to fetch request!',
          }),
        );
      });
  };
};

export const getGuardianRequest = () => {
  return (dispatch, getState) => {
    dispatch(setLoading());
    const reqBody = {};

    callApiPocketmoney('getGuardianRequest', 'POST', reqBody)
      .then(res => {
        const { status, successMessage, errorMessage, guardian, error } = res;
        dispatch(
          setStatus({
            error: error,
            successMessage: successMessage,
            errorMessage: errorMessage,
          }),
        );
        dispatch(setGuardianRequest(guardian));
      })
      .catch(err => {
        dispatch(
          setStatus({
            error: err?.response?.data || err,
            errorMessage:
              err?.response?.data?.errorMessage || 'Failed to fetch request!',
          }),
        );
      });
  };
};

export const getTaskList = reqData => {
  return (dispatch, getState) => {
    dispatch(
      setUserLoading({
        ...(reqData?.status == constant.parentApprovedStatus
          ? { getTasksCompleted: true }
          : reqData?.status == constant.childApprovedStatus
          ? { getTasksDue: true }
          : { getTasksCreated: true }),
      }),
    );
    const reqBody = {
      ...reqData,
    };

    callApiPocketmoney('getTask', 'POST', reqBody)
      .then(res => {
        const { status, successMessage, errorMessage, data, error } = res;
        dispatch(
          setUserLoading({
            ...(reqData?.status == constant.parentApprovedStatus
              ? { getTasksCompleted: false }
              : reqData?.status == constant.childApprovedStatus
              ? { getTasksDue: false }
              : { getTasksCreated: false }),
          }),
        );
        dispatch(
          setStatus({
            error: error,
            successMessage: successMessage,
            errorMessage: errorMessage,
          }),
        );
        if (status) {
          let state = getState();
          let paginationData = state.paginationReducer;
          if (reqData?.status == constant.parentApprovedStatus) {
            dispatch(setCompleteTask(data));
            if (
              paginationData?.paginationCompleteTask?.total != data?.totalCount
            ) {
              dispatch(
                setCompletedTaskPaginationPage({ total: data?.totalCount }),
              );
            }
          } else if (reqData?.status == constant.childApprovedStatus) {
            dispatch(setUpdateTask(data));
            if (
              paginationData?.paginationUpdateTask?.total != data?.totalCount
            ) {
              dispatch(
                setUpdateTaskPaginationPage({ total: data?.totalCount }),
              );
            }
          } else {
            dispatch(setAllTask(data));
            if (paginationData?.paginationAllTask?.total != data?.totalCount) {
              dispatch(setAllTaskPaginationPage({ total: data?.totalCount }));
            }
          }
        }
      })
      .catch(err => {
        dispatch(
          ...(reqData?.status == constant.parentApprovedStatus
            ? { getTasksCompleted: false }
            : reqData?.status == constant.childApprovedStatus
            ? { getTasksDue: false }
            : { getTasksCreated: false }),
        );
        dispatch(
          setStatus({
            error: err?.response?.data || err,
            errorMessage:
              err?.response?.data?.errorMessage || 'Failed to fetch request!',
          }),
        );
      });
  };
};
export const getKidsTaskList = reqData => {
  return (dispatch, getState) => {
    dispatch(
      setUserLoading({
        ...(reqData?.status == constant.parentApprovedStatus
          ? { getTasksCompleted: true }
          : reqData?.status == constant.createdStatus
          ? { getTasksDue: true }
          : { getTasksCreated: true }),
      }),
    );
    const reqBody = {
      ...reqData,
    };

    callApiPocketmoney('getTask', 'POST', reqBody)
      .then(res => {
        const { status, successMessage, errorMessage, data, error } = res;
        dispatch(
          setUserLoading({
            ...(reqData?.status == constant.parentApprovedStatus
              ? { getTasksCompleted: false }
              : reqData?.status == constant.createdStatus
              ? { getTasksDue: false }
              : { getTasksCreated: false }),
          }),
        );
        dispatch(
          setStatus({
            error: error,
            successMessage: successMessage,
            errorMessage: errorMessage,
          }),
        );
        if (status) {
          let state = getState();
          let paginationData = state.paginationReducer;
          if (reqData?.status == constant.parentApprovedStatus) {
            dispatch(setCompleteTask(data));
            if (
              paginationData?.paginationCompleteTask?.total != data?.totalCount
            ) {
              dispatch(
                setCompletedTaskPaginationPage({ total: data?.totalCount }),
              );
            }
          } else if (reqData?.status == constant.createdStatus) {
            dispatch(setUpdateTask(data));
            if (
              paginationData?.paginationUpdateTask?.total != data?.totalCount
            ) {
              dispatch(
                setUpdateTaskPaginationPage({ total: data?.totalCount }),
              );
            }
          } else {
            dispatch(setAllTask(data));
            if (paginationData?.paginationAllTask?.total != data?.totalCount) {
              dispatch(setAllTaskPaginationPage({ total: data?.totalCount }));
            }
          }
        }
      })
      .catch(err => {
        dispatch(
          setUserLoading({
            ...(reqData?.status == constant.parentApprovedStatus
              ? { getTasksCompleted: false }
              : reqData?.status == constant.createdStatus
              ? { getTasksDue: false }
              : { getTasksCreated: false }),
          }),
        );

        dispatch(
          setStatus({
            error: err?.response?.data || err,
            errorMessage:
              err?.response?.data?.errorMessage || 'Failed to fetch request!',
          }),
        );
      });
  };
};

export const getChildList = data => {
  return (dispatch, getState) => {
    dispatch(setUserLoading({ getKids: true }));
    const reqBody = {
      childId: data?.childId,
    };

    callApiPocketmoney('getChildrenList', 'POST', reqBody)
      .then(res => {
        const { status, successMessage, errorMessage, child, error } = res;
        dispatch(setUserLoading({ getKids: false }));
        dispatch(
          setStatus({
            error: error,
            successMessage: successMessage,
            errorMessage: errorMessage,
          }),
        );
        let state = getState();
        let profile = state.userReducer.profile;
        let childList = [];
        let receivedRequest = [];
        let sentRequest = [];
        (child || []).map(data => {
          (data.family || []).map(family => {
            if (family?._id == profile?._id) {
              if (family.status == 'Accept') {
                childList.push(data);
              } else if (family?.status == 'Waiting for Approval') {
                if (family?.request_type == 'Sent') {
                  receivedRequest.push(data);
                } else if (family?.request_type == 'Received') {
                  sentRequest.push(data);
                }
              }
            }
          });
        });
        dispatch(
          setChildList({
            childList: childList,
            recievedRequest: receivedRequest,
            sentRequest: sentRequest,
          }),
        );
      })
      .catch(err => {
        dispatch(setUserLoading({ getKids: false }));
        dispatch(
          setStatus({
            error: err?.response?.data || err,
            errorMessage:
              err?.response?.data?.errorMessage || 'Failed to fetch request!',
          }),
        );
      });
  };
};

export const getGuardianList = data => {
  return (dispatch, getState) => {
    dispatch(setUserLoading({ getGuardians: true }));
    const reqBody = {
      parentId: data?.parentId,
    };

    callApiPocketmoney('getGuardianList', 'POST', reqBody)
      .then(res => {
        const { status, successMessage, errorMessage, guardian, error } = res;
        dispatch(setUserLoading({ getGuardians: false }));
        dispatch(
          setStatus({
            error: error,
            successMessage: successMessage,
            errorMessage: errorMessage,
          }),
        );
        let state = getState();
        let profile = state.userReducer.profile;
        let guardianList = [];
        let receivedRequest = [];
        (guardian || []).map(data => {
          (data?.family || []).map(family => {
            if (family?._id == profile?._id) {
              if (family.status == 'Accept') {
                guardianList.push(data);
              } else if (family?.status == 'sent') {
                receivedRequest.push(data);
              }
            }
          });
        });
        dispatch(
          setGuardianList({
            guardianList: guardianList,
            receivedRequest: receivedRequest,
          }),
        );
      })
      .catch(err => {
        dispatch(setUserLoading({ getGuardians: false }));
        dispatch(
          setStatus({
            error: err?.response?.data || err,
            errorMessage:
              err?.response?.data?.errorMessage || 'Failed to fetch request!',
          }),
        );
      });
  };
};

export const updateChildRequest = data => {
  return (dispatch, getState) => {
    dispatch(setLoading());
    const reqBody = {
      ...data,
    };

    callApi('UpdateFamilyStatus', 'POST', reqBody)
      .then(res => {
        const { status, successMessage, errorMessage, error } = res;
        dispatch(
          setStatus({
            error: error,
            successMessage: successMessage,
            errorMessage: errorMessage,
          }),
        );
        if (status) {
          if (data?.isMinor) {
            dispatch(getGuardianList());
            dispatch(getGuardianRequest());
            dispatch(isLogInAsync());
          } else {
            dispatch(getChildList());
            dispatch(getChildrenRequest());
          }
          dispatch(notify('Request update successfull', 'success'));
        } else {
          dispatch(notify('Request update failed', 'error'));
        }
      })
      .catch(err => {
        dispatch(
          setStatus({
            error: err?.response?.data || err,
            errorMessage:
              err?.response?.data?.errorMessage || 'Failed to update request!',
          }),
        );
      });
  };
};

export const getTaskBy_Date = data => {
  return async (dispatch, getState) => {
    const reqBody = {
      id: data.id,
      fromDate: data.fromDate || null,
      toDate: data.toDate || null,
    };
    dispatch(
      setUserLoading({
        getTasks: true,
      }),
    );
    callApiPocketmoney('getTask', 'POST', reqBody)
      .then(res => {
        const { status, successMessage, errorMessage, data, error } = res;
        dispatch(
          setUserLoading({
            getTasks: false,
          }),
        );
        dispatch(setUserTaskByDate(data || null));
        dispatch(
          setStatus({
            error: error,
            successMessage: successMessage,
            errorMessage: errorMessage,
          }),
        );
      })
      .catch(err => {
        dispatch(
          setUserLoading({
            getTasks: false,
          }),
        );
        dispatch(
          setStatus({
            error: err?.response?.data || err,
            errorMessage:
              err?.response?.data?.errorMessage ||
              'Please try again by refresh..!',
          }),
        );
      });
  };
};

export const getRecommendsApi = reqBody =>
  callApi('getRecommends', 'POST', reqBody)
    .then(res => {
      return res;
    })
    .catch(err => {
      return err;
    });

export const updateChildData = (request, callBack) => {
  return new Promise((res, rej) => {
    let reqBody = request;
    callApi('UpdateUser', 'POST', reqBody)
      .then(response => {
        res(response);
        if (callBack && typeof callBack == 'function') {
          callBack();
        }
      })
      .catch(err => {
        rej(err);
      });
  });
};

export const createFamilyMember = request => {
  return new Promise((res, rej) => {
    let reqBody = request;
    callApi('CreateFamilyMember', 'POST', reqBody)
      .then(response => {
        res(response);
      })
      .catch(err => {
        rej(err);
      });
  });
};

export const uploadDoc = ({ file, key }) => {
  return new Promise((res, rej) => {
    let storedToken = getWithExpiry('token');
    let data = new FormData();
    data.append('file', file);
    data.append('path', key);
    data.append('token', storedToken);

    API.post('/UploadDocs', data, {
      'Content-Type': 'multipart/form-data',
    })
      .then(response => {
        res(response?.data);
      })
      .catch(err => {
        rej(err);
      });
  });
};

export const removeRelation = data => {
  return (dispatch, getState) => {
    dispatch(setLoading());
    const reqBody = {
      fromId: data.fromId,
      toId: data.toId,
    };
    callApi('RemoveFamilyRelation', 'POST', reqBody)
      .then(res => {
        const { status, successMessage, errorMessage, child, error } = res;
        dispatch(
          setStatus({
            error: error,
            successMessage: successMessage,
            errorMessage: errorMessage,
          }),
        );
        if (status) {
          dispatch(
            notify(successMessage || 'Child removed successfully', 'success'),
          );
          let state = getState();
          let filter = {
            sort: {
              updated_at: -1,
            },
          };
          filter = {
            ...filter,
            limit: state.paginationReducer.paginationAllTask.limit,
            skip:
              state.paginationReducer.paginationAllTask.skip *
              state.paginationReducer.paginationAllTask.limit,
          };
          dispatch(
            getTaskList({
              ...filter,
              filter: {
                $and: [
                  { status: { $ne: constant.parentApprovedStatus } },
                  { status: { $ne: constant.childApprovedStatus } },
                ],
              },
            }),
          );
          dispatch(
            getTaskList({
              ...filter,
              status: constant.parentApprovedStatus,
            }),
          );
          dispatch(getChildList());
        } else {
          dispatch(notify(errorMessage, 'error'));
        }
      })
      .catch(err => {
        dispatch(
          notify(
            err?.response?.data?.errorMessage || err?.response?.data,
            'error',
          ),
        );
        dispatch(
          setStatus({
            error: err?.response?.data || err,
            errorMessage:
              err?.response?.data?.errorMessage || 'Failed to delete relation!',
          }),
        );
      });
  };
};

export const setAllTaskPaginationPage = data => {
  return (dispatch, getState) => {
    dispatch({
      type: actionTypes.SET_ALLTASK_PAGINATION_PAGE,
      payload: data,
    });
  };
};

export const setCompletedTaskPaginationPage = data => {
  return (dispatch, getState) => {
    dispatch({
      type: actionTypes.SET_COMPLETEDTASK_PAGINATION_PAGE,
      payload: data,
    });
  };
};

export const setUpdateTaskPaginationPage = data => {
  return (dispatch, getState) => {
    dispatch({
      type: actionTypes.SET_UPDATETASK_PAGINATION_PAGE,
      payload: data,
    });
  };
};

export const uploadProfilePic = (key, file) => {
  return new Promise((resolve, reject) => {
    let storedToken = getWithExpiry('token');
    let data = new FormData();
    data.append('file', file);
    data.append('path', key);
    data.append('token', storedToken);

    API.post('/UploadDocs', data, {
      'Content-Type': 'multipart/form-data',
    })
      .then(res => {
        const { status, successMessage, errorMessage, data, error } = res.data;
        if (status) {
          resolve(data);
        } else {
          reject();
          notify(errorMessage, 'error');
        }
      })
      .catch(err => {
        reject();
        let errorMessage =
          err?.response?.data?.error?.errorMessage ||
          err?.response?.data?.error;
        notify(
          typeof errorMessage == 'string'
            ? errorMessage
            : 'Error while uploading file!!!',
          'error',
        );
      });
  });
};

export const getTaskOptions = data => {
  return (dispatch, getState) => {
    dispatch(setUserLoading({ getTask: true }));
    const reqBody = {
      id: data.id,
      frequency: data.frequency,
      category: data.category,
    };

    callApiPocketmoney('getTaskOptions', 'POST', reqBody)
      .then(res => {
        const { status, successMessage, errorMessage, data, error } = res;

        dispatch(
          setTaskOptions({
            value: data?.options || [],
            frequency: reqBody.frequency,
            category: reqBody.category,
          }),
        );
        dispatch(
          setStatus({
            error: error,
            successMessage: successMessage,
            errorMessage: errorMessage,
          }),
        );
        dispatch(setUserLoading({ getTask: false }));
      })
      .catch(err => {
        dispatch(
          setStatus({
            error: err?.response?.data || err,
            errorMessage:
              err?.response?.data?.errorMessage ||
              'Please try again by refresh..!',
          }),
        );
        dispatch(setUserLoading({ getTask: false }));
      });
  };
};

export const getRewardsParent = data => {
  return (dispatch, getState) => {
    dispatch(setLoading());
    const reqBody = {
      childId: data?.childId,
      month: data?.month,
      year: data?.year,
    };

    callApiPocketmoney('getRewardsParent', 'POST', reqBody)
      .then(res => {
        const {
          status,
          successMessage,
          errorMessage,
          data,
          error,
          totalReward,
        } = res;
        dispatch(setRewards({ rewards: data, totalReward: totalReward || 0 }));
        dispatch(
          setStatus({
            error: error,
            successMessage: successMessage,
            errorMessage: errorMessage,
          }),
        );
      })
      .catch(err => {
        dispatch(
          setStatus({
            error: err?.response?.data || err,
            errorMessage:
              err?.response?.data?.errorMessage ||
              'Please try again by refresh..!',
          }),
        );
        dispatch(notify('Failed to fetch rewards.', 'error'));
      });
  };
};

export const getRewardsKid = data => {
  return (dispatch, getState) => {
    dispatch(setLoading());
    const reqBody = {
      month: data?.month,
      year: data?.year,
    };

    callApiPocketmoney('getRewardsKid', 'POST', reqBody)
      .then(res => {
        const {
          status,
          successMessage,
          errorMessage,
          data,
          error,
          totalReward,
        } = res;

        dispatch(setRewards({ rewards: data, totalReward: totalReward || 0 }));
        dispatch(
          setStatus({
            error: error,
            successMessage: successMessage,
            errorMessage: errorMessage,
          }),
        );
      })
      .catch(err => {
        dispatch(
          setStatus({
            error: err?.response?.data || err,
            errorMessage:
              err?.response?.data?.errorMessage ||
              'Please try again by refresh..!',
          }),
        );
        dispatch(notify('Failed to fetch rewards.', 'error'));
      });
  };
};

export const addChildRequest = data => {
  return (dispatch, getState) => {
    dispatch(setLoading());
    const reqBody = {
      relation: data.relation,
      childId: data.childId,
      role: data.role,
    };
    callApiPocketmoney('addChildRequest', 'POST', reqBody)
      .then(res => {
        const { status, successMessage, errorMessage, data, error } = res;
        if (status) {
          dispatch(notify(successMessage, 'success'));
          dispatch(getProfile());
        } else {
          dispatch(notify(errorMessage, 'error'));
        }
        dispatch(
          setStatus({
            error: error,
            successMessage: successMessage,
            errorMessage: errorMessage,
          }),
        );
      })
      .catch(err => {
        dispatch(
          setStatus({
            error: err?.response?.data || err,
            errorMessage:
              err?.response?.data?.errorMessage ||
              'Please try again by refresh..!',
          }),
        );
        dispatch(notify('Failed to send a child request.', 'error'));
      });
  };
};

export const getProfile = () => {
  //# HERE WE CREATE DYNMAIC ACTION DISPATCHER FOR ALL CASE'S

  return async (dispatch, getState) => {
    //# HERE MAKE ASYNC CALLS.
    let storedToken = getWithExpiry('token');
    // dispatch(
    //   setUserLoading({
    //     getProfile: true,
    //   })
    // );
    callApi('GetProfile', 'POST')
      .then(res => {
        const { status, successMessage, errorMessage, user, error } = res;
        dispatch(
          setUser({
            user: user,
          }),
        );
        // dispatch(
        //   setUserLoading({
        //     getProfile: false,
        //   }),
        // );
        if (error) {
          dispatch(
            setStatus({
              error: error,
            }),
          );
        }
      })
      .catch(err => {
        // dispatch(
        //   setUserLoading({
        //     getProfile: false,
        //   }),
        // );
        dispatch(
          setStatus({
            error: err?.response?.data.error || err,
          }),
        );
        dispatch(
          notify(
            err?.response?.data?.errorMessage ||
              'Please try again by refresh..!',
            'error',
          ),
        );
      });
  };
};

export const updateRewards = () => {
  //# HERE WE CREATE DYNMAIC ACTION DISPATCHER FOR ALL CASE'S

  return async (dispatch, getState) => {
    //# HERE MAKE ASYNC CALLS.
    let storedToken = getWithExpiry('token');
    callApiPocketmoney('updateRewards', 'POST')
      .then(res => {
        const { status, successMessage, errorMessage, user, error } = res;
      })
      .catch(err => {});
  };
};

export const approveKyc = data => {
  return (dispatch, getState) => {
    dispatch(setLoading());

    const reqBody = {
      id: data.id,
      kycStatus: data.status,
    };
    callApiPocketmoney('approveChildKyc', 'POST', reqBody)
      .then(res => {
        const { status, successMessage, errorMessage, data, error } = res;
        dispatch(
          setStatus({
            error: error,
            successMessage: successMessage,
            errorMessage: errorMessage,
          }),
        );
        if (status) {
          dispatch(
            notify(successMessage || 'KYC approved successfuly', 'success'),
          );
          dispatch(getChildList());
        }
      })
      .catch(err => {
        dispatch(
          setStatus({
            error: err?.response?.data || err,
            errorMessage:
              err?.response?.data?.errorMessage ||
              'Please try again by refresh..!',
          }),
        );
      });
  };
};

export const inviteContact = data => {
  return async (dispatch, getState) => {
    dispatch(setUserLoading({ addParent: false }));

    callApi('inviteContact', 'POST', {
      contacts: [],
      inviteContact: data.inviteContact,
      from: data.from,
      referalLink: data.referalLink,
    })
      .then(response => {
        dispatch(
          setStatus({
            error: response.error,
            successMessage: response.successMessage,
            errorMessage: response.errorMessage,
          }),
        );
        dispatch(setUserLoading({ sentInvite: true }));

        if (response.status) {
          dispatch(
            notify(
              `Invitation sent to ${data?.inviteContact?.email}`,
              'success',
            ),
          );
        } else {
          dispatch(
            notify(
              response?.errorMessage || 'Please try again by refresh..!',
              'error',
            ),
          );
        }
      })
      .catch(err => {
        dispatch(
          setStatus({
            error: err?.response?.data || err,
            errorMessage:
              err?.response?.data?.errorMessage ||
              'Please try again by refresh..!',
          }),
        );
        dispatch(setUserLoading({ addParent: false }));

        dispatch(
          notify(
            err?.response?.data?.errorMessage ||
              'Please try again by refresh..!',
            'error',
          ),
        );
      });
  };
};

export const addContacts = data => {
  return new Promise(async (resolve, reject) => {
    await callApi('addContacts', 'POST', { contacts: data })
      .then(response => {
        resolve(response);
      })
      .catch(err => {
        reject(err);
      });
  });
};

export const logoutActivity = () => {
  //# HERE WE CREATE DYNMAIC ACTION DISPATCHER FOR ALL CASE'S

  return new Promise((response, reject) => {
    //# HERE MAKE ASYNC CALLS.
    let storedToken = getWithExpiry('token');
    const reqBody = {
      token: storedToken,
      channel: 'pm-website',
    };

    callApi('logoutActivity', 'POST', reqBody)
      .then(res => {
        response(res);
      })
      .catch(err => {
        reject(err);
      });
  });
};

export const deleteProfile = (data, callBack) => {
  return (dispatch, getState) => {
    let storedToken = getWithExpiry('token');
    dispatch(setLoading());
    const reqBody = {
      token: storedToken,
    };

    callApi('deleteProfile', 'POST', reqBody)
      .then(res => {
        const { status, successMessage, errorMessage, error } = res;
        if (status) {
          dispatch(setLoading(false));
          dispatch(logoutUser());
          dispatch(notify(successMessage, 'success'));
        }

        dispatch(
          setStatus({
            error: error,
            successMessage: successMessage,
            errorMessage: errorMessage,
          }),
        );
      })
      .catch(err => {
        dispatch(
          setStatus({
            error: err?.response?.data || err,
            errorMessage:
              err?.response?.data?.errorMessage ||
              'Please try again by refresh..!',
          }),
        );
      });
  };
};
