import React from 'react';
import {
  Grid,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import Cog from '../../../assets/images/reward/cogCoin.svg';
import CalenderBlack from '../../../assets/images/reward/calendar.svg';
import Women from '../../../assets/images/reward/Women.png';
import moment from 'moment';
import { connect } from 'react-redux';
import RewardsHistory from '../../reward/RewardsHistory';
import CommonModel from '../../../components/common/model/CommonModel';
import { priceFormatter } from '../../../utils/methods';
import { maxStringLength } from '../../../utils/stringMethods';
const useStyle = makeStyles(theme => ({
  root: {
    backgroundColor: '#eef7fa',
  },
  rewardCard: {
    width: '10vw',
    display: 'flex',
    alignItems: 'center',
    border: '2px solid #359DB6',
    backgroundColor: '#C9E5EC',
    borderRadius: '10px',
    paddingLeft: '10%',
    paddingTop: '5%',
    webkitBoxShadow: '-2px 4px 18px -2px rgba(0,0,0,0.15)',
    mozBoxShadow: '-2px 4px 18px -2px rgba(0,0,0,0.15)',
    boxShadow: '-2px 4px 18px -2px rgba(0,0,0,0.25)',
    [theme.breakpoints.down('xs')]: {
      width: '25vw',
    },
    [theme.breakpoints.between('sm', 'md')]: {
      width: '12vw',
    },
  },
  rewardPoints: {
    fontSize: '1.2rem',
    color: '#6F6F6F',
    fontWeight: 'bolder',
    // paddingRight: '8%',

    [theme.breakpoints.down('sm')]: {
      fontSize: '0.9rem',
    },
  },
  taskTitle: {
    fontSize: '0.8rem',
    color: '#6F6F6F',
    fontWeight: '400',
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.6rem',
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      fontSize: '0.7rem',
    },
  },
  CalenderIcon: {
    width: '10%',
    paddingRight: '3%',
    [theme.breakpoints.between('xs', 'sm')]: {
      width: '15%',
    },
  },
  Date: {
    fontSize: '0.7rem',
    color: '#6F6F6F',
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.4rem',
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      fontSize: '0.37rem',
    },
  },
  cogIcon: {
    width: '75%',
  },
  rewardsHistoryContainer: {
    maxWidth: '400px',
  },
}));
const KidRewardComponent = props => {
  const { rewardData, index, profile } = props;
  const theme = useTheme();
  const classes = useStyle(theme);
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const [rewardsHistory, setRewardsHistory] = React.useState();
  const handleRewardsHistoryModal = () => {
    setRewardsHistory(data => (data ? null : rewardData));
  };
  return (
    <>
      <CommonModel
        title={'Rewards'}
        open={rewardsHistory}
        handleClose={handleRewardsHistoryModal}
        body={<RewardsHistory rewardData={rewardsHistory} child={profile} />}
        containerStyle={classes.rewardsHistoryContainer}
      />
      {!matches ? (
        <Grid
          container
          className={classes.rewardCard}
          onClick={handleRewardsHistoryModal}>
          <Grid item xs={3} sm={4} md={3}>
            <img src={Cog} className={classes.cogIcon} />
          </Grid>
          <Grid item>
            <Typography className={classes.rewardPoints}>
              {priceFormatter(rewardData?.total_reward || 0)}
            </Typography>
          </Grid>

          <Grid item xs={12} sm={12} md={12}>
            <Typography className={classes.taskTitle}>
              {maxStringLength(rewardData?.taskTitle || '', 12)}
            </Typography>
          </Grid>
          <Grid
            item
            container
            style={{
              marginTop: '18%',
              marginBottom: '8%',
              alignItems: 'center',
            }}>
            <Grid item xs={8} md={12} sm={8}>
              <>
                <img src={CalenderBlack} className={classes.CalenderIcon} />
                <text className={classes.Date}>
                  {rewardData?.frequency === 'once'
                    ? moment(rewardData?.taskHistory[0].taskTimeStamp).format(
                        'MMM DD, yyyy',
                      )
                    : moment(rewardData?.startDate).format('MMM DD, yyyy')}
                </text>
              </>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Grid
          container
          onClick={handleRewardsHistoryModal}
          style={{
            width: '102px',
            height: '102px',
            border: '2px solid #359DB6',
            backgroundColor: '#C9E5EC',
            borderRadius: '10px',
            webkitBoxShadow: '-2px 4px 18px -2px rgba(0,0,0,0.75)',
            mozBoxShadow: '-2px 4px 18px -2px rgba(0,0,0,0.75)',
            boxShadow: '-2px 4px 18px -2px rgba(0,0,0,0.75)',
          }}>
          <Grid
            item
            xs={4}
            style={{
              paddingTop: '8px',
              paddingLeft: '6px',
            }}>
            <img src={Cog} style={{ width: '30px', height: '30px' }} />
          </Grid>
          <Grid item xs={8}>
            <Typography
              style={{
                fontSize: '1.1rem',
                color: '#6F6F6F',
                fontWeight: 'bold',
                paddingTop: '8px',
                paddingLeft: '8px',
              }}>
              {priceFormatter(rewardData?.total_reward || 0)}
            </Typography>
          </Grid>
          <Typography
            style={{
              fontSize: '0.7rem',
              color: '#6F6F6F',
              fontWeight: '600',
              paddingLeft: '10px',
              paddingBottom: '10px',
            }}>
            {maxStringLength(rewardData?.taskTitle || 'NA', 12)}
          </Typography>
          <Grid container>
            <Grid item>
              <img
                src={CalenderBlack}
                style={{
                  height: '12px',
                  width: '10px',
                  paddingLeft: '8px',
                  //  paddingTop: "8px",
                  paddingRight: '5px',
                }}
              />
              <text
                style={{
                  fontSize: '0.5rem',
                  fontWeight: 'bold',
                  color: '#6F6F6F',
                  //  paddingTop: "20px",
                }}>
                {rewardData?.frequency === 'once'
                  ? moment(rewardData?.taskHistory[0].taskTimeStamp).format(
                      'MMM DD, yyyy',
                    )
                  : moment(rewardData?.startDate).format('MMM DD, yyyy')}
              </text>
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
};

const mapStateToProps = ({ userReducer }) => {
  return {
    profile: userReducer.profile,
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(KidRewardComponent);
