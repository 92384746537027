import {
  Box,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  useTheme,
} from '@material-ui/core';
import clsx from 'clsx';
import DatePicker from 'react-multi-date-picker';
import DatePanel from 'react-multi-date-picker/plugins/date_panel';
import ContainerTitle from '../common/title/ContainerTitle';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';

const useStyle = makeStyles(theme => ({
  container: {
    position: 'relative',
    marginBottom: 20,
  },
  dateContainer: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    top: 9,
  },
  dateIconStyle: {
    position: 'absolute',
    right: 0,
    backgroundColor: '#eef7fa',
    color: 'black',
    fontSize: 23,
  },
  datePickerStyle: {
    backgroundColor: 'transparent',
    width: '100%',
  },
  errorStyle: {
    color: 'red',
    fontSize: '0.9rem',
    fontWeight: 500,
    position: 'absolute',
    bottom: -20,
  },
}));
const CustomDateSelectField = props => {
  const theme = useTheme();
  const classes = useStyle(theme);
  const {
    placeholder,
    variant,
    size,
    className,
    value,
    onChange,
    type,
    errorMessage,
    field,
    inputProps,
    id,
    range,
    multiple,
    minDate,
    maxDate,
    containerClassName,
    dateIconStyle,
    customDatePicker,
  } = props;
  const fieldProps = {
    placeholder,
    variant,
    size,
    value,
    onChange,
    type,
    inputProps,
    id,
    range,
    multiple,
    minDate,
    maxDate,
  };
  const handleIconClick = () => {
    if (handleIconClick) {
      let iconClick = document.getElementById(id);
      iconClick.focus();
    }
  };
  return (
    <>
      <Box className={clsx(classes.container, containerClassName)}>
        <Box
          className={clsx(
            classes.dateContainer,
            'custom-date-picker',
            customDatePicker,
          )}>
          <DatePicker
            {...fieldProps}
            className={clsx(classes.datePickerStyle, className)}
            plugins={[<DatePanel />]}
          />
          <CalendarTodayIcon
            onClick={handleIconClick}
            id='custom-date-picker'
            className={(classes.dateIconStyle, dateIconStyle)}
          />
        </Box>
        {errorMessage ? (
          <ContainerTitle
            title={errorMessage}
            className={clsx(classes.errorStyle)}
          />
        ) : null}
      </Box>
    </>
  );
};
CustomDateSelectField.defaultProps = {
  placeholder: '',
  onChange: () => {},
  id: 'select-date-field',
  containerClassName: '',
  dateIconStyle: '',
  customDatePicker: '',
};
export default CustomDateSelectField;
