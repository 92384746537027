import { Box, makeStyles, TextField, useTheme } from '@material-ui/core';
import clsx from 'clsx';
import ContainerTitle from '../common/title/ContainerTitle';

const useStyle = makeStyles(theme => ({
  container: {
    position: 'relative',
    marginBottom: 20,
  },
  textFieldStyle: {},
  errorStyle: {
    color: 'red',
    fontSize: '0.9rem',
    fontWeight: 500,
    position: 'absolute',
    bottom: -20,
  },
}));
const CustomTextField = props => {
  const theme = useTheme();
  const classes = useStyle(theme);
  const {
    placeholder,
    variant,
    size,
    className,
    value,
    onChange,
    type,
    errorMessage,
    field,
    inputProps,
    required,
    id,
    accept,
    label,
    InputProps,
    autocomplete,
    containerClassName,
    disabled,
  } = props;
  const fieldProps = {
    placeholder,
    variant,
    size,
    value,
    type,
    inputProps,
    required,
    id,
    accept,
    InputProps,
    label,
    autocomplete,
  };
  return (
    <>
      <Box className={clsx(classes.container, containerClassName)}>
        <TextField
          fullWidth
          {...fieldProps}
          className={clsx(classes.textFieldStyle, className)}
          onChange={onChange}
          disabled={disabled}
          id={id}
        />
        {errorMessage ? (
          <ContainerTitle title={errorMessage} className={classes.errorStyle} />
        ) : null}
      </Box>
    </>
  );
};
CustomTextField.defaultProps = {
  placeholder: '',
  size: 'small',
  type: 'text',
  disabled: false,
  onChange: () => {},
  containerClassName: '',
};
export default CustomTextField;
