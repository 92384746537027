import { Box, makeStyles, Typography, useTheme } from '@material-ui/core';
import clsx from 'clsx';
import { constant } from '../../../utils/constant';

const useStyles = makeStyles(theme => ({
  titleFont: {
    fontSize: '1.6rem',
    color: '#155B6B',
    fontWeight: 800,
    [theme.breakpoints.up(constant.breakLaptopAuthPoint)]: {
      fontSize: '2rem',
    },
    [theme.breakpoints.down(constant.breakSmallMobileAuthPoint)]: {
      fontSize: '1.4rem',
    },
  },
  subTitleFont: {
    fontSize: '0.8rem',
    color: '#359DB6',
    fontWeight: 500,
    marginTop: 4,
    [theme.breakpoints.up(constant.breakLaptopAuthPoint)]: {
      fontSize: '1.3rem',
    },
    [theme.breakpoints.down(constant.breakSmallMobileAuthPoint)]: {
      fontSize: '0.6rem',
    },
  },
  titleContainer: {
    marginTop: 16,
    marginBottom: 32,
    width: '100%',
    [theme.breakpoints.up(constant.breakLaptopAuthPoint)]: {
      marginTop: 23,
    },
    [theme.breakpoints.down(constant.breakMobileAuthPoint)]: {
      marginTop: 12,
    },
    [theme.breakpoints.down(constant.breakSmallMobileAuthPoint)]: {
      marginTop: 6,
      marginBottom: 16,
    },
  },
}));

const Title = props => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { title, subtitle } = props;
  return (
    <Box className={clsx(classes.titleContainer)}>
      <Box>
        <Typography className={clsx(classes.titleFont)}>{title}</Typography>
      </Box>
      <Box>
        <Typography className={clsx(classes.subTitleFont)}>
          {subtitle}
        </Typography>
      </Box>
    </Box>
  );
};

export default Title;
