// Auth Actions
export const SET_STATUS = 'SET_STATUS';
export const SET_LOADING = 'SET_LOADING';
export const PARENT_LOGIN_STATUS = 'PARENT_LOGIN_STATUS';
export const CHILDREN_LOGIN_STATUS = 'CHILDREN_LOGIN_STATUS';
export const CREATE_CHILDREN_ACCOUNT = 'CREATE_CHILDREN_ACCOUNT';
export const PARENT_RESET = 'PARENT_RESET';
export const IS_LOGGED_IN = 'IS_LOGGED_IN';
export const CHILD_OTP_VERIFIED = 'CHILD_OTP_VERIFIED';

// Notify Actions
export const ENQUEUE_SNACKBAR = 'ENQUEUE_SNACKBAR';
export const CLOSE_SNACKBAR = 'CLOSE_SNACKBAR';
export const REMOVE_SNACKBAR = 'REMOVE_SNACKBAR';
//User Actions
export const SET_USER = 'SET_USER';
export const SET_USER_LOADING = 'SET_USER_LOADING';
export const LOGOUT_USER = 'LOGOUT_USER';
export const SET_TASK = 'SET_TASK';
export const SET_TASK_BY_DATE = 'SET_TASK_BY_DATE';
export const SET_GUARDIAN_REQUEST = 'SET_GUARDIAN_REQUEST';
export const SET_ALL_TASK = 'SET_ALL_TASK';
export const SET_COMPLETE_TASK = 'SET_COMPLETE_TASK';
export const SET_UPDATE_TASK = 'SET_UPDATE_TASK';
export const SET_CHILD_LIST = 'SET_CHILD_LIST';
export const SET_GUARDIAN_LIST = 'SET_GUARDIAN_LIST';
export const SET_ALLTASK_PAGINATION_PAGE = 'SET_ALLTASK_PAGINATION_PAGE';
export const SET_COMPLETEDTASK_PAGINATION_PAGE =
  'SET_COMPLETEDTASK_PAGINATION_PAGE';
export const SET_UPDATETASK_PAGINATION_PAGE = 'SET_UPDATETASK_PAGINATION_PAGE';
export const SET_DEFAULT_PAGINATION = 'SET_DEFAULT_PAGINATION';
export const SET_RECOMMENDATION_LOADING = 'SET_RECOMMENDATION_LOADING';
export const SET_TASK_OPTIONS = 'SET_TASK_OPTIONS';
export const SET_REWARDS = 'SET_REWARDS';

//Pagination Actions
