import {
  Box,
  Grid,
  makeStyles,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import clsx from 'clsx';
import { connect } from 'react-redux';
import * as actionCreator from '../../store/action';
import { constant } from '../../utils/constant';
import TaskContainer from '../../pages/dashboard/components/TaskContainer';
import React from 'react';
import KidsTaskContainer from '../../pages/kidSection/kidsDashboard/components/KidsTaskContainer';
import moment from 'moment';
import KidTransparent from '../../assets/images/Kid_Transparent.gif';
import { taskListHeight } from '../../utils/dom';

const useStyle = makeStyles(theme => ({
  container: {},
  taskContainer: {
    marginBottom: '0.7rem',
  },
  kidloader: {
    width: '50%',
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      width: '50%',
    },
  },
}));
const KidsTaskList = props => {
  const {
    isParentLoggedIn,
    checked,
    getTaskList,
    completedTaskList,
    updateTaskList,
    allTaskList,
    paginationCompleteTask,
    paginationAllTask,
    paginationUpdateTask,
    taskContainerId,
    createdLoading,
    overDueLoading,

    completedLoading,
  } = props;
  const theme = useTheme();
  const classes = useStyle(theme);
  const firstLoad = React.useRef(true);
  const elementRef = React.useRef();
  const matches = useMediaQuery(theme.breakpoints.down('xs'));

  // if(isParentLoggedIn){

  // }
  const getTaskMethod = () => {
    let filter = {
      sort: {
        updated_at: -1,
      },
    };
    let paginationData =
      checked == 1
        ? paginationCompleteTask
        : checked == 2
        ? paginationUpdateTask
        : paginationAllTask;
    if (paginationData?.filter?.selectedChild) {
      filter.childId = paginationData?.filter?.selectedChild?._id;
    }
    if (checked == 1) {
      filter.status = constant.parentApprovedStatus;
    } else if (checked == 2) {
      filter.status = constant.createdStatus;
      filter.toDate = moment(new Date()).toISOString();
    } else {
      filter.kidsAllTaskFilter = {
        neStatus: constant.parentApprovedStatus,
        orStatus: constant.childApprovedStatus,
        orNeStatus: constant.createdStatus,
        orTimeStamp: moment(
          new Date(),
          // moment(new Date()).format('yyyy-MM-DD hh:mm'),
        ).toISOString(),
      };
    }

    filter = {
      ...filter,
      limit: paginationData.limit,
      skip: paginationData.skip * paginationData.limit,
    };
    getTaskList(filter);
  };
  React.useEffect(() => {
    // if (isParentLoggedIn) {
    let paginationData =
      checked == 1
        ? paginationCompleteTask
        : checked == 2
        ? paginationUpdateTask
        : paginationAllTask;
    if (firstLoad.current) {
      getTaskMethod();
    }
    firstLoad.current = paginationData.total === null ? false : true;
    // } else {
    //   getTaskMethod();
    // }
  }, [
    checked == 1
      ? paginationCompleteTask
      : checked == 2
      ? paginationUpdateTask
      : paginationAllTask,
  ]);

  React.useEffect(() => {
    if (!elementRef.current || matches) return; // wait for the elementRef to be available
    const resizeObserver = new ResizeObserver(() => {
      // Do what you want to do when the size of the element changes
      taskListHeight();
    });
    resizeObserver.observe(elementRef.current);
    return () => resizeObserver.disconnect(); // clean up
  }, []);

  const loading =
    checked == 1
      ? completedLoading
      : checked == 2
      ? overDueLoading
      : createdLoading;

  return (
    <Box id={taskContainerId} className={clsx(classes.container)}>
      <div id={`${taskContainerId}innerDiv`} ref={elementRef}>
        {loading ? (
          <Grid container justifyContent="center">
            <>
              <img src={KidTransparent} className={classes.kidloader} />
            </>
          </Grid>
        ) : (
          (checked == 1
            ? completedTaskList
            : checked == 2
            ? updateTaskList
            : allTaskList
          )?.task?.map((data, index) => {
            return (
              <Box className={clsx(classes.taskContainer)} key={data.id}>
                <KidsTaskContainer
                  checked={checked}
                  taskDetail={data}
                  isParentLoggedIn={isParentLoggedIn}
                />
              </Box>
            );
          })
        )}
      </div>
    </Box>
  );
};

const mapStateToProps = ({ userReducer, paginationReducer }) => {
  return {
    updateTaskList: userReducer.updateTasks,
    completedTaskList: userReducer.completedTasks,
    allTaskList: userReducer.allTasks,
    paginationUpdateTask: paginationReducer.paginationUpdateTask,
    paginationCompleteTask: paginationReducer.paginationCompleteTask,
    paginationAllTask: paginationReducer.paginationAllTask,
    completedLoading: userReducer.loadings.getTasksCompleted,
    createdLoading: userReducer.loadings.getTasksCreated,
    overDueLoading: userReducer.loadings.getTasksDue,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getTaskList: data => {
      dispatch(actionCreator.getKidsTaskList(data));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(KidsTaskList);
