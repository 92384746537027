import {
  Divider,
  Grid,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { React } from 'react';
import Footer from '../../../components/common/footer/Footer';
import Header from '../../../components/common/header/Header';
import curveImg from '../../../assets/images/curveCareer.svg';
import personImg from '../../../assets/images/careerPageManIcon.svg';
import design from '../../../assets/images/icon1.svg';

const useStyles = makeStyles(theme => ({
  gridContainer: {
    display: 'grid',
    backgroundColor: '#eef7fa',
    [theme.breakpoints.down('500')]: {
      display: 'grid',
    },
  },
  maintext: {
    color: 'black',
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: '2rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.4rem',
      marginTop: '2%',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.1rem',
      marginTop: '0%',
    },
  },
  mainContainer: {
    display: 'grid',
    padding: '6%',
    height: 'auto%',
    backgroundColor: '#eef7fa',
    [theme.breakpoints.down('xs')]: {
      marginTop: '12%',
      padding: '8%',
    },
  },
  innerContainer: {
    borderRadius: '15px',
    width: '80%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: '10% 10% 8% 10%',
    backgroundColor: '#fff',
    [theme.breakpoints.between('sm', 'md')]: {
      margin: '10%',
    },
    [theme.breakpoints.down('sm')]: {
      margin: '20% 10% 10%',
    },
    [theme.breakpoints.down('xs')]: {
      width: '70%',
      marginTop: '30%',
      marginLeft: '15%',
    },
  },
  innerContainer1: {
    position: 'absolute',
    top: '55%',
    left: '6%',
    [theme.breakpoints.down('sm')]: {
      top: '40%',
    },
  },
  sectionContainer: {
    alignItems: 'center',
    paddingBottom: '4%',
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
    },
  },
  textContainer1: {
    textAlign: 'center',
    color: '#6F6F6F',
    lineHeight: '2.3rem',
    fontSize: '1.2rem',
    padding: '2% 8% 0% 8%',
    [theme.breakpoints.down('sm')]: {
      marginRight: '0%',
      fontSize: '0.7rem',
      lineHeight: '1rem',
    },
    [theme.breakpoints.down('xs')]: {
      margin: '2% 6%',
      fontSize: '0.6rem',
      lineHeight: '1rem',
      padding: '2% 6%',
    },
  },
  textContaniner: {
    textAlign: 'right',
    marginRight: '2%',
    color: '#6F6F6F',
    fontSize: '1.2rem',
    lineHeight: '2.3rem',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      margin: '0%',
      fontSize: '0.7rem',
      paddingTop: '6%',
      lineHeight: '1rem',
    },
    [theme.breakpoints.down('xs')]: {
      margin: '2% 6% 6%',
      fontSize: '0.5rem',
      // padding: '6% 4%',
      lineHeight: '1rem',
    },
  },
  image: {
    width: '80%',
  },
  dividerGrid: {
    marginTop: '4%',
    marginBottom: '2%',
    [theme.breakpoints.down('xs')]: {
      marginBottom: '0%',
    },
  },
  imageGrid: {
    textAlign: 'center',
    paddingLeft: '8%',
    [theme.breakpoints.down('xs')]: {
      paddingLeft: '0%',
    },
  },
  image1: {
    width: '6vw',
    [theme.breakpoints.down('sm')]: {
      width: '8vw',
    },
    [theme.breakpoints.down('xs')]: {
      width: '15vw',
    },
  },
}));
const CareersPage = () => {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <div>
      <Header />
      <Grid container className={classes.gridContainer}>
        <>
          <Grid className={classes.innerContainer}>
            <Grid item xs={12} style={{ textAlign: 'right' }}>
              <img src={curveImg} className={classes.image1} />
            </Grid>
            <Grid item xs={12}>
              <Typography className={classes.maintext}>
                {'Want to '} <text style={{ color: '#359DB6' }}>{'Join '}</text>
                {'Our Team'}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography className={classes.textContainer1}>
                We are always looking for people with skills and the right
                attitude, and more importantly with a desire to be part of a
                progressive, all-inclusive team culture. We have opportunities
                coming up all around the globe for people.
              </Typography>
            </Grid>
            <Grid
              item
              xs={6}
              md={12}
              sm={10}
              // justifyContent="center"
              className={classes.dividerGrid}>
              <Divider variant="inset" width="85%" />
            </Grid>
            <Grid item container className={classes.sectionContainer}>
              <Grid
                item
                xs={6}
                sm={4}
                md={4}
                className={classes.imageGrid}
                style={{}}>
                <img src={personImg} className={classes.image} />
              </Grid>
              <Grid item xs={10} sm={7} md={7}>
                <Typography className={classes.textContaniner}>
                  {
                    'If you would like to join our workforce, please send across your CVs and details to us on our email id'
                  }{' '}
                  <text style={{ color: '#359DB6', fontWeight: 'bold' }}>
                    {'support@principalityofcogito.com'}
                  </text>
                  {' and we will be in touch with you.'}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          {!matches ? (
            <Grid item xs={12} className={classes.innerContainer1}>
              <img src={design} style={{ width: '7vw' }} />
            </Grid>
          ) : null}
        </>
      </Grid>
      <Footer />
    </div>
  );
};

export default CareersPage;
