import React from 'react';

import { Box, makeStyles, useTheme } from '@material-ui/core';

import { constant } from '../../utils/constant';
import Header from './CommonHeader';
import Sidebar from './Sidebar';
import PageBackgroundTheme1 from '../themeComponents/PageBackgroundThemeAge1';
import PageBackgroundTheme2 from '../themeComponents/PageBackgroundThemeAge2';
import PageBackgroundTheme3 from '../themeComponents/PageBackgroundTheme3';

const useStyles = makeStyles(theme => ({
  container: {
    // width: '100%',
    // height: '100vh',
    backgroundColor: theme?.customTheme?.pageBackgroundColor || '#fff',
  },
  screenContainer: {
    zIndex: 2,
    position: 'relative',
  },
  themeContainer: {
    zIndex: 1,
    position: 'relative',
  },
  rightContainerStyle: {
    display: 'flex',
    flexDirection: 'column',
  },
  childContainer: {
    flexGrow: 1,
    marginLeft: '4rem',
    minHeight: '90vh',
    // overflowY: 'scroll',
    [theme.breakpoints.up(constant.breakLaptopAuthPoint)]: {
      marginLeft: '5.5rem',
    },
    [theme.breakpoints.down(constant.breakMobileAuthPoint)]: {
      marginLeft: '0rem',
    },
  },
}));

const Layout = props => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { children } = props;
  const [hamburger, setHamburger] = React.useState(false);
  const handleHanburger = () => setHamburger(!hamburger);

  return (
    <Box className={classes.container}>
      <Box className={classes.screenContainer}>
        <Sidebar hamburger={hamburger} handleHanburger={handleHanburger} />
        <Box className={classes.rightContainerStyle}>
          <Header handleHanburger={handleHanburger} />
          <Box className={classes.childContainer}>{children}</Box>
        </Box>
      </Box>
      <Box className={classes.themeContainer}>
        {theme?.customTheme?.isChild1 ? (
          <PageBackgroundTheme1 />
        ) : theme?.customTheme?.isChild2 ? (
          <PageBackgroundTheme2 />
        ) : theme?.customTheme?.isChild3 ? (
          <PageBackgroundTheme3 />
        ) : null}
      </Box>
    </Box>
  );
};
export default Layout;
