import { makeStyles, useTheme } from '@material-ui/core';
import clsx from 'clsx';
import { constant } from '../../utils/constant';

const useStyle = makeStyles(theme => ({
  profilePic: {
    [theme.breakpoints.up(constant.breakLaptopAuthPoint)]: {
      width: '3.5rem',
      height: '2.8rem',
      borderRadius: '6px',
      border: '1px solid #1F5673',
    },
    [theme.breakpoints.down(constant.breakLaptopAuthPoint)]: {
      width: '3.5rem',
      height: '2.8rem',
      borderRadius: '5px',
      border: '1px solid #1F5673',
    },
    [theme.breakpoints.down(constant.breakMobileAuthPoint)]: {
      width: '3rem',
      height: '2.3rem',
      borderRadius: '5px',
      border: '1px solid #1F5673',
    },
    [theme.breakpoints.down(constant.breakSmallMobileAuthPoint)]: {
      width: '2.7rem',
      height: '2rem',
      borderRadius: '5px',
      border: '1px solid #1F5673',
    },
  },
}));

const KidsProfilePic = props => {
  const { pic } = props;
  const theme = useTheme();
  const classes = useStyle(theme);
  return <img src={pic} alt="profile" className={clsx(classes.profilePic)} />;
};
export default KidsProfilePic;
