import { makeStyles, useTheme } from '@material-ui/core';
import clsx from 'clsx';
import LOGO from '../../../assets/images/logo-light.svg';

const useStyle = makeStyles(theme => ({
  imageStyle: {
    width: 40,
    height: 'auto',
  },
}));
const CogitoLogo = props => {
  const theme = useTheme();
  const classes = useStyle(theme);
  const { width, height, className } = props;
  return (
    <img
      src={LOGO}
      alt="Logo"
      style={{ width, height }}
      id="home-redirect"
      className={clsx(className, classes.imageStyle)}
    />
  );
};
CogitoLogo.defaultProps = {
  className: '',
};
export default CogitoLogo;
