import { Box, makeStyles, useTheme } from '@material-ui/core';
import ParentTransparent from '../../assets/images/Parent_Transparent.gif';

const useStyle = makeStyles(theme => ({
  parentloaderContainer: {
    position: 'fixed',
    zIndex: 4,
    height: '100vh',
    width: '100vw',
    top: 0,
    left: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  parentloader: {
    height: '30vh',
    width: '30vh',
  },
}));

const FullScreenLoader = props => {
  const theme = useTheme();
  const classes = useStyle(theme);
  const { onClick } = props;

  return (
    <Box className={classes.parentloaderContainer} onClick={onClick}>
      <img src={ParentTransparent} className={classes.parentloader} />
    </Box>
  );
};
FullScreenLoader.defaultProps = {
  onClick: () => {},
};

export default FullScreenLoader;
