import { Box, Grid, makeStyles, useTheme } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';
import AuthScreen from '../preLoggedIn/accountType/components/AuthScreen';
import LeftContainer from '../preLoggedIn/accountType/components/LeftContainer';
import LoginContainer from './components/LoginContainer';

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
    height: '100vh',
    backgroundColor: '#EEF7FA',
  },
}));

const ParentLogin = () => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const leftContainer = (
    <LeftContainer isParent={true} child={<LoginContainer isParent={true} />} />
  );
  return (
    <Box className={clsx(classes.container)}>
      <AuthScreen leftContainer={leftContainer} />
    </Box>
  );
};

const mapStateToProps = ({}) => {
  return {};
};
const mapDispatchToProps = dispatch => {
  return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(ParentLogin);
