import {
  Box,
  Grid,
  makeStyles,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import { connect } from 'react-redux';
import BorderButton from '../../../components/buttons/BorderButton';
import CommonModel from '../../../components/common/model/CommonModel';
import ContainerTitle from '../../../components/common/title/ContainerTitle';
import * as actionCreator from '../../../store/action';
import { constant } from '../../../utils/constant';
import {
  capitalFirstLetter,
  maxStringLength,
} from '../../../utils/stringMethods';
import { getAgeFromDob } from '../../../utils/timeMethods';
import CreateChildModal from '../../dashboard/components/CreateChildModal';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import BaseButton from '../../../components/buttons/BaseButton';
import EmptyGuardian from '../../../components/EmptyData/EmptyGuardian';
import InviteParentModal from '../InviteParentModal';
import KidTransparent from '../../../assets/images/Kid_Transparent.gif';

const useStyle = makeStyles(theme => ({
  container: {
    padding: '2vh',
    position: 'relative',
  },
  inviteContainer: {
    minWidth: '10%',
  },
  headingStyle: {
    color: theme.customTheme.profilePageHeaderColor,
  },
  innerContainer: {
    padding: '2vh 2vh',
    [theme.breakpoints.down(constant.breakMobileAuthPoint)]: {
      padding: '1vh 0vh',
    },
    [theme.breakpoints.down(constant.breakSmallMobileAuthPoint)]: {
      padding: '0vh 0vh',
    },
  },
  headingContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  justifyCenter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modelTitleContainerStyle: {
    justifyContent: 'flex-start !important',
  },
  tableContainer: {
    marginTop: 20,
    borderTop: '1px solid #CBCBCB',
    [theme.breakpoints.down(640)]: {
      overflowX: 'scroll',
    },
  },
  tableRowContainer: {
    padding: '2% 1%',
    margin: '1vh 0',
    borderRadius: 12,
  },
  tableHeader: {
    fontSize: '1rem',
    color: theme?.customTheme?.profilePageHeaderColor,
  },
  tableDataStyle: {
    fontSize: '0.8rem',
    fontWeight: 300,
    // color: theme?.customTheme?.profilePageRowDataEvenColor,
  },
  oddTableDataStyle: {
    fontSize: '0.9rem',
    fontWeight: 500,
    // color: theme?.customTheme?.profilePageRowDataOddColor,
  },
  profileContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  profilePicContainer: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 5,
  },
  profilePic: {
    fontSize: '2.5rem',
    borderRadius: 50,
    margin: '0 1vh 0 0vh',
  },
  profileName: {},

  transparentRowClass: {
    backgroundColor: theme?.customTheme?.profilePageTransparentTableRowColor,
  },
  colouredRowClass: {
    backgroundColor: theme?.customTheme?.profilePageColouredTableRowColor,
  },
  noGuardianImageContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  noGuardianImageStyle: {
    width: '40%',
  },
  profilePic1: {
    margin: '0 1vh 0 0vh',
    borderRadius: '60px',
    [theme.breakpoints.up(constant.breakLaptopAuthPoint)]: {
      width: '2.3rem',
      height: '2.2rem',
    },
    [theme.breakpoints.down(constant.breakLaptopAuthPoint)]: {
      width: '2.5rem',
      height: '2.5rem',
    },
    [theme.breakpoints.down(constant.breakMobileAuthPoint)]: {
      width: '2.2rem',
      height: '2.2rem',
    },
    [theme.breakpoints.down(constant.breakSmallMobileAuthPoint)]: {
      width: '2rem',
      height: '2rem',
    },
  },
  inviteParentButton: {
    backgroundColor: theme.customTheme.inviteButton,
    '&:hover': {
      backgroundColor: theme.customTheme.profilePageTransparentTableRowColor,
      color: theme.customTheme.inviteButton,
    },
    [theme.breakpoints.down('xs')]: {
      marginBottom: '0.4rem',
    },
  },
  loader: {
    width: '20%',
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      width: '50%',
    },
    [theme.breakpoints.down('md')]: {
      width: '50%',
    },
  },
}));

const GuardianProfilePage = props => {
  const theme = useTheme();
  const classes = useStyle(theme);
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const { profile, getGuardianList, guardianList, getGuardians } = props;
  const [inviteParent, setInviteParent] = React.useState(false);

  const handleInviteParentModal = () => {
    setInviteParent(!inviteParent);
  };

  const profileDetail = data => {
    return (
      <Box className={clsx(classes.profileContainer)}>
        <Box className={clsx(classes.profilePicContainer)}>
          {data.profile_pic ? (
            <img
              src={`${process.env.REACT_APP_SPACE_KEY}/${data?.profile_pic}`}
              alt="profile"
              className={clsx(classes.profilePic1)}
            />
          ) : (
            <AccountCircleIcon className={clsx(classes.profilePic)} />
          )}
        </Box>
        <Box className={clsx(classes.profileName)}>
          {data.firstName + ' ' + data.lastName}
        </Box>
      </Box>
    );
  };

  React.useEffect(() => {
    getGuardianList();
  }, []);
  return (
    <>
      <CommonModel
        title="Invite Parent"
        titleContainerStyle={classes.modelTitleContainerStyle}
        containerStyle={classes.inviteContainer}
        open={inviteParent}
        handleClose={handleInviteParentModal}
        body={
          <InviteParentModal
            open={inviteParent}
            handleClose={handleInviteParentModal}
          />
        }
      />
      <Box className={clsx(classes.container)}>
        <Box className={clsx(classes.innerContainer)}>
          <Box className={clsx(classes.headingContainer)}>
            <ContainerTitle
              title={'Parents Profile'}
              className={classes.headingStyle}
            />

            <BorderButton
              className={classes.inviteParentButton}
              text="Invite Parent"
              onClick={handleInviteParentModal}
            />
          </Box>

          {!getGuardians ? (
            guardianList.length ? (
              <Box className={clsx(classes.tableContainer)}>
                <Grid
                  container
                  style={{
                    minWidth: 620,
                  }}>
                  <Grid
                    item
                    container
                    xs={12}
                    className={classes.tableRowContainer}>
                    <Grid item xs={4}>
                      <ContainerTitle
                        title={'Name'}
                        className={classes.tableHeader}
                      />
                    </Grid>
                    <Grid item xs={2} className={clsx(classes.justifyCenter)}>
                      <ContainerTitle
                        title={'Age'}
                        className={classes.tableHeader}
                      />
                    </Grid>
                    <Grid item xs={3} className={clsx(classes.justifyCenter)}>
                      <ContainerTitle
                        title={'Relation'}
                        className={classes.tableHeader}
                      />
                    </Grid>
                    <Grid item xs={3} className={clsx(classes.justifyCenter)}>
                      <ContainerTitle
                        title={'Email'}
                        className={classes.tableHeader}
                      />
                    </Grid>
                  </Grid>
                  {guardianList.length
                    ? guardianList?.map((data, index) => {
                        let relation = '';
                        data?.family?.map(familyData => {
                          if (familyData._id == profile._id) {
                            relation = familyData.yourRelation;
                          }
                        });
                        return (
                          <Grid
                            item
                            container
                            xs={12}
                            className={clsx(
                              classes.tableRowContainer,
                              index % 2 == 0
                                ? classes.transparentRowClass
                                : classes.colouredRowClass,
                            )}
                            key={data._id}>
                            <Grid item xs={4} style={{ paddingLeft: '1%' }}>
                              <ContainerTitle
                                title={profileDetail(data)}
                                className={
                                  index % 2 == 0
                                    ? classes.tableDataStyle
                                    : classes.oddTableDataStyle
                                }
                              />
                            </Grid>
                            <Grid
                              item
                              xs={2}
                              className={clsx(classes.justifyCenter)}>
                              <ContainerTitle
                                title={getAgeFromDob(data.dateOfBirth)}
                                className={
                                  index % 2 == 0
                                    ? classes.tableDataStyle
                                    : classes.oddTableDataStyle
                                }
                              />
                            </Grid>
                            <Grid
                              item
                              xs={3}
                              className={clsx(classes.justifyCenter)}>
                              <ContainerTitle
                                title={capitalFirstLetter(relation)}
                                className={
                                  index % 2 == 0
                                    ? classes.tableDataStyle
                                    : classes.oddTableDataStyle
                                }
                              />
                            </Grid>
                            <Grid
                              item
                              xs={3}
                              className={clsx(classes.justifyCenter)}>
                              {matches ? (
                                <ContainerTitle
                                  title={maxStringLength(data.email, 16)}
                                  className={
                                    index % 2 == 0
                                      ? classes.tableDataStyle
                                      : classes.oddTableDataStyle
                                  }
                                />
                              ) : (
                                <ContainerTitle
                                  title={maxStringLength(data.email, 30)}
                                  className={
                                    index % 2 == 0
                                      ? classes.tableDataStyle
                                      : classes.oddTableDataStyle
                                  }
                                />
                              )}
                            </Grid>
                          </Grid>
                        );
                      })
                    : null}
                </Grid>
              </Box>
            ) : (
              <Box className={classes.noGuardianImageContainer}>
                <EmptyGuardian
                  imageClass={classes.noGuardianImageStyle}
                  text={
                    <ContainerTitle
                      title={'No Parents added'}
                      className={clsx(classes.headingStyle)}
                    />
                  }
                />
              </Box>
            )
          ) : (
            <Grid container justifyContent="center">
              <img src={KidTransparent} className={classes.loader} />
            </Grid>
          )}
        </Box>
      </Box>
    </>
  );
};

const mapStateToProps = ({ userReducer, authReducer }) => {
  return {
    profile: userReducer.profile,
    guardianList: userReducer.guardianList,
    getGuardians: userReducer.loadings.getGuardians,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getGuardianList: () => {
      dispatch(actionCreator.getGuardianList());
    },
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(GuardianProfilePage);
