import React from 'react';
import {
  Container,
  Box,
  Grid,
  Link,
  useTheme,
  Typography,
  useMediaQuery,
  Divider,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import LOGO from '../../../assets/images/logo-light.svg';
import FbIcon from '../../../assets/images/FooterImages/FacebookIcon.png';
import InstaIcon from '../../../assets/images/FooterImages/Instagramcon.png';
import TwitterIcon from '../../../assets/images/FooterImages/TwitterIcon.png';
import LinkedIcon from '../../../assets/images/FooterImages/LinkedInIcon.png';
import SettingWhite from '../../../assets/images/landingPageImages/SettingWhite.png';
import StatsWhite from '../../../assets/images/landingPageImages/StatsWhite.png';
import clsx from 'clsx';
import AppStore from '../../../assets/images/App Store.svg';
import GooglePlayStore from '../../../assets/images/Google Play.svg';
const useStyles = makeStyles(theme => ({
  downloadIcon: {
    display: 'flex',
    flexDirection: 'row',
    paddingBottom: '3%',
    justifyContent: 'center',
  },
  downIcon: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 56,
  },

  alginText: {
    textAlign: 'center',
  },
  headText: {
    fontFamily: 'Inter',
    fontSize: 18,
    color: 'white',
    // textAlign: 'left',
    fontWeight: 'bolder',
    padding: '0 0 0.5em',
    [theme.breakpoints.down('xs')]: {
      padding: '8% 0% 0%',
    },
  },
  Links: {
    fontFamily: 'Inter',
    fontSize: 15,
    color: 'white',
    textAlign: 'left',
    padding: '0.5em',
  },

  subText: {
    fontFamily: 'Open Sans',
    fontSize: 16,
    textAlign: 'center',
    paddingLeft: '0rem',
  },
  paddingDown: {
    paddingBottom: 40,
    fontFamily: 'Open Sans',
  },

  socialIcons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    textAlign: 'left',
    margin: 'auto 2.5rem',
    [theme.breakpoints.down(1280)]: { margin: 'auto 10rem' },
    [theme.breakpoints.down(960)]: { margin: 'auto 7.5rem' },
    [theme.breakpoints.down(800)]: { margin: 'auto 5rem' },
    [theme.breakpoints.down(650)]: { margin: 'auto 3rem' },
    [theme.breakpoints.down(450)]: { margin: 'auto 2.5rem' },
  },
  designRight: {
    marginTop: '2%',
    position: 'absolute',
    right: '0',
    [theme.breakpoints.down('xs')]: {
      marginTop: '100px',
    },
  },
  designLeft: {
    marginTop: '6%',
    position: 'absolute',
    width: '45px',
    left: 0,
    // top: 120,
    [theme.breakpoints.down('xs')]: {
      marginTop: '200px',
    },
  },
  footerItems: {
    color: '#fff',
    [theme.breakpoints.down('xs')]: {
      paddingTop: '4%',
    },
  },
  footerContainer: {
    padding: '1% 9%',
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('xs')]: {
      marginTop: '2%',
    },
  },
  Divider: {
    width: '90%',
    background: '#fff',
    marginTop: '2%',
    [theme.breakpoints.down('xs')]: {
      marginTop: '8%',
    },
  },
  logo: {
    width: '20%',
    [theme.breakpoints.down('xs')]: {
      width: '30%',
    },
  },
  tagline: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.9rem',
    },
  },
}));

const Footer = () => {
  const themed = useTheme();
  const matches = useMediaQuery(themed.breakpoints.down('sm'));
  const matchesSm = useMediaQuery(themed.breakpoints.down('sm'));

  const classes = useStyles();
  return (
    <Box
      bgcolor="#155B6B"
      className="footerContainer"
      style={{ overflow: 'clip' }}>
      {matches ? null : (
        <>
          <img
            src={StatsWhite}
            align="right"
            style={{ paddingTop: '10px', width: '50px' }}
          />
          <img
            src={SettingWhite}
            align="left"
            style={{ paddingBottom: '20px', width: '50px' }}
          />
        </>
      )}

      <Box
        bgcolor="#155B6B"
        className="footerContainer"
        style={{
          overflow: 'clip',
        }}>
        {matches ? (
          <>
            <Grid container>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <Box>
                  <img
                    src={LOGO}
                    style={{
                      width: '50%',
                      paddingLeft: '50px',
                    }}
                  />
                  <Typography
                    style={{
                      fontFamily: 'Inter',
                      fontWeight: '400',
                      color: 'white',
                      letterSpacing: '0.5px',
                      paddingLeft: '50px',
                    }}>
                    Rewarding Responsibilities
                  </Typography>
                </Box>
                <Box
                  style={{
                    textAlign: 'center',
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                  }}>
                  <Box className={classes.headText}>
                    <Link
                      target="_blank"
                      href="https://apps.apple.com/in/app/pocketmoney-cogito/id6448836756">
                      <img
                        src={AppStore}
                        style={{
                          width: '135px',
                          marginRight: '0.8rem',
                        }}
                      />
                    </Link>
                  </Box>
                  <Box>
                    <Link
                      target="_blank"
                      href="https://play.google.com/store/apps/details?id=com.pocketmoneycogito">
                      <img
                        src={GooglePlayStore}
                        style={{
                          width: '135px',
                          marginRight: '0.8rem',
                        }}
                      />
                    </Link>
                  </Box>
                </Box>
              </div>
              <Box
                container
                style={{
                  padding: '2rem 0 0 0rem ',
                  display: 'flex',
                  color: 'white',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                }}>
                <Box
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-around',
                  }}>
                  <Box>
                    <Box className={classes.headText}>
                      <Link href="/aboutUs" color="inherit">
                        About Us
                      </Link>
                    </Box>

                    <Box className={classes.headText}>
                      <Link href="/ourStory" color="inherit">
                        {'Our Story'}
                      </Link>
                    </Box>
                    <Box>
                      <Box className={classes.headText}>
                        <Link href="/" color="inherit">
                          {/* FAQs */}
                        </Link>
                      </Box>
                    </Box>
                  </Box>
                  <Box>
                    <Box className={classes.headText}>
                      <Link href="/contactUs" color="inherit">
                        Contact Us
                      </Link>
                    </Box>
                    <Box className={classes.headText}>
                      <Link href="/" color="inherit">
                        {/* {"Blog & News"} */}
                      </Link>
                    </Box>
                  </Box>
                </Box>

                <Box>
                  <Box
                    style={{
                      textAlign: 'center',
                    }}>
                    <hr
                      style={{
                        marginTop: '50px',
                        background: 'white',
                        color: 'white',
                        borderColor: 'white',
                        borderWidth: '0px',
                        height: '1px',
                      }}
                    />
                    <Box>
                      <Grid xs={12} className={classes.subText}>
                        <Typography
                          style={{
                            fontFamily: 'Inter',
                            fontWeight: '400',
                            color: '#ffff',
                          }}>
                          {'All the rights reserved to COGITO 2023 Ltd. |'}
                          <Link href="/privacy" color="inherit">
                            Privacy Policy
                          </Link>
                        </Typography>
                      </Grid>
                      <Grid xs={12}>
                        <Box
                          style={{
                            alignItems: 'center',
                          }}>
                          <Link
                            target="_blank"
                            href="https://www.facebook.com/cogitometaverse/">
                            <img
                              src={FbIcon}
                              style={{
                                width: '30px',
                                paddingRight: '0.2rem',
                              }}
                            />
                          </Link>
                          <Link
                            target="_blank"
                            href="https://www.instagram.com/cogito/">
                            <img
                              src={InstaIcon}
                              style={{
                                width: '30px',
                                paddingRight: '0.2rem',
                              }}
                            />
                          </Link>
                          <Link
                            target="_blank"
                            href="https://twitter.com/CogitoMetaverse">
                            <img
                              src={TwitterIcon}
                              style={{
                                width: '30px',
                                paddingRight: '0.2rem',
                              }}
                            />
                          </Link>
                          <Link
                            target="_blank"
                            href="https://www.linkedin.com/company/principality-cogito/">
                            <img
                              src={LinkedIcon}
                              style={{
                                width: '30px',
                                //  paddingLeft: "1.5%",
                              }}
                            />
                          </Link>
                        </Box>
                      </Grid>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </>
        ) : (
          <>
            <Grid
              container
              style={{
                padding: '0rem 3rem',
                display: 'flex',
                justifyContent: 'space-between',
              }}>
              <Box>
                <img
                  src={LOGO}
                  alt="LOGO"
                  style={{
                    width: '50px',
                    color: '#359DB6',
                    alignItem: 'left',
                  }}
                />
                <Typography
                  style={{
                    fontFamily: 'Inter',
                    fontWeight: '400',
                    letterSpacing: '0.5px',
                    color: 'white',
                  }}>
                  Rewarding Responsibilities
                </Typography>
              </Box>
              <Box>
                <Box className={classes.headText}>
                  <Link href="/aboutUs" color="inherit">
                    About Us
                  </Link>
                </Box>

                <Box className={classes.headText}>
                  <Link href="/ourStory" color="inherit">
                    {'Our Story'}
                  </Link>
                </Box>
                <Box>
                  <Box className={classes.headText}>
                    <Link href="/" color="inherit">
                      {/* FAQs */}
                    </Link>
                  </Box>
                </Box>
              </Box>
              <Box>
                <Box className={classes.headText} py={{ sm: 1 }}>
                  <Link href="/contactUs" color="inherit">
                    Contact Us
                  </Link>
                </Box>
                <Box className={classes.headText} py={{ sm: 1 }}>
                  <Link href="/" color="inherit">
                    {/* {"Blog & News"} */}
                  </Link>
                </Box>
              </Box>
              <Box style={{ textAlign: 'center' }}>
                <Box className={classes.headText}>
                  <Link
                    target="_blank"
                    href="https://apps.apple.com/in/app/pocketmoney-cogito/id6448836756">
                    <img
                      src={AppStore}
                      style={{
                        width: '135px',
                        marginRight: '0.8rem',
                      }}
                    />
                  </Link>
                </Box>
                <Box>
                  <Link
                    target="_blank"
                    href="https://play.google.com/store/apps/details?id=com.pocketmoneycogito">
                    <img
                      src={GooglePlayStore}
                      style={{
                        width: '135px',
                        marginRight: '0.8rem',
                      }}
                    />
                  </Link>
                </Box>
              </Box>
            </Grid>
            <Grid>
              <hr
                style={{
                  marginTop: '50px',
                  background: 'white',
                  color: 'white',
                  borderColor: 'white',
                  borderWidth: '0px',
                  height: '1px',
                }}
              />
              <Box>
                <Grid xs={12} container>
                  <Grid xs={11} className={classes.subText}>
                    <Typography
                      style={{
                        fontFamily: 'Inter',
                        fontWeight: '400',
                        color: '#ffff',
                      }}>
                      {'All the rights reserved to COGITO 2023 Ltd. |'}{' '}
                      <Link href="/privacy" color="inherit">
                        Privacy Policy
                      </Link>
                    </Typography>
                  </Grid>
                  <Grid
                    xs={1}
                    style={{
                      alignItems: 'right',
                      display: 'flex',
                      justifyContent: 'flex-end',
                    }}>
                    <Box
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'right',
                      }}>
                      <Link
                        target="_blank"
                        href="https://www.facebook.com/cogitometaverse/">
                        <img
                          src={FbIcon}
                          style={{
                            width: '30px',
                            paddingRight: '0.2rem',
                          }}
                        />
                      </Link>
                      <Link
                        target="_blank"
                        href="https://www.instagram.com/cogito/">
                        <img
                          src={InstaIcon}
                          style={{
                            width: '30px',
                            paddingRight: '0.2rem',
                          }}
                        />
                      </Link>
                      <Link
                        target="_blank"
                        href="https://twitter.com/CogitoMetaverse">
                        <img
                          src={TwitterIcon}
                          style={{
                            width: '30px',
                            paddingRight: '0.2rem',
                          }}
                        />
                      </Link>
                      <Link
                        target="_blank"
                        href="https://www.linkedin.com/company/principality-cogito/">
                        <img
                          src={LinkedIcon}
                          style={{
                            width: '30px',
                            //  paddingLeft: "1.5%",
                          }}
                        />
                      </Link>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </>
        )}
      </Box>
    </Box>
  );
};

export default Footer;
