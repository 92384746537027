import { Box, makeStyles, useTheme } from '@material-ui/core';
import clsx from 'clsx';
// import emptyTaskImage from "../../assets/images/emptyData/NoTaskImage.png";
import emptyTaskImage from '../../assets/images/emptyData/No_Tasks_Available.png';

const useStyle = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  imageStyle: {
    width: '70%',
  },
}));
const EmptyTask = props => {
  const { imageClass, text, isTop } = props;
  const theme = useTheme();
  const classes = useStyle(theme);
  return (
    <Box className={clsx(classes.container)}>
      {isTop && text ? text : null}
      <img
        src={emptyTaskImage}
        className={clsx(classes.imageStyle, imageClass)}
      />
      {!isTop && text ? text : null}
    </Box>
  );
};
EmptyTask.defaultProps = {
  imageClass: '',
  text: '',
  isTop: false,
};

export default EmptyTask;
