import { grey } from '@material-ui/core/colors';
import { constant } from '../utils/constant';

export const parentTheme = {
  isParent: true,
  text: '#0362fc',
  pageBackgroundColor: '#EEF7FA',
  headerBackgroundColor: '#C9E5EC',
  navBarIconColorSelected: '#359DB6',
  navBarIconColorUnSelected: '#999999',
  commonModalHeaderBackground: '#A3D4E0',
  commonModalBackground: '#C9E5EC',
  commonModalButtonColor: '#359DB6',
  commonModalCancelButtonBorder: '2px solid #359DB6',
  commonModalCancelButtontext: '#359DB6',
  commonModalTextStyle: '#333333',
  commonModalImageContainer: '1px solid #C9E5EC',
  sidebar: '#C9E5EC',
  NoProfileImage: '#6CB7CA',
  HeaderNameColor: '#333333',
  RequestContainer: '#C9E5EC',
  sidebarBoxShadow: '0px 4px 6px -2px rgba(50, 118, 109, 1)',
  requestContainerSelect: grey[600],
  selectFieldBorder: '0.5px solid #000',
  cancelIconColor: '#699595',
  acceptIconColor: '#359DB6',
};
