import React from 'react';
import {
  Button,
  Grid,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { connect } from 'react-redux';
import Calender from '../../../assets/images/reward/Calender.png';
import GiftBox from '../../../assets/images/reward/GiftBox.png';
import KidRewardComponent from './KidRewardComponent';
import KidRewardComponent2 from './KidRewardComponent2';
import KidRewardCard from './KidRewardCard';
import * as actionCreator from '../../../store/action';
import DatePicker from 'react-datepicker';
import Line from '../../../assets/images/reward/Line.png';
import NoRewards from '../../../assets/images/rewardsImages/NoRewardsKids.svg';

import 'react-datepicker/dist/react-datepicker.css';
import clsx from 'clsx';
import { priceFormatter } from '../../../utils/methods';
import moment from 'moment';
import Icon from 'react-multi-date-picker/components/icon';
import {
  CalendarTodayTwoTone,
  CalendarViewDay,
  CalendarViewDayOutlined,
  CallEndSharp,
  DateRange,
  PermContactCalendarOutlined,
} from '@material-ui/icons';
import { Calendar } from 'react-multi-date-picker';
import CalendarToday from '@material-ui/icons/CalendarToday';

const useStyle = makeStyles(theme => ({
  container: {
    padding: '1rem',
    position: 'relative',
  },
  Button: {
    width: '20vw',
    height: '60%',
    border: theme.customTheme.rewardCalendarButton,
    backgroundColor: 'transparent',
    color: theme.customTheme.rewardCalendar,
    borderRadius: '20px',
    alignItems: 'center',
    marginBottom: '3px',
    [theme.breakpoints.down('md')]: {
      width: '30vw',
    },
    [theme.breakpoints.up('md')]: {
      width: '20vw',
    },
    [theme.breakpoints.down('xs')]: {
      width: '50vw',
    },
  },

  Button3: {
    width: 'auto',
    height: '50%',
    // border: '2px solid #CB8F6E',
    backgroundColor: 'transparent',
    color: '#CB8F6E',
    borderRadius: '20px',
    alignItems: 'center',
    marginBottom: '3px',
    [theme.breakpoints.down('sm')]: {
      // width: '90px',
      height: '35px',
      backgroundColor: 'transparent',
    },
  },
  Button2: {
    width: '120px',
    height: '50%',
    border: '2px solid #359DB6',
    backgroundColor: 'transparent',
    color: '#359DB6',
    position: 'relative',
    borderRadius: '20px',
    paddingLeft: '15px',
    marginBottom: '3px',
    [theme.breakpoints.down('sm')]: {
      width: '120px',
      height: '35px',
      paddingLeft: '5px',
      marginBottom: '3px',
    },
    component: {
      width: '700px',
      height: '500px',
    },
  },
  rewardBox: {
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      paddingTop: '10%',
    },
    [theme.breakpoints.down('md')]: {
      paddingTop: '0% !important',
    },
  },
  rewards: {
    height: '60vh',
    overflowY: 'scroll',
    justifyContent: 'center',
    [theme.breakpoints.only('sm')]: {
      height: '50vh',
    },
    [theme.breakpoints.down('sm')]: {
      height: 'auto',
      overflowY: 'scroll',
    },
  },
  firstContainer: {
    borderRadius: '20px',
    height: '55vh',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#fff',
    [theme.breakpoints.only('sm')]: {
      height: '15vh',
      marginBottom: '5%',
      display: 'flex',
      flexDirection: 'column-reverse',
    },
    [theme.breakpoints.between(1000, 1200)]: {
      height: '35vh',
    },
  },
  GiftBox: {
    width: '25vw',
    [theme.breakpoints.only('sm')]: {
      width: '18vw',
      marginTop: '18%',
      // paddingLeft: '20%',
    },
  },
  firstContainerText: {
    marginTop: '10%',
    [theme.breakpoints.down('sm')]: {
      marginTop: '5%',
      marginBottom: '8%',
    },
  },
  dividerLine: {
    textAlign: 'center',
    height: '55vh',
    overflow: 'clip',
    [theme.breakpoints.between('sm', 'md')]: {
      height: '50vh',
    },
    [theme.breakpoints.between(1000, 1200)]: {
      height: '35vh',
    },
  },
  Button2OptionContainer: {
    position: 'absolute',
    top: 36,
    left: 0,
    width: '100%',
    padding: 5,
    minHeight: 26,
    borderRadius: 8,
    zIndex: 1,
    backgroundColor: 'rgb(201, 229, 236)',
    border: '0.5px solid rgba(0,0,0,0.2)',
  },
  Button2Option: {
    position: 'relative',
    zIndex: 2,
  },
  Button2OptionBackground: {
    position: 'fixed',
    top: 0,
    left: 0,
    height: '100vh',
    width: '100vw',
    zIndex: 1,
  },
  Button2OptionList: {
    display: 'flex',
    alignItems: 'center',
    padding: 3,
    borderRadius: 8,
    marginBottom: 2,
    '&:hover': {
      backgroundColor: '#359DB6',
      color: '#fff',
    },
  },
  SelectedButton2OptionList: {
    backgroundColor: '#359DB6',
    color: '#fff',
  },
  Button2OptionListImg: {
    height: 22,
    width: 22,
    borderRadius: 16,
    marginRight: 5,
  },
  root: {
    marginTop: '0 !important',
  },
  removeElement: {
    display: 'none !important',
  },
  header: {
    paddingBottom: '8px',
    background: 'white !important',
  },
  month: {
    padding: '8px 0',
  },
  selectedMonth: {
    background: 'gray !important',
    padding: '8px 0',
  },
  inputBox: {
    // background: 'white !important',
    border: 'none',
  },
  NoRewards: {
    borderRadius: '20px',
    height: '60vh',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#ffff',
  },
  NoRewardImg: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
}));
const KidReward = props => {
  const { getRewards, rewards } = props;
  const theme = useTheme();
  const classes = useStyle(theme);
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const ref = React.useRef();
  const [startDate, setStartDate] = React.useState(new Date());
  const [totalReward, setTotalReward] = React.useState(0);

  const selectDateField = () => {
    document
      .getElementById('hide-datepicker')
      .getElementsByTagName('input')[0]
      .click();
  };

  React.useEffect(() => {
    getRewards({
      month: startDate.getMonth() + 1,
      year: startDate.getFullYear(),
    });
  }, [startDate]);

  React.useEffect(() => {
    let reward = 0;
    rewards?.map(data => {
      reward += data?.total_reward || 0;
    });
    setTotalReward(reward);
  }, [rewards]);
  return (
    <div
      style={{
        // backgroundColor: '#eef7fa',
        padding: '2% 5% 0% 5%',
      }}>
      <Typography
        style={{
          color: theme?.customTheme?.rewardTitleTextColor,
          fontSize: '1.5rem',
          fontWeight: 'bold',
          paddingBottom: '1%',
        }}>
        Rewards
      </Typography>

      <Grid
        container
        spacing={1}
        style={{ paddingTop: '1vh', paddingBottom: '2vh' }}>
        <Grid item xs={12}>
          <Typography
            style={{
              color: 'black',
              fontSize: '0.8rem',
              color: '#6F6F6F',
              fontWeight: 'bold',
            }}>
            Select a month to see your rewards
          </Typography>
        </Grid>
        <Grid
          item
          xs={4}
          sm={2}
          md={2}
          lg={1}
          className={clsx('hide-datepicker')}
          id={'hide-datepicker'}>
          <Button
            className={clsx(classes.Button)}
            onClick={selectDateField}
            startIcon={<DateRange />}>
            <text style={{ paddingRight: '15px' }}>
              {' '}
              Date - {moment(startDate).format('MMM, YYYY')}{' '}
            </text>
          </Button>
          <DatePicker
            selected={startDate}
            onChange={date => setStartDate(date)}
            dateFormat="MMM y"
            showMonthYearPicker
            ref={ref}
            onYearChange={() => {
              const selectedMonth = document.getElementsByClassName(
                'react-datepicker__month-text--keyboard-selected',
              );
              selectedMonth[0].classList.add(classes.selectedMonth);
            }}
            onCalendarOpen={() => {
              const x = document.getElementsByClassName(
                'react-datepicker-popper',
              );
              const y = document.getElementsByClassName(
                'react-datepicker__triangle',
              );
              const z = document.getElementsByClassName(
                'react-datepicker__header',
              );
              const months = document.getElementsByClassName(
                'react-datepicker__month-text',
              );
              const selectedMonth = document.getElementsByClassName(
                'react-datepicker__month-text--keyboard-selected',
              );
              x[0].classList.add(classes.root);
              y[0].classList.add(classes.removeElement);
              z[0].classList.add(classes.header);
              for (let i = 0; i < months.length; i++) {
                months[i].classList.add(classes.month);
              }
              selectedMonth[0].classList.add(classes.selectedMonth);
            }}
          />
        </Grid>
      </Grid>
      <Grid
        container
        style={{
          padding: '1% 0%',
        }}>
        <Grid item container display="flex" justifyContent="center">
          {/* rewards points text and image */}
          {totalReward ? (
            <>
              {matches ? (
                <Grid xs={12} style={{ paddingBottom: '8%' }}>
                  <KidRewardCard />
                </Grid>
              ) : (
                <Grid
                  flexDirection="column"
                  item
                  container
                  spacing={1}
                  xs={12}
                  md={4}
                  className={classes.firstContainer}>
                  <Grid item className={classes.firstContainerText}>
                    <Typography
                      style={{
                        textAlign: 'center',
                        color: '#ff725e',
                        fontSize: '1.2rem',
                      }}>
                      Hi!
                    </Typography>
                    <Typography
                      style={{
                        textAlign: 'center',
                        color: '#359DB6',
                        fontSize: '1rem',
                        fontWeight: 'bold',
                      }}>
                      {`You have recieved `}
                      <text style={{ color: '#ff725e' }}>
                        {' '}
                        {priceFormatter(totalReward)}
                      </text>
                      {` COGs`}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <img className={classes.GiftBox} src={GiftBox} />
                  </Grid>
                </Grid>
              )}

              <Grid
                item
                container
                xs={12}
                md={8}
                sm={12}
                className={classes.rewardBox}>
                {!matches ? (
                  <Grid item md={1} className={classes.dividerLine}>
                    <img src={Line} />
                  </Grid>
                ) : null}
                <Grid
                  item
                  container
                  xs={12}
                  md={10}
                  sm={12}
                  spacing={2}
                  className={classes.rewards}>
                  {rewards
                    ? rewards.map((rewardData, index) => {
                        return (
                          <>
                            <Grid item key={index}>
                              <KidRewardComponent
                                rewardData={rewardData}
                                index={index}
                              />
                            </Grid>
                          </>
                        );
                      })
                    : null}
                </Grid>
              </Grid>
            </>
          ) : (
            <>
              <Grid
                flexDirection="column"
                item
                container
                spacing={1}
                xs={12}
                md={12}
                sm={12}
                className={classes.NoRewards}>
                <Grid item style={{ marginTop: '0%' }}>
                  <img src={NoRewards} className={classes.NoRewardImg} />
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = ({ userReducer, authReducer }) => {
  return {
    profile: userReducer.profile,
    isParentLoggedIn: authReducer.isParentLoggedIn,
    isChildrenLoggedIn: authReducer.isChildrenLoggedIn,
    rewards: userReducer.rewards,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getRewards: data => dispatch(actionCreator.getRewardsKid(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(KidReward);
