import {
    Avatar,
    Box,
    Chip,
    Grid,
    makeStyles,
    Typography,
    useTheme,
    Checkbox,
  } from '@material-ui/core';
  import clsx from 'clsx';
  import ContainerTitle from '../../../components/common/title/ContainerTitle';
  import BorderButton from '../../../components/buttons/BorderButton';
  import * as actionCreator from '../../../store/action';
  import {
    getAgeFromDob,
    getDeadline,
    getTimeInMinutes,
    getWeeklyDays,
    mergeDateAndTime,
    monthFromIndex,
    yyyyMmDd,
  } from '../../../utils/timeMethods';
  import CustomTextField from '../../../components/muiInputFields.js/CustomTextField';
  import React from 'react';
  import { connect } from 'react-redux';
  import { constant } from '../../../utils/constant';
  import CustomSelectField from '../../../components/muiInputFields.js/CustomSelectFiled';
  import CustomDateSelectField from '../../../components/muiInputFields.js/CustomDateSelectField';
  import { date } from 'yup';
  import CheckIcon from '@material-ui/icons/Check';
  import moment from 'moment';
  import { getAgeGroup } from '../../../utils/methods';
  
  const useStyle = makeStyles(theme => ({
    createTaskContainer: {
      width: '100%',
      maxWidth: '800px',
    },
    fieldContainer: {
      position: 'relative',
      paddingTop: 2,
      paddingBottom: 2,
      paddingLeft: 10,
      paddingRight: 10,
      borderRadius: 6,
    },
    activeFieldBackground: {
      backgroundColor: '#A3CED9',
    },
    inactiveFieldBackground: {
      backgroundColor: '#A3CED9',
      opacity: 0.3,
      pointerEvents: 'none',
    },
    titleStyle: {
      color: '#333333',
      fontSize: '1rem',
      fontWeight: 550,
    },
    errorText: {
      color: '#e64c4c',
      fontWeight: '600',
      fontSize: '0.9rem',
    },
    assignToList: {
      display: 'flex',
      justifyContent: 'flex-start',
      flexWrap: 'wrap',
      marginTop: 12,
    },
    chipStyle: {
      margin: '0 5px',
      padding: 4,
      border: '1.3px solid #606668',
      minWidth: 100,
      display: 'flex',
      justifyContent: 'flex-start',
      '&:hover': {
        border: '1.3px solid #888',
      },
    },
    unSelectedChipStyle: {
      backgroundColor: 'transparent',
      '&:hover': {
        backgroundColor: 'transparent',
      },
      '&:focus': {
        backgroundColor: 'transparent',
      },
    },
    selectedChipStyle: {
      backgroundColor: '#a3ced9',
      border: '1px solid #246576',
      color: '#1c6172',
      fontSize: '0.9rem',
      fontWeight: '500',
      '&:hover': {
        backgroundColor: '#a3ced9',
        border: '1px solid #246576',
      },
      '&:focus': {
        backgroundColor: '#a3ced9',
        border: '1px solid #246576',
      },
    },
    submitContainer: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: 12,
    },
    errorStyle: {
      color: 'red',
      fontSize: '0.9rem',
      fontWeight: 500,
      position: 'absolute',
      bottom: -25,
    },
    selectedDateContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
    },
    inputFieldContainer: {
      marginBottom: 16,
    },
    checkBoxContianer: {
      display: 'flex',
      alignItems: 'center',
    },
    checkBoxTitleStyle: {
      color: '#212121',
    },
    checkBoxStyle: {
      color: '#359DB6 !important',
    },
  }));
  const EditTaskModal = props => {
    const {
      open,
      handleClose,
      childList,
      createChildrenTask,
      notify,
      tasksOptions,
      getTaskOptions,
      alternateCallBack,
    } = props;
    const theme = useTheme();
    const classes = useStyle(theme);
    const [fieldData, setFieldData] = React.useState({
      title: {
        value: '',
        errorMessage: '',
      },
      task: {
        value: '',
        options: [],
        errorMessage: '',
      },
      schedule: {
        value: '',
        options: [
          { value: 'once', label: 'Once' },
          { value: 'daily', label: 'Daily' },
          { value: 'weekly', label: 'Weekly' },
        ],
        errorMessage: '',
      },
      category: {
        value: '',
        options: [
          { value: 1, label: '0 to 5 Years' },
          { value: 2, label: '6 to 10 Years' },
          { value: 3, label: '11 to 16 Years' },
        ],
        errorMessage: '',
      },
      timeStamps: {
        value: [],
        errorMessage: '',
        minDate: yyyyMmDd(new Date()),
      },
      startTimeStamps: {},
      timeStampRange: {
        value: [],
        errorMessage: '',
        minDate: yyyyMmDd(new Date()),
        maxDate: yyyyMmDd(
          new Date(new Date().getTime() + 6 * 30 * 24 * 60 * 60 * 1000),
        ),
      },
      date: {
        value: '',
        type: 'date',
        inputProps: { min: yyyyMmDd(new Date()) },
        errorMessage: '',
      },
      startTime: {
        value: '',
        type: 'time',
        errorMessage: '',
      },
      duration: {
        value: '',
        errorMessage: '',
      },
      detail: {
        value: '',
        errorMessage: '',
      },
      anytimeBox: {
        value: false,
        errorMessage: '',
      },
      assignedTo: [],
    });
  
    const [ageGroupChildList, setAgeGroupChildList] = React.useState(null);
    const handleFormValue = (field, e) => {
      let newData = {};
      newData[field] = fieldData[field];
      newData[field].value = e.target.value;
      newData[field].errorMessage = '';
      setFieldData(data => ({ ...data, ...newData }));
    };
  
    const handleSelectedChild = data => {
      let newData = fieldData['assignedTo'];
  
      if (newData.includes(data._id)) {
        newData = newData.filter(childIdList => childIdList != data._id);
      } else {
        newData.push(data._id);
      }
      let age = getAgeFromDob(data.dateOfBirth);
      let category = '';
      if (newData?.length) {
        category = getAgeGroup(age);
      } else {
        category = '';
      }
      setFieldData(data => {
        return {
          ...data,
          assignedTo: newData,
          category: {
            ...data.category,
            value: category || '',
          },
        };
      });
    };
  
    const handleFormError = (field, error) => {
      let newData = {};
      newData[field] = fieldData[field];
      newData[field].errorMessage = error;
      setFieldData(data => ({ ...data, ...newData }));
    };
  
    const formValidate = () => {
      let taskError = 'Task is required.';
      let dateError = 'Date is required.';
      let timeStanpRangeError = 'Date range is required.';
      let startTimeError = 'Start time is required.';
      let timeStampError = 'Dates are required.';
      let durationError = 'Duration is required.';
      let childListError = 'No Child is selected';
      let categoryError = 'Category is required.';
      let scheduleError = 'Schedule is required.';
  
      let errorMessage = '';
  
      if (!fieldData.task.value) {
        errorMessage = taskError;
        handleFormError('task', taskError);
      } else if (!fieldData.category.value) {
        errorMessage = categoryError;
        handleFormError('category', categoryError);
      } else if (!fieldData.schedule.value) {
        errorMessage = scheduleError;
        handleFormError('schedule', scheduleError);
      } else if (
        fieldData.schedule.value != 'once' &&
        fieldData.timeStampRange.value?.length < 2
      ) {
        errorMessage = timeStanpRangeError;
        handleFormError('timeStampRange', timeStanpRangeError);
      } else if (
        fieldData.schedule.value == 'once' &&
        !fieldData.timeStamps.value?.length
      ) {
        errorMessage = dateError;
        handleFormError('timeStamps', dateError);
      } else if (!fieldData.startTime.value && !fieldData.anytimeBox.value) {
        errorMessage = startTimeError;
        handleFormError('startTime', startTimeError);
      } else if (!fieldData.duration.value && !fieldData.anytimeBox.value) {
        errorMessage = durationError;
        handleFormError('duration', durationError);
      } else if (fieldData.assignedTo.length == 0) {
        errorMessage = childListError;
      }
  
      if(fieldData.anytimeBox.value){
        handleFormError('startTime', "");
        handleFormError('duration', "");
      }
  
      notify(errorMessage, 'error');
  
      // if (fieldData.schedule.value != 'once') {
      //   handleFormError('date', !fieldData.date.value ? dateError : '');
  
      //   handleFormError(
      //     'startTime',
      //     !fieldData.startTime.value ? startTimeError : '',
      //   );
      // }
      // handleFormError('duration', !fieldData.duration.value ? durationError : '');
  
      setFieldData(data => ({
        ...data,
        assignedToError: fieldData.assignedTo.length == 0 ? childListError : '',
      }));
    };
  
    const submitFormData = () => {
      let duration = fieldData.anytimeBox.value
        ? 24 * 60
        : Number(fieldData.duration?.value);
      let timeStamps = [];
      let deadline = [];
      let startDate = null;
      let endDate = null;
  
      if (fieldData.schedule.value != 'once') {
        timeStamps = mergeDateAndTime(
          fieldData.timeStampRange.value?.[0],
          fieldData.anytimeBox.value ? '00:00' : fieldData.startTime?.value,
        );
        deadline = getDeadline(timeStamps, duration);
        startDate = mergeDateAndTime(
          fieldData.timeStampRange.value?.[0],
          '00:00',
        );
        endDate = mergeDateAndTime(fieldData.timeStampRange.value?.[1], '00:00');
      } else {
        timeStamps = fieldData?.timeStamps?.value?.map(date => {
          let tempTImeStamp = mergeDateAndTime(
            date,
            fieldData.anytimeBox.value ? '00:00' : fieldData?.startTime?.value,
          );
          let tempDeadline = getDeadline(tempTImeStamp, duration);
          deadline.push(tempDeadline);
          return tempTImeStamp;
        });
      }
  
      let data = {
        taskType: 'home',
        taskTitle: fieldData.task.value,
        taskDescription: fieldData.detail.value,
        duration: duration || 720,
        frequency: fieldData.schedule.value,
        startDate,
        endDate,
        reward: 0,
        category: fieldData.category.value,
        alternateCallBack: alternateCallBack,
      };
      let selectedTask = tasksOptions?.value?.filter(
        data => data.taskName === fieldData.task.value,
      )?.[0];
      data.taskTypeId = selectedTask?.id || null;
      data.reward = selectedTask?.reward || 0;
      let childList = [];
      for (let i = 0; i < fieldData.assignedTo?.length || 0; i++) {
        childList.push(fieldData?.assignedTo?.[i]);
      }
      data.childId = childList;
      if (fieldData.schedule.value == 'once') {
        data.taskTimeStamp = timeStamps.map(time => moment(time).toISOString());
        data.deadline = deadline.map(time => moment(time).toISOString());
      } else {
        data.taskTimeStamp = [moment(timeStamps).toISOString()];
        data.deadline = [moment(deadline).toISOString()];
        if (startDate) {
          let tempDate = new Date(startDate);
          let millisDiff = tempDate.getTimezoneOffset() * 60 * 1000;
          let shouldShiftTime =
            new Date(timeStamps).getDate() !=
            new Date(new Date(timeStamps).getTime() + millisDiff).getDate();
          startDate = new Date(
            tempDate.getTime() -
              millisDiff +
              (shouldShiftTime
                ? (millisDiff / Math.abs(millisDiff)) * 86400000
                : 0),
          );
          let month = monthFromIndex(startDate.getMonth())?.number || 1;
          let hours = startDate.getHours();
          let minutes = startDate.getMinutes();
          data.startDate = moment(
            mergeDateAndTime(
              `${startDate.getFullYear()}-${
                month < 10 ? '0' + month : month
              }-${startDate.getDate()}`,
              `${hours < 10 ? '0' + hours : hours}:${
                minutes < 10 ? '0' + minutes : minutes
              }`,
            ),
          ).toISOString();
          tempDate = new Date(data.endDate);
          endDate = new Date(
            tempDate.getTime() -
              millisDiff +
              (shouldShiftTime
                ? (millisDiff / Math.abs(millisDiff)) * 86400000
                : 0),
          );
          month = monthFromIndex(endDate.getMonth())?.number || 1;
          hours = endDate.getHours();
          minutes = endDate.getMinutes();
          data.endDate = moment(
            mergeDateAndTime(
              `${endDate.getFullYear()}-${
                month < 10 ? '0' + month : month
              }-${endDate.getDate()}`,
              `${hours < 10 ? '0' + hours : hours}:${
                minutes < 10 ? '0' + minutes : minutes
              }`,
            ),
          ).toISOString();
        }
      }
      createChildrenTask(data);
    };
  
    const setTimeStampStartTime = (data, e) => {
      let timeStampStartTime = fieldData.startTimeStamps;
      timeStampStartTime[data].value = e.target.value;
      setFieldData(oldData => ({
        ...oldData,
        startTimeStamps: { ...timeStampStartTime },
      }));
    };
  
    const setTimeStampDates = e => {
      let timeStampStartTime = fieldData.startTimeStamps || {};
      let tempStartTime = {};
      let dates = e.map(date => {
        let tempDate =
          date.year +
          '-' +
          (date.month.number > 9 ? '' : '0') +
          date.month.number +
          '-' +
          (date.day > 9 ? '' : '0') +
          date.day;
  
        if (Object.keys(timeStampStartTime).includes(tempDate)) {
          tempStartTime[tempDate] = timeStampStartTime[tempDate];
        } else {
          tempStartTime[tempDate] = {
            value: '',
            errorMessage: '',
            type: 'time',
          };
        }
        return tempDate;
      });
      handleFormValue('timeStamps', {
        target: { value: dates },
      });
      setFieldData(oldData => ({
        ...oldData,
        startTimeStamps: { ...tempStartTime },
      }));
    };
  
    const setTimeStampRange = e => {
      let dates = e.map(date => {
        let tempDate =
          date.year +
          '-' +
          (date.month.number > 9 ? '' : '0') +
          date.month.number +
          '-' +
          (date.day > 9 ? '' : '0') +
          date.day;
        return tempDate;
      });
      setFieldData(data => {
        let temp = {};
        temp['timeStampRange'] = data.timeStampRange;
        let previousDate = data.timeStampRange.value?.[0];
        let newSelectedDate = dates.filter(d => d != previousDate)?.[0];
        if (
          dates.length > 1 &&
          previousDate &&
          newSelectedDate &&
          fieldData.schedule.value == 'weekly'
        ) {
          dates = getWeeklyDays(previousDate, newSelectedDate);
          if (
            new Date(fieldData.timeStampRange.minDate).getTime() >
            new Date(dates[0]).getTime()
          ) {
            dates = dates.filter((_, i) => i != 0);
          }
        }
        temp['timeStampRange'].value = dates;
        return { ...data, ...temp };
      });
    };
  
    const validateTimeStamp = () => {
      let response =
        !!fieldData?.timeStamps?.value?.length &&
        (!!fieldData.startTime.value || fieldData.anytimeBox.value);
      return response;
    };
  
    const onSubmitHandler = e => {
      e.preventDefault();
      if (
        !!fieldData.task.value &&
        ((fieldData.schedule.value != 'once' &&
          // !!fieldData.date.value &&
          fieldData?.timeStampRange?.value?.length == 2 &&
          (!!fieldData.startTime.value || fieldData.anytimeBox.value)) ||
          (fieldData.schedule.value == 'once' && validateTimeStamp())) &&
        (!!fieldData.duration.value || fieldData.anytimeBox.value) &&
        fieldData.assignedTo.length > 0 &&
        !!fieldData.category.value
      ) {
        submitFormData();
        handleClose();
      } else {
        formValidate();
      }
    };
  
    React.useEffect(() => {
      handleFormValue('timeStampRange', {
        target: { value: [] },
      });
    }, [fieldData.schedule.value]);
  
    React.useEffect(() => {
      if (
        fieldData.category.value &&
        fieldData.schedule.value &&
        (fieldData.category.value != tasksOptions.category ||
          fieldData.schedule.value != tasksOptions.frequency)
      ) {
        getTaskOptions({
          category: fieldData.category.value,
          frequency: fieldData.schedule.value,
        });
      }
    }, [fieldData.category.value, fieldData.schedule.value]);
  
    React.useEffect(() => {
      if (tasksOptions?.value?.length) {
        setFieldData(data => {
          let tempData = data.task;
          tempData.options = tasksOptions?.value?.map(i => ({
            value: i.taskName,
            label: i.taskName,
            reward: i.reward,
          }));
          return { ...data, task: tempData };
        });
      } else {
        setFieldData(data => {
          let tempData = data.task;
          tempData.options = [];
          tempData.value = '';
          return { ...data, task: tempData };
        });
      }
    }, [tasksOptions]);
  
    // React.useEffect(() => {
    //   if (fieldData.assignedTo.length) {
    //     let childData = childList.find(
    //       child => child.id === fieldData.assignedTo[0].value,
    //     );
    //     let age = getAgeFromDob(childData?.dateOfBirth);
    //     let categoryValue = 0;
    //     categoryValue = getAgeGroup(age);
  
    //     setFieldData({
    //       ...fieldData,
    //       category: {
    //         ...fieldData.category,
    //         value: categoryValue,
    //       },
    //     });
    //   }
    // }, [fieldData.assignedTo, childList]);
  
    return (
      <>
        <Box
          className={clsx(
            classes.createTaskContainer,
            'hide-input-bottom-border-container',
          )}>
          <form onSubmit={onSubmitHandler}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Box className={clsx(classes.fieldContainer)}>
                  <ContainerTitle
                    title={'Assign To'}
                    className={clsx(classes.titleStyle)}
                  />
                  <Box className={clsx(classes.assignToList)}>
                    {childList?.length ? (
                      childList?.map(childData => {
                        let idSelected = fieldData.assignedTo.includes(
                          childData._id,
                        );
                        let chipProps = {
                          label: childData.userName,
                          className: clsx(
                            classes.chipStyle,
                            idSelected
                              ? classes.selectedChipStyle
                              : classes.unSelectedChipStyle,
                          ),
                        };
                        chipProps.avatar = (
                          <Avatar
                            alt={childData.userName}
                            src={`${process.env.REACT_APP_SPACE_KEY}/${childData.profile_pic}`}
                          />
                        );
                        let age = getAgeFromDob(childData?.dateOfBirth);
                        let categoryValue = getAgeGroup(age);
                        let disabled =
                          fieldData?.category?.value &&
                          fieldData?.category?.value !== categoryValue;
                        return (
                          <Chip
                            key={childData.userName}
                            {...chipProps}
                            onClick={() => {
                              handleSelectedChild(childData);
                            }}
                            focus={false}
                            disabled={disabled}
                          />
                        );
                      })
                    ) : (
                      <ContainerTitle
                        title={"You don't have any children added."}
                        className={clsx(classes.titleStyle, classes.errorText)}
                      />
                    )}
                  </Box>
                  {fieldData.category ? (
                    <ContainerTitle
                      title={fieldData.assignedToError}
                      className={clsx(classes.errorStyle)}
                    />
                  ) : null}
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box
                  className={clsx(
                    classes.fieldContainer,
                    classes.activeFieldBackground,
                  )}>
                  <ContainerTitle
                    title={'Age Group'}
                    className={clsx(classes.titleStyle)}
                  />
                  <CustomSelectField
                    containerClassName={classes.inputFieldContainer}
                    {...fieldData.category}
                    disabled={true}
                  />
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box
                  className={clsx(
                    classes.fieldContainer,
                    classes.activeFieldBackground,
                  )}>
                  <ContainerTitle
                    title={'Schedule'}
                    className={clsx(classes.titleStyle)}
                  />
  
                  <CustomSelectField
                    containerClassName={classes.inputFieldContainer}
                    {...fieldData.schedule}
                    onChange={e => {
                      handleFormValue('schedule', e);
                    }}
                  />
                </Box>
              </Grid>
  
              <Grid item xs={6}>
                <Box
                  className={clsx(
                    classes.fieldContainer,
                    fieldData.category.value &&
                      fieldData.schedule.value &&
                      fieldData.task?.options?.length
                      ? classes.activeFieldBackground
                      : classes.inactiveFieldBackground,
                  )}>
                  <ContainerTitle
                    title={'Task'}
                    className={clsx(classes.titleStyle)}
                  />
                  <CustomSelectField
                    containerClassName={classes.inputFieldContainer}
                    {...fieldData.task}
                    onChange={e => {
                      handleFormValue('task', e);
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box
                  className={clsx(
                    classes.fieldContainer,
                    fieldData.schedule.value
                      ? classes.activeFieldBackground
                      : classes.inactiveFieldBackground,
                  )}>
                  <ContainerTitle
                    title={
                      fieldData.schedule.value == 'once'
                        ? 'Select Dates'
                        : 'Select Dates Range'
                    }
                    className={clsx(classes.titleStyle)}
                  />
  
                  <CustomDateSelectField
                    containerClassName={classes.inputFieldContainer}
                    range={fieldData.schedule.value != 'once'}
                    {...(fieldData.schedule.value == 'once'
                      ? fieldData.timeStamps
                      : fieldData.timeStampRange)}
                    onChange={e => {
                      fieldData.schedule.value == 'once'
                        ? setTimeStampDates(e)
                        : setTimeStampRange(e);
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box className={clsx(classes.checkBoxContianer)}>
                  <Checkbox
                    checked={fieldData.anytimeBox.value}
                    onChange={e => {
                      handleFormValue('anytimeBox', {
                        target: { value: e.target.checked },
                      });
                    }}
                    className={clsx(classes.checkBoxStyle)}
                  />
                  <ContainerTitle
                    title={'Anytime in the selected day'}
                    className={clsx(
                      classes.titleStyle,
                      classes.checkBoxTitleStyle,
                    )}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box
                  className={clsx(
                    classes.fieldContainer,
                    !fieldData.anytimeBox.value &&
                      fieldData.schedule.value &&
                      ((fieldData.schedule.value == 'once' &&
                        fieldData?.timeStamps?.value?.length) ||
                        (fieldData.schedule.value != 'once' &&
                          fieldData?.timeStampRange?.value?.length == 2))
                      ? classes.activeFieldBackground
                      : classes.inactiveFieldBackground,
                  )}>
                  <ContainerTitle
                    title={'Start Time'}
                    className={clsx(classes.titleStyle)}
                  />
                  <CustomTextField
                    containerClassName={classes.inputFieldContainer}
                    {...fieldData.startTime}
                    onChange={e => {
                      handleFormValue('startTime', e);
                    }}
                  />
                </Box>
              </Grid>
  
              <Grid item xs={12} sm={6}>
                <Box
                  className={clsx(
                    classes.fieldContainer,
                    !fieldData.anytimeBox.value
                      ? classes.activeFieldBackground
                      : classes.inactiveFieldBackground,
                  )}>
                  <ContainerTitle
                    title={'Duration(in minutes)'}
                    className={clsx(classes.titleStyle)}
                  />
                  <CustomTextField
                    containerClassName={classes.inputFieldContainer}
                    {...fieldData.duration}
                    onChange={e => {
                      let value = e.target.value;
                      if (
                        !isNaN(Number(value)) &&
                        Number(value) >= 0 &&
                        !value.includes('.') &&
                        Number(value) <=
                          (fieldData.schedule.value == 'weekly' ? 7 : 1) *
                            24 *
                            60 &&
                        (value?.[0] != 0 || value?.[1] != 0)
                      ) {
                        handleFormValue('duration', e);
                      }
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Box
                  className={clsx(
                    classes.fieldContainer,
                    classes.activeFieldBackground,
                  )}>
                  <ContainerTitle
                    title={'Other Details'}
                    className={clsx(classes.titleStyle)}
                  />
                  <CustomTextField
                    containerClassName={classes.inputFieldContainer}
                    {...fieldData.detail}
                    onChange={e => {
                      handleFormValue('detail', e);
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box className={clsx(classes.submitContainer)}>
                  <BorderButton text={'Submit'} type={'submit'} />
                </Box>
              </Grid>
            </Grid>
          </form>
        </Box>
      </>
    );
  };
  const mapStateToProps = ({ userReducer, authReducer }) => {
    return {
      childList: userReducer.childList,
      tasksOptions: userReducer.tasksOptions,
    };
  };
  
  const mapDispatchToProps = dispatch => {
    return {
      createChildrenTask: data => {
        dispatch(actionCreator.createTaskAction(data));
      },
      notify: (data, variant) => {
        dispatch(actionCreator.notify(data, variant));
      },
      getTaskOptions: data => {
        dispatch(actionCreator.getTaskOptions(data));
      },
    };
  };
  export default connect(mapStateToProps, mapDispatchToProps)(EditTaskModal);
  