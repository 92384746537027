import {
  Box,
  CircularProgress,
  Grid,
  InputAdornment,
  makeStyles,
  TextField,
  Typography,
  useTheme,
} from '@material-ui/core';
import clsx from 'clsx';
import BorderButton from '../../components/buttons/BorderButton';
import * as actionCreator from '../../store/action';

import React from 'react';
import { connect } from 'react-redux';
import { constant } from '../../utils/constant';
import { useEffect } from 'react';
import { emailValidator } from '../../utils/textValidator';
import { VerifiedUser, VerifiedUserRounded } from '@material-ui/icons';
import { invalid } from 'moment';
import { circularProgressClasses } from '@mui/material';
import SmallLoader from '../../components/loader/SmallLoader';

const useStyle = makeStyles(theme => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  heading: {
    color: '#17505E',
    fontWeight: 'bold',
    marginTop: '2%',
  },
  createTaskContainer: {
    width: '100%',
    maxWidth: '800px',
  },
  fieldContainer: {
    position: 'relative',
    background: '#A3CED9',
    borderRadius: '10px',
    padding: '5px 15px',
  },
  titleStyle: {
    color: '#333333',
    fontSize: '1rem',
    fontWeight: 550,
  },
  assignToList: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    marginTop: 12,
  },
  chipStyle: {
    margin: '0 5px',
    padding: 4,
    border: '1.3px solid #074D5F',
    minWidth: 100,
    display: 'flex',
    justifyContent: 'flex-start',
    '&:hover': {
      border: '1.3px solid #888',
    },
  },
  unSelectedChipStyle: {
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&:focus': {
      backgroundColor: 'transparent',
    },
  },
  selectedChipStyle: {
    backgroundColor: '#A3D4E0',
    '&:hover': {
      backgroundColor: '#A3D4E0',
    },
    '&:focus': {
      backgroundColor: '#A3D4E0',
    },
  },
  submitContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 12,
  },
  errorStyle: {
    color: 'red',
    fontSize: '0.9rem',
    fontWeight: 500,
    position: 'absolute',
    bottom: -25,
  },
  selectedDateContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  inputFieldStyle: {
    opacity: 0,
    position: 'absolute',
    top: 0,
    left: -10000,
  },
  uploadChildProfileImageStyle: {
    width: '100%',
    backgroundColor: 'transparent',
    color: '#333',
    // border: "2px solid #359DB6",
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  imageContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  imageStyle: {
    width: '50px',
    maxWidth: 500,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  addCircleSharpIcon: {
    fontSize: '1.3rem',
    color: '#333',
    marginLeft: 5,
  },
  addGuardianContainerStyle: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 8,
  },
  guardianListContainer: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  guardianAddedContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    border: '1.5px solid #359DB6',
    borderRadius: 18,
    padding: 2,
  },
  guardianInnerContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  profileIconStyle: {
    fontSize: '1.5rem',
    marginRight: '0.5rem',
    [theme.breakpoints.up(constant.breakLaptopAuthPoint)]: {
      fontSize: '2.5rem',
      marginRight: '0.7rem',
    },
    [theme.breakpoints.down(constant.breakMobileAuthPoint)]: {
      fontSize: '1.8rem',
    },
    [theme.breakpoints.down(constant.breakSmallMobileAuthPoint)]: {
      fontSize: '1.6rem',
    },
  },
  backContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  backIconStyle: {
    fontSize: '1rem',
  },
  backTextStyle: {
    fontSize: '1rem',
  },
  verifyTitleContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  verifyTitleTextStyle: {
    color: '#155B6B',
  },
  verifyTitleDescriptionContainerStyle: {
    color: '#155B6B',
    display: 'flex',
    justifyContent: 'center',
  },
  verifyTitleDescriptionTextStyle: {
    textAlign: 'center',
    color: '#155B6B',
    fontSize: '0.8rem',
    maxWidth: 422,
  },
  otpInputBoxContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 20,
  },
  otpTextFieldStyle: {
    width: '2.5rem',
    margin: '0 3px',
    backgroundColor: '#359DB6',
    borderRadius: 5,
    textAlign: 'center',
  },
  stage3InputField: {
    backgroundColor: '#359DB6',
  },
  stage3InputWidth: {
    width: '60%',
  },
  centerField: {
    display: 'flex',
    justifyContent: 'center',
  },
  closeIconStyle: {
    color: '#359DB6',
  },
}));

const InviteParentModal = props => {
  const {
    getRecommendationLoader,
    recommendationLoader,
    profile,
    handleClose,
    inviteContact,
    successMessage,
    loadings,
  } = props;
  const theme = useTheme();

  const classes = useStyle(theme);
  const [showError, setShowError] = React.useState(false);
  const [inviteTo, setInviteTo] = React.useState('');
  const [inValidUser, setInValidUser] = React.useState(true);
  const [errorMessage, setErrorMessage] = React.useState(null);
  const handleChange = val => {
    // if (emailValidator) {
    setInviteTo(val);
    // }
  };

  useEffect(() => {
    if (successMessage) {
      handleClose();
    }
  }, [successMessage]);

  const getRecommends = async searchUser => {
    let reqBody = {
      searchUser: searchUser,
    };
    getRecommendationLoader(true);
    let res = await actionCreator.getRecommendsApi(reqBody);
    getRecommendationLoader(false);

    if (res?.user?.length > 0) {
      setInValidUser(true);
      setErrorMessage('Already a user');
    } else {
      setInValidUser(false);
      setErrorMessage(null);
    }
  };

  const onSubmkitHandler = e => {
    e?.preventDefault();
  };

  React.useEffect(() => {
    let timeoutId;
    if (inviteTo) {
      if (emailValidator(inviteTo)) {
        setErrorMessage(null);

        timeoutId = setTimeout(() => {
          getRecommends(inviteTo);
          setShowError(true);
        }, 2000);
      } else {
        setInValidUser(true);
        setErrorMessage('Enter a valid Email');
        setShowError(true);
      }

      return () => clearTimeout(timeoutId);
    }
  }, [inviteTo]);

  const handleEmailInvite = () => {
    let data = {
      inviteContact: { email: inviteTo, name: inviteTo },
      from: profile._id,
      referalLink: `${process.env.REACT_APP_STATIC_LINK}/ref/${profile?.nodes?.[0]?.referral_code}`,
      handleClose: handleClose,
    };
    inviteContact(data);
  };

  return (
    <>
      <Box
        className={clsx(
          classes.createTaskContainer,
          'avoid-autofill-color-359DB6',
        )}>
        <form onSubmit={onSubmkitHandler}>
          <Grid container spacing={1}>
            {/* Child details - new child */}
            <Grid
              container
              item
              alignContent="center"
              xs={12}
              spacing={1}
              style={{
                // border: '2px solid #359DB6',
                padding: '20px',
                marginTop: '20px',
                borderRadius: '10px',
              }}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  variant="outlined"
                  id="email"
                  label="Enter email to send invite..."
                  name="email"
                  type="email"
                  onChange={e => handleChange(e.currentTarget.value)}
                  value={inviteTo}
                  InputLabelProps={{
                    classes: {
                      root: classes.root,
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {inValidUser === false ? (
                          <VerifiedUser
                            style={{
                              color: '#1ba12b',
                            }}
                          />
                        ) : recommendationLoader ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : (
                          <VerifiedUserRounded
                            style={{
                              color: '#73777B',
                            }}
                          />
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
                <Typography style={{ color: 'red' }}>
                  {showError && inValidUser && inviteTo ? errorMessage : null}
                </Typography>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Box className={clsx(classes.submitContainer)}>
                <BorderButton
                  text={!loadings ? 'Invite' : <SmallLoader />}
                  type={'submit'}
                  disabled={inValidUser || loadings}
                  onClick={handleEmailInvite}
                />
              </Box>
            </Grid>
          </Grid>
        </form>
      </Box>
    </>
  );
};
const mapStateToProps = ({ userReducer, authReducer }) => {
  return {
    loadings: userReducer.loadings.addParent,
    profile: userReducer.profile,
    recommendationLoader: userReducer?.loadings?.getRecommendation,
    childList: userReducer?.childList,
    successMessage: authReducer?.successMessage,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    notify: data => {
      dispatch(actionCreator.notify(data));
    },
    getRecommendationLoader: status => {
      dispatch(actionCreator.setGetRecommendation(status));
    },
    sendOtp: (data, callBack) => {
      dispatch(actionCreator.sendSignupOtp(data, callBack));
    },
    confirmOtp: (data, callBack) => {
      dispatch(actionCreator.confirmOTP(data, callBack));
    },
    createChildrenAccount: (data, callBack) => {
      dispatch(actionCreator.createChildrenAccount(data, callBack));
    },
    getChildrenRequest: () => {
      dispatch(actionCreator.getChildrenRequest());
    },
    uploadDoc: data => actionCreator.uploadDoc(data),
    addChildRequest: data => dispatch(actionCreator.addChildRequest(data)),
    inviteContact: (data, handleClose) => {
      dispatch(actionCreator.inviteContact(data));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(InviteParentModal);
