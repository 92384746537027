import { Box, Grid, makeStyles, useTheme } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';
import ReviewCarousel from '../../../../components/carousel/ReviewCarousel';
import { constant } from '../../../../utils/constant';

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
    height: '100vh',
    backgroundColor: 'grey',
    position: 'relative',
    minHeight: 250,
    [theme.breakpoints.down(constant.breakMobileAuthPoint)]: {
      height: '100%',
    },
  },
  reviewCarousel: {
    position: 'absolute',
    bottom: '15vh',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down(constant.breakMobileAuthPoint)]: {
      bottom: '2rem',
    },
  },
}));

const RightContainer = () => {
  const theme = useTheme();
  const classes = useStyles(theme);
  return (
    <Box className={clsx(classes.container)}>
      <Box className={clsx(classes.reviewCarousel)}>
        <ReviewCarousel />
      </Box>
    </Box>
  );
};

const mapStateToProps = ({}) => {
  return {};
};
const mapDispatchToProps = dispatch => {
  return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(RightContainer);
