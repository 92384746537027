import { Box, makeStyles, useTheme } from '@material-ui/core';
import clsx from 'clsx';
import ContainerTitle from '../../../components/common/title/ContainerTitle';
import BorderButton from '../../../components/buttons/BorderButton';
import * as actionCreator from '../../../store/action';
import React from 'react';
import { connect } from 'react-redux';

const useStyle = makeStyles(theme => ({
  createTaskContainer: {
    width: '100%',
    maxWidth: '800px',
  },
  desclaimerStyle: {
    color: '#333333',
    fontSize: '1.2rem',
    fontWeight: 600,
  },
  actionContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 24,
  },
  cancelClass: {
    marginRight: 16,
    color: '#359DB6',
    border: '2px solid #359DB6',
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}));

const DeleteTaskModal = props => {
  const {
    open,
    handleClose,
    deleteTaskAction,
    taskDetail,
    onDelete,
    callBack,
  } = props;
  const theme = useTheme();
  const classes = useStyle(theme);

  const handleDeleteTask = () => {
    deleteTaskAction({ id: taskDetail?.id }, callBack);
    handleClose();
    onDelete();
  };
  return (
    <>
      <Box
        className={clsx(
          classes.createTaskContainer,
          'avoid-autofill-color-359DB6',
        )}>
        <Box>
          <ContainerTitle
            title={`Are you sure you want to delete this task?`}
            className={clsx(classes.desclaimerStyle)}
          />
        </Box>
        <Box className={clsx(classes.actionContainer)}>
          <BorderButton
            text={'Cancel'}
            onClick={handleClose}
            className={classes.cancelClass}
          />
          <BorderButton text={'Sure'} onClick={handleDeleteTask} />
        </Box>
      </Box>
    </>
  );
};
const mapStateToProps = ({ userReducer, authReducer }) => {
  return {
    profile: userReducer.profile,
    recommendationLoader: userReducer?.loadings?.getRecommendation,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    notify: (data, variant) => {
      dispatch(actionCreator.notify(data, variant));
    },
    deleteTaskAction: (data, callBack) => {
      dispatch(actionCreator.deleteTaskAction(data, callBack));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(DeleteTaskModal);
