import { Box, makeStyles, Typography, useTheme } from '@material-ui/core';
import Rating from '@material-ui/lab/Rating';
import clsx from 'clsx';
import React from 'react';
import BorderButton from '../buttons/BorderButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { constant } from '../../utils/constant';
import { maxStringLength } from '../../utils/stringMethods';

const useStyles = makeStyles(theme => ({
  container: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  reviewBox: {
    borderRadius: 8,
    minHeight: 90,
    // minWidth: 160,
    // maxWidth: 220,
    width: '15rem',
    backgroundColor: 'rgba(193,157,141,0.3)',
    padding: '1.5rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    maxWidth: '35rem',
    [theme.breakpoints.up(constant.breakLaptopAuthPoint)]: {
      width: '80%',
    },
    [theme.breakpoints.down(constant.breakLaptopAuthPoint)]: {
      width: '80%',
    },
  },
  reviewText: {
    fontFamily: 'sans-serif',
    fontSize: 12,
    marginBottom: 12,
    color: '#fff',
    fontWeight: 500,
    [theme.breakpoints.up(constant.breakLaptopAuthPoint)]: {
      fontSize: '1.3rem',
      marginBottom: '1.4rem',
    },
    [theme.breakpoints.down(constant.breakLaptopAuthPoint)]: {
      fontSize: '1.3rem',
      marginBottom: '1.2rem',
    },
    [theme.breakpoints.down(constant.breakSmallMobileAuthPoint)]: {
      fontSize: '0.9rem',
      marginBottom: '0.6rem',
    },
  },
  reviewBy: {
    fontFamily: 'sans-serif',
    fontSize: '1.3rem',
    color: '#fff',
    fontWeight: 600,
    [theme.breakpoints.up(constant.breakLaptopAuthPoint)]: {
      fontSize: '1.3rem',
    },
    [theme.breakpoints.down(constant.breakLaptopAuthPoint)]: {
      fontSize: '1.1rem',
    },
    [theme.breakpoints.down(constant.breakSmallMobileAuthPoint)]: {
      fontSize: '0.9rem',
    },
  },
  ratingStyle: {
    fontFamily: 'sans-serif',
    fontSize: '1.5rem',
    color: '#fff',
    fontWeight: 600,
    [theme.breakpoints.up(constant.breakLaptopAuthPoint)]: {
      fontSize: '1.5rem',
    },
    [theme.breakpoints.down(constant.breakLaptopAuthPoint)]: {
      fontSize: '1.2rem',
    },
    [theme.breakpoints.down(constant.breakSmallMobileAuthPoint)]: {
      fontSize: '1rem',
    },
  },
  bottomContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  arrowStyle: {
    fontSize: '1.3rem',
    [theme.breakpoints.up(constant.breakLaptopAuthPoint)]: {
      fontSize: '1.7rem',
    },
    [theme.breakpoints.down(constant.breakLaptopAuthPoint)]: {
      fontSize: '1.4rem',
    },
    [theme.breakpoints.down(constant.breakSmallMobileAuthPoint)]: {
      fontSize: '1rem',
    },
  },
  buttonStyle: {
    padding: 4,
    border: '1px solid #fff',
    backgroundColor: 'rgba(255,255,255,0)',
    color: '#ffffff',
    borderRadius: 50,
    display: 'flex',
    alignItems: 'center',
    minWidth: 0,
    [theme.breakpoints.up(constant.breakLaptopAuthPoint)]: {
      width: '3rem',
      height: '3rem',
      margin: '0 0.3rem',
    },
    [theme.breakpoints.down(constant.breakLaptopAuthPoint)]: {
      width: '2.5rem',
      height: '2.5rem',
      margin: '0 0.2rem',
    },
    [theme.breakpoints.down(constant.breakSmallMobileAuthPoint)]: {
      width: '2rem',
      height: '2rem',
    },
  },
}));

const ReviewCarousel = props => {
  const {} = props;
  const theme = useTheme();
  const classes = useStyles(theme);
  const [current, setCurrent] = React.useState(0);

  const reviewList = [
    {
      review: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.`,
      reviewedBy: 'Sini Lane',
      rating: 3,
    },
    {
      review: `Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.`,
      reviewedBy: 'Sini',
      rating: 2,
    },
    {
      review: `Sedre et dolore magna aliqua.`,
      reviewedBy: 'Sini',
      rating: 5,
    },
  ];

  const handleNext = () => {
    if (current > 0) {
      setCurrent(current - 1);
    } else {
      setCurrent(reviewList.length - 1);
    }
  };
  const handleBack = () => {
    if (current < reviewList.length - 1) {
      setCurrent(current + 1);
    } else {
      setCurrent(0);
    }
  };
  return (
    <Box className={clsx(classes.container)}>
      {reviewList.map((reviewData, index) => {
        if (current != index) return;
        return (
          <Box className={clsx(classes.reviewBox)} key={index}>
            <Typography className={clsx(classes.reviewText)}>
              {`"${maxStringLength(reviewData.review, 180)}”`}
            </Typography>
            <Box className={clsx(classes.bottomContainer)}>
              <Box>
                <Typography className={clsx(classes.reviewBy)}>
                  {reviewData.reviewedBy}
                </Typography>
                {reviewData.rating != undefined ? (
                  <Box>
                    <Rating
                      className={clsx(classes.ratingStyle)}
                      value={reviewData.rating}
                      readOnly
                    />
                  </Box>
                ) : null}
              </Box>
              <Box className={clsx(classes.bottomContainer)}>
                <BorderButton
                  className={classes.buttonStyle}
                  text={<ArrowBackIcon className={clsx(classes.arrowStyle)} />}
                  onClick={handleBack}
                />
                <BorderButton
                  className={classes.buttonStyle}
                  text={
                    <ArrowForwardIcon className={clsx(classes.arrowStyle)} />
                  }
                  onClick={handleNext}
                />
              </Box>
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};
export default ReviewCarousel;
