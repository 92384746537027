import { Box, Grid, makeStyles, useTheme } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';
import RightContainer from './RightContainer';
import { constant } from '../../../../utils/constant';

const useStyles = makeStyles(theme => ({
  container: {
    // width: '100vw',
    // height: '100vh',
    backgroundColor: '#EEF7FA',
    // overflowY: 'scroll',
    // display: 'flex',
    // flexDirection: 'row',
    // [theme.breakpoints.down(constant.breakMobileAuthPoint)]: {
    //   flexDirection: 'column-reverse',
    // },
  },
  leftContainer: {
    width: '100%',
    [theme.breakpoints.down(constant.breakMobileAuthPoint)]: {
      width: '100%',
      order: 2,
    },
    [theme.breakpoints.down(450)]: {
      width: '100%',
      order: 2,
      height: '50%',
    },
  },
  rightContianer: {
    width: '50%',
    [theme.breakpoints.down(constant.breakMobileAuthPoint)]: {
      width: '100%',
      flexGrow: 1,
    },
  },
}));

const AuthScreen = props => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { leftContainer } = props;

  return (
    <Box className={clsx(classes.container)}>
      <Box className={clsx(classes.leftContainer)}>{leftContainer}</Box>
      {/* <Box className={clsx(classes.rightContianer)}>
        <RightContainer />
      </Box> */}
    </Box>
  );
};
AuthScreen.defaultProps = {
  leftContainer: <Box></Box>,
};
const mapStateToProps = ({}) => {
  return {};
};
const mapDispatchToProps = dispatch => {
  return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(AuthScreen);
