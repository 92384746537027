export const getAgeGroup = age => {
  let categoryValue = '';
  if (age >= 0 && age <= 5) {
    categoryValue = 1;
  } else if (age >= 6 && age <= 10) {
    categoryValue = 2;
  } else if (age >= 11 && age <= 16) {
    categoryValue = 3;
  }

  return categoryValue;
};

export const priceFormatter = price => {
  if (Number(price)) {
    let val = Number(price)?.toFixed(3).toLocaleString(
      'en-US',
      // {
      //     style: 'currency',
      //     currency: 'USD',
      //   }
    );
    return val;
  } else return 0;
};

export const isValueExist = (id, list = [], keytocheck) => {
  let exist = list?.filter(data => data[keytocheck] === id) || [];
  return exist;
};

export const sizeInMegaBytes = size => {
  if (Number(size)) {
    let val = Number(size) / (1024 * 1024);
    return val;
  } else return 0;
};
