import { makeStyles, useTheme } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  image1Style: {
    width: '80vw',
    right: '10vw',
    top: 0,
    position: 'fixed',
    [theme.breakpoints.down(600)]: {
      width: '150vw',
    },
    [theme.breakpoints.down(450)]: {
      width: '200vw',
    },
  },
  image2Style: {
    width: '90vw',
    right: '5vw',
    // height: "100vh",
    bottom: -240,
    position: 'fixed',
    [theme.breakpoints.down(1200)]: {
      width: '100vw',
      right: 0,
    },
    [theme.breakpoints.down(900)]: {
      width: '110vw',
      right: '-5vw',
    },
    [theme.breakpoints.down(700)]: {
      width: '130vw',
      right: '-15vw',
      bottom: -160,
    },
    [theme.breakpoints.down(500)]: {
      width: '130vw',
      right: '-15vw',
      bottom: -100,
    },
  },
}));
const PageBackgroundTheme2 = () => {
  const theme = useTheme();
  const classes = useStyles(theme);
  return (
    <>
      <img
        src={theme?.customTheme?.pageBackgroundImage1}
        className={clsx(classes.image2Style)}
      />
      <img
        src={theme?.customTheme?.pageBackgroundImage2}
        className={clsx(classes.image1Style)}
      />
    </>
  );
};

export default PageBackgroundTheme2;
