import {
  Box,
  Button,
  Grid,
  makeStyles,
  Typography,
  useTheme,
} from '@material-ui/core';
import clsx from 'clsx';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import BorderButton from '../../components/buttons/BorderButton';
import ContainerTitle from '../../components/common/title/ContainerTitle';
import FilterDropdown from '../../components/filter/FilterDropdown';
import Pagination from '../../components/pagination/Pagination';
import * as actionCreator from '../../store/action';
import { constant } from '../../utils/constant';
import KidsContainer from './components/KidsContainer';
import RequestList from './components/RequestList';
import TaskList from '../../components/tasks/TaskList';
import AddIcon from '@material-ui/icons/Add';
import CreateTaskModal from './components/CreateTaskModal';
import CommonModel from '../../components/common/model/CommonModel';
import EmptyTask from '../../components/EmptyData/EmptyTask';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

const useStyle = makeStyles(theme => {
  const isParent = theme?.customTheme?.isParent;
  const maxWidthTask = 500;
  const breakTo100After = 730;
  const breakTo50After = 1000;
  return {
    container: {
      padding: '1rem',
      position: 'relative',
    },
    innerContainer: {
      padding: '1rem 1rem',
      [theme.breakpoints.down(constant.breakMobileAuthPoint)]: {
        padding: '0.5rem 0.5rem',
      },
      [theme.breakpoints.down(constant.breakSmallMobileAuthPoint)]: {
        padding: '0rem 0.2rem',
      },
    },
    titleContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: '1rem',
      position: 'relative',
      [theme.breakpoints.down(constant.breakMobileAuthPoint)]: {
        marginBottom: '0.8rem',
      },
      [theme.breakpoints.down(constant.breakSmallMobileAuthPoint)]: {
        marginBottom: '0rem',
      },
    },
    titleContainerForKid: {
      display: 'flex',
      //  justifyContent: "space-between",
      alignItems: 'center',
      marginBottom: '1rem',
      position: 'relative',
      [theme.breakpoints.down(constant.breakMobileAuthPoint)]: {
        marginBottom: '0.8rem',
      },
      [theme.breakpoints.down(constant.breakSmallMobileAuthPoint)]: {
        marginBottom: '0rem',
      },
    },
    gridContainer: {
      display: 'flex',
      justifyContent: 'space-around',
      flexDirection: 'row',
      flexWrap: 'wrap',
    },
    gridContainer1: {
      width: '33%',
      maxWidth: maxWidthTask,
      [theme.breakpoints.down(breakTo50After)]: {
        width: '50%',
      },
      [theme.breakpoints.down(breakTo100After)]: {
        width: '100%',
      },
    },
    gridContainer2: {
      width: '33%',
      maxWidth: maxWidthTask,
      [theme.breakpoints.down(breakTo50After)]: {
        width: '50%',
      },
      [theme.breakpoints.down(breakTo100After)]: {
        width: '100%',
      },
    },
    gridContainer3: {
      width: '33%',
      maxWidth: maxWidthTask,
      [theme.breakpoints.down(breakTo50After)]: {
        width: '50%',
      },
      [theme.breakpoints.down(breakTo100After)]: {
        width: '100%',
      },
    },
    paginationContainer: {
      marginTop: '1rem',
      padding: '0.5rem',
      borderBottomRightRadius: 12,
      borderBottomLeftRadius: 12,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    paginationContainerUnchecked: {
      backgroundColor: '#359DB6',
    },
    paginationContainerUpdate: {
      backgroundColor: '#C9ADA7',
    },
    paginationContainerChecked: {
      backgroundColor: '#699595',
    },
    createTaskContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    createTaskButton: {
      borderRadius: 50,
      width: 110,
      backgroundColor: 'transparent',
      color: '#359DB6',
      border: '1.2px solid #359DB6',
      [theme.breakpoints.down(constant.breakMobileAuthPoint)]: {
        padding: '0.3rem',
        fontSize: '0.7rem',
        width: 110,
      },
      [theme.breakpoints.down(700)]: {
        width: 100,
      },
      [theme.breakpoints.down(550)]: {
        width: 90,
      },
      [theme.breakpoints.down(500)]: {
        width: 75,
      },
      '&:hover': {
        backgroundColor: 'rgba(0,0,0,0.03)',
      },
    },
    createTaskText: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: '1rem',
      color: '#359DB6',
      fontWeight: 550,
      [theme.breakpoints.down(constant.breakMobileAuthPoint)]: {
        fontSize: '0.8rem',
      },
    },
    addIcon: {
      fontSize: '1.4rem',
      color: '#359DB6',
      [theme.breakpoints.down(constant.breakMobileAuthPoint)]: {
        fontSize: '1.2rem',
      },
    },
    addProfileButton: {
      borderRadius: 50,
      width: 147,
      height: '40px',
      backgroundColor: 'transparent',
      color: '#359DB6',
      border: '1.2px solid #359DB6',
      [theme.breakpoints.down(constant.breakMobileAuthPoint)]: {
        padding: '0.3rem',
        fontSize: '0.7rem',
        width: 110,
      },
      [theme.breakpoints.down(899)]: {
        width: 100,
        // marginLeft: "577",
      },
      [theme.breakpoints.down(700)]: {
        width: 100,
        // marginLeft: "290px",
      },
      [theme.breakpoints.down(550)]: {
        width: 100,
        // marginLeft: "120px",
      },
      [theme.breakpoints.down(500)]: {
        width: 100,
        // marginLeft: "122px",
      },
      '&:hover': {
        backgroundColor: 'rgba(0,0,0,0.03)',
      },
    },
    modelTitleContainerStyle: {
      justifyContent: 'flex-start !important',
    },
    emptyTaskStyle: { color: '#359DB6' },
    namStyle: { color: '#fff' },
    nameStylee: { color: theme.customTheme.commonModalTextStyle },
    viewLessButton: {
      alignItems: 'center',
      display: 'flex',
      color: '#359DB6',
    },
  };
});
const Dashboard = props => {
  const {
    getChildList,
    receivedRequest,
    paginationCompletedTaskList,
    paginationAllTaskList,
    paginationUpdateTaskList,
    setAllTaskPaginationPage,
    setCompletedTaskPaginationPage,
    setUpdateTaskPaginationPage,
    profile,
  } = props;
  const isParentLoggedIn = true;
  const firstLoad = React.useRef(true);
  const theme = useTheme();
  const classes = useStyle(theme);
  const [createTaskModal, setCreateTaskModal] = React.useState();
  const [addChild, setAddChild] = React.useState(false);
  const [expand, setexpand] = useState(false);
  const handleCreateTaskModal = () => {
    setCreateTaskModal(!createTaskModal);
  };

  const handleAllTaskPagination = (event, value) => {
    setAllTaskPaginationPage({ skip: value - 1 });
  };

  const handleCompletedTaskPagination = (event, value) => {
    setCompletedTaskPaginationPage({ skip: value - 1 });
  };

  const handleUpdateTaskPagination = (event, value) => {
    setUpdateTaskPaginationPage({ skip: value - 1 });
  };

  const handleAddChildModal = () => {
    setAddChild(!addChild);
  };

  React.useEffect(() => {
    getChildList();
    firstLoad.current = false;
  }, []);

  return (
    <Box className={clsx(classes.container)}>
      {receivedRequest?.length ? (
        <Box className={clsx(classes.innerContainer)}>
          <Box className={clsx(classes.titleContainer)}>
            <ContainerTitle title={constant.guardianrequest} />
          </Box>

          <RequestList guardianRequest={receivedRequest} isExpand={expand} />
          {receivedRequest?.length > 2 ? (
            <Grid style={{ textAlign: 'right' }}>
              <Button
                style={{ textTransform: 'none' }}
                onClick={() => {
                  setexpand(!expand);
                }}>
                {expand ? (
                  <Typography className={classes.viewLessButton}>
                    {' View Less'}
                    <ExpandLessIcon style={{ color: '#359DB6' }} />
                  </Typography>
                ) : (
                  <Typography className={classes.viewLessButton}>
                    {'View More'}
                    <ExpandMoreIcon style={{ color: '#359DB6' }} />
                  </Typography>
                )}
              </Button>
            </Grid>
          ) : null}
        </Box>
      ) : null}
      <Box className={clsx(classes.gridContainer)}>
        <Box className={clsx(classes.innerContainer, classes.gridContainer1)}>
          <Box className={clsx(classes.titleContainer)}>
            <ContainerTitle title={constant.taskList} />
            <Box className={clsx(classes.createTaskContainer)}>
              <FilterDropdown />
              <BorderButton
                className={classes.createTaskButton}
                id='createTaskButton'
                text={
                  <Box className={classes.createTaskText}>
                    <AddIcon className={classes.addIcon} />
                    {constant.create}
                  </Box>
                }
                onClick={handleCreateTaskModal}
              />
              {createTaskModal ? (
                <CommonModel
                  title={constant.createTask}
                  titleContainerStyle={classes.modelTitleContainerStyle}
                  open={createTaskModal}
                  handleClose={handleCreateTaskModal}
                  body={
                    <CreateTaskModal
                      open={createTaskModal}
                      handleClose={handleCreateTaskModal}
                    />
                  }
                />
              ) : null}
            </Box>
          </Box>

          {paginationAllTaskList?.total === null ||
          paginationAllTaskList?.total ? (
            <TaskList taskContainerId={'task-container-1'} />
          ) : (
            <EmptyTask
              text={
                <ContainerTitle
                  title={'No tasks to display'}
                  className={classes.emptyTaskStyle}
                />
              }
            />
          )}
          {paginationAllTaskList?.total ? (
            <Box
              className={clsx(
                classes.paginationContainer,
                classes.paginationContainerUnchecked,
                'paginationDashboardContainer',
              )}>
              <Pagination
                page={paginationAllTaskList?.skip + 1}
                pageCount={Math.ceil(
                  (paginationAllTaskList?.total || 0) /
                    (paginationAllTaskList?.limit || 1),
                )}
                onChange={handleAllTaskPagination}
              />
            </Box>
          ) : null}
        </Box>
        <Box className={clsx(classes.innerContainer, classes.gridContainer2)}>
          <Box className={clsx(classes.titleContainer)}>
            <ContainerTitle title={constant.completedTaskList} />
            <FilterDropdown checked={1} />
          </Box>
          {paginationCompletedTaskList?.total === null ||
          paginationCompletedTaskList?.total ? (
            <TaskList checked={1} taskContainerId={'task-container-2'} />
          ) : (
            <EmptyTask
              text={
                <ContainerTitle
                  title={'No tasks to display'}
                  className={classes.emptyTaskStyle}
                />
              }
            />
          )}
          {paginationCompletedTaskList?.total ? (
            <Box
              className={clsx(
                classes.paginationContainer,
                classes.paginationContainerChecked,
                'paginationDashboardContainer',
              )}>
              <Pagination
                page={paginationCompletedTaskList?.skip + 1}
                pageCount={Math.ceil(
                  (paginationCompletedTaskList?.total || 0) /
                    (paginationCompletedTaskList?.limit || 1),
                )}
                onChange={handleCompletedTaskPagination}
              />
            </Box>
          ) : null}
        </Box>
        <Box className={clsx(classes.innerContainer, classes.gridContainer3)}>
          <Box className={clsx(classes.titleContainer)}>
            <ContainerTitle title={constant.overdueTaskList} />
            <FilterDropdown checked={2} />
          </Box>
          {paginationUpdateTaskList?.total === null ||
          paginationUpdateTaskList?.total ? (
            <TaskList checked={2} taskContainerId={'task-container-3'} />
          ) : (
            <EmptyTask
              text={
                <ContainerTitle
                  title={'No tasks to display'}
                  className={classes.emptyTaskStyle}
                />
              }
            />
          )}
          {paginationUpdateTaskList?.total ? (
            <Box
              className={clsx(
                classes.paginationContainer,
                classes.paginationContainerUpdate,
                'paginationDashboardContainer',
              )}>
              <Pagination
                page={paginationUpdateTaskList?.skip + 1}
                pageCount={Math.ceil(
                  (paginationUpdateTaskList?.total || 0) /
                    (paginationUpdateTaskList?.limit || 1),
                )}
                onChange={handleUpdateTaskPagination}
              />
            </Box>
          ) : null}
        </Box>
      </Box>
    </Box>
  );
};

const mapStateToProps = ({ userReducer, authReducer, paginationReducer }) => {
  return {
    profile: userReducer.profile,
    isParentLoggedIn: authReducer.isParentLoggedIn,
    isChildrenLoggedIn: authReducer.isChildrenLoggedIn,
    paginationAllTaskList: paginationReducer.paginationAllTask,
    paginationCompletedTaskList: paginationReducer.paginationCompleteTask,
    paginationUpdateTaskList: paginationReducer.paginationUpdateTask,
    getTaskLoading: userReducer.loadings.getTasks,
    receivedRequest: userReducer.recievedRequest,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getChildList: () => {
      dispatch(actionCreator.getChildList());
    },
    setAllTaskPaginationPage: data => {
      dispatch(actionCreator.setAllTaskPaginationPage(data));
    },
    setCompletedTaskPaginationPage: data => {
      dispatch(actionCreator.setCompletedTaskPaginationPage(data));
    },
    setUpdateTaskPaginationPage: data => {
      dispatch(actionCreator.setUpdateTaskPaginationPage(data));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
