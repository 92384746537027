import { Box, Grid, makeStyles, useTheme } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';
import AuthScreen from './components/AuthScreen';
import LeftContainer from './components/LeftContainer';
import SelectAccountType from './components/SelectAccountType';
import { constant } from '../../../utils/constant';

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
    height: '100vh',
    backgroundColor: '#EEF7FA',
  },
}));

const AccountType = () => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const leftContainer = (
    <LeftContainer
      child={<SelectAccountType />}
      backButton={constant.routes.landingPage}
    />
  );
  return (
    <Box className={clsx(classes.container)}>
      <AuthScreen leftContainer={leftContainer} />
    </Box>
  );
};

const mapStateToProps = ({}) => {
  return {};
};
const mapDispatchToProps = dispatch => {
  return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(AccountType);
