import { Box, makeStyles, useTheme } from '@material-ui/core';
import React from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';
import * as actionCreator from '../../../store/action';
import RequestContainer from './RequestContainer';

const useStyle = makeStyles(theme => ({
  container: {},
  requestContainer: {
    marginBottom: '0.7rem',
  },
}));

const RequestList = props => {
  const { guardianRequest, isMinor, location, isExpand } = props;
  const theme = useTheme();
  const classes = useStyle(theme);

  return (
    <Box className={clsx(classes.container)}>
      {guardianRequest
        ?.slice(0, isExpand ? guardianRequest?.length || 0 : 2)
        .map((request, index) => {
          return (
            <Box key={index} className={clsx(classes.requestContainer)}>
              <RequestContainer
                detail={request}
                isMinor={isMinor}
                location={location}
              />
            </Box>
          );
        })}
    </Box>
  );
};

const mapStateToProps = ({ userReducer }) => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(RequestList);
