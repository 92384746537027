import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { Field } from 'formik';

const useStyle = makeStyles(theme => ({
  fieldStyle: {
    border: 'none',
    padding: '14px',
    borderRadius: 8,
    backgroundColor: '#359DB640',
    width: 121,
    fontSize: 14,
    fontWeight: 500,
    fontFamily: 'poppins',
  },
}));
const FormikTextFieldCustom = props => {
  const {
    name,
    type,
    touched,
    error,
    border,
    padding,
    borderRadius,
    backgroundColor,
    width,
    fontFamily,
    fontSize,
    fontWeight,
    style,
    textStyle,
    placeholder,
    value,
    onChange,
    className,
    id,
  } = props;
  const classes = useStyle();
  return (
    <>
      <Field
        name={name}
        type={type}
        value={value}
        onChange={onChange}
        placeholder={placeholder || ''}
        id={id}
        className={clsx('no-outline', classes.fieldStyle, className)}
        style={{
          border,
          padding,
          borderRadius,
          backgroundColor,
          width,
          fontFamily,
          fontSize,
          fontWeight,
          ...style,
        }}
      />
      {error && touched ? (
        <div
          style={{
            fontFamily,
            fontSize,
            fontWeight,
            ...textStyle,
          }}>
          {error}
        </div>
      ) : null}
    </>
  );
};
FormikTextFieldCustom.defaultProps = {
  name: '',
  type: 'text',
  style: {},
  textStyle: {},
  value: '',
  className: '',
};

export default FormikTextFieldCustom;
