import {
  Grid,
  ListItemText,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import React from 'react';
import Header from '../../../components/common/header/Header';
import { List, ListItem } from '@material-ui/core';
import People from '../../../assets/images/aboutUs/IllustrationTeam.svg';
import KidIllustration from '../../../assets/images/aboutUs/KidIllustration.svg';
import WomenImg from '../../../assets/images/aboutUs/WomenImg.svg';
import CurveImg from '../../../assets/images/contactUs/sideBorder.svg';
import PatternDesign from '../../../assets/images/icon1.svg';
import { textAlign } from '@mui/system';

const useStyles = makeStyles(theme => ({
  gridContainer: {
    display: 'grid',
    backgroundColor: '#eef7fa',
  },
  innerContainer: {
    margin: '10% 14% 4% 14%',
    backgroundColor: '#ffff',
    borderRadius: '20px',
    [theme.breakpoints.up('xs')]: {
      margin: '40% 10% 4% 10%',
    },
    [theme.breakpoints.up('sm')]: {
      margin: '22% 10% 4% 10%',
    },
    [theme.breakpoints.up('md')]: {
      margin: '10% 10% 4% 10%',
    },
  },
  heading: {
    fontSize: '2rem',
    fontWeight: 'bold',
  },
  basicCssForText: {
    color: '#6F6F6F',
  },
  paraHeading: {
    fontSize: '1.1rem',
    fontWeight: 'bold',
    margin: '3% 10% 3% 10% ',
    color: '#6F6F6F',
  },
  subText: {
    textAlign: 'center',
    margin: '3% 10% 3% 10% ',
    lineHeight: '2rem',
    color: '#6F6F6F',
  },
  subText2: {
    margin: '0 10% 3% 10%',
    lineHeight: '2rem',
    color: '#6F6F6F',
  },
  text: {
    fontWeight: 'bold',
    fontSize: '0.8rem',
    color: '#1D1D1D',
    textAlign: 'center',
    margin: ' 10% 2% 0 20%',
    [theme.breakpoints.down('xs')]: {
      margin: '5% 0 5% 0',
    },
    [theme.breakpoints.down('md')]: {
      margin: ' 10% 15% 0 15%',
    },
  },
  KidIllustration: {
    width: '15vw',
    [theme.breakpoints.down('xs')]: {
      width: '60vw',
    },
    [theme.breakpoints.down('md')]: {
      width: '63vw',
      marginLeft: '10%',
    },
    [theme.breakpoints.up(960)]: {
      width: '17vw',
    },
  },

  PatternDesign1: {
    width: '7vw',
    position: 'absolute',
    top: '82%',
    left: '7%',
    [theme.breakpoints.down('md')]: {
      left: '5vw',
    },
  },
  PatternDesign2: {
    width: '7vw',
    position: 'absolute',
    top: '180%',
    right: '7%',
    [theme.breakpoints.down('md')]: {
      right: '6vw',
    },
  },
  descText: {
    color: '#6F6F6F',
    margin: '0 17% 2.5% 10%',
    lineHeight: '1.8rem',
    fontSize: '0.9rem',
    [theme.breakpoints.down('md')]: {
      margin: '0 10% 12% 10%',
    },
  },
  headText: {
    color: '#333333',
    margin: '0 10% 2% 10%',
    fontWeight: 'bold',
  },
  peopleImg: {
    width: 'auto',
    [theme.breakpoints.down('xs')]: {
      width: '72vw',
    },
    [theme.breakpoints.down('md')]: {
      width: '53vw',
    },
    [theme.breakpoints.down('sm')]: {
      width: '60vw',
    },
  },
  textDescription: {
    [theme.breakpoints.down('md')]: {
      margin: '0 10% 2% 10%',
    },
  },
}));

const AboutUsPage = () => {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('xs'));
  return (
    <div>
      <Header />
      <Grid container className={classes.gridContainer}>
        <Grid className={classes.innerContainer}>
          <Grid>
            <Grid
              item
              xs={12}
              style={{ textAlign: 'center', margin: '30px 0' }}>
              <Typography className={classes.heading}>
                <text style={{ color: '#359DB6' }}>{'Privacy Policy'}</text>{' '}
                <br />
              </Typography>
            </Grid>

            <Grid container>
              <Grid item xs={12} style={{ textAlign: 'left' }}>
                <Typography className={classes.headText}>
                  What is Pocket Money?
                </Typography>
              </Grid>

              <Grid item xs={12} style={{ textAlign: 'left' }}>
                <Typography className={classes.descText}>
                  Pocket Money is an Ed-tech invention created by Cogito
                  Metaverse. It is available as a mobile app and in a
                  desktop/web version. Pocket Money is focused on creating a
                  future full of citizens who are financially aware and know all
                  about money management. Since the future of money is digital,
                  we realised that there needs to be a tool that educates
                  children today about the realities of personal finance and at
                  the same time instills them with a sense of independence. The
                  best way to make this happen is by presenting children with
                  opportunities where they can equate effort with value and that
                  is why we have designed Pocket Money. We have put in some
                  great expertise to design the interface of the app so that
                  both kids and parents can easily navigate through its features
                  and make the most of it. All the transactions on the Pocket
                  Money are managed by Cogito Metaverse, with payments being
                  made in our official Cogito currency, the ‘COG’. So parents or
                  families will need to register or sign up to Pocket Money
                  through the Cogito Metaverse website to access the benefits of
                  Pocket Money. Pocket Money will help children to be
                  independent and prepare them to face the challenges of life
                  confidently. We are sure that our initiative and innovation
                  will have long lasting impacts on the future generations of
                  the world.
                </Typography>
              </Grid>
              <Grid item xs={12} style={{ textAlign: 'left' }}>
                <Typography className={classes.headText}>
                  Who runs Pocket Money?
                </Typography>
              </Grid>
              <Grid item xs={12} style={{ textAlign: 'left' }}>
                <Typography className={classes.descText}>
                  Pocket Money has been developed by the creators of Cogito
                  Metaverse. Cogito operates under the Principality of Cogito, a
                  digital space which brings together smart minds and is a haven
                  for ordinary people, investors, businesses and non-profit
                  organisations. The constitution of the Principality of Cogito
                  details the legalities around the structure of power and
                  control for Cogito. All the users of Pocket Money have to be
                  registered members or e-residents of the Cogito Metaverse.
                </Typography>
              </Grid>
              <Grid item xs={12} style={{ textAlign: 'left' }}>
                <Typography className={classes.headText}>
                  How does Pocket Money work?
                </Typography>
              </Grid>
              <Grid item xs={12} style={{ textAlign: 'left' }}>
                <Typography className={classes.descText}>
                  The Pocket Money app follows a simple flow of interaction.
                  Beginning with the guardian of the child, creating the tasks
                  and assigning it to the child. Once that is done, the child
                  receives the task and fulfils the requirements to complete it.
                  The guardian then receives a notification to approve the
                  completion of the task. Finally, after all the tasks of the
                  month are done, the total value (pocket money) is all together
                  transferred to the child’s account. This amount that is paid
                  to the child as rewards for their completed tasks is made in
                  Cogs, the official digital currency of Cogito Metaverse. The
                  app allows parents and guardians to add as many children they
                  would like and the children too can add their guardians, by
                  sending requests on the app.
                </Typography>
                <Typography className={classes.descText}>
                  For guardians the flow of the app is as follows:
                  <List component="ol">
                    <ListItem component="li">
                      <ListItemText primary="1. Login" />
                    </ListItem>
                    <ListItem component="li">
                      <ListItemText primary="2. Create task" />
                    </ListItem>
                    <ListItem component="li">
                      <ListItemText primary="3. Assign task to child" />
                    </ListItem>
                    <ListItem component="li">
                      <ListItemText primary="4. Approve task completed by child" />
                    </ListItem>
                    <ListItem component="li">
                      <ListItemText primary="5. Child receives rewards from Cogito by the end of the month. " />
                    </ListItem>
                  </List>
                  For children the flow of the app is as follows:
                  <List component="ol">
                    <ListItem component="li">
                      <ListItemText primary="1. Login" />
                    </ListItem>
                    <ListItem component="li">
                      <ListItemText primary="2. Complete assigned Tasks" />
                    </ListItem>
                    <ListItem component="li">
                      <ListItemText primary="3. Send for approval to guardian" />
                    </ListItem>
                    <ListItem component="li">
                      <ListItemText primary="4. Once approved, child receives rewards from Cogito by the end of the month" />
                    </ListItem>
                  </List>
                </Typography>
              </Grid>

              <Grid item xs={12} style={{ textAlign: 'left' }}>
                <Typography className={classes.headText}>
                  What information do we collect through the app?
                </Typography>
              </Grid>
              <Grid item xs={12} style={{ textAlign: 'left' }}>
                <Typography className={classes.descText}>
                  The information collected by the user registering on Pocket
                  Money includes- name, age, date of birth, email address, phone
                  number, photo and ID proof.
                </Typography>
              </Grid>

              <Grid item xs={12} style={{ textAlign: 'left' }}>
                <Typography className={classes.headText}>
                  What tasks are included in the app?
                </Typography>
              </Grid>
              <Grid item xs={12} style={{ textAlign: 'left' }}>
                <Typography className={classes.descText}>
                  Pocket Money has been designed keeping in mind children of all
                  ages, therefore, the app can include a range of tasks. For
                  example: ‘clean your room’ or ‘brush your teeth’. The app
                  provides guardians a list of tasks to choose from at the time
                  of creating a task, so they can make the best choice keeping
                  in mind the age group of their child.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default AboutUsPage;
