import { Box, Grid, makeStyles, useTheme } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import { connect } from 'react-redux';
import ContainerTitle from '../../components/common/title/ContainerTitle';
import * as actionCreator from '../../store/action';
import { constant } from '../../utils/constant';
import FilterListIcon from '@material-ui/icons/FilterList';
import BaseButton from '../buttons/BaseButton';
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined';
import CheckBoxOutlineBlankOutlinedIcon from '@material-ui/icons/CheckBoxOutlineBlankOutlined';
import { initialState } from '../../store/reducer/paginationReducer';

const useStyle = ({ checked }) =>
  makeStyles(theme => {
    const isParent = theme?.customTheme?.isParent;
    return {
      container: {
        padding: '1rem',
        position: 'relative',
      },
      alignCenter: {
        display: 'flex',
        alignItems: 'center',
      },
      filterContainer: {
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        padding: '0.4rem',
        border: `1.2px solid ${checked ? '#083750' : '#359DB6'}`,
        borderRadius: 50,
      },
      filterIconStyle: {
        fontSize: '1rem',
        marginRight: '0.7rem',
        color: checked ? '#083750' : '#359DB6',
      },
      textStyle: {
        fontSize: '0.9rem',
        fontWeight: 400,
        color: checked ? '#083750' : '#359DB6',
      },
      closeBackgroundContainer: {
        position: 'fixed',
        top: 0,
        left: 0,
        height: '100vh',
        width: '100vw',
        zIndex: 2,
      },
      dropdownContainer: {
        position: 'absolute',
        top: '3.5rem',
        right: '1rem',
        padding: '0.3rem 0rem',
        backgroundColor: checked ? '#365353' : '#359DB6',
        borderRadius: 7,
        zIndex: 3,
      },
      dropdownOption: {
        padding: '0.3rem 0.5rem',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        width: '100%',
      },
      onSelectDropdown: {
        backgroundColor: checked ? '#699595' : '#C9E5ECaa',
        // color: checked ? "#fff" : "#303030",
        borderRadius: '1px',
      },
      checkboxStyle1: {
        fontSize: '1.4rem',

        color: checked ? '#fff' : '#303030',
        paddingTop: '1px',
        paddingRight: '3px',
      },
      checkboxStyle: {
        fontSize: '1.4rem',

        color: checked ? '#fff' : '#fff',
        paddingTop: '1px',
        paddingRight: '3px',
      },
      optionNameStyle: {
        fontSize: '0.9rem',
        color: checked ? '#fff' : '#fff',
        fontWeight: 500,
        whiteSpace: 'nowrap',
        marginTop: '0.1rem',
      },
    };
  });
const FilterDropdown = props => {
  const {
    isParentLoggedIn,
    childList,
    setCompletedTaskPaginationPage,
    setAllTaskPaginationPage,
    setUpdateTaskPaginationPage,
    checked,
  } = props;

  const theme = useTheme();
  const classes = useStyle({ checked })(theme);
  const [selectedOption, setSelectedOption] = React.useState(null);
  const [dropdownState, setDropdownState] = React.useState(false);
  const firstLoad = React.useRef(true);
  const options = childList.map((child,index) => ({
    name: `${child?.firstName} ${child?.lastName}`,
    data: child,
    index:index
  }));

  const handleDropdown = () => {
    setDropdownState(!dropdownState);
  };
  const handleDropdownOption = option => {
    setSelectedOption(data => (option._id == data?._id ? null : option));
    handleDropdown();
  };
  const dropDown = options => (
    <>
      <Box
        className={clsx(classes.closeBackgroundContainer)}
        onClick={handleDropdown}></Box>
      <Box className={clsx(classes.dropdownContainer)}>
        {options.map(option => (
          <BaseButton
            key={option.name}
            onClick={() => handleDropdownOption(option.data)}
            id = {`filter-options-${option.index}`}
            style={{ width: '100%' }}
           
            text={
              <Box
                className={clsx(
                  classes.dropdownOption,

                  selectedOption?._id == option.data._id
                    ? classes.onSelectDropdown
                    : '',
                )}
                // style={{ backgroundColor: "#699595", borderRadius: "6px" }}
              >
                {selectedOption?._id == option.data._id ? (
                  <CheckBoxOutlinedIcon
                    className={clsx(classes.checkboxStyle1)}
                  />
                ) : (
                  <CheckBoxOutlineBlankOutlinedIcon
                    className={clsx(classes.checkboxStyle)}
                  />
                )}
                <ContainerTitle
                  title={option.name}
                  className={clsx(classes.optionNameStyle)}
                />
              </Box>
            }
          />
        ))}
      </Box>
    </>
  );

  React.useEffect(() => {
    if (!firstLoad.current) {
      let filter = initialState.paginationAllTask;
      filter.total = 1;
      if (selectedOption) {
        filter.filter.selectedChild = selectedOption;
      } else {
        filter.filter.selectedChild = null;
      }
      if (checked == 1) {
        setCompletedTaskPaginationPage(filter);
      } else if (checked == 2) {
        setUpdateTaskPaginationPage(filter);
      } else {
        setAllTaskPaginationPage(filter);
      }
    }
    firstLoad.current = false;
  }, [selectedOption]);
  return (
    <Box className={clsx(classes.container)}>
      <BaseButton
        onClick={handleDropdown}
        id={`filter-Dropdown-${checked||0}`}
        text={
          <Box className={clsx(classes.filterContainer)}>
            <Box className={clsx(classes.alignCenter)}>
              <FilterListIcon className={clsx(classes.filterIconStyle)} />
            </Box>
            <Box className={clsx(classes.alignCenter)}>
              <ContainerTitle
                title={constant.filter}
                className={clsx(classes.textStyle)}
              />
            </Box>
          </Box>
        }
      />
      {dropdownState ? dropDown(options) : null}
    </Box>
  );
};

const mapStateToProps = ({ userReducer, authReducer }) => {
  return {
    childList: userReducer.childList,
    profile: userReducer.profile,
    isParentLoggedIn: authReducer.isParentLoggedIn,
    isChildrenLoggedIn: authReducer.isChildrenLoggedIn,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setAllTaskPaginationPage: data => {
      dispatch(actionCreator.setAllTaskPaginationPage(data));
    },
    setCompletedTaskPaginationPage: data => {
      dispatch(actionCreator.setCompletedTaskPaginationPage(data));
    },
    setUpdateTaskPaginationPage: data => {
      dispatch(actionCreator.setUpdateTaskPaginationPage(data));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(FilterDropdown);
