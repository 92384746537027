import { Box, makeStyles, MenuItem, Select, useTheme } from '@material-ui/core';
import clsx from 'clsx';
import ContainerTitle from '../common/title/ContainerTitle';

const useStyle = makeStyles(theme => ({
  container: {
    position: 'relative',
    marginBottom: 20,
  },
  textFieldStyle: {},
  errorStyle: {
    color: 'red',
    fontSize: '0.9rem',
    fontWeight: 500,
    position: 'absolute',
    bottom: -20,
  },
}));
const CustomSelectField = props => {
  const theme = useTheme();
  const classes = useStyle(theme);
  const {
    placeholder,
    variant,
    size,
    className,
    value,
    onChange,
    type,
    errorMessage,
    field,
    required,
    inputProps,
    options,
    containerClassName,
    disabled,
  id
  } = props;
  const fieldProps = {
    placeholder,
    variant,
    size,
    value,
    type,
    inputProps,
    required,
    disabled,
  };
  return (
    <>
      <Box className={clsx(classes.container, containerClassName)}>
        <Select
          variant="outlined"
          labelId="demo-simple-select-label"
          id={id}
          fullWidth
          {...fieldProps}
          className={clsx(classes.textFieldStyle, className)}
          onChange={onChange}>
          {options.map((data,index) => (
            <MenuItem value={data.value} key={data.value} id={`menu-item-${index}`}>
              {data.label}
            </MenuItem>
          ))}
        </Select>
        {errorMessage ? (
          <ContainerTitle title={errorMessage} className={classes.errorStyle} />
        ) : null}
      </Box>
    </>
  );
};
CustomSelectField.defaultProps = {
  placeholder: '',
  size: 'small',
  type: 'text',
  options: [],
  onChange: () => {},
  containerClassName: '',
};
export default CustomSelectField;
