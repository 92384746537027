import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { Box, Grid, makeStyles, Typography, useTheme } from '@material-ui/core';
import Dot from '../../../src/assets/images/calenderPage/Dot.png';
import { isTaskActive } from '../../utils/timeMethods';
import * as actionCreator from '../../store/action/index';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { constant } from '../../utils/constant';
import BorderButton from '../../components/buttons/BorderButton';
import CommonModel from '../../components/common/model/CommonModel';
import UpdateModal from '../../components/tasks/UpdateModal';
import { maxStringLength } from '../../utils/stringMethods';

const useStyles = makeStyles(theme => ({
  box: {
    height: '100px',
    border: '2px solid #5986B6',
    borderRadius: '8px',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#FFF',
    boxShadow: '4px 4px 3px 0px rgba(28,27,27,0.64)',
    webkitBoxShadow: '4px 4px 3px 0p    x rgba(28,27,27,0.64)',
    mozBoxShadow: '4px 4px 3px 0px rgba(28,27,27,0.64)',
    marginBottom: '2vh',
    padding: '10px',
    [theme.breakpoints.down('xs')]: {
      padding: '12px',
    },
  },
  subBox: {
    display: 'flex',
    alignItems: 'center',
  },
  detailText: {
    display: 'flex',
    alignItems: 'center',
    color: 'black',
    fontSize: '1.1rem',
    fontWeight: 'bold',
  },
  detailBox: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  dotsText: {
    display: 'flex',
    alignItems: 'center',
    color: 'black',
    fontSize: '0.7rem',
    [theme.breakpoints.up('xs')]: {
      paddingLeft: '0px',
    },
  },
  dots: {
    width: '35px',
  },
  imgBox: {
    display: 'flex',
    alignItems: 'center',
    width: '4rem',
    borderRadius: '60px',
    height: '4rem',
    [theme.breakpoints.down(400)]: {
      height: '3rem',
    },
  },
  status: {
    borderRadius: '8px !important',
    textAlign: 'center',
  },
  statusText: {
    color: '#fff',
    // fontSize:'5rem'
    fontSize: '0.8rem !important',
    [theme.breakpoints.down(1200)]: {
      fontSize: '1vw !important',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.45vw !important',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.6rem !important',
    },
  },
  approveButton: {
    color: '#359DB6',
    border: '1px solid #359DB6',
    backgroundColor: 'transparent',
    fontSize: '0.8rem !important',
    [theme.breakpoints.down(1200)]: {
      fontSize: '1.2vw !important',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.45vw !important',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.6rem !important',
    },
    fontWeight: 400,
    height: '100%',
    padding: 0,
    width: '100%',
    borderRadius: '5px',
    '&:hover': {
      backgroundColor: '#359DB6',
      color: '#fff',
    },
  },
  time: {
    color: 'black',
    fontSize: '0.7rem',
    [theme.breakpoints.between(600, 650)]: {
      fontSize: '0.6rem',
    },
  },
  titleTextStyle: {
    color: theme.customTheme.commonModalTextStyle,
  },
  profileImage: {
    width: '80px',
    height: '80px',
    borderRadius: '50%',
    objectFit: 'cover',
    [theme.breakpoints.down('xs')]: {
      width: '60px',
      height: '60px',
    },
    [theme.breakpoints.down(480)]: {
      width: '50px',
      height: '50px',
      marginRight: '10px',
    },
  },
}));

const CalenderCardY = props => {
  const {
    childList,
    header,
    subTitle,
    status,
    timeTo,
    timeFrom,
    taskData,
    taskDetail,
    checked,
    updateCallBack,
  } = props;
  const theme = useTheme();
  const [childData, setChildData] = useState();
  const classes = useStyles(theme);
  const isParentLoggedIn = true;

  useEffect(() => {
    childList?.map(data => {
      if (data?._id == taskData?.childId) {
        setChildData(data);
      }
    });
  }, [taskData, childList]);
  const [updateModal, setUpdateModal] = React.useState(null);

  const handleUpdateTaskModal = () => {
    setUpdateModal(!updateModal);
  };

  const taskStatus =
    status == constant.parentApprovedStatus || status == constant.parentApproved
      ? 'APPROVED'
      : status == constant.childApprovedStatus
      ? 'COMPLETED'
      : status == constant.createdStatus
      ? 'CREATED'
      : status == constant.inProgress
      ? isTaskActive(taskDetail.taskTimeStamp)
        ? 'Ongoing'
        : 'Scheduled'
      : '';
  return (
    <>
      <Grid
        item
        xs={12}
        md={5}
        className={classes.box}
        style={{
          backgroundColor:
            status == 'CREATED'
              ? '#C9E5EC'
              : status == constant.parentApprovedStatus
              ? '#C5E2E2'
              : '#C9E5EC',
          border:
            status == 'CREATED'
              ? '2px solid #359DB6'
              : status == constant.parentApprovedStatus
              ? '2px solid #699595'
              : '2px solid #89bdc9',
        }}>
        {!checked ? (
          <CommonModel
            title={constant.updateTask}
            titleContainerStyle={classes.modelTitleContainerStyle}
            open={updateModal}
            titleStyle={classes.titleTextStyle}
            handleClose={handleUpdateTaskModal}
            body={
              <UpdateModal
                open={updateModal}
                handleClose={handleUpdateTaskModal}
                taskDetail={{ ...taskData, child: childData }}
                callBack={updateCallBack}
                isParentLoggedIn={isParentLoggedIn}
              />
            }
          />
        ) : null}
        <Grid
          container
          justifyContent="space-around"
          style={{ height: '100%' }}
          onClick={handleUpdateTaskModal}>
          <Grid
            container
            item
            xs={2}
            md={3}
            xl={2}
            justifyContent="center"
            alignItems="center"
            style={{
              width: '100%',
            }}>
            {childData?.profile_pic ? (
              <img
                src={`${process.env.REACT_APP_SPACE_KEY}/${childData?.profile_pic}`}
                alt="profile_pic"
                className={classes.profileImage}
              />
            ) : (
              <AccountCircleIcon className={classes.imgBox} />
            )}
          </Grid>

          <Grid
            item
            xs={10}
            md={9}
            xl={10}
            container
            className={classes.subBox}>
            <Grid
              item
              container
              xs={12}
              className={classes.detailBox}
              style={{ height: '100%', display: 'flex', alignItems: 'center' }}>
              <Grid
                item
                container
                xs={12}
                style={{
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                }}>
                <Grid item xs={8}>
                  <Typography
                    style={{
                      color: status == 'IN PROGRESS' ? '#fff' : 'black',
                      color: 'black',
                    }}
                    className={classes.detailText}>
                    {maxStringLength(header, 16)}
                  </Typography>
                  <Typography
                    style={{
                      color: status == 'IN PROGRESS' ? '#E0E0E0' : 'black',
                      color: 'black',
                    }}
                    className={classes.dotsText}>
                    {maxStringLength(subTitle, 26)}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={4}
                  l={3}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}>
                  <div
                    className={classes.status}
                    style={{
                      width: '100%',
                      padding: '5px 10px',
                      backgroundColor:
                        status == 'CREATED'
                          ? '#359DB6'
                          : status == 'IN PROGRESS'
                          ? '#083750'
                          : status == 'NOT STARTED'
                          ? '#C9E5EC'
                          : status == constant.parentApprovedStatus
                          ? '#699595'
                          : status == constant.childApprovedStatus
                          ? '#359DB6'
                          : '#359DB6',
                    }}>
                    <Typography className={classes.statusText}>
                      {taskStatus}
                    </Typography>{' '}
                    {''}
                  </div>
                </Grid>

                {/* time */}
                <Grid item container xs={12}>
                  <Grid item xs={8}>
                    <img src={Dot} />
                    <text className={classes.time}>
                      {' '}
                      {moment(timeTo).format('hh:mm A')}{' '}
                    </text>{' '}
                    <text className={classes.time}> - </text>
                    <text className={classes.time}>
                      {' '}
                      {moment(timeFrom).format('hh:mm A')}{' '}
                    </text>
                    <text style={{ color: '#999999' }}> </text>
                    {}
                  </Grid>

                  {/* approve status */}
                  <Grid item xs={4} l={3}>
                    <div
                      className={classes.approve}
                      style={{
                        width: '100%',
                      }}>
                      {status === constant.childApprovedStatus ? (
                        <BorderButton
                          className={classes.approveButton}
                          text={'APPROVE'}
                          onClick={handleUpdateTaskModal}
                        />
                      ) : null}
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

const mapStateToProps = ({ userReducer }) => {
  return {
    profile: userReducer.profile,
    childList: userReducer.childList,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getChildList: () => {
      dispatch(actionCreator.getChildList());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CalenderCardY);
