import {
  Box,
  Button,
  Grid,
  makeStyles,
  Typography,
  useTheme,
} from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import { connect } from 'react-redux';
import ContainerTitle from '../../../components/common/title/ContainerTitle';
import EmptyTask from '../../../components/EmptyData/EmptyTask';
import Pagination from '../../../components/pagination/Pagination';
import * as actionCreator from '../../../store/action';
import { constant } from '../../../utils/constant';
import RewardsContainer from './components/RewardsContainer';
import RequestList from '../../dashboard/components/RequestList';
import KidsTaskList from '../../../components/tasks/KidsTaskList';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { useState } from 'react';
const useStyle = makeStyles(theme => {
  const isParent = theme?.customTheme?.isParent;

  return {
    container: {
      padding: '1rem',
      position: 'relative',
    },
    innerContainer: {
      padding: '1rem 1rem',
      [theme.breakpoints.down(constant.breakMobileAuthPoint)]: {
        padding: '1rem 0rem',
      },
      [theme.breakpoints.down(constant.breakSmallMobileAuthPoint)]: {
        padding: '0rem 0rem',
      },
    },
    headContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
      },
    },
    titleContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: '1rem',
      position: 'relative',
      [theme.breakpoints.down(constant.breakMobileAuthPoint)]: {
        marginBottom: '0.8rem',
      },
      [theme.breakpoints.down(constant.breakSmallMobileAuthPoint)]: {
        marginBottom: '0rem',
      },
    },
    gridContainer: {
      display: 'flex',
      // justifyContent: 'space-between',
      flexDirection: 'row',
      flexWrap: 'wrap',
    },
    gridContainer1: {
      width: isParent ? '35%' : '33%',
      [theme.breakpoints.down(constant.breakLaptopAuthPoint)]: {
        width: '50%',
        // paddingTop: '8%',
      },
      [theme.breakpoints.down(constant.breakMobileAuthPoint)]: {
        width: '100%',
        paddingTop: '8%',
      },
    },
    gridContainer2: {
      width: isParent ? '35%' : '33%',
      [theme.breakpoints.down(constant.breakLaptopAuthPoint)]: {
        width: '50%',
      },
      [theme.breakpoints.down(constant.breakMobileAuthPoint)]: {
        width: '100%',
        paddingTop: '8%',
      },
    },
    gridContainer3: {
      width: '30%',
      [theme.breakpoints.down(constant.breakLaptopAuthPoint)]: {
        width: '35%',
      },
      [theme.breakpoints.down(constant.breakMobileAuthPoint)]: {
        width: '100%',
        paddingTop: '8%',
      },
    },
    paginationContainer: {
      padding: '0.5rem',
      borderBottomRightRadius: 12,
      borderBottomLeftRadius: 12,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    paginationContainerOverdue: {
      backgroundColor: theme.customTheme.paginationOverduetasks,
    },
    paginationContainerAllTasks: {
      backgroundColor: theme.customTheme.paginationAlltasks,
    },
    paginationCompletedTask: {
      backgroundColor: theme.customTheme.paginationCompletedTasks,
    },
    paginationContainerChecked: {
      backgroundColor: '#42C184',
    },
    createTaskContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    createTaskButton: {
      borderRadius: 6,
      width: 110,
      [theme.breakpoints.down(constant.breakMobileAuthPoint)]: {
        padding: '0.3rem',
        fontSize: '0.7rem',
        width: 110,
      },
      [theme.breakpoints.down(700)]: {
        width: 100,
      },
      [theme.breakpoints.down(550)]: {
        width: 90,
      },
      [theme.breakpoints.down(500)]: {
        width: 75,
      },
    },
    createTaskText: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: '1rem',
      color: '#fff',
      fontWeight: 550,
      [theme.breakpoints.down(constant.breakMobileAuthPoint)]: {
        fontSize: '0.8rem',
      },
    },
    addIcon: {
      fontSize: '1.4rem',
      color: '#fff',
      [theme.breakpoints.down(constant.breakMobileAuthPoint)]: {
        fontSize: '1.2rem',
      },
    },
    modelTitleContainerStyle: {
      justifyContent: 'flex-start !important',
    },
    taskUpperContainer: {
      padding: '0.5rem 0.8rem',
      borderTopRightRadius: 12,
      borderTopLeftRadius: 12,
    },
    headerNameTextStyle: {
      color: theme?.customTheme?.nameTextColorAtDashboard,
      fontSize: '2rem',
    },
    headerSubTextStyle: {
      color: theme?.customTheme?.nameSubTextColorAtDashboard,
      fontSize: '1rem',
      fontWeight: 400,
    },
    allTaskTitle: {
      color: theme.customTheme.taskContainerTitle,
    },
    // completedTaskTitle: {
    //   color: theme.customTheme.taskContainerTitle,
    // },

    noTaskText: {
      color: theme.customTheme.NoTaskText,
    },
    emptyTaskStyle: { color: '#359DB6' },
    rewardsBox: {
      [theme.breakpoints.down('xs')]: {
        marginTop: '6%',
      },
    },
    viewLessButton: {
      alignItems: 'center',
      display: 'flex',
      color: '#3E6E9B',
    },
  };
});
const KidsDashboard = props => {
  const {
    getChildList,
    getChildrenRequest,
    getTaskList,
    getGuardianList,
    guardianRequest,
    paginationCompletedTaskList,
    paginationAllTaskList,
    paginationUpdateTaskList,
    setAllTaskPaginationPage,
    setCompletedTaskPaginationPage,
    setUpdateTaskPaginationPage,
    profile,
    updateTaskList,
    completedTaskList,
    allTaskList,
    getRewards,
    getGuardianRequest,
  } = props;
  const isParentLoggedIn = false;
  const firstLoad = React.useRef(true);
  const theme = useTheme();
  const classes = useStyle(theme);
  const [createTaskModal, setCreateTaskModal] = React.useState();
  const [expand, setexpand] = useState(false);

  const handleCreateTaskModal = () => {
    setCreateTaskModal(!createTaskModal);
  };

  const handleAllTaskPagination = (event, value) => {
    setAllTaskPaginationPage({ skip: value - 1 });
  };

  const handleCompletedTaskPagination = (event, value) => {
    setCompletedTaskPaginationPage({ skip: value - 1 });
  };

  const handleUpdateTaskPagination = (event, value) => {
    setUpdateTaskPaginationPage({ skip: value - 1 });
  };

  React.useEffect(() => {
    getGuardianList();
    firstLoad.current = false;
    let today = new Date();
    getRewards({
      month: today.getMonth() + 1,
      year: today.getFullYear(),
    });
  }, []);

  React.useEffect(() => {
    getGuardianRequest();
  }, []);
  return (
    <Box className={clsx(classes.container)}>
      {guardianRequest?.length ? (
        <Box className={clsx(classes.innerContainer)}>
          <Box className={clsx(classes.titleContainer)}>
            <ContainerTitle
              title={constant.kidrequest}
              style={{ color: theme?.customTheme?.requestContainerTitleColor }}
            />
          </Box>
          <RequestList
            guardianRequest={guardianRequest}
            isMinor={true}
            isExpand={expand}
          />
          {guardianRequest?.length > 2 ? (
            <Grid style={{ textAlign: 'right' }}>
              <Button
                style={{ textTransform: 'none' }}
                onClick={() => {
                  setexpand(!expand);
                }}>
                {expand ? (
                  <Typography className={classes.viewLessButton}>
                    {' View Less'}
                    <ExpandLessIcon style={{ color: '#FF9838' }} />
                  </Typography>
                ) : (
                  <Typography className={classes.viewLessButton}>
                    {'View More'}
                    <ExpandMoreIcon style={{ color: '#FF9838' }} />
                  </Typography>
                )}
              </Button>
            </Grid>
          ) : null}
        </Box>
      ) : null}

      <Box className={clsx(classes.innerContainer, classes.headContainer)}>
        <Box>
          <ContainerTitle
            title={`Hey, ${profile?.firstName}!`}
            className={classes.headerNameTextStyle}
          />
          <ContainerTitle
            title={'Let’s be productive today.'}
            className={classes.headerSubTextStyle}
          />
        </Box>
        <Box className={classes.rewardsBox}>
          <RewardsContainer />
        </Box>
      </Box>
      <Box className={clsx(classes.gridContainer)}>
        {/* ----Created tasks */}
        <Box className={clsx(classes.innerContainer, classes.gridContainer1)}>
          <Box className={clsx(classes.taskUpperContainer)}>
            <Box className={clsx(classes.titleContainer)}>
              <ContainerTitle
                title={constant.taskList}
                className={classes.allTaskTitle}
              />
            </Box>
            {paginationAllTaskList?.total === null ||
            paginationAllTaskList?.total ? (
              <KidsTaskList
                taskContainerId={'task-container-1'}
                isParentLoggedIn={isParentLoggedIn}
              />
            ) : (
              <EmptyTask
                text={
                  <ContainerTitle
                    title={'No Tasks to display'}
                    className={classes.noTaskText}
                  />
                }
              />
            )}
          </Box>
          {paginationAllTaskList?.total ? (
            <Box
              className={clsx(
                classes.paginationContainer,
                classes.paginationContainerAllTasks,

                'paginationDashboardContainer',
              )}>
              <Pagination
                page={paginationAllTaskList?.skip + 1}
                pageCount={Math.ceil(
                  (paginationAllTaskList?.total || 0) /
                    (paginationAllTaskList?.limit || 1),
                )}
                onChange={handleAllTaskPagination}
              />
            </Box>
          ) : null}
        </Box>

        {/*------ completed Task List ------- */}
        <Box className={clsx(classes.innerContainer, classes.gridContainer2)}>
          <Box className={clsx(classes.taskUpperContainer)}>
            <Box className={clsx(classes.titleContainer)}>
              <ContainerTitle
                title={constant.completedTaskList}
                className={classes.allTaskTitle}
              />
            </Box>
            {paginationCompletedTaskList?.total === null ||
            paginationCompletedTaskList?.total ? (
              <KidsTaskList
                taskContainerId={'task-container-2'}
                checked={1}
                isParentLoggedIn={isParentLoggedIn}
              />
            ) : (
              <EmptyTask
                text={
                  <ContainerTitle
                    title={'No Tasks to display'}
                    className={classes.noTaskText}
                  />
                }
              />
            )}
          </Box>
          {paginationCompletedTaskList?.total ? (
            <Box
              className={clsx(
                classes.paginationContainer,
                classes.paginationCompletedTask,
                'paginationDashboardContainer',
              )}>
              <Pagination
                page={paginationCompletedTaskList?.skip + 1}
                pageCount={Math.ceil(
                  (paginationCompletedTaskList?.total || 0) /
                    (paginationCompletedTaskList?.limit || 1),
                )}
                onChange={handleCompletedTaskPagination}
              />
            </Box>
          ) : null}
        </Box>

        {/*------ OverDue tasks -------*/}
        <Box className={clsx(classes.innerContainer, classes.gridContainer1)}>
          <Box className={clsx(classes.taskUpperContainer)}>
            <Box className={clsx(classes.titleContainer)}>
              <ContainerTitle
                title={constant.delayedTaskList}
                className={classes.allTaskTitle}
              />
            </Box>
            {paginationUpdateTaskList?.total === null ||
            paginationUpdateTaskList?.total ? (
              <KidsTaskList
                taskContainerId={'task-container-3'}
                checked={2}
                isParentLoggedIn={isParentLoggedIn}
              />
            ) : (
              <EmptyTask
                text={
                  <ContainerTitle
                    title={'No Tasks to display'}
                    className={classes.noTaskText}
                  />
                }
              />
            )}
          </Box>
          {paginationUpdateTaskList?.total ? (
            <Box
              className={clsx(
                classes.paginationContainer,
                classes.paginationContainerOverdue,
                'paginationDashboardContainer',
              )}>
              <Pagination
                page={paginationUpdateTaskList?.skip + 1}
                pageCount={Math.ceil(
                  (paginationUpdateTaskList?.total || 0) /
                    (paginationUpdateTaskList?.limit || 1),
                )}
                onChange={handleUpdateTaskPagination}
              />
            </Box>
          ) : null}
        </Box>
      </Box>
      {/* <Box className={clsx(classes.innerContainer, classes.gridContainer3)}>
        <Box className={clsx(classes.titleContainer)}>
          <ContainerTitle
            title={constant.guardiansProfile}
            style={{ color: constant.transparent }}
          />
        </Box>
        <ParentsContainer />
      </Box> */}
    </Box>
  );
};

const mapStateToProps = ({ userReducer, authReducer, paginationReducer }) => {
  return {
    profile: userReducer.profile,
    isParentLoggedIn: authReducer.isParentLoggedIn,
    isChildrenLoggedIn: authReducer.isChildrenLoggedIn,
    guardianRequest: userReducer.guardianRequest,
    paginationAllTaskList: paginationReducer.paginationAllTask,
    paginationCompletedTaskList: paginationReducer.paginationCompleteTask,
    paginationUpdateTaskList: paginationReducer.paginationUpdateTask,
    updateTaskList: userReducer.updateTasks,
    completedTaskList: userReducer.completedTasks,
    allTaskList: userReducer.allTasks,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getChildrenRequest: () => {
      dispatch(actionCreator.getChildrenRequest());
    },
    getGuardianList: () => {
      dispatch(actionCreator.getGuardianList());
    },
    setAllTaskPaginationPage: data => {
      dispatch(actionCreator.setAllTaskPaginationPage(data));
    },
    setCompletedTaskPaginationPage: data => {
      dispatch(actionCreator.setCompletedTaskPaginationPage(data));
    },
    getTaskList: data => {
      dispatch(actionCreator.getTaskList(data));
    },
    getChildList: () => {
      dispatch(actionCreator.getChildList());
    },
    setUpdateTaskPaginationPage: data => {
      dispatch(actionCreator.setUpdateTaskPaginationPage(data));
    },
    getRewards: data => dispatch(actionCreator.getRewardsKid(data)),
    getGuardianRequest: () => {
      dispatch(actionCreator.getGuardianRequest());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(KidsDashboard);
