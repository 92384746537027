import React from 'react';
import {
  Grid,
  Hidden,
  makeStyles,
  Typography,
  useTheme,
} from '@material-ui/core';
// import Dots from '../../../src/assets/images/calenderPage/Dots.png';
// import Men from '../../../src/assets/images/calenderPage/Men.png';
// import women from '../../../src/assets/images/calenderPage/women.png';
import Dot from '../../../../src/assets/images/calenderPage/Dot.png';
import moment from 'moment';
import { connect } from 'react-redux';
import * as actionCreator from '../../../store/action/index';
import { useEffect } from 'react';
import { useState } from 'react';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import BorderButton from '../../../components/buttons/BorderButton';
import { constant } from '../../../utils/constant';
import CommonModel from '../../../components/common/model/CommonModel';
import { isTaskActive } from '../../../utils/timeMethods';
import UpdateModal from '../../../components/tasks/UpdateModal';
import { maxStringLength } from '../../../utils/stringMethods';
import ProfilePic from '../../../components/profilePic/ProfilePic';
// import ProfilePic from '../../components/profilePic/ProfilePic';

const useStyles = makeStyles(theme => ({
  box: {
    height: '100px',
    border: '2px solid',
    borderColor: theme.customTheme.borderColorCalendarCard,
    borderRadius: '8px',
    backgroundColor: theme.customTheme.calendarCardBackgroundColor,
    boxShadow: '4px 4px 3px 0px rgba(28,27,27,0.64)',
    webkitBoxShadow: '4px 4px 3px 0p    x rgba(28,27,27,0.64)',
    mozBoxShadow: '4px 4px 3px 0px rgba(28,27,27,0.64)',
    padding: '10px',
    [theme.breakpoints.down('xs')]: {
      padding: '12px',
    },
  },
  subBox: {
    display: 'flex',
    alignItems: 'center',
  },
  ƒ: {},
  subBox2: {
    height: 'auto',
  },
  bigLetter: {
    color: 'black',
    fontSize: '30px',
    paddingTop: '20px',
    paddingLeft: '15px',
  },
  smallLetter: {
    color: 'black',
    fontSize: '20px',
    paddingTop: '50px',
    paddingRight: '10px',
  },
  text: {
    color: 'black',
    fontSize: '14px',
    paddingLeft: '30px',
  },
  detailText: {
    // display: 'flex',
    overflow: 'hideen',
    textOverflow: 'elipsis',
    alignItems: 'center',
    color: theme.customTheme.textColorCalendarCard,
    fontSize: '15px',
    fontWeight: 'bold',
    [theme.breakpoints.down('xs')]: {
      fontSize: '14px',
    },
  },
  detailBox: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  detailBox1: {
    paddingBottom: '0px',
  },
  dotsText: {
    display: 'flex',
    alignItems: 'center',
    color: theme.customTheme.textColorCalendarCard,
    fontSize: '12px',
    // overflow: 'hidden',
    // whiteSpace: 'nowrap',
    // textOverflow: 'ellipsis',
    [theme.breakpoints.up('xs')]: {
      paddingLeft: '0px',
      fontSize: '10px',
    },
  },
  dots: {
    width: '35px',
  },
  imgBox: {
    display: 'flex',
    justifyContent: 'center',
    width: '3rem',
    borderRadius: '60px',
    height: '3rem',
  },
  status: {
    // height: 'auto',
    borderRadius: '5px !important',
    textAlign: 'center',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  statusText: {
    color: '#fff',
    textOverflow: 'clip',
    fontSize: '0.8rem !important',
    [theme.breakpoints.down(1200)]: {
      fontSize: '1vw !important',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.45vw !important',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.6rem !important',
    },
  },
  submitButton: {
    color: theme.customTheme.statusButton,
    border: `2px solid ${theme.customTheme.statusButton}`,
    backgroundColor: 'transparent',
    fontSize: '0.8rem !important',
    [theme.breakpoints.down(1200)]: {
      fontSize: '1.2vw !important',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.45vw !important',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.6rem !important',
    },
    fontWeight: 400,
    padding: 0,
    width: '100%',
    height: '65%',
    borderRadius: '5px',
    '&:hover': {
      backgroundColor: theme.customTheme.statusButton,
      color: '#fff',
    },
  },
  headerStyle: {
    backgroundColor: theme.customTheme.updateTaskModalHeaderColor,
    borderTopLeftRadius: 7,
    borderTopRightRadius: 7,
  },
  bodyStyle: {
    backgroundColor: theme.customTheme.updateTaskModalBackgroundColor,
    borderBottomLeftRadius: 7,
    borderBottomRightRadius: 7,
  },
  statusBox: {
    display: 'flex',
    flexDirection: 'column',
    height: '70%',
    // alignItems: 'center',
    justifyContent: 'center',
  },
  time: {
    // color: theme.customTheme.profilePageHeaderColor,
    fontWeight: '550',
    fontSize: '0.7rem',
    [theme.breakpoints.between(600, 650)]: {
      fontSize: '0.6rem',
    },
  },
  modalContainerSyle: {
    borderRadius: '20px',
    [theme.breakpoints.up('md')]: {
      minWidth: '30vw',
      maxWidth: '45vw',
    },
  },
  closeStyleBox: {
    borderRadius: 50,
    border: '2px solid #fff',
    color: '#fff !important',
  },
  titleTextStyle: {
    color: theme.customTheme.commonModalTextStyle,
  },
  profileImage: {
    width: '80px',
    height: '80px',
    borderRadius: '50%',
    objectFit: 'cover',
    [theme.breakpoints.down('xs')]: {
      width: '60px',
      height: '60px',
    },
    [theme.breakpoints.down(480)]: {
      width: '50px',
      height: '50px',
      marginRight: '10px',
    },
  },
}));

const KidCalenderCardY = props => {
  const {
    childList,
    getChildList,
    profile,
    header,
    subTitle,
    status,
    timeTo,
    timeFrom,
    taskData,
    checked,
    taskTimeStamp,
    currentDate,
    guardianList,
    updateCallBack,
  } = props;
  const theme = useTheme();
  const [childData, setChildData] = useState();
  const classes = useStyles(theme);
  const isParentLoggedIn = false;
  const [profileImage, setProfileImage] = useState(null);
  useEffect(() => {
    childList?.map(data => {
      if (data?._id == taskData?.childId) {
        setChildData(data);
      }
    });
  }, [taskData, childList]);

  const [updateModal, setUpdateModal] = useState(false);
  useEffect(() => {
    for (let i = 0; i < guardianList.length; i++) {
      if (guardianList[i]._id === taskData?.createdBy) {
        setProfileImage(guardianList[i].profile_pic);
      }
    }
  }, []);
  const handleUpdateTaskModal = () => {
    setUpdateModal(!updateModal);
  };
  const taskStatus =
    status == constant.parentApprovedStatus
      ? 'APPROVED'
      : status == constant.childApprovedStatus
      ? 'SUBMITTED'
      : status == constant.createdStatus
      ? 'CREATED'
      : '';
  return (
    <>
      {!checked ? (
        <CommonModel
          title={constant.updateTask}
          titleContainerStyle={classes.modelTitleContainerStyle}
          open={updateModal}
          handleClose={handleUpdateTaskModal}
          headerStyle={classes.headerStyle}
          containerStyle={classes.modalContainerSyle}
          bodyStyle={classes.bodyStyle}
          titleStyle={classes.titleTextStyle}
          closeStyle={classes.closeStyleBox}
          body={
            <UpdateModal
              open={updateModal}
              setUpdateModal={setUpdateModal}
              handleClose={handleUpdateTaskModal}
              taskDetail={{ ...taskData, profile }}
              callBack={updateCallBack}
              isParentLoggedIn={isParentLoggedIn}
            />
          }
        />
      ) : null}
      {/* <div className={classes.box}> */}
      <Grid container onClick={handleUpdateTaskModal} className={classes.box}>
        <Grid
          item
          xs={2}
          md={3}
          xl={2}
          justifyContent="center"
          alignItems="center"
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
          }}>
          {profile?.profile_pic ? (
            <ProfilePic
              pic={`${process.env.REACT_APP_SPACE_KEY}/${profileImage}`}
            />
          ) : (
            <AccountCircleIcon className={classes.imgBox} />
          )}
        </Grid>

        <Grid container item xs={10} md={9} xl={10} className={classes.subBox}>
          <Grid
            container
            xs={12}
            className={classes.detailBox}
            style={{
              alignItems: 'center',
              justifyContent: 'space-between',
              height: '60%',
            }}>
            <Grid item xs={8}>
              <Typography className={classes.detailText}>
                {maxStringLength(header, '16')}
              </Typography>
              <Typography className={classes.dotsText}>
                {maxStringLength(subTitle, 25)}
              </Typography>
            </Grid>
            <Grid xs={4} l={3} className={classes.statusBox}>
              <div
                className={classes.status}
                style={{
                  backgroundColor:
                    status == 'CREATED'
                      ? theme.customTheme.statusButton
                      : status == 'ONGOING' || status == 'NOT STARTED'
                      ? theme.customTheme.overDuetaskColor
                      : status == 'PARENT APPROVED'
                      ? theme.customTheme.approvedStatus
                      : status == 'COMPLETED'
                      ? theme.customTheme.paginationAlltasks
                      : theme.customTheme.paginationAlltasks,
                  border:
                    status == 'CREATED'
                      ? theme.customTheme.statusButton
                      : status == 'ONGOING'
                      ? '2px solid #FF9838'
                      : status == 'NOT STARTED'
                      ? '2px solid #E36464'
                      : status == 'PARENT APPROVED'
                      ? '#88bfaa'
                      : status == 'COMPLETED'
                      ? theme.customTheme.statusButton
                      : theme.customTheme.statusButton,
                }}>
                <Typography className={classes.statusText}>
                  {taskStatus}
                </Typography>
              </div>
            </Grid>
          </Grid>

          <Grid item container xs={12}>
            <Grid item xs={8}>
              {/* time */}
              {/* <img src={Dot} />{' '} */}
              <text className={classes.time}>
                {' '}
                {moment(timeTo).format('hh:mm A')}{' '}
              </text>{' '}
              <text> - </text>
              <text className={classes.time}>
                {' '}
                {moment(timeFrom).format('hh:mm A')}{' '}
              </text>
              <text> </text>
              {}
            </Grid>

            {/* submit status */}
            <Grid item xs={4} l={3}>
              <div className={classes.submit} style={{ width: '100%' }}>
                {status === constant.createdStatus &&
                isTaskActive(taskData?.taskTimeStamp) ? (
                  <BorderButton
                    className={classes.submitButton}
                    text={'Submit'}
                    onClick={handleUpdateTaskModal}
                  />
                ) : null}
              </div>
            </Grid>
          </Grid>

          {/* submit status */}
          {/* <div className={classes.subBox2}>
            <div className={classes.detailBox}></div>
          </div> */}
        </Grid>
      </Grid>
      {/* </div> */}
    </>
  );
};
const mapStateToProps = ({ userReducer }) => {
  return {
    profile: userReducer.profile,
    childList: userReducer.childList,
    guardianList: userReducer.guardianList,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getChildList: () => {
      dispatch(actionCreator.getChildList());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(KidCalenderCardY);
