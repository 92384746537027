import {
  Divider,
  Grid,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import React from 'react';
import Footer from '../../../components/common/footer/Footer';
import Header from '../../../components/common/header/Header';
import Rectangle from '../../../../src/assets/images/landingPageImages/Rectangle.png';
import BoyWithElement from '../../../../src/assets/images/landingPageImages/BoyWithElement.svg';
import GirlWithElement from '../../../../src/assets/images/landingPageImages/GirlWithElement.svg';
import FeatureTab from './FeatureTab';
import {
  ArrowForward,
  KeyboardArrowRight,
  RemoveRounded,
} from '@material-ui/icons';
import PMasTool from '../../../assets/images/landingPageImages/PMasTool.svg';
import lastGridImg1 from '../../../assets/images/landingPageImages/lastgridImg1.svg';
import lastGridImg2 from '../../../assets/images/landingPageImages/lastGridImg2.svg';
import backgroundImg from '../../../assets/images/landingPageImages/Background.svg';
import clsx from 'clsx';
const useStyles = makeStyles(theme => ({
  image: {
    width: '100%',
  },
  gridContainer: {
    display: 'grid',
    backgroundColor: '#eef7fa',
    [theme.breakpoints.down('500')]: {
      display: 'grid',
    },
  },
  maintext: {
    color: 'black',
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: '48px',
    fontFamily: 'Merriweather',
    padding: '0px 180px 35px 180px',
    marginTop: '110px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '25px',
      padding: '0 40px 40px',
    },
    [theme.breakpoints.between('sm', 'md')]: {
      fontSize: '30px',
      padding: '0 12% 5%',
    },
  },

  FirstGrid: {
    display: 'flex',
    flexDirection: 'row',
  },
  LineIcon: {
    fill: '#FF7300',
    fontSize: '3rem',
    margin: '1% 3% 0%',
    // marginLeft: '12%',
    [theme.breakpoints.down('sm')]: {
      marginTop: '0%',
    },
  },
  Intro: {
    textAlign: 'left',
    margin: '2% 12% 0% 0%',
    lineHeight: '35px',
    fontSize: '22px',
    fontFamily: 'Noto Sans KR',
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      margin: '1% 12% 0% 0%',
      textAlign: 'center',
    },
  },
  ItemContainer2: {
    backgroundImage: `url('https://cogito.sgp1.cdn.digitaloceanspaces.com/assets/curve.png')`,
    backgroundPosition: 'center',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundColor: '#fff',
    // padding: '0% 5%',
    [theme.breakpoints.down('xs')]: {
      padding: '5%',
      backgroundSize: 'fit',
    },
  },
  ItemContainer3: {
    backgroundColor: '#fff',
    padding: '0% 5%',
    [theme.breakpoints.down('xs')]: {
      padding: '5%',
    },
  },
  PointsGrid: {
    paddingTop: '5%',
    flexDirection: 'row',
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  PointsGrid2: {
    paddingBottom: '5%',
    flexDirection: 'row',
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  KeyPoint: {
    padding: '5%',
    borderBottom: '2px Dotted grey',
    fontWeight: 700,
    // fontSize: '32px',
    fontFamily: 'Merriweather',
    [theme.breakpoints.down('xs')]: {
      padding: '2% 0%',
    },
  },

  KeyPoints2: {
    padding: '5%',
    fontWeight: 'bold',
    fontFamily: 'Merriweather',
    [theme.breakpoints.down('xs')]: {
      padding: '2% 0%',
    },
  },
  KeyPointverticalBorder: {
    borderRight: '2px Dotted Grey',
  },
  Points: {
    fontWeight: 'bold',
    [theme.breakpoints.down('xs')]: {
      paddingLeft: '4%',
    },
  },
  ThirdGrid: {
    padding: '5% 0%',
    [theme.breakpoints.down('xs')]: {},
  },
  maintext2: {
    color: 'black',
    textAlign: 'left',
    fontWeight: 'bold',
    fontSize: '48px',
    marginTop: '20px',
    fontFamily: 'Merriweather',
    [theme.breakpoints.down('sm')]: {
      fontSize: '30px',
      textAlign: 'center',
      padding: '2%',
    },
  },
  Description: {
    lineHeight: '48px',
    fontSize: '22px',
    fontWeight: 400,
    paddingRight: '20%',
    paddingTop: '2%',
    fontFamily: 'Noto Sans KR',
    [theme.breakpoints.down('sm')]: {
      fontWeight: 400,
      padding: '2% 4%',
      fontSize: '15px',
      lineHeight: '28px',
      textAlign: 'center',
    },
  },
  FourthGrid: {
    padding: '5%',
  },
  FourthGridContent: {
    fontSize: '1rem',
    fontWeight: 'bold',
    lineHeight: '35px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.9rem',
    },
  },
  MobileViewPoints: {
    // borderBottom: '2px Dotted grey',
    paddingTop: '8%',
    flexDirection: 'row',
    display: 'flex',
  },
  maintextLast: {
    color: 'black',
    textAlign: 'center',
    fontWeight: 'bold',
    fontFamily: 'Merriweather',
    fontSize: '48px',
    marginTop: '20px',
    padding: '4% 4% 0%',
    [theme.breakpoints.down('xs')]: {
      fontSize: '30px',
      textAlign: 'center',
      padding: '2%',
    },
  },
  DescriptionLast: {
    textAlign: 'center',
    padding: '2%  10%',
    lineHeight: '38px',
    fontWeight: 400,
    fontSize: '22px',
    fontFamily: 'Noto Sans KR',
  },
}));

const LandingPage = () => {
  const theme = useTheme();
  const classes = useStyles();
  const matches = useMediaQuery(theme.breakpoints.down('xs'));
  const data = [
    { id: '1', text: 'Simple and easy to use' },
    { id: '2', text: 'Age appropriate tasks' },
    { id: '3', text: 'Customizable for your family' },
    {
      id: '4',
      text: 'Gives families the ability to begin learning about finance.',
    },
  ];
  return (
    <div>
      <Header />
      <Grid className={classes.gridContainer}>
        <Grid item container style={{ paddingBottom: '4%' }}>
          <Grid item xs={12} style={{ padding: '0% 8%' }}>
            <Typography className={classes.maintext}>
              {'How to'}{' '}
              <text style={{ color: '#359DB6' }}>{'Teach Children'}</text>{' '}
              {'to Work Hard and Earn'}{' '}
              <text style={{ color: '#359DB6' }}>{'Reward'}</text>
            </Typography>
          </Grid>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center">
            <Grid item xs={12} sm={7} md={3}>
              <img src={GirlWithElement} className={classes.image} />
            </Grid>

            <Grid item xs={12} sm={7} md={4} className={classes.FirstGrid}>
              <RemoveRounded className={classes.LineIcon} />
              <Typography className={classes.Intro}>
                A wise man once reminded parents and guardians to train up a
                child in the way they should go, and when they are old they will
                not depart from it. Are you looking for a fun and easy way to
                teach your kids about responsibility, managing money, and being
                more self-dependent? Look no further than Cogito Ed-Tech Pocket
                Money! Our platform is designed to help kids learn about these
                important skills through rewards.
              </Typography>
            </Grid>
            <Grid item xs={12} sm={7} md={3}>
              <img src={BoyWithElement} className={classes.image} />
            </Grid>
          </Grid>
        </Grid>

        <Grid item container className={classes.ItemContainer2}>
          <Grid item xs={12} className={classes.PointsGrid}>
            <Grid
              item
              xs={12}
              sm={3}
              className={clsx(
                matches ? classes.MobileViewPoints : classes.KeyPoint,
                matches ? null : classes.KeyPointverticalBorder,
              )}>
              {matches ? <ArrowForward /> : null}
              <Typography className={classes.Points}>
                {' '}
                Learn about responsibility
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={3}
              className={clsx(
                matches ? classes.MobileViewPoints : classes.KeyPoint,
                matches ? null : classes.KeyPointverticalBorder,
              )}>
              {matches ? <ArrowForward /> : null}
              <Typography className={classes.Points}>Begin savings </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={3}
              className={clsx(
                matches ? null : classes.KeyPointverticalBorder,
                matches ? classes.MobileViewPoints : classes.KeyPoint,
              )}>
              {matches ? <ArrowForward /> : null}
              <Typography className={classes.Points}>
                Understand the importance of earning and Investing{' '}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={3}
              className={matches ? classes.MobileViewPoints : classes.KeyPoint}>
              {matches ? <ArrowForward /> : null}
              <Typography className={classes.Points}>
                Learn about the value of smart work
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} className={classes.PointsGrid2}>
            <Grid
              item
              xs={12}
              sm={3}
              className={clsx(
                matches ? classes.MobileViewPoints : classes.KeyPoints2,
                matches ? null : classes.KeyPointverticalBorder,
              )}>
              {matches ? <ArrowForward /> : null}
              <Typography className={classes.Points}>
                Begin to understand budgeting and saving
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={3}
              className={clsx(
                matches ? classes.MobileViewPoints : classes.KeyPoints2,
                matches ? null : classes.KeyPointverticalBorder,
              )}>
              {matches ? <ArrowForward /> : null}
              <Typography className={classes.Points}>
                Understand money management
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={3}
              className={clsx(
                matches ? classes.MobileViewPoints : classes.KeyPoints2,
                matches ? null : classes.KeyPointverticalBorder,
              )}>
              {matches ? <ArrowForward /> : null}
              <Typography className={classes.Points}>
                Learn value for money{' '}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={3}
              className={
                matches ? classes.MobileViewPoints : classes.KeyPoints2
              }>
              {matches ? <ArrowForward /> : null}
              <Typography className={classes.Points}>
                Learn where and where not to spend money
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item container className={classes.ThirdGrid}>
          <Grid item xs={12} sm={5} style={{ textAlign: 'center' }}>
            <img src={PMasTool} width="70%" />
          </Grid>
          <Grid item xs={12} sm={7}>
            <Typography className={classes.maintext2}>
              Pocket Money as a <text style={{ color: '#359DB6' }}> Tool</text>{' '}
            </Typography>
            <Typography className={classes.Description}>
              The Pocket Money App is a tool made by the creators of Cogito
              Metaverse to help kids learn about money. We know that the future
              is digital, so it's important for kids to learn about personal
              finance and be self-dependent. With this app, kids can learn
              practical skills that will help them in the future. They can also
              see how hard work pays off and feel good about being responsible.
              The Pocket Money App is a great way for kids to learn about the
              value of their hard work and become more confident in the modern
              world.
            </Typography>
          </Grid>
        </Grid>
        <Grid item container className={classes.ItemContainer3}>
          <Grid item xs={12} sm={6} className={classes.FourthGrid}>
            <Typography className={classes.FourthGridContent}>
              {' '}
              “The best way to make this happen is by presenting our kids with
              opportunities where they can equate effort with value and learn
              skills that will stay with them for a lifetime. The Pocket Money
              App supports this idea and offers children a platform where they
              can become responsible and learn the value of their hard work.”
            </Typography>
            <Typography style={{ fontSize: '0.7rem' }}>
              {' '}
              Metaverse Creative
            </Typography>
          </Grid>
          {!matches ? (
            <Divider orientation="vertical" variant="middle" />
          ) : (
            <Grid item xs={12}>
              <Divider orientation="horizontal" />
            </Grid>
          )}
          <Grid item xs={12} sm={5} className={classes.FourthGrid}>
            {data?.map(text => {
              return (
                <Grid
                  item
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    padding: '2%',
                  }}>
                  <KeyboardArrowRight style={{ fill: '#359DB6' }} />
                  <Typography style={{ color: '#C9ADA7', paddingLeft: '2%' }}>
                    {text?.text}{' '}
                  </Typography>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
        <Grid item container className={classes.ThirdGrid}>
          <Grid
            item
            xs={12}
            sm={3}
            style={{
              textAlign: matches ? 'center' : 'right',
              paddingTop: '8%',
            }}>
            <img src={lastGridImg1} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography className={classes.maintextLast}>
              What We are<text style={{ color: '#359DB6' }}> In Short </text>{' '}
            </Typography>
            <Typography className={classes.DescriptionLast}>
              All you have to do is give your kids tasks to complete, and when
              they finish, they'll earn COGs and receive recognition for their
              hard work. Let Pocket Money be your guide to teaching your kids
              about responsibility and financial management in a fun and
              engaging way!
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={3}
            style={{
              textAlign: matches ? 'center' : 'left',
              paddingTop: '8%',
            }}>
            <img src={lastGridImg2} />
          </Grid>
        </Grid>
        <Footer />
      </Grid>
    </div>
  );
};
export default LandingPage;
