import {
  Box,
  Grid,
  makeStyles,
  useTheme,
  Button,
  useMediaQuery,
  Typography,
} from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import { connect } from 'react-redux';
import * as actionCreator from '../../../store/action';
import ParentTransparent from '../../../assets/images/Parent_Transparent.gif';
import BorderButton from '../../../components/buttons/BorderButton';
import CommonModel from '../../../components/common/model/CommonModel';
import ContainerTitle from '../../../components/common/title/ContainerTitle';
import { constant } from '../../../utils/constant';
import { capitalFirstLetter } from '../../../utils/stringMethods';
import { getAgeFromDob } from '../../../utils/timeMethods';
import CreateChildModal from '../../dashboard/components/CreateChildModal';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import BaseButton from '../../../components/buttons/BaseButton';
import EditChildModal from './EditChildModal';
import EmptyGuardian from '../../../components/EmptyData/EmptyGuardian';
import DeleteChildModal from './DeleteChildModal';
import CreateGuardianModal from '../../dashboard/components/CreateGuardianModal';
import CancelIcon from '@material-ui/icons/Cancel';
import RequestList from '../../dashboard/components/RequestList';
import { maxStringLength } from '../../../utils/stringMethods';
import ApproveKycModal from './ApproveKycModal';
import DismissKyc from './DismissKyc';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

const useStyle = makeStyles(theme => ({
  container: {
    padding: '2vh',
    position: 'relative',
  },
  innerContainer: {
    padding: '2vh 2vh',
    [theme.breakpoints.down(constant.breakMobileAuthPoint)]: {
      padding: '1vh 0vh',
    },
    [theme.breakpoints.down(constant.breakSmallMobileAuthPoint)]: {
      padding: '0vh 0vh',
    },
  },
  headingContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  justifyCenter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modelTitleContainerStyle: {
    justifyContent: 'flex-start !important',
  },
  tableContainer: {
    [theme.breakpoints.down(640)]: {
      overflowX: 'scroll',
    },
  },
  pendingRequest: {
    marginTop: '2%',
    borderTop: '1px solid #CBCBCB',
  },
  tableRowContainer: {
    // fontSize: "1rem",
    padding: '1% 1%',
    borderRadius: 12,
    height: '5rem',
    alignContent: 'center',
  },
  tableHeader: {
    fontSize: '1rem',
  },
  tableDataStyle: {
    fontSize: '0.9rem',
    fontWeight: 500,
  },
  profileContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  profilePicContainer: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 5,
    borderRadius: '60px',
    width: '2.5rem',
    height: '2.5rem',
    overflow: 'hidden',
  },
  profilePic: {
    fontSize: '2.4rem',
    borderRadius: 50,
  },
  profileName: {},
  noGuardianImageContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  noGuardianImageStyle: {
    width: '40%',
  },
  titleStyle: {
    color: theme?.customTheme?.nameTextColotAtDashboard,
    marginBottom: '2vh',
  },
  deleteModalContainerStyle: {
    maxWidth: 480,
  },
  dismissModalContainerStyle: {
    maxWidth: 470,
  },
  profilePic1: {
    margin: '0 1vh 0 0vh',
    width: '100%',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
    },
  },
  addGuardianButton: {
    marginLeft: '1rem',
    fontSize: '0.8rem',
    [theme.breakpoints.down('xs')]: {
      marginLeft: '0rem',
    },
  },
  addChildButton: {
    [theme.breakpoints.down('xs')]: {
      marginBottom: '0.4rem',
    },
  },
  image2: {
    width: 57,
    height: 40,
    '&:hover': {
      // boxShadow: '0px 4px 6px -2px rgba(66 193 104)',
      // borderRadius: 9,
      background: 'none',
    },
    [theme.breakpoints.up(constant.breakLaptopAuthPoint)]: {
      width: 57,
      height: 30,
    },
    [theme.breakpoints.down(constant.breakMobileAuthPoint)]: {
      width: 57,
      height: 30,
    },
    [theme.breakpoints.down(constant.breakSmallMobileAuthPoint)]: {
      width: 57,
      height: 30,
    },
    [theme.breakpoints.down(580)]: {
      width: 22,
      height: 30,
    },
  },
  kyc: {
    color: '#359DB6',
    fontSize: '0.8rem',
    marginTop: '0%',
    backgroundColor: '#AAE0ED',
    border: '1px solid #359DB6',
    borderRadius: '50px',
    height: 30,
    width: 115,
    padding: 12,
    '&:hover': {
      backgroundColor: '#359DB6',
      color: '#AAE0ED',
      border: '2px solid #AAE0ED',
      borderRadius: '50px',
      background: 'none',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.6rem',
      width: 90,
      padding: 10,
      height: 20,
    },
  },
  dismissKyc: {
    color: '#c24632',
    fontSize: '0.8rem',
    marginTop: '0%',
    backgroundColor: '#AAE0ED',
    border: '1px solid #c24632',
    borderRadius: '50px',
    height: 30,
    width: 115,
    padding: 12,
    '&:hover': {
      backgroundColor: '#97c6d1',
      color: '#c24632',
      border: '2px solid #c24632',
      borderRadius: '50px',
      background: 'none',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.6rem',
      width: 90,
      padding: 10,
      height: 20,
    },
  },
  kycTitle: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.8rem',
    },
  },
  requestTitleContainer: {
    marginBottom: '2%',
  },
  loader: {
    width: '30%',
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      width: '50%',
    },
    [theme.breakpoints.down('md')]: {
      width: '30%',
    },
  },
}));

const ChildrenProfilePage = props => {
  const theme = useTheme();
  const classes = useStyle(theme);

  const {
    childList,
    getChildList,
    profile,
    updateChildRequest,
    getProfile,
    loadings,
    sentRequest,
  } = props;
  const [pendingChild, setPendingChild] = React.useState(null);

  const [addChild, setAddChild] = React.useState(false);
  const [addGuardian, setAddGuardian] = React.useState(false);
  const [editChild, setEditChild] = React.useState();
  const [deleteChild, setDeleteChild] = React.useState();
  const [approveKyc, setApproveKyc] = React.useState(false);
  const [dismissKyc, setDismissKyc] = React.useState(false);
  const [expand, setexpand] = React.useState(false);

  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  const handleAddChildModal = () => {
    setAddChild(!addChild);
  };

  const handleApproveKycModal = data => {
    setApproveKyc(data ? data : false);
  };
  const handleDismissKyc = data => {
    setDismissKyc(data ? data : false);
  };

  const handleAddGuardianModal = () => {
    setAddGuardian(!addGuardian);
  };

  const profileDetail = data => {
    return (
      <Box className={clsx(classes.profileContainer)}>
        <Box
          className={clsx(classes.profilePicContainer)}
          style={{ paddingLeft: '1%' }}>
          {data.profile_pic ? (
            <img
              src={`${process.env.REACT_APP_SPACE_KEY}/${data?.profile_pic}`}
              alt="profile"
              className={clsx(classes.profilePic1)}
            />
          ) : (
            <AccountCircleIcon className={clsx(classes.profilePic)} />
          )}
        </Box>
        <Box className={clsx(classes.profileName)}>
          {data.firstName + ' ' + data.lastName}
        </Box>
      </Box>
    );
  };

  const handleEditChild = data => {
    setEditChild(editChild ? null : data);
  };

  const handleDeleteChild = data => {
    setDeleteChild(deleteChild ? null : data);
  };

  React.useEffect(() => {
    setPendingChild(
      profile?.family?.filter(member => {
        let match = sentRequest.filter(child => child._id == member._id);
        return match.length;
      }),
    );
  }, [sentRequest]);

  React.useEffect(() => {
    getChildList();
  }, [profile]);

  const handleCancel = val => {
    let data = {
      from: profile?._id,
      to: val?._id,
      status: 'Cancelled',
      isMinor: true,
    };
    updateChildRequest(data);
    getProfile();
  };

  return (
    <>
      <CommonModel
        title="Add Child"
        titleContainerStyle={classes.modelTitleContainerStyle}
        open={addChild}
        handleClose={handleAddChildModal}
        body={
          <CreateChildModal open={addChild} handleClose={handleAddChildModal} />
        }
      />
      <CommonModel
        title="Add Parent"
        titleContainerStyle={classes.modelTitleContainerStyle}
        open={addGuardian}
        handleClose={handleAddGuardianModal}
        body={
          <CreateGuardianModal
            open={addGuardian}
            handleClose={handleAddGuardianModal}
          />
        }
      />
      <CommonModel
        title="Approve KYC"
        titleContainerStyle={classes.modelTitleContainerStyle}
        open={approveKyc}
        handleClose={() => handleApproveKycModal()}
        body={
          <ApproveKycModal
            open={approveKyc}
            handleClose={() => handleApproveKycModal()}
          />
        }
      />
      <CommonModel
        title="Edit Child"
        titleContainerStyle={classes.modelTitleContainerStyle}
        open={editChild}
        handleClose={handleEditChild}
        body={<EditChildModal open={editChild} handleClose={handleEditChild} />}
      />
      <CommonModel
        title={constant.deleteProfile}
        titleContainerStyle={classes.modelTitleContainerStyle}
        containerStyle={classes.deleteModalContainerStyle}
        open={deleteChild}
        handleClose={handleDeleteChild}
        body={
          <DeleteChildModal
            open={deleteChild}
            handleClose={handleDeleteChild}
          />
        }
      />
      <CommonModel
        title={constant.dismissKYC}
        titleContainerStyle={classes.modelTitleContainerStyle}
        containerStyle={classes.dismissModalContainerStyle}
        open={dismissKyc}
        handleClose={() => handleDismissKyc()}
        body={
          <DismissKyc
            open={dismissKyc}
            handleClose={() => handleDismissKyc()}
          />
        }
      />
      <Box className={clsx(classes.container)}>
        <Box className={clsx(classes.innerContainer)}>
          <Box className={clsx(classes.headingContainer)}>
            <ContainerTitle title={'Kids Profile'} />
            <Box className={classes.buttonContainer}>
              <BorderButton
                className={classes.addChildButton}
                text="Add Child"
                onClick={handleAddChildModal}
                id={`add-child`}
              />
              <BorderButton
                className={classes.addGuardianButton}
                text="Add Parent"
                onClick={handleAddGuardianModal}
                id={`add-parent`}
              />
            </Box>
          </Box>

          <Box className={clsx(classes.pendingRequest)}>
            {pendingChild?.length ? (
              <>
                <Box className={clsx(classes.innerContainer)}>
                  <Box className={clsx(classes.requestTitleContainer)}>
                    <ContainerTitle title={constant.guardianSentRequest} />
                  </Box>
                  <RequestList
                    guardianRequest={pendingChild}
                    location="addChildInParent"
                    isExpand={expand}
                  />
                </Box>
                {pendingChild?.length > 2 ? (
                  <Grid style={{ textAlign: 'right' }}>
                    <Button
                      style={{ textTransform: 'none' }}
                      onClick={() => {
                        setexpand(!expand);
                      }}
                      id={`more-less-icon`}>
                      {expand ? (
                        <Typography className={classes.viewLessButton}>
                          {' View Less'}
                          <ExpandLessIcon style={{ color: '#359DB6' }} />
                        </Typography>
                      ) : (
                        <Typography className={classes.viewLessButton}>
                          {'View More'}
                          <ExpandMoreIcon style={{ color: '#359DB6' }} />
                        </Typography>
                      )}
                    </Button>
                  </Grid>
                ) : null}
              </>
            ) : null}
          </Box>

          {!loadings ? (
            childList?.length ? (
              <Box className={'horizontal-scrollbar'}>
                <Box className={clsx(classes.tableContainer)}>
                  <Grid
                    container
                    style={{
                      minWidth: 620,
                    }}>
                    <Grid
                      item
                      container
                      xs={12}
                      className={classes.tableRowContainer}>
                      <Grid item xs={3}>
                        <ContainerTitle
                          title={'Name'}
                          className={classes.tableHeader}
                        />
                      </Grid>
                      <Grid item xs={1} className={clsx(classes.justifyCenter)}>
                        <ContainerTitle
                          title={'Age'}
                          className={classes.tableHeader}
                        />
                      </Grid>
                      <Grid item xs={2} className={clsx(classes.justifyCenter)}>
                        <ContainerTitle
                          title={'Relation'}
                          className={classes.tableHeader}
                        />
                      </Grid>
                      <Grid item xs={3} className={clsx(classes.justifyCenter)}>
                        <ContainerTitle
                          title={'Email'}
                          className={classes.tableHeader}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={1}
                        sm={2}
                        className={clsx(
                          classes.justifyCenter,
                          classes.kycTitle,
                        )}>
                        <ContainerTitle
                          title={'KYC Status'}
                          className={classes.tableHeader}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={2}
                        sm={1}
                        className={clsx(classes.justifyCenter)}>
                        <ContainerTitle
                          title={'Actions'}
                          className={classes.tableHeader}
                        />
                      </Grid>
                    </Grid>
                    {childList?.length
                      ? childList?.map((data, index) => {
                          let relation = '';
                          data?.family?.map(familyData => {
                            if (familyData._id == profile._id) {
                              relation = familyData.yourRelation;
                            }
                          });
                          let age = getAgeFromDob(data.dateOfBirth);
                          return (
                            <Grid
                              item
                              container
                              xs={12}
                              className={clsx(classes.tableRowContainer)}
                              style={{
                                backgroundColor:
                                  index % 2 ? 'transparent' : '#C9E5EC',
                              }}
                              key={data._id}>
                              <Grid item xs={3}>
                                <ContainerTitle
                                  title={profileDetail(data)}
                                  className={classes.tableDataStyle}
                                />
                              </Grid>
                              <Grid
                                item
                                xs={1}
                                className={clsx(classes.justifyCenter)}>
                                <ContainerTitle
                                  title={getAgeFromDob(data.dateOfBirth)}
                                  className={classes.tableDataStyle}
                                />
                              </Grid>
                              <Grid
                                item
                                xs={2}
                                className={clsx(classes.justifyCenter)}>
                                <ContainerTitle
                                  title={capitalFirstLetter(relation)}
                                  className={classes.tableDataStyle}
                                />
                              </Grid>
                              <Grid
                                item
                                xs={3}
                                className={clsx(classes.justifyCenter)}>
                                  <ContainerTitle
                                    title={maxStringLength(data?.email || "", matches ? 14 :30)}
                                    className={classes.tableDataStyle}
                                  />
                               
                              </Grid>
                              {age >= 16 ? null : (
                                <>
                                  <Grid
                                    item
                                    xs={2}
                                    className={clsx(classes.justifyCenter)}>
                                    {data?.kyc?.status ? (
                                      <Typography
                                        style={{
                                          fontSize: '0.8rem',
                                          color: '#359DB6',
                                          fontWeight: 'bold',
                                        }}>
                                        KYC Approved
                                      </Typography>
                                    ) : data?.parentApprovedKYCStatus
                                        ?.kycStatus ? (
                                      <>
                                        <Button
                                          className={classes.dismissKyc}
                                          onClick={() => handleDismissKyc(data)}
                                          id={`dismiss-kyc`}>
                                          Dismiss KYC
                                        </Button>
                                      </>
                                    ) : (
                                      <Button
                                        className={classes.kyc}
                                        onClick={() =>
                                          handleApproveKycModal(data)
                                        }
                                        id={`approve-kyc`}>
                                        Approve KYC
                                      </Button>
                                    )}
                                  </Grid>
                                  <Grid
                                    item
                                    xs={1}
                                    className={clsx(classes.justifyCenter)}>
                                    <BaseButton
                                      text={<EditIcon />}
                                      onClick={() => handleEditChild(data)}
                                      id={`edit-action-child`}
                                    />
                                    <BaseButton
                                      text={<DeleteIcon />}
                                      onClick={() => handleDeleteChild(data)}
                                      id={`delete-child`}
                                    />
                                  </Grid>
                                </>
                              )}
                            </Grid>
                          );
                        })
                      : null}
                  </Grid>
                </Box>
              </Box>
            ) : (
              <Box className={classes.noGuardianImageContainer}>
                <EmptyGuardian
                  imageClass={classes.noGuardianImageStyle}
                  text={
                    <ContainerTitle
                      title={'Add a child to get started!'}
                      className={clsx(classes.titleStyle)}
                    />
                  }
                />
              </Box>
            )
          ) : (
            <Grid container justifyContent="center">
              <img className={classes.loader} src={ParentTransparent} />
            </Grid>
          )}
        </Box>
      </Box>
    </>
  );
};

const mapStateToProps = ({ userReducer, authReducer }) => {
  return {
    profile: userReducer.profile,
    childList: userReducer.childList,
    sentRequest: userReducer.sentRequest,
    loadings: userReducer.loadings.getKids,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getChildList: () => {
      dispatch(actionCreator.getChildList());
    },
    updateChildRequest: data =>
      dispatch(actionCreator.updateChildRequest(data)),
    getProfile: () => dispatch(actionCreator.getProfile()),
    approveKyc: data => dispatch(actionCreator.approveKyc(data)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ChildrenProfilePage);
