import React from 'react';
import {
  Box,
  Grid,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import Cog from '../../assets/images/reward/cogCoin.svg';
import CalenderBlack from '../../assets/images/reward/calendar.svg';
import moment from 'moment';
import { connect } from 'react-redux';
import CommonModel from '../../components/common/model/CommonModel';
import { constant } from '../../utils/constant';
import RewardsHistory from './RewardsHistory';
import { priceFormatter } from '../../utils/methods';
import { AccountCircleRounded } from '@material-ui/icons';
import { maxStringLength } from '../../utils/stringMethods';
const useStyle = makeStyles(theme => ({
  root: {
    backgroundColor: '#eef7fa',
  },
  rewardBox: {
    width: '10vw',
    // height: '18vh',
    display: 'flex',
    alignItems: 'end',
    border: '2px solid #359DB6',
    backgroundColor: '#C9E5EC',
    borderRadius: '10px',
    paddingLeft: '10%',
    paddingTop: '5%',
    webkitBoxShadow: '-2px 4px 18px -2px rgba(0,0,0,0.15)',
    mozBoxShadow: '-2px 4px 18px -2px rgba(0,0,0,0.15)',
    boxShadow: '-2px 4px 18px -2px rgba(0,0,0,0.25)',
    [theme.breakpoints.down('xs')]: {
      width: '25vw',
    },
    [theme.breakpoints.between('sm', 'md')]: {
      width: '12vw',
    },
  },
  rewardPoints: {
    fontSize: '1.2rem',
    color: '#6F6F6F',
    fontWeight: 'bolder',
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.9rem',
    },
  },
  taskTitle: {
    fontSize: '0.8rem',
    color: '#6F6F6F',
    fontWeight: '400',
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.6rem',
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      fontSize: '0.7rem',
    },
  },
  CalenderIcon: {
    width: '12%',
    paddingRight: '3%',
    [theme.breakpoints.between('xs', 'sm')]: {
      width: '15%',
    },
  },
  dueDate: {
    fontSize: '0.58rem',
    color: '#6F6F6F',
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.4rem',
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      fontSize: '0.37rem',
    },
  },
  profileImage: {
    paddingLeft: '3%',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '0%',
    },
  },
  cogIcon: {
    width: '75%',
  },
  rewardsHistoryContainer: {
    maxWidth: '400px',
  },
}));
const RewardComponent = props => {
  const { rewardData, index, profile, childList } = props;
  const theme = useTheme();
  const classes = useStyle(theme);
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const [childData, setChildData] = React.useState();
  const [rewardsHistory, setRewardsHistory] = React.useState();
  React.useEffect(() => {
    childList?.map(child => {
      if (child?._id == rewardData?.childId) {
        setChildData(child);
      }
    });
  }, [rewardData, childList]);

  const handleRewardsHistoryModal = () => {
    setRewardsHistory(data => (data ? null : rewardData));
  };

  return (
    <>
      <CommonModel
        title={'Rewards'}
        open={rewardsHistory}
        handleClose={handleRewardsHistoryModal}
        body={<RewardsHistory rewardData={rewardsHistory} child={childData} />}
        containerStyle={classes.rewardsHistoryContainer}
      />
      <Grid
        container
        className={classes.rewardBox}
        onClick={handleRewardsHistoryModal}>
        <Grid item xs={3} sm={4} md={3}>
          <img src={Cog} className={classes.cogIcon} />
        </Grid>
        <Grid item>
          <Typography className={classes.rewardPoints}>
            {priceFormatter(rewardData?.total_reward || 0)}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <Typography className={classes.taskTitle}>
            {maxStringLength(rewardData?.taskTitle || '', 12)}
          </Typography>
        </Grid>

        <Grid item container style={{ marginTop: '18%', marginBottom: '6%' }}>
          <Grid item xs={8} md={8} sm={8}>
            <img src={CalenderBlack} className={classes.CalenderIcon} />
            <text className={classes.dueDate}>
              {rewardData?.frequency == 'once'
                ? moment(rewardData?.taskHistory[0].taskTimeStamp).format(
                    'MMM DD, yyyy',
                  )
                : moment(rewardData?.startDate).format('MMM DD, yyyy')}
            </text>
          </Grid>
          <Grid item xs={4} md={4} sm={4} className={classes.profileImage}>
            {childData?.profile_pic ? (
              <img
                src={`${process.env.REACT_APP_SPACE_KEY}/${childData?.profile_pic}`}
                style={{
                  borderRadius: '50%',
                  width: '90%',
                }}
              />
            ) : null}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

const mapStateToProps = ({ userReducer, authReducer }) => {
  return {
    profile: userReducer.profile,
    childList: userReducer.childList,
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(RewardComponent);
