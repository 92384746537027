import React from 'react';
import {
  Grid,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import Cog from '../../assets/images/reward/COG.png';
import CalenderBlack from '../../assets/images/reward/CalenderBlack.png';
import CalenderWhite from '../../assets/images/reward/CalenderWhite.png';
import Women2 from '../../assets/images/reward/Women2.png';
const useStyle = makeStyles(theme => ({
  root: {
    //   backgroundColor: "#eef7fa",
  },
}));
const RewardComponent2 = () => {
  const theme = useTheme();
  const classes = useStyle(theme);
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <>
      {!matches ? (
        <Grid
          container
          style={{
            width: '140px',
            height: '140px',
            border: '2px solid #083750',
            backgroundColor: '#1B5563',
            borderRadius: '10px',
            webkitBoxShadow: '-2px 4px 18px -2px rgba(0,0,0,0.75)',
            mozBoxShadow: '-2px 4px 18px -2px rgba(0,0,0,0.75)',
            boxShadow: '-2px 4px 18px -2px rgba(0,0,0,0.75)',
          }}>
          <Grid
            item
            xs={4}
            style={{
              paddingTop: '8px',
              paddingLeft: '6px',
            }}>
            <img src={Cog} />
          </Grid>
          <Grid item xs={1}>
            <Typography
              style={{
                fontSize: '1.4rem',
                color: '#fff',
                fontWeight: 'bold',
                paddingTop: '8px',
              }}>
              20
            </Typography>
          </Grid>
          <Typography
            style={{
              fontSize: '1rem',
              color: '#fff',
              fontWeight: '600',
              paddingLeft: '10px',
              paddingBottom: '20px',
            }}>
            Mow the lawn
          </Typography>
          <Grid container>
            <Grid item>
              <img
                src={CalenderWhite}
                style={{
                  height: '15px',
                  width: '15px',
                  paddingLeft: '8px',
                  paddingTop: '8px',
                  paddingRight: '5px',
                }}
              />
              <text
                style={{
                  fontSize: '0.7rem',
                  color: '#fff',
                  paddingTop: '20px',
                }}>
                Nov 08, 2022
              </text>
            </Grid>
            <Grid item style={{ paddingLeft: '10px' }}>
              <img src={Women2} />
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Grid
          container
          style={{
            width: '102px',
            height: '102px',
            border: '2px solid #083750',
            backgroundColor: '#1B5563',
            borderRadius: '10px',
            webkitBoxShadow: '-2px 4px 18px -2px rgba(0,0,0,0.75)',
            mozBoxShadow: '-2px 4px 18px -2px rgba(0,0,0,0.75)',
            boxShadow: '-2px 4px 18px -2px rgba(0,0,0,0.75)',
          }}>
          <Grid
            item
            xs={4}
            style={{
              paddingTop: '8px',
              paddingLeft: '6px',
            }}>
            <img src={Cog} style={{ width: '30px', height: '30px' }} />
          </Grid>
          <Grid item xs={1}>
            <Typography
              style={{
                fontSize: '1.1rem',
                color: '#fff',
                fontWeight: 'bold',
                paddingTop: '8px',
                paddingLeft: '8px',
              }}>
              200
            </Typography>
          </Grid>
          <Typography
            style={{
              fontSize: '0.7rem',
              color: '#fff',
              fontWeight: '600',
              fontWeight: '600',
              paddingLeft: '10px',
              paddingBottom: '10px',
            }}>
            Mow the lawn
          </Typography>
          <Grid container>
            <Grid item>
              <img
                src={CalenderWhite}
                style={{
                  height: '12px',
                  width: '23px',
                  paddingLeft: '8px',
                  //  paddingTop: "8px",
                  paddingRight: '5px',
                }}
              />
              <text
                style={{
                  fontSize: '0.5rem',
                  color: '#fff',
                  fontWeight: 'bold',
                  //  paddingTop: "20px",
                }}>
                Nov 08, 2022
              </text>
            </Grid>
            <Grid item style={{ paddingLeft: '5px' }}>
              <img src={Women2} style={{ width: '18px', height: '18px' }} />
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default RewardComponent2;
