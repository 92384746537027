import { connect } from 'react-redux';
import clsx from 'clsx';

import { Box, makeStyles, Typography, useTheme } from '@material-ui/core';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import MenuIcon from '@material-ui/icons/Menu';
import RedeemIcon from '@material-ui/icons/Redeem';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { constant } from '../../utils/constant';
import BaseButton from '../buttons/BaseButton';
import CogitoLogo from '../common/Logo/CogitoLogo';
import * as actionCreator from '../../store/action';
import ProfilePic from '../profilePic/ProfilePic';

const useStyles = makeStyles(theme => ({
  headerStyle: {
    height: '10vh',
    backgroundColor: theme?.customTheme?.headerBackgroundColor,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    // position: 'fixed',
    width: '100%',
    zIndex: 1,
    // [theme.breakpoints.up(constant.breakLaptopAuthPoint)]: {
    //   height: 70,
    // },
    // [theme.breakpoints.down(constant.breakMobileAuthPoint)]: {
    //   height: 80,
    //   padding: '0.5rem 0',
    // },
  },
  headerProfileComponentStyle: {
    display: 'flex',
    alignItems: 'center',
  },
  headerOption: {
    margin: 'auto 0.4rem',
    [theme.breakpoints.up(constant.breakLaptopAuthPoint)]: {
      margin: 'auto 0.6rem',
    },
  },
  profileContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  rewardContainer: {
    borderRadius: '50%',
    height: '2rem',
    width: '2rem',
    backgroundColor: '#359DB6',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.up(constant.breakLaptopAuthPoint)]: {
      height: '2.4rem',
      width: '2.4rem',
    },
    [theme.breakpoints.down(constant.breakMobileAuthPoint)]: {
      display: 'none',
    },
  },
  rewardIconStyle: {
    fontSize: '1rem',
    [theme.breakpoints.up(constant.breakLaptopAuthPoint)]: {
      fontSize: '1.5rem',
    },
  },
  profileIconStyle: {
    fontSize: '2rem',
    marginRight: '0.5rem',
    color: theme.customTheme.NoProfileImage,
    [theme.breakpoints.up(constant.breakLaptopAuthPoint)]: {
      fontSize: '2.5rem',
      marginRight: '0.7rem',
    },
  },
  profilePicStyle: {
    borderRadius: 50,
  },
  profileFontStyle: {
    fontSize: '1rem',
    [theme.breakpoints.up(constant.breakLaptopAuthPoint)]: {
      fontSize: '1.5rem',
    },
  },
  optionColor: {
    color: theme.customTheme.HeaderNameColor,
  },
  menuContainer: {
    display: 'none',
    padding: 0,
    [theme.breakpoints.down(constant.breakMobileAuthPoint)]: {
      display: 'flex',
      padding: '0 0.7rem',
    },
  },
  cogitoLogoStyle: {
    width: '2rem',
    marginLeft: '0.7rem',
  },
  hamburgerButtonStyle: {},
  hamburgerIconStyle: {
    fontSize: '2rem',
  },
}));

const Header = props => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { handleHanburger, profile } = props;
  return (
    <Box className={classes.headerStyle}>
      <Box>
        <Box className={clsx(classes.menuContainer)}>
          <BaseButton
            onClick={handleHanburger}
            text={<MenuIcon className={clsx(classes.hamburgerIconStyle)} />}
            className={clsx(classes.hamburgerButtonStyle)}
          />
          <CogitoLogo className={classes.cogitoLogoStyle} />
        </Box>
      </Box>
      <Box className={classes.headerProfileComponentStyle}>
        {/* <Box className={classes.headerOption}>
          <Box className={classes.rewardContainer}>
            <RedeemIcon
              className={clsx(classes.rewardIconStyle, classes.optionColor)}
            />
          </Box>
        </Box> */}
        <Box className={classes.headerOption}>
          <Box className={classes.profileContainer}>
            {profile?.profile_pic ? (
              <ProfilePic
                pic={`${process.env.REACT_APP_SPACE_KEY}/${profile?.profile_pic}`}
                className={classes.profilePicStyle}
              />
            ) : (
              <AccountCircleIcon className={clsx(classes.profileIconStyle)} />
            )}
            <Box
              className={clsx(
                classes.profileContainer,
                //  "hoverPointer"
              )}>
              <Typography
                className={clsx(classes.profileFontStyle, classes.optionColor)}>
                {(profile?.firstName || '') + ' ' + (profile?.lastName || '')}
              </Typography>
              {/* <ExpandMoreIcon
        className={clsx(classes.rewardIconStyle, classes.optionColor)}
       /> */}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const mapStateToProps = ({ userReducer }) => {
  return {
    profile: userReducer.profile,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateChildRequest: data =>
      dispatch(actionCreator.updateChildRequest(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
