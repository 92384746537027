import React from 'react';
import { Box, Grid, makeStyles, useTheme } from '@material-ui/core';
import moment from 'moment';
import { connect } from 'react-redux';
import clsx from 'clsx';
import ContainerTitle from '../../components/common/title/ContainerTitle';
import { constant } from '../../utils/constant';
const useStyle = makeStyles(theme => ({
  container: {
    width: '100%',
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  titleStyle: {
    fontSize: '1rem',
  },
  overviewContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  childProfile: {
    display: 'flex',
    alignItems: 'center',
  },
  totalRewardStyle: {
    fontSize: '0.9rem',
  },
  historyContainer: {},
  historyContent: {
    backgroundColor: '#C5E2E2',
    display: 'flex',
    justifyContent: 'space-between',
    padding: 8,
    borderRadius: 12,
    margin: '5px 0 0 0',
  },
}));
const RewardsHistory = props => {
  const { rewardData, child } = props;
  const theme = useTheme();
  const classes = useStyle(theme);

  const historyFiltered = rewardData.taskHistory?.filter(
    data => data.status === constant.parentApprovedStatus,
  );

  return (
    <>
      <Box className={clsx(classes.container)}>
        <Box className={classes.titleContainer}>
          <ContainerTitle
            title={rewardData.taskTitle}
            className={clsx(classes.titleStyle)}
          />
        </Box>
        <Box className={classes.overviewContainer}>
          <Box className={classes.childProfile}>
            {child?.profile_pic ? (
              <img
                src={`${process.env.REACT_APP_SPACE_KEY}/${child?.profile_pic}`}
                style={{
                  borderRadius: '50%',
                  width: 30,
                  height: 30,
                  marginRight: 5,
                }}
              />
            ) : null}
            <ContainerTitle
              title={child?.firstName || 'NA'}
              className={clsx(classes.totalRewardStyle)}
            />
          </Box>
          <Box>
            <ContainerTitle
              title={`Total Rewards: ${rewardData?.total_reward || 0}`}
              className={clsx(classes.totalRewardStyle)}
            />
          </Box>
        </Box>
        <Box className={classes.historyContainer}>
          <Grid container spacing={1}>
            {historyFiltered?.map(data => {
              return (
                <Grid item xs={12}>
                  <Box className={classes.historyContent}>
                    <ContainerTitle
                      title={moment(data.taskTimeStamp).format(
                        'DD-MM-YYYY HH:mm',
                      )}
                      className={clsx(classes.totalRewardStyle)}
                    />
                    <ContainerTitle
                      title={`${constant.parentApproved}`}
                      className={clsx(classes.totalRewardStyle)}
                    />
                  </Box>
                </Grid>
              );
            })}
          </Grid>
        </Box>
      </Box>
    </>
  );
};

const mapStateToProps = ({ userReducer, authReducer }) => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(RewardsHistory);
