import { Box, makeStyles, Typography, useTheme } from '@material-ui/core';
import clsx from 'clsx';
import { connect } from 'react-redux';
import * as actionCreator from '../../../store/action';

const useStyle = makeStyles(theme => ({
  container: {},
  textStyle: {
    // color: '#333333',
    fontWeight: 600,
    fontSize: '1.3rem',
  },
}));
const ContainerTitle = props => {
  const { title, style, className } = props;
  const theme = useTheme();
  const classes = useStyle(theme);
  return (
    <Box className={clsx(classes.container)}>
      <Typography
        style={{ ...style }}
        className={clsx(className, classes.textStyle)}>
        {title}
      </Typography>
    </Box>
  );
};
ContainerTitle.defaultProps = {
  style: {},
  className: '',
};

const mapStateToProps = ({}) => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(ContainerTitle);
