import { makeStyles, useTheme } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  image1Style: {
    width: '100vw',
    right: 0,
    bottom: 0,
    position: 'fixed',
    [theme.breakpoints.down(600)]: {
      width: '150vw',
    },
    [theme.breakpoints.down(450)]: {
      width: '200vw',
    },
  },
  image2Style: {
    width: '100vw',
    // height: "100vh",
    right: 0,
    top: 0,
    position: 'fixed',
    [theme.breakpoints.down(600)]: {
      height: '100vh',
      width: 'unset',
    },
  },
}));
const PageBackgroundTheme1 = () => {
  const theme = useTheme();
  const classes = useStyles(theme);
  return (
    <>
      <img
        src={theme?.customTheme?.pageBackgroundImage1}
        className={clsx(classes.image2Style)}
      />
      <img
        src={theme?.customTheme?.pageBackgroundImage2}
        className={clsx(classes.image1Style)}
      />
    </>
  );
};

export default PageBackgroundTheme1;
