import { Box, makeStyles, useTheme } from '@material-ui/core';
import clsx from 'clsx';
import { connect } from 'react-redux';
import * as actionCreator from '../../../store/action';
import { constant } from '../../../utils/constant';
import ContainerTitle from '../../../components/common/title/ContainerTitle';
import KidProfileContainer from './KidProfileContainer';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import React from 'react';
import CommonModel from '../../../components/common/model/CommonModel';
import CreateChildModal from './CreateChildModal';
import EmptyGuardian from '../../../components/EmptyData/EmptyGuardian';

const useStyle = makeStyles(theme => ({
  container: {
    padding: '0rem 0rem',
    borderRadius: 8,
    backgroundColor: '#359DB6',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
    maxWidth: '50vw',
    [theme.breakpoints.down(constant.breakMobileAuthPoint)]: {
      maxWidth: '100%',
    },
  },
  innerContainer: {
    // display: "flex",
    justifyContent: 'start',
    alignItems: 'center',
    width: '100%',
    flexWrap: 'wrap',
  },
  innerContainerNoChild: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  childContainer: {
    // display: "flex",
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0.5rem 0.5rem',
  },
  titleStyle: {
    color: '#359DB6',
    marginBottom: '1rem',
  },
  addChildContainer: {
    border: '0.2px dotted #6F6F6F',
    padding: 3,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    top: '-10px',
    borderRadius: 12,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  addChildContainerNoKids: {
    border: '0.2px dotted #6F6F6F',
    padding: 3,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    top: '-10px',
    margin: '0 15px',
    borderRadius: 12,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  personAddIconStyle: {
    fontSize: '3rem',
    border: '0.2px solid #6F6F6F',
    color: '#6F6F6F',
    borderRadius: 12,
    [theme.breakpoints.down(constant.breakMobileAuthPoint)]: {
      fontSize: '2.5rem',
    },
    [theme.breakpoints.down(constant.breakSmallMobileAuthPoint)]: {
      fontSize: '2rem',
    },
  },
  modelTitleContainerStyle: {
    justifyContent: 'flex-start !important',
  },
  noGuardianImageStyle: {
    width: 120,
  },
}));
const KidsContainer = props => {
  const { childList } = props;
  const theme = useTheme();
  const classes = useStyle(theme);
  const [addChild, setAddChild] = React.useState(false);
  const handleAddChildModal = () => {
    setAddChild(!addChild);
  };
  return (
    <Box className={clsx(classes.container)}>
      <CommonModel
        title={constant.createProfile}
        titleContainerStyle={classes.modelTitleContainerStyle}
        open={addChild}
        handleClose={handleAddChildModal}
        body={
          <CreateChildModal open={addChild} handleClose={handleAddChildModal} />
        }
      />
      {/* <ContainerTitle
    title={constant.kidsProfile}
    className={clsx(classes.titleStyle)}
   /> */}
      {childList?.length ? (
        <Box className={clsx(classes.innerContainer)}>
          {childList?.length
            ? childList.map(child => (
                <Box className={clsx(classes.childContainer)} key={child._id}>
                  <KidProfileContainer kidData={child} />
                </Box>
              ))
            : null}
          {/* <Box
      className={clsx(classes.addChildContainer)}
      onClick={handleAddChildModal}
     >
      <PersonAddIcon className={clsx(classes.personAddIconStyle)} />
     </Box> */}
        </Box>
      ) : (
        <Box className={clsx(classes.innerContainerNoChild)}>
          <EmptyGuardian
            imageClass={classes.noGuardianImageStyle}
            text={
              <ContainerTitle
                title={'Kids not found!'}
                className={clsx(classes.titleStyle)}
              />
            }
          />
          {/* <Box
      className={clsx(classes.addChildContainerNoKids)}
      onClick={handleAddChildModal}
     >
      <PersonAddIcon className={clsx(classes.personAddIconStyle)} />
     </Box> */}
        </Box>
      )}
    </Box>
  );
};

const mapStateToProps = ({ userReducer }) => {
  return {
    childList: userReducer.childList,
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(KidsContainer);
