import {
  Container,
  Divider,
  Grid,
  Typography,
  makeStyles,
  useTheme,
} from '@material-ui/core';
import React from 'react';
import Header from '../../../../components/common/header/Header';
import curveImg from '../../../../assets/images/curveCareer.svg';
import dots from '../../../../assets/images/icon1.svg';
import mainImg from '../../../../assets/images/OurStory/FirstImg.svg';
import secondImg from '../../../../assets/images/OurStory/SecondImg.svg';
import lastImg from '../../../../assets/images/OurStory/lastImg.svg';
import Footer from '../../../../components/common/footer/Footer';
const useStyles = makeStyles(theme => ({
  GridContainer: {
    display: 'grid',
    background: '#eef7fa',
  },
  innerContainer: {
    borderRadius: '15px',
    width: '70%',
    display: 'flex',
    justifyContent: 'center',
    margin: '10% 8% 10% 13%',
    backgroundColor: '#fff',
    [theme.breakpoints.down('sm')]: {
      margin: '20% 15%',
    },
    [theme.breakpoints.down('xs')]: {
      width: '90%',
      margin: '40% 4%',
    },
  },
  imgItem: {
    textAlign: 'right',
  },
  curveImage: {
    width: '90px',
  },

  title: {
    textAlign: 'center',
    fontSize: '40px',
    fontWeight: 500,
    fontFamily: 'poppins',
    [theme.breakpoints.down('xs')]: {
      fontSize: '30px',
    },
  },
  text: {
    padding: '4% ',
    lineHeight: '38px',
    fontFamily: 'poppins',
    fontWeight: 200,
    textAlign: 'center',
    color: '#6F6F6F',
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.8rem',
      lineHeight: '28px',
    },
  },
  Dots: {
    position: 'relative',
    left: -115,
    top: -390,
    width: '12%',
    [theme.breakpoints.only('sm')]: {
      left: -77,
      top: -140,
      width: '15%',
    },
    [theme.breakpoints.down('xs')]: {
      left: -47,
      top: -140,
      width: '20%',
    },
  },
  firstImage: {
    width: '70%',
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      width: '80%',
    },
  },
  text2: {
    fontWeight: '600',
    padding: '8% 2%',
    lineHeight: '28px',
    fontFamily: 'poppins',
    textAlign: 'center',
    fontSize: '1rem',
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.8rem',
      lineHeight: '28px',
    },
  },
  lastImg: {
    textAlign: 'right',
    paddingBottom: '8%',
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
    },
  },

  Dots2: {
    position: 'relative',
    right: -745,
    width: '12%',
    top: -100,
    [theme.breakpoints.only('sm')]: {
      right: -468,
      top: -70,
      width: '15%',
    },
    [theme.breakpoints.down('xs')]: {
      right: -287,
      top: -50,
      width: '20%',
    },
  },
  itemContainer: {
    padding: '0% 8% 0%',
  },
  itemContainer2: {
    padding: '6% 8% 0%',
  },
  thirdContainer: {
    display: 'flex',
    flexDirection: 'row',
    paddingRight: '8%',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column-reverse',
      padding: '8%',
    },
  },
  secondImg: {
    width: '120%',
    [theme.breakpoints.down('xs')]: {
      width: '90%',
    },
  },

  Container: {
    borderRadius: '15px',
    width: '70%',
    display: 'flex',
    justifyContent: 'center',
    // margin: '10% 8%',
    backgroundColor: '#fff',
  },
}));
const OurStory = () => {
  const classes = useStyles();
  const theme = useTheme();

  const data = [
    { id: '1', text: 'Build wealth' },
    { id: '2', text: 'Access interest free finance' },
    { id: '3', text: 'Establish companies' },
    { id: '4', text: 'Develop asset protection trusts' },
    { id: '5', text: 'Benefit from simple and low tax rates' },
    { id: '6', text: 'Gain financial education' },
  ];
  return (
    <div className={classes.GridContainer}>
      <Header />
      <Container>
        <Grid item container className={classes.innerContainer}>
          <Grid item xs={12} className={classes.imgItem}>
            <img src={curveImg} className={classes.curveImage} />
          </Grid>
          <Grid item Container className={classes.itemContainer}>
            <Grid item xs={12}>
              <Typography className={classes.title}>
                {'Our'} <text style={{ color: '#359DB6' }}>Story</text>
              </Typography>
              <Typography className={classes.text}>
                The inception of Pocket Money took place at the working grounds
                of our foundation project- the Cogito Metaverse. The Cogito
                Metaverse is a virtual world that uses advanced technology to
                provide its members a digital space where they can build wealth,
                run businesses and support each other as a community.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <img src={dots} className={classes.Dots} />
              <img src={mainImg} className={classes.firstImage} />
            </Grid>
            <Grid item xs={12}>
              <Typography className={classes.text}>
                Cogito Metaverse is open for membership to people from all walks
                of life, there are no age and geographical restrictions. Our aim
                is to help each Cogito Metaverse member, the e-residents, in
                having the ability to- build wealth, establish companies,
                support each other and do no interest trade and finance. We
                offer fair and low tax rates and investing opportunities within
                the Metaverse that make us different in the ways a traditional
                bank or investment company works. All members have the ability
                to establish companies and asset protection trusts in the Cogito
                Metaverse, which gives them the ability to build wealth in a new
                way.
              </Typography>
            </Grid>
          </Grid>
          <Divider orientation="horizontal" variant="middle" />
          <Grid item container className={classes.itemContainer}>
            <Grid item xs={12} className={classes.text2}>
              In Cogito Metaverse, we have revolutionised the way finance is
              managed in the digital world. Here, citizens have the ability to:
            </Grid>
            <Grid className={classes.thirdContainer}>
              <Grid item xs={12} sm={6}>
                {data?.map((points, i) => {
                  return (
                    <ul
                      style={{
                        color: '#6F6F6F',
                        fontWeight: 'bold',
                      }}>
                      <li key={i}>{points.text}</li>
                    </ul>
                  );
                })}
              </Grid>
              <Grid item xs={12} sm={6}>
                <img src={secondImg} className={classes.secondImg} />
              </Grid>
            </Grid>
            <Grid item xs={12} className={classes.text}>
              So while Cogito Metaverse began with an intention of providing
              people a space where they can grow financially and support each
              other, Pocket Money came into being to extend that intention to
              children and families.
              <br />
              So parents or families will need to register with Cogito Metaverse
              to access the benefits of the Pocket Money App. You can register
              yourself to the
              <a
                href="https://app.principalityofcogito.com/signup"
                style={{ textDecoration: 'none' }}
                target="_blank">
                {' '}
                Cogito Metaverse
              </a>
              , today!
            </Grid>
            <img src={dots} className={classes.Dots2} />
            <Grid></Grid>
          </Grid>
          <Divider variant="middle" />
          <Grid item container className={classes.itemContainer2}>
            <Grid item xs={12} sm={8} className={classes.text}>
              The Pocket Money App will help children to be independent and
              prepare them to face the challenges of life confidently. We are
              sure that our initiative and innovation will have long lasting
              impacts on the future generations of the world.
            </Grid>
            <Grid item xs={12} sm={4} className={classes.lastImg}>
              <img src={lastImg} />
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </div>
  );
};

export default OurStory;
